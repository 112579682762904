<template>
  <DateRangePickerField
    v-bind="$attrs"
    :dateFormat="dateFormat"
    :presets="dateRangePresets"
    :timeFormat="timeFormat"
    :timezone="timezone"
  />
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import _ from 'lodash'
import DateRangePickerField from '@/components/daterange/DateRangePickerField.vue'
import { generatePresets } from '@/utils/DateRangePresets'

export default {
  inheritAttrs: false,
  components: {
    DateRangePickerField
  },
  props: {
    payClass: Object,
    allowedPresets: Array,
    showPayPeriodPresets: {
      type: Boolean,
      default: true
    },
    showFuturePresets: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState(['timezone']),
    ...mapGetters(['payPeriodSettings']),
    ...mapGetters('formatPreferences', ['dateFormat', 'timeFormat']),
    dateRangePresets () {
      const payPeriodSettings = this.payPeriodSettings(this.payClass)
      const presets = generatePresets({
        timezone: this.timezone,
        payPeriodType: payPeriodSettings.payPeriodType,
        payPeriodStarts: payPeriodSettings.payPeriodStarts,
        includeFuture: this.showFuturePresets
      })
      let allowedPresets = this.allowedPresets
        ? _.pick(presets, this.allowedPresets)
        : presets
      if (!this.showPayPeriodPresets) {
        allowedPresets = _.omit(allowedPresets, ['thisperiod', 'lastperiod'])
      }
      return Object.entries(allowedPresets)
        .map(([key, preset]) => ({
          value: key,
          label: preset.label,
          // TODO: Should we add day to end in all cases?
          factory: () => [preset.start(), preset.end().startOf('day').add(1, 'day')]
        }))
    },
  }
}
</script>
