<template>
  <vue-upload-component
    ref="upload"
    :drop="drop"
		v-bind="$attrs"
    v-model="forwardValue"
  />
</template>
<script>
import VueUploadComponent from 'vue-upload-component'

// For some reason, VueUploadComponent is setting dropActive on the whole document
// regardless of the drop element. So we need to implement our own dropActive property.
// https://github.com/lian-yue/vue-upload-component/issues/406

export default {
  name: 'FileUpload',
  inheritAttrs: false,
  components: {
    VueUploadComponent
  },
  props: [
    'modelValue',
    'drop'
  ],
  emits: ['drop-active-changed', 'update:modelValue'],
  data () {
    return {
      ref: null,
      dropElement: null,
      dragActive: false
    }
  },
	computed: {
		forwardValue: {
			get () {
				return this.modelValue
			},
			set (v) {
				this.$emit('update:modelValue', v)
			}
		},
    dropActive () {
      return Boolean(this.ref && this.ref.dropActive && this.dragActive)
    }
	},
  watch: {
    drop(newDrop, oldDrop) {
      this.onDropChange(newDrop, oldDrop)
    },
    dropActive (dropActive) {
      this.$emit('drop-active-changed', dropActive)
    }
  },
  methods: {
    onDropChange (newDrop, oldDrop) {
      if (this.dropElement) {
        this.dropElement.removeEventListener('dragenter', this.onDragEnter, false)
        this.dropElement.removeEventListener('dragleave', this.onDragLeave, false)
        this.dropElement.removeEventListener('dragend', this.onDragEnd, false)
        this.dropElement = null
      }

      if (newDrop) {
        if (typeof newDrop === 'string') {
          this.dropElement = document.querySelector(newDrop) || this.$root.$el.querySelector(newDrop)
        } else if (newDrop === true) {
          this.dropElement = this.$parent.$el
        } else {
          this.dropElement = newDrop
        }

        if (this.dropElement) {
          this.dropElement.addEventListener('dragenter', this.onDragEnter, false)
          this.dropElement.addEventListener('dragleave', this.onDragLeave, false)
          this.dropElement.addEventListener('dragend', this.onDragEnd, false)
        }
      }
    },
    onDragEnter (e) {
      e.preventDefault()
      this.dragActive = true
    },
    onDragLeave (e) {
      e.preventDefault()
      // Ignore dragleave events that are fired on elements that are contained inside the drop element.
      // https://stackoverflow.com/a/53766274/1237919
      if (!this.dropElement.contains(e.fromElement)) {
        this.dragActive = false
      }
    },
    onDrop (e) {
      e.preventDefault()
      this.dragActive = false
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.ref = this.$refs.upload
      this.onDropChange(this.drop)
    })
  }
}
</script>
