<template>
  <Spinner height="100vh">
    <template #header>
      <div class="splash-icon"></div>
    </template>
    <template #footer>
      <div class="splash-text">{{ $t('splash-text') }}</div>
    </template>
  </Spinner>
</template>

<script>
import Spinner from './Spinner.vue'

export default {
  name: 'Splashscreen',
  i18n: {
    messages: {
      en: {
        "splash-text": "Loading Application"
      }
    }
  },
  components: {
    Spinner
  }
}
</script>

<style lang="scss">

.spinner-container {
    .splash-icon {
      background-image: url("/img/logo-symbol.png");
      background-repeat: no-repeat;
      background-size: 57px;
      width: 57px;
      height: 57px;
      margin-bottom: 50px;
    }

    .splash-text {
      margin-top: 10px;
      font-size: 13px;
    }
}
</style>
