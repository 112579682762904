<template>
  <div>
    <router-view v-slot="{ Component }">
      <keep-alive :include="includeInKeepAlive">
        <component v-if="routesToChild" :is="Component" />
        <table-of-contents v-else />
      </keep-alive>
    </router-view>
</div>
</template>
<script>
import TableOfContents from './TableOfContents.vue'
import { useRouterViewMeta } from '@/composables/useRouterViewMeta'

export default {
  name: 'RouterPassThrough',
  setup () {
    const routerViewMeta = useRouterViewMeta()
    return {
      ...routerViewMeta
    }
  },
  inheritAttrs: false,
  components: {
    TableOfContents
  },
  data () {
    return {
      // Initialize to true, because an initial incorrect false
      // can cause table of contents template to have binding errors.
      routesToChild: true
    }
  },
  watch: {
    activeRouteFullPath (activeRouteFullPath) {
      if (activeRouteFullPath) this.checkIfRoutesToChild()
    }
  },
  methods: {
    checkIfRoutesToChild () {
      this.routesToChild = this.$route.matched.slice(-1)[0] !== this.matchedRoute
      // console.log(`RouterPassThrough routes to child: ${this.routesToChild}`)
    }
  },
  mounted () {
    this.checkIfRoutesToChild()
  }
}
</script>
