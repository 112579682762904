<template>
  <div>

    <form-group :validator="v$.value.type">
      <template #label>
        Type
        <help-text-icon>
          If the <code>Type</code> matches the pay mode of this worker's <code>Pay Class</code>,
          then this <code>Pay Rate</code> may be used to calculate total pay.
          <br><br>
          If set to <code>Salary</code>, then if the <code>Pay interval</code> below does not match
          the worker's <code>Pay period</code> defined in the assigned <code>Pay class</code>, then
          it will automatically be converted.
        </help-text-icon>
      </template>
      <template #default="slotProps">
        <b-form-radio-group
          v-bind="slotProps"
          v-model="value.type"
        >
          <b-form-radio value="hourly">Hourly</b-form-radio>
          <b-form-radio value="salary">Salary</b-form-radio>
        </b-form-radio-group>
      </template>
    </form-group>

    <hr/>

    <form-group
      :label="rateLabel"
      :validator="v$.value.rate"
      v-if="value.type === 'hourly'"
    >
      <template #default="slotProps">
        <b-form-input
          v-bind="slotProps"
          v-model="value.rate"
          :number="true"
          min="0"
          type="number"
        />
      </template>
    </form-group>

    <div v-else-if="value.type === 'salary'">
      <form-group
        :validator="v$.value.rate"
      >
        <template #label>
          {{ rateLabel }}
          <help-text-icon>
            The <code>Base pay</code> is the salary for the duration of the <code>Pay interval</code>
            specified below.
          </help-text-icon>
        </template>
        <template #default="slotProps">
          <b-form-input
            v-bind="slotProps"
            v-model="value.rate"
            :number="true"
            min="0"
            type="number"
          />
        </template>
      </form-group>

      <form-group :validator="v$.value.interval">
        <template #label>
          Pay interval
          <help-text-icon>
            You may choose whatever <code>Pay interval</code> that you prefer.
            If it does not match the worker's <code>Pay period</code> defined in the assigned <code>Pay class</code>,
            then we will automatically calculate the appropriate pay for the <code>Pay period</code>.
          </help-text-icon>
        </template>
        <template #default="slotProps">
          <key-multiselect
            v-bind="slotProps"
            v-model="value.interval"
            label="label"
            track-by="value"
            select-label=""
            deselect-label=""
            :options="intervalOptions">
          </key-multiselect>
        </template>
      </form-group>
    </div>

    <hr/>

    <form-group :validator="v$.value.start">
      <template #label>
        Effective date
        <help-text-icon>
          The <code>Effective date</code> is the date on which this <code>Pay Rate</code> becomes effective.
          That way, you can add a new <code>Pay Rate</code> if you decide to change the worker's pay, and still
          keep track of past Pay Rates.
          <br><br>
          Exactly one <code>Pay Rate</code> per <code>Type</code> must have an unspecified <code>Effective date</code>,
          in order that it be used as the default <code>Pay Rate</code>. Typically, the default <code>Pay Rate</code>
          will be the first <code>Pay Rate</code> that you create.
          <br><br>
          For <code>Salary</code> rates, the <code>Base pay</code> effective at the beginning of the <code>Pay period</code>
          will be used for the entire pay period. That is, we will not use multiple <code>Pay rates</code> in a single
          salary-based pay period.
        </help-text-icon>
      </template>
      <template #default="slotProps">
        <date-picker
          v-bind="slotProps"
          v-model="value.start"
        />
      </template>
    </form-group>

  </div>
</template>
<script>
import { decimal, helpers, minValue, required, requiredIf } from '@vuelidate/validators'
import { maxDecimalAccuracy } from '@/utils/validators'
import DatePicker from '@/components/DatePicker.vue'
import HelpTextIcon from '@/components/HelpTextIcon.vue'
import KeyMultiselect from '@/components/KeyMultiselect.vue'
import { useVuelidate } from '@vuelidate/core'

export default {
  name: 'PayRateForm',
  setup () {
    return { v$: useVuelidate({ $scope: false, $stopPropagation: true }) }
  },
  components: {
    DatePicker,
    HelpTextIcon,
    KeyMultiselect
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    defaultHourly: Number,
    defaultSalary: Number,
    hourlyStartDates: {
      type: Map,
      required: true
    },
    salaryStartDates: {
      type: Map,
      required: true
    }
  },
  emits: ['invalidChanged'],
  data () {
    return {
      intervalOptions: [
        { value: 'week', label: 'Week' },
        { value: 'biweek', label: 'Bi-week' },
        { value: 'semimonth', label: 'Semi-month' },
        { value: 'month', label: 'Month' },
        { value: 'year', label: 'Year' }
      ]
    }
  },
  computed: {
    idWithDefault () {
      switch (this.value.type) {
        case 'hourly': return this.defaultHourly
        case 'salary': return this.defaultSalary
        default: return null
      }
    },
    payRateStartDates () {
      switch (this.value.type) {
        case 'hourly': return this.hourlyStartDates
        case 'salary': return this.salaryStartDates
        default: return null
      }
    },
    rateLabel () {
      return this.value.type === 'hourly' ? 'Hourly wage' : 'Base pay'
    }
  },
  watch: {
    'value.type': {
      handler (newType, oldType) {
        if (newType === 'hourly') this.value.interval = 'hour'
        // Clear rate and interval when type changes, since they appear differently based on type.
        // But ignore form loading.
        if (newType && oldType) {
          if (newType !== 'hourly') this.value.interval = null
          this.value.rate = null
        }
      }
    },
    'v$.$invalid': {
      handler (invalid) {
        this.$emit('invalidChanged', invalid)
      },
      immediate: true
    },
  },
  validations () {
    return {
      value: {
        type: {
          required
        },
        rate: {
          required,
          decimal,
          maxDecimalAccuracy: maxDecimalAccuracy(3, this.rateLabel),
          minValue: minValue(0)
        },
        interval: {
          required
        },
        start: {
          // Not more than one pay rate per type may have start value blank, i.e., it's the default.
          required: helpers.withMessage('There may not be more than one Pay Rate per type that has an unspecified Effective Date, i.e., default Pay Rate.', requiredIf(() => {
            return Number.isInteger(this.idWithDefault) && this.idWithDefault !== this.value.id
          })),
          uniqueStart: helpers.withMessage('There may not be more than one Pay Rate per type that has a particular Effective Date.', value => {
            if (!value || !this.value.type) return true
            const idWithStart = this.payRateStartDates.get(value)
            return !Number.isInteger(idWithStart) || idWithStart === this.value.id
          })

          // Not requiring at least one default value. We'll only require there's not more than one default value.
          // TODO: Make sure validation works if user tries to edit a default pay rate's type.
          // notAllowed: helpers.withMessage('Exactly one Pay Rate per type must have an unspecified Effective Date in order that it be the default Pay Rate.', value => {
          //   const notAllowed = !Number.isInteger(this.idWithDefault) || this.idWithDefault === this.value.id
          //   return !value || !notAllowed
          // })
        }
      }
    }
  }
}
</script>
