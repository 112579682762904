<template>
  <div role="tablist">
    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1 basic-information" role="tab">
        <b-btn block v-b-toggle.accordion1 :variant="v$.$validationGroups.basicInformationGroup.$invalid ? 'danger' : 'dark'">
          <span class="text">Basic Information</span>
          <font-awesome-icon :icon="allTabsExpanded ? 'angle-double-up' : 'angle-double-down'" size="lg" class="toggle-all-tabs" @click.stop="toggleAllTabs" />
        </b-btn>
      </b-card-header>
      <b-collapse id="accordion1" ref="accordion1" visible :accordion="accordionId" role="tabpanel">
        <b-card-body>
          <b-container>
            <b-row>
              <b-col cols="12" sm="6">
                <form-group :validator="v$.form.name" :label="fieldLabels.name">
                  <template #default="slotProps">
                    <b-form-input
                      v-bind="slotProps"
                      v-model.trim="form.name"
                      type="text"
                      :readonly="isPersonalDevice"
                    />
                  </template>
                </form-group>
              </b-col>
              <b-col cols="12" sm="6">
                <form-group>
                  <b-form-checkbox
                    v-model="form.active"
                    :disabled="isPersonalDevice"
                  >
                    Active
                  </b-form-checkbox>
                  <div v-if="isPersonalDevice" class="personal-device-pointer">
                    <font-awesome-icon icon="hand-point-right" size="lg" />
                    PERSONAL DEVICE
                  </div>
                </form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" sm="6" v-show="!singleOrgUnit">
                <form-group :validator="v$.form.orgUnit">
                  <template #label>
                    {{ fieldLabels.orgUnit }}
                    <help-text-icon>
                      Organization units can be added or edited under <b>Settings =&gt; Organization =&gt; Organization Units</b>.
                    </help-text-icon>
                  </template>
                  <template #default="slotProps">
                    <key-multiselect
                      v-bind="slotProps"
                      v-model="form.orgUnit"
                      label="name"
                      track-by="id"
                      select-label=""
                      deselect-label=""
                      :options="sortedOrgUnits"
                      :allow-empty="false"
                      :disabled="isPersonalDevice"
                    />
                  </template>
                </form-group>
              </b-col>
            </b-row>
          </b-container>
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-btn block v-b-toggle.accordion2 :variant="v$.$validationGroups.extraInformationGroup.$invalid ? 'danger' : 'dark'">Extra Information</b-btn>
      </b-card-header>
      <b-collapse id="accordion2" ref="accordion2" :accordion="accordionId" role="tabpanel">
        <b-card-body>
          <b-container>
              <b-row>
                <b-col cols="12" sm="6" v-if="geoEnabled">
                  <form-group v-if="!form.geoOverrideToDisabled">
                    <template #label>
                      Geo Fences
                      <help-text-icon>
                        A geo fence is an optional field, used to enforce the geographical location where this user can Clock IN or OUT.
                        Geo fences may be edited under <b>Settings =&gt; Devices =&gt; Geo Fences</b>.
                      </help-text-icon>
                    </template>
                    <geo-rule-select
                      v-model="form.geoRules"
                      :id="form.id"
                      :disabled="isPersonalDevice"
                    />
                  </form-group>
                  <form-group v-if="!form.geoOverrideToDisabled">
                    <template #label>
                      Geo Provider
                      <help-text-icon>
                        In Version 2.11 of the Fareclock mobile app, a brand new geo detection system was added
                        that is more robust, uses less battery life, and improves the fake location blocking.
                        We are calling these geo improvements <code>Version 2</code> of the geo feature.
                        Version 2.11 still has the old geo system as well, which we call <code>Version 1</code>.
                        We will be automatically and gradually moving devices running version 2.11 over to
                        the <code>Version 2</code>. The <code>Automatic</code> value here means that you are
                        letting us control that switchover. If you'd like to force one version or the other,
                        you may configure that here. Note that this setting only applies to app version 2.11.
                        App versions older than 2.11 always use <code>Version 1</code>, and we may end up
                        removing <code>Version 1</code> when version 2.12 eventually comes out.
                      </help-text-icon>
                    </template>
                    <div class="flex flex-wrap gap-3">
                      <b-form-radio v-model="form.geoProvider" :value="null">Automatic</b-form-radio>
                      <b-form-radio v-model="form.geoProvider" value="default">Version 1</b-form-radio>
                      <b-form-radio v-model="form.geoProvider" value="transistorsoft">Version 2</b-form-radio>
                    </div>
                  </form-group>
                  <form-group>
                    <b-form-checkbox v-model="form.geoOverrideToDisabled">
                      Override Geo to Disabled
                      <help-text-icon>
                        If you check this option, then the geo feature will be disabled on this device.
                        This setting can be useful for devices without a GPS sensor and/or stationary mounted devices.
                      </help-text-icon>
                    </b-form-checkbox>
                  </form-group>
                  <form-group v-if="!form.geoOverrideToDisabled && eligibleForBgGeo">
                    <b-form-checkbox v-model="form.bgGeoOverrideToDisabled">
                      Override Background Geo to Disabled
                      <help-text-icon>
                        If you check this option, then the background geo feature will be disabled on this device.
                      </help-text-icon>
                    </b-form-checkbox>
                  </form-group>
                </b-col>
                <b-col cols="12" sm="6">
                  <form-group>
                    <template #label>
                      Announcement
                      <help-text-icon>
                        Announcement displays on device, either on Home screen or after worker logs in, depending on mode.
                        May also be customized for entire organization and/or each worker, either in addition to this message or by override.
                      </help-text-icon>
                    </template>
                    <b-form-textarea
                            v-model="form.announcement"
                            :rows="3"
                            :max-rows="6">
                    </b-form-textarea>
                    <b-form-radio-group v-model="form.announcementMode">
                      <b-form-radio value="home">Home screen</b-form-radio>
                      <b-form-radio value="session">Employee session</b-form-radio>
                    </b-form-radio-group>
                    <b-form-checkbox
                            v-model="form.announcementOverride"
                            :value="true"
                            :unchecked-value="false">
                      Announcement override
                    </b-form-checkbox>
                  </form-group>
                </b-col>
                <b-col cols="12" sm="6">
                  <b-form-checkbox v-model="form.notifyIfInactive">
                    Email administrators if device has not communicated with Fareclock service for a duration of:
                  </b-form-checkbox>
                  <form-group :validator="v$.form.notifyDelayMinutes">
                    <template #default="slotProps">
                      <duration-input
                        v-bind="slotProps"
                        v-model="form.notifyDelayMinutes"
                        :disabled="!form.notifyIfInactive" />
                    </template>
                  </form-group>
                  <b-form-checkbox v-model="form.notifyRepeat" v-if="form.notifyIfInactive">
                    Repeat sending notification after delay as long as device has not yet communicated
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6">
                  <form-group>
                    <b-form-checkbox v-model="form.localModeAlways" v-if="!requireOnline">
                      Local mode always
                      <help-text-icon>
                        If you enable this option, then punches on the time clock will be submitted in the background.
                        This setting can be useful for devices with a slow internet connection so that the worker need not wait until the punch is uploaded.
                      </help-text-icon>
                    </b-form-checkbox>
                  </form-group>
                  <form-group>
                    <b-form-checkbox v-model="form.enableDebug" v-if="isAppAdmin">
                      Enable debug
                    </b-form-checkbox>
                  </form-group>
                </b-col>
                <b-col cols="12" sm="6">
                  <form-group style="max-width: 15rem">
                    <template #label>
                      Language
                      <help-text-icon>
                        This is the language to use in the mobile app for this device.
                        New in version 3.0.
                      </help-text-icon>
                    </template>
                    <key-multiselect
                      v-model="form.language"
                      :options="languageOptions"
                      label="label"
                      track-by="value"
                      select-label=""
                      deselect-label=""
                    />
                  </form-group>
                </b-col>
              </b-row>
            </b-container>
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-btn block v-b-toggle.accordionSecurity :variant="v$.$validationGroups.securitySettingsGroup.$invalid ? 'danger' : 'dark'">Security Settings</b-btn>
      </b-card-header>
      <b-collapse id="accordionSecurity" ref="accordionSecurity" :accordion="accordionId" role="tabpanel">
        <b-card-body>
          <b-container>
            <b-row>
              <b-col cols="12" sm="6">
                <form-group :validator="v$.form.pin">
                  <template #label>
                    {{ fieldLabels.settingsPin }}
                    <help-text-icon>
                      This pin is used to access administrator functions on the time clock app itself.
                      <br/><br/>
                      Random Pin will generate a six-digit pin.
                    </help-text-icon>
                  </template>
                  <div class="pin-container">
                    <b-form-input v-model.trim="form.settingsPin"
                                :state="!v$.form.settingsPin.$invalid"
                                type="text"></b-form-input>
                    <b-btn variant="primary" @click="randomPin">
                      <font-awesome-icon icon="random" :rotation="180" />
                      Random Pin
                    </b-btn>
                    <br/>
                    <b-form-invalid-feedback>
                      Pin must be between 1 and 12 digits.
                    </b-form-invalid-feedback>
                  </div>
                </form-group>
              </b-col>
              <b-col cols="12" sm="12">
                  <form-group>
                    <template #label>
                      Administrator Pin Permissions
                      <help-text-icon>
                        The <code>Administrator Pin</code> will grant access to the permissions below.
                      </help-text-icon>
                    </template>
                    <b-form-checkbox-group
                      v-model="form.settingsPinPermissions"
                      ref="permissions"
                      class="checkbox-group checkbox-group-permissions"
                    >
                    <b-form-checkbox value="view_worker">
                      View workers
                      <help-text-icon>
                        If you enable this option, then the administrator pin above will allow user to access the worker list in the app.
                        If you do not check this box, then administrator will need to enter email and password to access.
                        <br/><br/>
                        <b>
                          If this device is running a version of our app older than 2.7, then enabling this option will also allow the
                          user to automatically view worker pin numbers and reports. If you want old versions of our app to have the other
                          permissions below, then you will need to go to Settings => Device Defaults, and enable
                          <code>Lenient administrator access on legacy versions</code>.
                        </b>
                      </help-text-icon>
                    </b-form-checkbox>
                    <b-form-checkbox value="add_worker" :disabled="!formSettingsPinPermissions.includes('view_worker')">
                      Add workers
                    </b-form-checkbox>
                    <b-form-checkbox value="enroll_face" :disabled="!formSettingsPinPermissions.includes('view_worker')">
                      Enroll worker faces
                    </b-form-checkbox>
                    <b-form-checkbox value="add_punch" :disabled="!formSettingsPinPermissions.includes('view_worker')">
                      Add punches
                    </b-form-checkbox>
                    <b-form-checkbox value="view_worker_pin" :disabled="!formSettingsPinPermissions.includes('view_worker')">
                      View worker pins
                    </b-form-checkbox>
                    <b-form-checkbox value="view_worker_reports" :disabled="!formSettingsPinPermissions.includes('view_worker')">
                      View worker reports
                    </b-form-checkbox>
                  </b-form-checkbox-group>
                  </form-group>
              </b-col>
            </b-row>
          </b-container>
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body class="mb-1" v-show="costingEnabled && form.orgUnit">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-btn block v-b-toggle.accordionCosting :variant="v$.$validationGroups.costingOverridesGroup.$invalid ? 'danger' : 'dark'">Costing Overrides</b-btn>
      </b-card-header>
      <b-collapse id="accordionCosting" ref="accordionCosting" :accordion="accordionId" role="tabpanel">
        <b-card-body>
          <b-container>
            <b-row>
              <b-col cols="12" sm="6">
                <p>
                  <u>Lock Costing Selection on Device</u>
                  <help-text-icon>
                    If set, the time clock will be locked to the selected job information.
                  </help-text-icon>
                </p>
                <!-- TODO: Only pass worker prop if personal device is not group mode.
                           Maybe listen to an event from DevicePersonalSettings component? -->
                <costing-selection
                  :value="form"
                  :deviceOrgUnit="form.orgUnit"
                  :worker="form.orgUser"
                  @validityChanged="costingSelectionValid = $event"
                  jobLabelAttribute="fixJobLabel"
                  jobCategoryAttribute="fixJobCategory"
                  jobAttribute="fixJob"
                  jobPhaseAttribute="fixJobPhase"
                  costCodeAttribute="fixCostCode"
                  costingIsPartialAttribute="fixCostingIsPartial"
                />
              </b-col>
            </b-row>
          </b-container>
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body class="mb-1" v-show="hasFaceFeature">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-btn block v-b-toggle.accordion3 :variant="v$.$validationGroups.faceOverridesGroup.$invalid ? 'danger' : 'dark'">Face Overrides</b-btn>
      </b-card-header>
      <b-collapse id="accordion3" ref="accordion3" :accordion="accordionId" role="tabpanel">
        <b-card-body>
          <b-container>
            <b-row>
              <b-col cols="12" sm="6">
                <b-form-checkbox
                        v-model="form.faceOverride"
                        :value="true"
                        :unchecked-value="false">
                  Override Device Defaults
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row v-if="form.faceOverride" class="face-overrides">
              <b-col cols="12">
                <face-mode-selector v-model="form.faceMode" />
                <common-face-settings
                  :value="form"
                  :faceMode="form.faceMode"
                  :enable-min-face-score="false"
                  :enable-offline-face-fail-save-punch="false"
                  @validityChanged="commonFaceSettingsValid = $event"
                />
              </b-col>
            </b-row>
          </b-container>
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body class="mb-1" v-show="scheduleEnabled">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-btn block v-b-toggle.accordionShift variant="dark">Shift Overrides</b-btn>
      </b-card-header>
      <b-collapse id="accordionShift" ref="accordionShift" :accordion="accordionId" role="tabpanel">
        <b-card-body>
          <b-container>
            <b-row>
              <b-col cols="12">
                <b-form-checkbox
                        v-model="form.enableShiftSchedule"
                        :value="true"
                        :unchecked-value="false">
                    Override Employee Shift Calendar for this Device
                    <help-text-icon>
                      If set, device's shift schedule will override the employee shift schedule.
                      This schedule is applied when the employee is not already in the middle of a shift.
                      If the employee is already in the middle of a shift, then that shift will continue.
                    </help-text-icon>
                </b-form-checkbox>
              </b-col>
              <b-col cols="12" v-if="form.enableShiftSchedule">
                <b-btn v-if="originalData.id" class="open-shift-calendar" variant="outline-primary" @click="openShiftCalendar">
                  Open Shift Calendar for this Device
                </b-btn>
                <div style="margin-top: 1rem" v-else>
                  <font-awesome-icon icon="circle-info" style="margin-right: .25rem" />
                  After you save this new Device, you'll be able to edit its shift calendar.
                </div>
                <div v-if="shiftMode === 'schedule' && !originalData.enableShiftSchedule && form.enableShiftSchedule" class="shift-schedule-warning">
                  <font-awesome-icon icon="triangle-exclamation" />
                  If you don't set a shift schedule, then no one will be able to clock IN to this job!
                </div>
              </b-col>
            </b-row>
          </b-container>
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body class="mb-1" v-show="isPersonalDevice">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-btn block v-b-toggle.accordionPersonalDevice variant="dark">Personal Device Settings</b-btn>
      </b-card-header>
      <b-collapse id="accordionPersonalDevice" ref="accordionPersonalDevice" :accordion="accordionId" role="tabpanel">
        <b-card-body>
          <device-personal-settings :deviceId="isPersonalDevice ? originalData.id : null" />
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body class="mb-1" v-show="!addingNew">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-btn block v-b-toggle.accordion4 variant="dark">Status</b-btn>
      </b-card-header>
      <b-collapse id="accordion4" ref="accordion4" :accordion="accordionId" role="tabpanel">
        <b-card-body>
          <b-container class="status">
              <b-row>
                <template v-if="originalData.clockSessionValid">
                  <b-col cols="12" sm="6">
                    <form-group label="IP address">
                      {{ originalData.clockSessionIpAddress }}
                    </form-group>
                  </b-col>
                  <b-col cols="12" sm="6">
                    <form-group label="User agent">
                      {{ originalData.clockSessionUserAgent }}
                    </form-group>
                  </b-col>
                  <b-col cols="12" sm="6">
                    <form-group label="Device model">
                      {{ originalData.clockSessionDeviceModel }}
                    </form-group>
                  </b-col>
                  <b-col cols="12" sm="6">
                    <form-group label="Device Unique ID">
                      {{ originalData.clockSessionDeviceUuid }}
                    </form-group>
                  </b-col>
                  <b-col cols="12" sm="6">
                    <form-group label="Connection Type">
                      {{ originalData.clockSessionConnType }}
                    </form-group>
                  </b-col>
                  <b-col cols="12" sm="6">
                    <form-group label="App version">
                      {{ originalData.clockSessionAppVersion }}
                    </form-group>
                  </b-col>
                  <b-col cols="12" sm="6">
                    <form-group label="Installed at">
                      {{ clockSessionCreated }}
                    </form-group>
                  </b-col>
                  <b-col cols="12" sm="6">
                    <form-group label="Installed by">
                      {{ clockSessionCreatedBy }}
                    </form-group>
                  </b-col>
                  <b-col cols="12" sm="6">
                    <form-group label="Last communication">
                      {{ clockSessionModified }}
                    </form-group>
                  </b-col>
                </template>
                <template v-else>
                  <b-col cols="12" sm="6">
                    Device not currently set up
                  </b-col>
                </template>
                <b-col cols="12" sm="6" v-if="canViewAudit && form.id">
                  <form-group label="Audit logs">
                    <audit-links
                      :instanceId="form.id"
                      kind="DEVICE"
                      actorTitle="View changes made by this device"
                      affectedTitle="View changes made to this device"
                    />
                  </form-group>
                </b-col>
              </b-row>
            </b-container>
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body class="mb-1" v-show="originalData.clockSessionValid">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-btn block v-b-toggle.accordion5 variant="danger">Danger Zone</b-btn>
      </b-card-header>
      <b-collapse id="accordion5" ref="accordion5" :accordion="accordionId" role="tabpanel">
        <b-card-body>
          <b-button variant="danger" @click="disconnectDevice">
            <font-awesome-layers class="fa-lg" style="margin-left: .25rem; margin-right: 1rem">
              <font-awesome-icon icon="mobile-alt" transform="down-1" />
              <font-awesome-icon icon="minus" transform="shrink-6 right-14" />
            </font-awesome-layers>
            Disconnect Device
          </b-button>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import _ from 'lodash'
import { useVuelidate } from '@vuelidate/core'
import { helpers, integer, minLength, maxLength, minValue, required, requiredIf } from '@vuelidate/validators'
import CommonFaceSettings from './CommonFaceSettings.vue'
import AuditLinks from '@/components/AuditLinks.vue'
import DurationInput from '@/components/DurationInput.vue'
import FaceModeSelector from '@/components/FaceModeSelector.vue'
import HelpTextIcon from '@/components/HelpTextIcon.vue'
import KeyMultiselect from '@/components/KeyMultiselect.vue'
import { randomDigits } from '@/utils/misc'
import DevicePersonalSettings from './DevicePersonalSettings.vue'
import CostingSelection from '../costing/CostingSelection.vue'
import deviceService from './services/DeviceService'
import Accordion from '@/mixins/Accordion'
import DetailBasedForm from '@/mixins/DetailBasedForm'
import GeoRuleSelect from '@/views/settings/devices/GeoRuleSelect.vue'
import { migrateDeviceData } from './util'
import { useModalController } from 'bootstrap-vue-next'

const NewDevice = () => ({
  active: true,
  orgUnit: null,
  settingsPin: '123456',
  settingsPinPermissions: [],
  enableShiftSchedule: false,
  notifyDelayMinutes: null,
  announcementOverride: false,
  localModeAlways: false,
  faceOverride: false,
  fixCostingIsPartial: false,
  geoProvider: null,
  geoRules: [],
  geoOverrideToDisabled: false,
  language: null,
  enableDebug: false
})

export default {
  setup () {
    const modalController = useModalController()
    return {
      v$: useVuelidate({ $scope: false, $stopPropagation: true }),
      confirmModal: modalController.confirm,
      showModal: modalController.show
    }
  },
  inheritAttrs: false,
  components: {
    AuditLinks,
    CommonFaceSettings,
    CostingSelection,
    DevicePersonalSettings,
    DurationInput,
    FaceModeSelector,
    GeoRuleSelect,
    HelpTextIcon,
    KeyMultiselect
  },
  mixins: [
    Accordion,
    DetailBasedForm
  ],
  data () {
    return {
      form: NewDevice(),
      fieldLabels: {
        name: 'Name',
        orgUnit: 'Org Unit',
        settingsPin: 'Administrator Pin',
        notifyDelayMinutes: 'Notification Delay'
      },
      costingSelectionValid: true,
      commonFaceSettingsValid: true,
      languageOptions: [
        { value: 'en', label: 'English' },
        { value: 'es', label: 'Español' }
      ]
    }
  },
  watch: {
    originalData: {
      handler (newValue) {
        if (_.isEmpty(newValue)) {
          this.formDataChanged(NewDevice())
        } else {
          // clean up some inconsistencies in original legacy data,
          // in order to prevent false dirty.
          migrateDeviceData(newValue)

          this.formDataChanged(_.cloneDeep(newValue))
        }
      },
      immediate: true
    },
    formData (newValue) {
      this.form = newValue
    },
    form: function (value) {
      if (!value.orgUnit && this.singleOrgUnit) {
        this.form.orgUnit = this.sortedOrgUnits[0].id
      }
    },
    formInvalid: {
      handler (value) {
        this.formInvalidChanged(value)
      },
      immediate: true
    },
    singleOrgUnit (value) {
      if (!this.form.orgUnit) {
        this.form.orgUnit = this.sortedOrgUnits[0].id
      }
    },
    'form.notifyIfInactive' (value) {
      if (!value) {
        this.form.notifyDelayMinutes = null
        this.form.notifyRepeat = false
      }
    },
    'form.settingsPinPermissions' (value) {
      if (value && !value.includes('view_worker')) {
        if (value.includes('add_worker')) {
          this.form.settingsPinPermissions = value.filter(perm => perm !== 'add_worker')
        }
        if (value.includes('enroll_face')) {
          this.form.settingsPinPermissions = value.filter(perm => perm !== 'enroll_face')
        }
        if (value.includes('add_punch')) {
          this.form.settingsPinPermissions = value.filter(perm => perm !== 'add_punch')
        }
        if (value.includes('view_worker_pin')) {
          this.form.settingsPinPermissions = value.filter(perm => perm !== 'view_worker_pin')
        }
        if (value.includes('view_worker_reports')) {
          this.form.settingsPinPermissions = value.filter(perm => perm !== 'view_worker_reports')
        }
      }
    },
    'form.faceOverride' (faceOverride) {
      if (!faceOverride) this.form.faceMode = null
    }
  },
  computed: {
    ...mapState('userProfile', ['isAppAdmin']),
    ...mapState([
      'shiftMode'
    ]),
    ...mapState('deviceDefaults', {
      requireOnline: state => state.originalData.requireOnline
    }),
    ...mapGetters([
      'accessAllOrgUnits',
      'canViewAudit',
      'hasFaceFeature',
      'geoEnabled',
      'scheduleEnabled',
      'costingEnabled',
      'enhancedCostingEnabled'
    ]),
    ...mapGetters({
      orgUnits: 'orgUnits/sortedItems',
      shiftClasses: 'shiftClasses/sortedItems',
      formatDateTimeTz: 'formatPreferences/formatDateTimeTz',
      formatName: 'formatPreferences/formatName'
    }),
    isPersonalDevice () {
      return !!this.originalData.orgUser
    },
    sortedOrgUnits () {
      return this.orgUnits('name').filter(item => item.active)
    },
    sortedShiftClasses () {
      return this.shiftClasses('name')
    },
    singleOrgUnit () {
      return this.accessAllOrgUnits && this.sortedOrgUnits.length === 1
    },
    formInvalid () {
      return this.v$.$invalid
    },
    formSettingsPinPermissions () {
      return (this.form && this.form.settingsPinPermissions) || []
    },
    invalidFields () {
      return Object.keys(this.v$.form.$params)
        .filter(fieldName => this.v$.form[fieldName].$invalid)
    },
    clockSessionCreated () {
      return this.originalData.clockSessionCreated
        ? this.formatDateTimeTz(this.originalData.clockSessionCreated, this.timezoneByOrgUnit[this.originalData.orgUnit])
        : null
    },
    clockSessionModified () {
      return this.originalData.clockSessionModified
        ? this.formatDateTimeTz(this.originalData.clockSessionModified, this.timezoneByOrgUnit[this.originalData.orgUnit])
        : null
    },
    clockSessionCreatedBy () {
      const createdByName = this.originalData.clockSessionCreatedByName
      if (!createdByName) return null
      return this.formatName(createdByName.firstName, createdByName.lastName)
    },
    timezoneByOrgUnit () {
      return Object.fromEntries(this.orgUnits('name').map(orgUnit => [orgUnit.id, orgUnit.timezone]))
    },
    eligibleForBgGeo () {
      // TODO: We need device settings to check personal device mode.
      return this.isPersonalDevice
    }
  },
  validations () {
    return {
      $validationGroups: {
        basicInformationGroup: ['form.name', 'form.orgUnit'],
        extraInformationGroup: ['form.notifyDelayMinutes'],
        securitySettingsGroup: ['form.settingsPin'],
        costingOverridesGroup: ['costingSelectionValid'],
        faceOverridesGroup: ['form.faceMode', 'commonFaceSettingsValid'],
      },
      form: {
        name: {
          required,
          maxLength: maxLength(50)
        },
        orgUnit: {
          required
        },
        settingsPin: {
          required,
          integer,
          minLength: minLength(1),
          maxLength: maxLength(12)
        },
        notifyDelayMinutes: {
          required: requiredIf(() => this.form.notifyIfInactive),
          integer: helpers.withMessage(`${this.fieldLabels.fieldLabels} must be in format "HH:MM"`, integer),
          minValue: helpers.withMessage(`${this.fieldLabels.fieldLabels} must be at least 5 minutes`, minValue(5))
        },
        faceMode: {
          required: requiredIf(() => this.form.faceOverride),
        }
      },
      commonFaceSettingsValid: {
        childValid: () => !!this.commonFaceSettingsValid
      },
      costingSelectionValid: {
        childValid: () => !!this.costingSelectionValid
      }
    }
  },
  methods: {
    randomPin () {
      const pin = randomDigits(6)
      this.form.settingsPin = pin
    },
    async disconnectDevice () {
      if (this.addingNew) return

      const result = await this.confirmModal({
        props: {
          body: 'Are you sure you want to disconnect this device?',
          centered: true,
          noCloseOnBackdrop: true
        }
      })

      if (!result) return

      deviceService.disconnectDevice(this.originalData.id)
        .then(() => this.$toast.success('Device disconnected'))
        .catch(() => this.$toast.error('Failed to disconnect device'))
        .finally(() => {
          // clear all signs of a connected device, and make sure
          // it does not affect dirty state
          this.originalData.clockSessionValid = false
          this.originalData.clockSessionModified = null
          this.originalData.clockSessionAppVersion = null
          this.form.clockSessionValid = false
          this.form.clockSessionModified = null
          this.form.clockSessionAppVersion = null
          this.form.clockSessionCreatedByName = null
        })
    },
    openShiftCalendar () {
      import(/* webpackChunkName: "schedule-modal" */ '@/views/manage/schedule/ScheduleModal.vue')
        .then(module => {
          const ScheduleModal = module.default
          this.showModal({
            component: ScheduleModal,
            props: {
              resourceTypeId: 'device',
              resourceId: this.originalData.id,
              resourceName: this.originalData.name

            }
          })
        })
    }
  },
  mounted () {
    this.$store.dispatch('deviceDefaults/load')
    this.$store.dispatch('orgUnits/load')
    if (this.scheduleEnabled) {
      // Load shift classes for ResourceCalendarWindow.
      this.$store.dispatch('shiftClasses/load')
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '@/assets/scss/variables';

.personal-device-pointer {
  margin-top: .75rem;
  svg {
    margin-right: 5px;
  }
}

.pin-container {
  // do some flex magic to deal with bootstrap requirement
  // that invalid-feedback element is at same dom level as input.
  display: flex;
  flex-wrap: wrap;

  .form-control {
    flex-grow: 1;
    // setting width still allows it to expand, but it also
    // allows button to be on same line.
    width: 100px;
  }

  .btn {
    margin-left: 10px;
  }

  .invalid-feedback {
    flex-basis: 100%;
  }
}

.checkbox-group-permissions {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;

  .custom-checkbox {
    // match bottom margin of form group
    margin-bottom: 1rem;

    flex-basis: 30%;

    @include media-breakpoint-down(md) {
      flex-basis: 45%;
    }

    @include media-breakpoint-only(xs) {
      flex-basis: 100%;
    }
  }
}

.face-overrides {
  margin-top: 20px;
}

.open-shift-calendar {
  margin-top: 1rem;
}

.shift-schedule-warning {
  margin-top: .5rem;
  color: $flat-ui-carrot;
  svg {
    margin-right: .25rem;
  }
}

.status {
  :deep(fieldset) {
    legend {
      text-decoration: underline
    }
    div {
      font-style: italic;
    }
  }
}
</style>
