<template>
  <div class="worker-document-form">
    <form-group label="Name" :validator="v$.value.name">
      <template #default="slotProps">
        <b-form-input
          v-bind="slotProps"
          v-model="value.name"
          type="text"
        />
      </template>
    </form-group>
    <form-group label="Kind">
      <div class="kind">
        {{ kind }}
        <font-awesome-icon :icon="icon" class="file-icon" />
      </div>
    </form-group>
    <form-group label="Size" :validator="v$.value.size">
      <div>
        {{ size }}
        <b-btn v-if="v$.value.size.$invalid && fileCanBeCompressed" size="sm" variant="primary" class="compress-btn" @click="compress">
          <font-awesome-icon icon="compress-arrows-alt" />
          Compress
        </b-btn>
      </div>
    </form-group>
    <form-group label="Last modified">
      <div>{{ modified }}</div>
    </form-group>
    <form-group label="Download">
      <div class="download">
        <b-button @click="$emit('download', value)" variant="link" style="padding:0">
          <font-awesome-icon icon="file-arrow-down" class="download-icon" />
        </b-button>
      </div>
    </form-group>
  </div>
</template>
<script>
import { helpers, maxLength, maxValue, required } from '@vuelidate/validators'
import { resolveFontAwesomeIconForMimeType } from '@/utils/mimeIcons'
import { mapState } from 'vuex'
import { compressImage, mimeTypeCanBeCompressed } from '@/utils/image'
import { useVuelidate } from '@vuelidate/core'

export default {
  name: 'WorkerDocumentForm',
  setup () {
    return {
      v$: useVuelidate({ $scope: false, $stopPropagation: true })
    }
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    kind: String,
    size: String,
    modified: String
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapState(['maxDocumentFileSize']),
    icon () {
      return resolveFontAwesomeIconForMimeType(this.value.mimeType)
    },
    // TODO: Figure out how to compress PDF. Might have to use a cloud service, or ImageMagick on Cloud Function?
    // TODO: If using Cloud Function, we would need to upload directly to Cloud Storage first, because Cloud Function
    // TODO: has 10 MB request body limit.
    fileCanBeCompressed () {
      return mimeTypeCanBeCompressed(this.value.mimeType)
    }
  },
  methods: {
    compress () {
      compressImage(this.value, this.maxDocumentFileSize)
    }
  },
  watch: {
    'v$.$invalid': {
      handler (invalid) {
        this.$emit('invalid-changed', invalid)
      },
      immediate: true
    }
  },
  validations () {
    return {
      value: {
        name: {
          required,
          maxLength: maxLength(200)
        },
        size: {
          maxValue: helpers.withMessage(
            'Maximum file size allowed is 2 MB.',
            maxValue(this.maxDocumentFileSize)
          )
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.worker-document-form {
  .compress-btn {
    margin-left: 10px;
  }
  .kind {
    display: flex;
    align-items: center;
    .file-icon {
      margin-left: 10px;
      font-size: 1.2rem;
    }
  }
  .download {
    .download-icon {
      font-size: 1.5rem;
    }
  }
}
</style>
