<template>
  <key-multiselect
    v-bind="$attrs"
    v-model="forwardValue"
    :multiple="true"
    label="name"
    track-by="id"
    select-label=""
    deselect-label=""
    resourceName="Break"
    :options="sortedShiftBreaks"
    :showAddNewItem="canEditOrgSettings"
    @add-new-item="addNewItem"
  />
</template>
<script>
import KeyMultiselect from '@/components/KeyMultiselect.vue'
import { mapGetters } from 'vuex'
import { useModalController } from 'bootstrap-vue-next'
import { computed, ref } from 'vue'
import BreakFormModal from './BreakFormModal.vue'

export default {
  name: 'BreakSelect',
  setup () {
    return {
      showModal: useModalController().show
    }
  },
  inheritAttrs: false,
  components: {
    KeyMultiselect
  },
  props: {
    modelValue: [Array, Number]
  },
  emits: ['item-added', 'update:modelValue'],
  computed: {
    ...mapGetters(['canEditOrgSettings']),
    ...mapGetters({
      shiftBreaks: 'shiftBreaks/sortedItems'
    }),
    sortedShiftBreaks () {
      return this.shiftBreaks('name')
    },
    forwardValue: {
      get () {
        return this.modelValue
      },
      set (v) {
        this.$emit('update:modelValue', v)
      }
    }
  },
  methods: {
    addNewItem () {
      const itemId = ref(null)
      this.showModal({
          component: BreakFormModal,
          // TODO: computed?
          props: computed(() => ({
            itemId: itemId.value,
            onSaved: item => {
              itemId.value = item.id
              this.$emit('update:modelValue', item.id)
              this.$emit('item-added', item)
            }
          }))
        })
    }
  },
  mounted () {
    this.$store.dispatch('shiftBreaks/load')
  },
}
</script>
