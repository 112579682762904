<template>
  <div :class="['enhanced-grid', { 'customize-pinned': customizePinned }]">
    <grid-tools
      :searchEnabled="searchEnabled"
      :filterEnabled="filterEnabled"
      v-model:filterOpen="filterOpen"
      :allColumns="allColumnDefsInPreferredOrderForMenu"
      :columnLabelKey="columnLabelKey"
      :columnIsSelected="columnIsVisible"
      :addItemEnabled="addItemEnabled"
      :filterInUse="filterInUse"
      :searchText="searchInputText"
      :printEnabled="printEnabled"
      :refreshEnabled="refreshEnabled"
      :disableControls="loading || isPrinting"
      :bulkImportConfig="bulkImportConfig"
      @applySearch="applySearch"
      @applyFilter="applyFilter(true)"
      @clearFilter="clearFilter"
      @selectedColumnsUpdated="columnPrefsUpdated"
      @reset-columns-to-defaults="resetColumnsToDefaults"
      @print="print()"
      @csv="csv()"
      @excel="excel()"
      @pdf="pdf()"
      @addItem="$emit('add-item')"
      @refresh="search()"
      @bulk-import-completed="bulkImportCompleted"
      @customize-pinned="customizePinned = $event"
    >
      <template #quick-filter="slotProps">
        <slot name="quick-filter" v-bind="slotProps"/>
        <b-col cols="auto">
          <b-checkbox v-if="quickActiveFilterEnabled"
            v-model="filterFormData.active"
            value="active"
            unchecked-value="all"
            :disabled="loading"
            class="show-active-only"
          >
            Show active only
          </b-checkbox>
        </b-col>
      </template>
      <template #filter-form>
        <slot name="filter-form" :disabled="loading" />
      </template>
    </grid-tools>

    <ag-grid-vue
        class="printable"
        :gridOptions="gridOptions"
        :columnDefs="allColumnDefsInPreferredOrder"
        :rowData="gridRowData"
        :enableFilter="false"
        :sideBar="false"
        :suppressCellFocus="true"
        :suppressRowClickSelection="true"
        :suppressMenuHide="false"
        :loadingOverlayComponentParams="loadingOverlayComponentParams"
        :defaultColDef="{ suppressHeaderMenuButton: true }"
        :excelStyles="excelStyles"
        @grid-ready="onGridReady"
        @column-moved="columnMoved"
        @column-visible="columnVisible"
        @column-resized="columnResized"
        @row-clicked="$emit('row-clicked', $event)"
    />

    <more-results
      :loading="loadingMoreResults"
      :canLoadMore="!!cursor && !isPrinting"
      @load="loadNextPage"
    />
  </div>
</template>
<script>
import ManagesAgGrid from '@/mixins/ManagesAgGrid'
import Maskable from '@/mixins/Maskable'
import AgGridVue from '@/components/grid/ag-grid-vue'
import GridTools from '@/components/grid/GridTools.vue'
import MoreResults from '@/components/grid/MoreResults.vue'
import _ from 'lodash'
import { useModalController } from 'bootstrap-vue-next'

// Bring in more recent grid changes to MasterDetail.

export default {
  setup () {
    const modalController = useModalController()
    return {
      showModal: modalController.show
    }
  },
  inheritAttrs: false,
  mixins: [
    // TODO: Implement a mixin similar to ManagesAgGrid, but without routing.
    ManagesAgGrid,
    Maskable
  ],
  components: {
    AgGridVue,
    GridTools,
    MoreResults
  },
  props: {
    searchEnabled: {
      type: Boolean,
      default: true
    },
    filterEnabled: {
      type: Boolean,
      default: true
    },
    addItemEnabled: {
      type: Boolean,
      default: false
    },
    printEnabled: {
      type: Boolean,
      default: true
    },
    refreshEnabled: {
      type: Boolean,
      default: true
    },
    defaultSortModel: Array,
    bulkImportConfig: Function,
    quickActiveFilterEnabled: Boolean
  },
  computed: {
    gridRowData () {
      return _.isEmpty(this.rowData) ? [] : this.rowData
    },
    reportTitle () {
      return this.pluralResourceName
    },
    loadingOverlayComponentParams () {
      return {
        // TODO: Fix should be showing error message and retry button.
        errorMessage: this.loadErrorMessage,
        retry: () => {
          this.search()
        }
      }
    },
  },
  watch: {
    'filterFormData.active' (active) {
      this.applyFilter(true)
    },
    // TODO: Display error message in grid overlay instead of toast.
    loadErrorMessage (loadErrorMessage) {
      if (loadErrorMessage) {
        this.$toast.error(loadErrorMessage, 5000)
      }
    }
  },
  methods: {
    onGridReady (params) {
      this.gridApi = params.api
      if (this.defaultSortModel) {
        params.api.setSortModel(this.defaultSortModel)
      }
    },
    bulkImportCompleted () {
      // Refresh grid
      this.search()
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.enhanced-grid {
  // But not we also need to give this container an explicit
  // height in order for the detail form to set its own height 100%.
  // Height is total viewport height - heights of breadcrumb bar, tab
  // panel header, and footer.
  // TODO: This approach is really brittle, and will be hard to re-use
  // TOOD: elsewhere.
  height: calc(100vh - 55px - 39px - 50px);

  // Position relative in order to set detail form
  // absolute position above it. But turn off when
  // printing.
  @media screen {
    position: relative;
  }

  .show-active-only {
    margin-left: 25px;
    margin-right: 10px;
    padding-top: 7px;
    font-size: .8rem;
  }

  .ag-grid {
    margin-top: 10px;

    height: 40vh;

    @media screen and (min-height: 550px) {
      height: 55vh;
    }

    @media screen and (min-height: 800px) {
      height: 65vh;
    }
  }

  &.customize-pinned {
    // TODO: Should we resize grid tools too?
    .ag-grid {
      // When sidebar is pinned, then we need to adjust main page width accordingly.
      // On xs screen, we need to hide all children except the sidebar.
      // https://bootstrap-vue.org/docs/components/sidebar#width
      @include media-breakpoint-up(sm) {
        width: calc(100% - 320px);
      }
      @include media-breakpoint-only(xs) {
        > :not(.b-sidebar-outer) {
          visibility: hidden;
        }
      }
    }
  }
}
</style>
