import ManagesGridColumns from './ManagesGridColumns'
import _ from 'lodash'
import storage from '@/utils/SafeLocalStorage'
import { mapState } from 'vuex'

export default {
  data () {
    return {
      columnLabelKey: 'headerName',
      columnIsVisible: columnDef => !columnDef.hide,
      columnVisibleEntry: (visible) => ({ hide: !visible }),
      columnShowInMenu: column => column.showInColumnMenu !== false && (!this.gridApi?.isPivotMode?.() || !!column.columnDef.aggFunc),
      lastMovedColumn: null,
      enableStorage: true
    }
  },
  // TODO: Fix mixin extending not working in Vue 3.
  // TODO: Seems like it's supposed to work?
  // https://github.com/vuejs/core/blob/72263fa12eec5daf72ce12bb17fbdc878536d53e/packages/runtime-core/__tests__/apiOptions.spec.ts#L933
  mixins: [ManagesGridColumns],
  computed: {
    ...mapState(['organizationId']),
    columnPrefsLocalStorageKey () {
      // TODO: Should we also include user id?
      return `${this.orchestrator}-column-prefs-${this.organizationId}`
    }
  },
  watch: {
    columnPrefs (columnPrefs) {
      if (this.enableStorage) {
        if (this.areColumnPrefsDefault) {
          storage.removeItem(this.columnPrefsLocalStorageKey)
        } else {
          storage.setItem(this.columnPrefsLocalStorageKey, JSON.stringify(columnPrefs))
        }
      }
      this.$emit('columnPrefsChanged', columnPrefs)
    }
  },
  methods: {
    // The following events occur when user drags columns on grid.
    columnMoved (event) {
      // console.log(`event source ${event.source} to index ${event.toIndex} for column`, event.column, event)
      // I'm not sure why, but often column is undefined. I think it may occur as part of feedback loop
      // after columnPrefs is updated. The column is not defined again until the mouse drag is released
      // and restarted. This behavior prevents us from moving the column more than one position at a time.
      // In order to move more than one position, we'll keep track of the last moved column and assume
      // it's the same if undefined. Although this approach seems to improve the user experience, it's
      // still not totally smooth. This could probably be investigated further.
      if (event.source !== 'uiColumnMoved') return
      const column = this.lastMovedColumn = event.column || this.lastMovedColumn
      if (!column) return

      // Only visible columns should be moved around, but toIndex is position in
      // entire allColumnDefsInPreferredOrder list, i.e., including hidden items.
      // We'll move column in preference list to immediately after previous visible item.
      const toIndex = event.toIndex
      const fieldName = column.colDef.field

      const fromIndex = this.allColumnDefsInPreferredOrder.findIndex(columnDef => columnDef.field === fieldName)
      const fromPrefIndex = this.findIndexOfColumnPref(fieldName)

      let toPrefIndex
      if (toIndex === 0) {
        // moved to begining of list
        toPrefIndex = 0
      } else if (toIndex < fromIndex) {
        // moved to earlier in list
        const prevVisibleIndex = _.findLastIndex(this.allColumnDefsInPreferredOrder, this.columnIsVisible, toIndex - 1)
        const prevVisibleField = prevVisibleIndex > -1 ? this.allColumnDefsInPreferredOrder[prevVisibleIndex] : null
        toPrefIndex = this.findIndexOfColumnPref(prevVisibleField.field) + 1
      } else {
        // moved to later in list
        const prevVisibleIndex = _.findLastIndex(this.allColumnDefsInPreferredOrder, this.columnIsVisible, toIndex)
        const prevVisibleField = prevVisibleIndex > -1 ? this.allColumnDefsInPreferredOrder[prevVisibleIndex] : null
        toPrefIndex = this.findIndexOfColumnPref(prevVisibleField.field)
      }

      const columnPrefs = [...this.columnPrefs]
      this.moveItemInArray(columnPrefs, fromPrefIndex, toPrefIndex)
      this.columnPrefsUpdated(columnPrefs)
    },
    columnVisible (event) {
      if (!event.column) return // this can be null
      const fieldName = event.column.colDef.field
      // We always expect not visible, because this event only occurs when column is
      // dragged out of ag-grid to remove it. But to be extra cautious, in case it
      // it is visible, we'll append it to end of preference list.
      const columnPrefs = event.visible
        ? this.columnPrefs.some(columnPref => columnPref === fieldName || columnPref?.field === fieldName) ? this.columnPrefs : this.columnPrefs.concat([fieldName])
        : this.columnPrefs.filter(columnPref => columnPref !== fieldName && columnPref?.field !== fieldName)
      this.columnPrefsUpdated(columnPrefs)
    },
    columnResized (event) {
      if (!event.finished || !event.column) return
      this.columnWidthUpdated(event.column.colDef.field, event.column.actualWidth)
    },
    resetColumnsToDefaults () {
      if (this.enableStorage) {
        storage.removeItem(this.columnPrefsLocalStorageKey)
      }
      this.columnPrefsUpdated(this.defaultColumnPrefs)
    },
    findIndexOfColumnPref (fieldName) {
      // Sometimes column pref is just the field name; sometimes its object containing field name and other properties.
      return this.columnPrefs.findIndex(cp => cp === fieldName || cp.field === fieldName)
    }
  },
  created () {
    // initialize hide property on each column to undefined, if it's not set.
    // this is necessary to set up vue reactivity on the property.
    // TODO: Not sure this is necessary anymore in Vue 3?
    // this.allAppliedColumns.forEach(col => {
    //   if (!Object.prototype.hasOwnProperty.call(col.columnDef, 'hide')) {
    //     col.columnDef.hide = undefined
    //   }
    // })

    // Restore column prefs from local storage.
    // TODO: Using columns from local storage could cause grid to appear empty
    // TODO: if all preferred columns were renamed or removed in an application
    // TODO: update.
    if (this.enableStorage) {
      const columnPrefs = storage.getItem(this.columnPrefsLocalStorageKey)
      if (columnPrefs) {
        this.columnPrefsUpdated(JSON.parse(columnPrefs))
      }
    }
  }
}
