import restClient from '@/services/clients/rest'
import { createRestCrudService } from '@/services/GenericCrudService'

const resourceName = 'Shift Card'
const resourcePath = 'shiftcards'
const crudService = createRestCrudService(resourceName, resourcePath)

export default Object.assign(crudService, {
  async recalculate (shiftCardId) {
    try {
      const response = await restClient.post(`${resourcePath}/${shiftCardId}/recalculate`)
      return response.data
    } catch (error) {
      console.warn(`Error recalculating shift card ${shiftCardId}`, error)
      throw error
    }
  },
  async override (shiftCardId, data) {
    try {
      const response = await restClient.post(`${resourcePath}/${shiftCardId}/override`, data)
      return response.data
    } catch (error) {
      console.warn(`Error posting overrides for ${resourceName} ${shiftCardId}`, error)
      throw error
    }
  },
  async applyBulkAction (actionData, itemIds) {
    try {
      const response = await restClient.post(`${resourcePath}/bulk-action`, {
        itemIds,
        actionData
      })
      return response.data
    } catch (error) {
      console.warn(`Error submitting bulk action`, error, actionData)
      throw error
    }
  }
})
