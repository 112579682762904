
// https://www.geodatasource.com/developers/javascript
export function getLatLngDistance (lat1, lon1, lat2, lon2, unit) {
  const radlat1 = Math.PI * lat1 / 180
  const radlat2 = Math.PI * lat2 / 180
  const theta = lon1 - lon2
  const radtheta = Math.PI * theta / 180
  let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
  dist = Math.acos(dist)
  dist = dist * 180 / Math.PI
  dist = dist * 60 * 1.1515
  if (unit === 'K') dist = dist * 1.609344
  if (unit === 'N') dist = dist * 0.8684
  if (unit === 'm') dist = dist * 1609.344
  return dist
}

// Solve for smallest circle problem using ChatGPT implementation of Convex Hull algorithm.
// https://en.wikipedia.org/wiki/Smallest-circle_problem

// Function to find the orientation of three points (p, q, r)
// Returns 0 if p, q, r are collinear
// Returns 1 if clockwise
// Returns 2 if counterclockwise
function orientation(p, q, r) {
  const val = (q[1] - p[1]) * (r[0] - q[0]) - (q[0] - p[0]) * (r[1] - q[1])
  if (val == 0) return 0 // Collinear
  return (val > 0) ? 1 : 2 // Clockwise or counterclockwise
}

// Function to find the convex hull of a set of points
function convexHull(points) {
  if (points.length < 3) return [] // Convex hull requires at least 3 points

  let leftmost = 0
  for (let i = 1; i < points.length; i++) {
      if (points[i][0] < points[leftmost][0])
          leftmost = i
  }

  let p = leftmost
  let hull = []

  do {
      hull.push(points[p])
      let q = (p + 1) % points.length

      for (let i = 0; i < points.length; i++) {
          if (orientation(points[p], points[i], points[q]) == 2)
              q = i
      }

      p = q
  } while (p != leftmost)

  return hull
}

// Function to find the smallest circle enclosing a set of points
export function smallestEnclosingCircle(points) {
  const hull = convexHull(points)

  let centerX = 0, centerY = 0
  for (const point of hull) {
      centerX += point[0]
      centerY += point[1]
  }
  centerX /= hull.length
  centerY /= hull.length

  let radius = 0
  for (const point of hull) {
      const dist = getLatLngDistance(centerY, centerX, point[1], point[0], 'm')
      if (dist > radius)
          radius = dist
  }

  return { center: [centerX, centerY], radius: radius }
}

export function metersToSliderValue (meters) {
  // log10(meters / 10)
  return Math.log(meters / 10) / Math.LN10
}

export function sliderValueToMeters (sliderValue) {
  // inverse of metersToSliderValue
  const meters = Math.pow(10, sliderValue) * 10
  return Math.round(meters)
}
