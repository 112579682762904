import moment from 'moment'
import { mapGetters } from 'vuex'

// Mixing component should have the computed fields 'customRowCount' and 'customParams', and method 'customData'.

export default {
  props: {
    exportDataSource: String,
    transformCustomData: Function
  },
  computed: {
    ...mapGetters(['hasIntegrationFeature']),
    ...mapGetters({
      integrationTemplates: 'integrationTemplates/sortedItems'
    }),
    sortedIntegrationTemplates () {
      return this.exportDataSource ? this.integrationTemplates('name').filter(t => t.dataSource === this.exportDataSource) : []
    },
    customExportOptions () {
      return this.sortedIntegrationTemplates.map(t => ({
        id: t.id,
        label: t.name,
        base: t.base
      }))
    },
  },
  methods: {
    defaultTransformCustomData (data, params, nunjucksEnv, vuexStore) {
      // TODO: Need a generic way to get exact filtered dates, so we don't have to convert
      // TODO: from preset.
      const timezone = this.$store.state.timezone
      const transformedData = {
        meta: {
          params
        },
        date: moment.tz(timezone),
        payrollId: this.$store.state.payrollId,
        timezone: timezone,
        ...data
      }
      return [transformedData, nunjucksEnv]
    },
    async exportToCustom (customOptionId, headless = false) {
      if (headless && this.customRowCount < 1) {
        return Promise.reject('No data to export')
      }

      // Make sure templates have loaded in case of headless mode.
      await this.$store.dispatch('integrationTemplates/load')

      const template = this.sortedIntegrationTemplates.find(t => t.id === customOptionId)

      if (!template) return Promise.reject('Template not found')

      return import(/* webpackChunkName: "nunjucks" */ '@/utils/nunjucks')
        .then(module => module.configureEnvironment())
        .then(nunjucksEnv =>
          this.customData().then(data =>
            (this.transformCustomData || this.defaultTransformCustomData)(data, this.customParams, nunjucksEnv))
        )
        .then(([data, nunjucksEnv]) => {
          // TODO: Enrich data, e.g., meta, etc.
          const rendereredFileName = nunjucksEnv.renderString(template.filename, data)
          // Change suffix from .xls to .xlsx
          const fileName = rendereredFileName.endsWith('.xls') ? rendereredFileName + 'x' : rendereredFileName
          const fileContents = nunjucksEnv.renderString(template.template, data)
          const isExcel = /\.xlsx?$/.test(fileName)

          if (isExcel) {
            // Convert old xls xml style that we use in the template, to modern native xlsx.
            return import(/* webpackChunkName: "xlsx" */ '@sheet/core')
              .then(module => {
                const XLSX = module.default
                const workbook = XLSX.read(fileContents, { type: 'string', cellStyles: true })
                XLSX.writeFile(workbook, fileName, { cellStyles: true })
              })
              .then(() => ({ title: fileName }))
          }

          if (template.exportAction === 'download') {
            return import(/* webpackChunkName: "file-saver" */ '@/utils/file-saver')
              .then(module => {
                module.saveAs(new Blob([fileContents]), fileName)
              })
              .then(() => ({ title: fileName }))
          } else {
            return {
              window: this.displayContentInNewWindow(fileContents),
              title: fileName
            }
          }
        })
        .then(result => {
          // TODO: exportRowItems doesn't seem to exist anywhere??
          this.$emit('exported', { type: 'custom', customId: this.customOptionId, data: this.exportRowItems})
          return result
        })
    },
    displayContentInNewWindow (content) {
      const win = window.open()
      win.document.open()
      win.document.write(content)
      win.document.close()
      return win
    }
  },
  created () {
    if (this.exportDataSource && this.hasIntegrationFeature) {
      this.$store.dispatch('integrationTemplates/load')
    }
  }
}
