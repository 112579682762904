<template>
  <!-- The outer v-show enables parent to specify style to be used only when showing. -->
  <div v-show="shouldDisplay">
    <div v-if="shouldDisplay" class="face-status-error">
      <font-awesome-icon icon="triangle-exclamation" aria-hidden="true" />
      {{ errorMessage }}
    </div>
  </div>
</template>
<script>

const FaceStatusMessages = {
  BAD_LIGHTING: 'Bad lighting',
  BAD_POSE: 'Bad pose',
  BAD_SHARPNESS: 'Bad sharpness',
  OBJECT_NOT_FOUND: 'No face',
  OCCLUSION: 'Face not clear',
  SPOOF_DETECTED: 'Low liveness confidence',
  OK: 'Low confidence' // A failed OK is deprecated, now that the min score is enforced in face service.
  // MATCH_NOT_FOUND: Should we show an error message?
  // TODO: We should also bind the face log error string as a prop, because it is more detailed.
}

export default {
  name: 'FaceStatusError',
  props: {
    faceStatus: String,
    // Errors only means this component is only mounted when there is an error.
    // The implication is that if true, then status 'OK' is a low score.
    errorsOnly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    errorMessage () {
      if (this.faceStatus === 'OK' && !this.errorsOnly) return undefined
      return FaceStatusMessages[this.faceStatus]
    },
    shouldDisplay () {
      return !!this.errorMessage
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables';

.face-status-error {
  color: $flat-ui-alizarin;
  font-size: .75rem;
  svg {
    margin-right: .5rem;
  }
}
</style>
