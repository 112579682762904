import _ from 'lodash'
import { escapeHtml } from '@/utils/misc'

// partitionTargetLabels returns sorted label list strings
// from given label ids and names lists.
export function partitionTargetLabels (labelIds, labelNames, escape = true) {
  return {
    userLabelNames: sortAndJoinLabelNames(
      (labelNames || []).flatMap((labelName, index) =>
        labelIds[index].startsWith('user:') ? [labelName]: []),
      escape
    ),
    workLabelNames: sortAndJoinLabelNames(
      (labelNames || []).flatMap((labelName, index) =>
        labelIds[index].startsWith('work:') ? [labelName]: []),
      escape
    ),
    allLabelNames: sortAndJoinLabelNames(labelNames || [], escape)
  }
}

// partitionSeparateTargetLabels is used for shift card reports,
// where label lists are already separated, but we still need to
// sort, join, and include list of all labels.
export function partitionSeparateTargetLabels (userLabelNames, workLabelNames) {
  return {
    userLabelNames: sortAndJoinLabelNames(userLabelNames || []),
    workLabelNames: sortAndJoinLabelNames(workLabelNames || []),
    allLabelNames: sortAndJoinLabelNames(_.uniq((userLabelNames || []).concat(workLabelNames || [])))
  }
}

export function partitionTargetLabelsFromSupplementalData (labels, supplementalData) {
 const labelNames = labels.map(label => _.get(supplementalData, `label.${label.split(':')[1]}.name`))
 return partitionTargetLabels(labels, labelNames)
}

export function sortAndJoinLabelNames (labelNames, escape = true) {
  return (labelNames || []).map(l => (escape ? escapeHtml(l) : l)).sort().join(', ')
}

export function getGenericLabelNames(labels, supplementalData) {

  if (_.isEmpty(labels)) return {}

  const labelEntries = Object.entries(_.groupBy(labels.map(label => label.split(':')), 0))
    .map(([labelType, groupedLabels]) => [labelType, groupedLabels.map(groupedLabel => groupedLabel[1])])

  return Object.fromEntries(labelEntries.map(([labelType, labelValues]) => {
    switch (labelType) {
      case 'org_unit':
        return ['orgUnitName', supplementalData.orgUnit[labelValues[0]].name]
      case 'department':
        return ['departmentName', supplementalData.department[labelValues[0]].name]
      case 'user':
        return ['userLabelNames', sortAndJoinLabelNames(labelValues.map(labelValue => supplementalData.label[labelValue].name))]
      case 'work':
        return ['workLabelNames', sortAndJoinLabelNames(labelValues.map(labelValue => supplementalData.label[labelValue].name))]
      case 'cred_policy':
          return ['credentialPolicyName', supplementalData.credentialPolicy[labelValues[0]].name]
      case 'active':
          return ['active', labelValues[0] === 'true']
    }
  }))
}

export function getLabelNames(labelIds, supplementalData, escape = true) {
  if (_.isEmpty(labelIds)) return null
  const labelNames = (labelIds || []).map(labelId => supplementalData.label[labelId].name)
  return sortAndJoinLabelNames(labelNames, escape)
}

export function findLabelIdsOfType(labels, labelType) {
  if (_.isEmpty(labels)) return null
  return labels
    .filter(l => l.startsWith(`${labelType}:`))
    .map(l => parseInt(l.split(':')[1]))
}

export function updateLabelIdsOfType(labels, labelType, newLabelIds) {
  labels = _.isEmpty(labels) ? [] : labels
  const otherLabels = labels.filter(label => !label.startsWith(labelType))
  return otherLabels.concat(newLabelIds.map(labelId => `${labelType}:${labelId}`))
}
