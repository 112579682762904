<template>
  <div class="phone-number-input">
    <key-multiselect
      v-model.trim="phoneType"
      :options="phoneTypeOptions"
      taggable
      label="label"
      track-by="value"
      placeholder=""
      select-label=""
      deselect-label=""
    >
    </key-multiselect>
    <b-form-input
      v-model.trim="phoneNumber"
      type="text">
    </b-form-input>
  </div>
</template>
<script>
import KeyMultiselect from '@/components/KeyMultiselect.vue'
import _ from 'lodash'

export default {
  components: {
    KeyMultiselect
  },
  props: {
    modelValue: String
  },
  emits: ['update:modelValue'],
  data () {
    return {
      phoneTypeOptions: [
        {
          value: 'mobile',
          label: 'Mobile'
        },
        {
          value: 'work',
          label: 'Work'
        },
        {
          value: 'home',
          label: 'Home'
        },
        {
          value: 'fax',
          label: 'Fax'
        },
        {
          value: 'pager',
          label: 'Pager'
        },
        {
          value: 'other',
          label: 'Other'
        }
      ]
    }
  },
  computed: {
    phoneType: {
      get () {
        return (_.isString(this.modelValue) && this.modelValue.split(':')[0]) || ''
      },
      set (v) {
        if (v || this.phoneNumber) {
          this.$emit('update:modelValue', (v || '') + ':' + this.phoneNumber)
        } else {
          this.$emit('update:modelValue', '')
        }
      }
    },
    phoneNumber: {
      get () {
        return (_.isString(this.modelValue) && this.modelValue.split(':')[1]) || ''
      },
      set (v) {
        if (this.phoneType || v) {
          this.$emit('update:modelValue', this.phoneType + ':' + (v || ''))
        } else {
          this.$emit('update:modelValue', '')
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .phone-number-input {
    display: flex;

    .multiselect {
      width: 150px;
    }
  }
</style>
