<template>
  <b-modal
    v-bind="$attrs"
    :title="`${resourceName} Bulk Action`"
    :dialog-class="[dialogClass, 'bulk-action-modal-dialog']"
    static
    centered
    no-trap
    no-fade
    ok-title="Apply"
    :cancel-title="submitState === 'success' ? 'Close' : 'Cancel'"
    :ok-disabled="applyDisabled"
    :no-close-on-backdrop="true"
    :no-close-on-esc="true"
    button-size="sm"
    @ok="apply"
    @hidden="onHidden"
  >
    <h6>{{ actionLabel }}</h6>

    <div class="section info">
      <font-awesome-icon icon="circle-info" />
      <span class="status-text">You selected {{ itemCount }} {{ inflect(resourceName, itemCount) }}.</span>
    </div>

    <!-- Warn if any items are not pre-eligible -->
    <div v-if="preIneligibleItemCount === itemCount" class="error">
      <font-awesome-icon icon="triangle-exclamation" />
      <span class="status-text">{{ preIneligibleMessage }}</span>
    </div>
    <div v-else-if="preEligibleItemCount < itemCount" class="warning">
      <font-awesome-icon icon="triangle-exclamation" />
      <span class="status-text">{{ preIneligibleMessage }}</span>
    </div>

    <!-- Data form inputs -->

    <slot v-if="preEligibleItemCount > 0" v-bind="{ formInputDisabled }"></slot>

    <!-- Error if data is invalid. -->
    <template v-if="preEligibleItemCount > 0">
      <div v-if="!dataValid" class=" section error">
        <font-awesome-icon icon="triangle-exclamation" />
        <span class="status-text">{{ invalidDataMessage }}</span>
      </div>
      <!-- Post-eligibility messages -->
      <div v-else class="section">
        <div v-if="itemCount === postEligibleItemCount" class="success">
          <font-awesome-icon icon="circle-check" />
          <span class="status-text">100% of {{ preIneligibleItemCount > 0 ? 'remaining' : ''}} selected {{ pluralResourceName }} {{ postEligiblePhrase }}.</span>
        </div>
        <div v-else-if="postEligibleItemCount === 0 && submitState !== 'success'" class="error">
          <font-awesome-icon icon="triangle-exclamation" />
          <span class="status-text">All {{ preIneligibleItemCount > 0 ? 'remaining' : ''}} {{ pluralResourceName }} are {{ postIneligiblePhrase }}.</span>
        </div>
        <template v-else>
          <!-- Distinguish between pre and post eligible -->
          <div class="warning" v-if="postIneligibleItemCount > 0">
            <font-awesome-icon icon="triangle-exclamation" />
            <span class="status-text">{{ postIneligibleItemCount }} {{ inflect(resourceName, postIneligibleItemCount) }} {{ postIneligibleItemCount == 1 ? 'is' : 'are' }} {{ postIneligiblePhrase }}.</span>
          </div>
          <div class="info">
            <font-awesome-icon icon="circle-info" />
            <span class="status-text">{{ postEligibleItemCount }} {{ inflect(resourceName, postEligibleItemCount) }} {{ postEligiblePhrase }}.</span>
          </div>
        </template>
      </div>
    </template>

    <!-- Status summary -->
    <b-card v-if="submitState" class="section">
      <div v-if="saving" class="waiting">
        <font-awesome-icon icon="circle-notch" spin />
        <span class="status-text">{{ submitState === 'submitting' ? 'Submitting': `Updating ${postEligibleItemCount} ${inflect(resourceName, postEligibleItemCount)}` }}...</span>
      </div>
      <div v-else-if="submitState === 'success'" class="info">
        <font-awesome-icon icon="circle-info" />
        <span class="status-text">Bulk update complete</span>
      </div>
      <div v-else-if="submitState === 'failed'" class="error">
        <font-awesome-icon icon="triangle-exclamation" />
        <span class="status-text">{{ errorMessage }}</span>
      </div>

      <b-progress v-if="saving" :value="successCount + failureCount" :max="postEligibleItemCount" show-value animated class="progress section"></b-progress>

      <div v-if="successCount && !saving" class="success">
        <font-awesome-icon icon="circle-check" />
        <span class="status-text">Successfully updated {{ successCount }} {{ inflect(resourceName, successCount) }}</span>
      </div>
      <div v-if="failureCount" class="error">
        <font-awesome-icon icon="triangle-exclamation" />
        <span class="status-text">Failed to update {{ failureCount }} {{ inflect(resourceName, failureCount) }}</span>
      </div>
    </b-card>
  </b-modal>
</template>
<script>
import _ from 'lodash'
import { inflect, pluralize } from 'inflection'
import bulkActionService from '@/services/BulkActionService'
import { extractErrorMessage, extractErrorDataMessage, jsonParseSafe } from '@/utils/misc'

export default {
  inheritAttrs: false,
  name: 'BulkActionModal',
  props: {
    resourceName: String,
    orchestrator: String,
    actionOption: Object,
    applyBulkAction: Function,
    items: Array,
    dialogClass: {
      default: null
    }
  },
  data () {
    return {
      inflect,
      submitState: null,
      itemStatus: {},
      pollKey: null,
      errorMessage: null,
      successCount: 0,
      failureCount: 0
    }
  },
  computed: {
    pluralResourceName () {
      return pluralize(this.resourceName)
    },
    preEligibleItems () {
      const preValidate = this.actionOption.preValidate
      return preValidate ? this.items.filter(item => preValidate(item)) : this.items
    },
    postEligibleItems () {
      const postValidate = this.actionOption.postValidate
      return postValidate ? this.preEligibleItems.filter(item => postValidate(item)) : this.preEligibleItems
    },
    preEligibleItemCount () {
      return this.preEligibleItems.length
    },
    postEligibleItemCount () {
      return this.postEligibleItems.length
    },
    preIneligibleItemCount () {
      return this.itemCount - this.preEligibleItemCount
    },
    postIneligibleItemCount () {
      // Don't count pre-ineligible items
      return this.preEligibleItemCount - this.postEligibleItemCount
    },
    itemCount () {
      return this.items.length
    },
    actionLabel () {
      return this.actionOption.label
    },
    dataValid () {
      return !this.actionOption.validateData || this.actionOption.validateData()
    },
    invalidDataMessage () {
      return this.actionOption.invalidDataMessage
    },
    preIneligibleMessage () {
      const preIneligiblePhrase = this.actionOption.preIneligiblePhrase && this.actionOption.preIneligiblePhrase()
      const preIneligibleItemCount = this.itemCount - this.preEligibleItemCount
      const article = preIneligibleItemCount === this.itemCount
        ? (preIneligibleItemCount > 1 ? 'All' : 'This')
        : preIneligibleItemCount
      return `${article} ${inflect(this.resourceName, preIneligibleItemCount)} ${preIneligibleItemCount === 1 ? 'does' : 'do'} ${preIneligiblePhrase}.`
    },
    postEligiblePhrase () {
      return this.actionOption.postEligiblePhrase && this.actionOption.postEligiblePhrase()
    },
    postIneligiblePhrase () {
      return this.actionOption.postIneligiblePhrase()
    },
    applyDisabled () {
      return !this.dataValid || !this.postEligibleItemCount || (!!this.submitState && this.submitState !== 'failed')
    },
    formInputDisabled () {
      return !!this.submitState && this.submitState !== 'failed'
    },
    saving () {
      return ['submitting', 'updating'].includes(this.submitState)
    },
    inProgressCount () {
      return this.postEligibleItemCount - this.successCount - this.failureCount
    }
  },
  methods: {
    onHidden () {
      // If was in progress, then emit aborted event.
      if (this.saving) {
        const itemStatus = Object.fromEntries(Object.entries(this.itemStatus).map(item => {
          return item[1].state === 'progress' ? [item[0], {
            state: 'aborted',
            icon: 'triangle-exclamation',
            variant: 'error',
            text: 'Unknown result'
          }] : item
        }))
        this.$emit('state-change', 'aborted', itemStatus)
      }
    },
    apply (event) {
      // don't close modal
      event.preventDefault()

      const actionData = this.actionOption.data
      const itemIds = this.postEligibleItems.map(item => item.id)

      const eligibleItems = new Set(itemIds)

      // Update grid row status on all selected items (either ineligible or processing).
      this.submitState = 'submitting'
      this.errorMessage = null
      this.itemStatus = Object.fromEntries(
        this.items.map(item => {
          if (eligibleItems.has(item.id)) {
            return [item.id, {
              state: 'progress',
              icon: 'circle-notch',
              variant: 'waiting',
              text: 'Updating...'
            }]
          } else {
            return [item.id, {
              state: 'ineligible',
              icon: 'triangle-exclamation',
              variant: 'warning',
              text: 'Not eligible for bulk action'
            }]
          }
        })
      )
      this.$emit('state-change', this.submitState, this.itemStatus)

      this.applyBulkAction(actionData, itemIds)
        .then(data => {
          this.pollKey = data.pollKey
          this.submitState = 'updating'
          setTimeout(this.pollBulkActionStatus, 2000)
        })
        .catch(error => {
          this.submitState = 'failed'
          this.errorMessage = extractErrorMessage(error)
          this.itemStatus = Object.fromEntries(
            Object.entries(this.itemStatus)
              .map(itemStatus => {
                if (itemStatus[1].state !== 'progress') return itemStatus // only fill in error message for items that we tried to update
                return [itemStatus[0], {
                    state: 'failed',
                    icon: 'triangle-exclamation',
                    variant: 'error',
                    text: 'Failed to update'
                }]
              })
          )
          this.$emit('state-change', this.submitState, this.itemStatus)
        })
    },

    pollBulkActionStatus () {
      // TODO: Consider using a timestamp offset to stream new completions as they occur.
      // TODO: Should we allow admin to close modal while in progress, and if so what do we do to grid and bulk select state?

      bulkActionService.pollBulkActionStatus(this.pollKey)
        .then(data => {
          // Set overall status summary.
          if (data.status === 'complete') {
            this.submitState = data.success ? 'success' : 'failed'
            if (!data.success && data.message) {
              this.errorMessage = extractErrorDataMessage(jsonParseSafe(data.message, data.message))
            }
          }

          this.successCount = data.successCount
          this.failureCount = data.failureCount

          // Set updated status of each item.
          // We also need to set status on new rows we will be adding below, e.g., splitting punches in cost distribution.
          if (data.items) {
            const updatedItems = Object.fromEntries(data.items.map(item => [item.entityId, item]))
            this.itemStatus = Object.fromEntries(
              Object.entries(this.itemStatus)
                .map(itemStatus => {
                  const updatedStatus = updatedItems[itemStatus[0]]
                  if (!updatedStatus) return itemStatus // nothing updated
                  return [itemStatus[0], updatedStatus.success
                    ? {
                      state: 'success',
                      icon: 'circle-check',
                      variant: 'success',
                      text: updatedStatus.message || 'Successfully updated'
                    }
                    : {
                      state: 'failed',
                      icon: 'triangle-exclamation',
                      variant: 'error',
                      text: extractErrorDataMessage(jsonParseSafe(updatedStatus.message, updatedStatus.message))
                    }]
                })
            )
            this.$emit('state-change', this.submitState, this.itemStatus)

            // Update row data with actual change applied (e.g., active, pay class, etc).

            const updatedSuccessItems = data.items.filter(item => item.success)
            if (updatedSuccessItems.length > 0) {
              const actionData = this.actionOption.data
              const masterItems = Object.fromEntries(this.$store.state[this.orchestrator].masterItems.map(item => [item.id, item]))
              const transaction = {
                add: [],
                update: [],
                remove: []
              }
              const supplementalData = {}
              updatedSuccessItems
                .filter(item => item.entityId in masterItems)
                .forEach(item => {
                    const extraInfo = item.extraInfo
                    if (extraInfo && extraInfo.deleted) transaction.remove.push(item.entityId)
                    else if (extraInfo && extraInfo.items) {
                      extraInfo.items.forEach(extraItem => {
                        const isUpdate = extraItem.id === item.entityId
                        transaction[isUpdate ? 'update' : 'add'].push(extraItem)
                        if (!isUpdate) {
                          // Add status for new item.
                          this.itemStatus[extraItem.id] = {
                            state: 'success',
                            icon: 'circle-check',
                            variant: 'success',
                            text: item.message || 'Successfully updated'
                          }
                        }
                      })
                      if (extraInfo.supplementalData) {
                        _.merge(supplementalData, extraInfo.supplementalData)
                      }
                    }
                    else {
                      if (this.actionOption.applyToLocalItem) {
                        const masterItem = masterItems[item.entityId]
                        const updatedItem = this.actionOption.applyToLocalItem({...masterItem}, actionData, extraInfo)
                        transaction.update.push(updatedItem)
                      }
                    }
                })
              this.$store.getters[`${this.orchestrator}/eventBus`].emit('applyGridTransaction', { transaction })
              if (!_.isEmpty(supplementalData)) this.$store.commit(`${this.orchestrator}/supplementalDataAdded`, supplementalData)
              // Emit state change for any new items.
              this.$emit('state-change', this.submitState, this.itemStatus)
            }
          }

          // Schedule next poll if not complete and modal still open.
          if (data.status !== 'complete' && this.isMounted) {
            setTimeout(this.pollBulkActionStatus, 2000)
          }
        })
        .catch(error => {
          // TODO: Figure out what kinds of errors should be re-polled, and which should not.
          if (this.isMounted) {
            setTimeout(this.pollBulkActionStatus, 2000)
          }
        })
    },

    labelsFullValueChange (data) {
      this.labelsFullValues = data
    }
  },
  mounted () {
    this.isMounted = true
  },
  unmounted () {
    this.isMounted = false
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/variables';

.bulk-action-modal-dialog {
  .section {
    margin-top: 1rem;
  }

  .progress {
    margin-bottom: 1rem;
  }

  .error {
    color: $flat-ui-alizarin;
  }
  .warning {
    color: $flat-ui-sunflower;
  }
  .success {
    color: $flat-ui-emerald;
  }
  .info {
    color: $flat-ui-peter-river;
  }
  .waiting {
    color: $fc-logo-orange;
  }

  .status-text {
    margin-left: .75rem;
  }
}
</style>
