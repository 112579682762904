<template>
  <div class="dashboard">
    <div class="line-break" />
    <current-pay-period v-if="payPeriodVisible" />
    <last-pay-period v-if="payPeriodVisible" />
    <roll-call-summary v-if="canViewReports" />
    <div class="line-break" />
    <face-failure v-if="faceFailureVisible" />
    <employee-usage v-if="canEditWorkers" />
    <no-console-access v-if="noConsoleAccess" />
  </div>
</template>
<script>
import CurrentPayPeriod from './CurrentPayPeriod.vue'
import EmployeeUsage from './EmployeeUsage.vue'
import FaceFailure from './FaceFailure.vue'
import LastPayPeriod from './LastPayPeriod.vue'
import NoConsoleAccess from './NoConsoleAccess.vue'
import RollCallSummary from './RollCallSummary.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Dashboard',
  components: {
    CurrentPayPeriod,
    EmployeeUsage,
    FaceFailure,
    LastPayPeriod,
    NoConsoleAccess,
    RollCallSummary
  },
  computed: {
    ...mapGetters([
      'canEditPunchTimes',
      'canEditWorkers',
      'canManageClockLogs',
      'canViewReports',
      'hasFaceFeature',
      'noConsoleAccess'
    ]),
    faceFailureVisible () {
      return this.hasFaceFeature && this.canManageClockLogs
    },
    payPeriodVisible () {
      return this.canEditPunchTimes && this.canViewReports
    }
  }
}
</script>
<style lang="scss" scoped>

.dashboard {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;

  > .panel {
    margin-right: 1rem;
    max-width: 30rem; // default max width

    // use lower z-index for dashboard panel masks than default masks,
    // so that sidebar can open over them.
    :deep(.v-mask) {
      z-index: 1000;

      > .v-mask-contents {
        z-index: 1001;
      }
    }
  }

  .line-break {
    flex-basis: 100%;
  }
}

</style>
