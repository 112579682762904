<template>
  <b-card no-body class="mb-1 geo-rule-form">
    <b-card-body>
      <b-container>
        <b-row>
          <b-col cols="12" sm="6">
            <form-group :validator="v$.form.name" :label="fieldLabels.name">
              <template #default="slotProps">
                <b-form-input
                  v-bind="slotProps"
                  v-model.trim="form.name"
                  type="text"
                />
              </template>
            </form-group>
          </b-col>
          <b-col cols="12" sm="6">
            <form-group>
              <b-form-checkbox v-model="form.active">
                Active
              </b-form-checkbox>
            </form-group>
          </b-col>
          <b-col cols="12">
            <form-group
              class="geo-fence"
              label="Geofence region"
              label-cols="12"
              label-cols-sm="3"
              :validator="v$.fenceCenter"
            >
              <div class="geo-fence-display form-display">
                <template v-if="form.fenceAddress">
                  {{ form.fenceAddress }}<br>
                  <template v-if="isFencePolygon">
                    Polygon
                    <br>
                    Legacy: Circle Within {{ fenceRangeDisplay }}
                    <help-text-icon>
                      Polygon geo fences are natively supported on Fareclock app versions 3.4 and later.
                      The Fareclock cloud also enforces polygon geo fences for all app versions when the device is online and when post-processing offline punches.
                      The circle drawn around the polygon is automatically used for older versions of the mobile app that do not support polygon geofences.
                    </help-text-icon>
                  </template>
                  <template v-else>
                    Within {{ fenceRangeDisplay }}
                  </template>
                </template>
                <template v-else-if="form.fenceLat && form.fenceLng">
                  Latitude: {{ form.fenceLat }}&deg;
                  <br/>
                  Longitude: {{ form.fenceLng }}&deg;
                </template>
                <template v-else>
                  No location set
                </template>
              </div>
              <b-btn variant="link" @click="editFenceCenter">Edit</b-btn>
            </form-group>
            <geo-location-editor v-model="showLocationEditor" :location="form" />
          </b-col>
          <b-col cols="12">
            <form-group label="Policy" label-cols="12" label-cols-sm="3">
              <b-form-radio-group v-model="form.fenceSilent" stacked>
                <b-form-radio :value="false">Block punches outside geo fence</b-form-radio>
                <b-form-radio :value="true">Punches outside of geo fence silently flagged for review</b-form-radio>
              </b-form-radio-group>
            </form-group>
          </b-col>
        </b-row>
      </b-container>
    </b-card-body>
  </b-card>
</template>
<script>
import _ from 'lodash'
import { useVuelidate } from '@vuelidate/core'
import { between, numeric, required } from '@vuelidate/validators'
import GeoLocationEditor from './GeoLocationEditor.vue'
import HelpTextIcon from '@/components/HelpTextIcon.vue'
import DetailBasedForm from '@/mixins/DetailBasedForm'
import { mapGetters } from 'vuex'

function NewGeoFence () {
  return {
    active: true,
    name: null,
    fenceEnabled: true,
    fenceAddress: null,
    fenceLat: null,
    fenceLng: null,
    fenceRange: 100,
    fenceVertices: null,
    fenceSilent: false
  }
}

export default {
  name: 'GeoRuleForm',
  setup () {
    return {
      v$: useVuelidate({ $scope: false, $stopPropagation: true })
    }
  },
  inheritAttrs: false,
  mixins: [DetailBasedForm],
  components: {
    GeoLocationEditor,
    HelpTextIcon
  },
  data () {
    return {
      form: NewGeoFence(),
      fieldLabels: {
        name: 'Name'
      },
      showLocationEditor: false
    }
  },
  computed: {
    ...mapGetters('formatPreferences', ['formatDistance']),
    formInvalid () {
      return this.v$.$invalid
    },
    invalidFields () {
      const fields = Object.keys(this.v$.form.$params)
        .filter(fieldName => this.v$.form[fieldName].$invalid)
      if (!this.fenceCenter) fields.push('fenceCenter')
      return fields
    },
    fenceCenter () {
      if (!this.form.fenceAddress && !this.form.fenceLat && !this.form.fenceLng) return null
      return {
        address: this.form.fenceAddress,
        lat: this.form.fenceLat,
        lng: this.form.fenceLng
      }
    },
    fenceRangeDisplay () {
      return this.formatDistance(this.form.fenceRange)
    },
    isFencePolygon () {
      return this.form.fenceVertices?.length > 0
    }
  },
  watch: {
    originalData: {
      handler (newValue) {
        if (_.isEmpty(newValue)) {
          this.formDataChanged(NewGeoFence())
        } else {
          // fence should always be enabled on geo rules
          newValue.fenceEnabled = true
          newValue.fenceVertices = newValue.fenceVertices || null
          this.formDataChanged(_.cloneDeep(newValue))
        }
      },
      immediate: true
    },
    formData (newValue) {
      this.form = newValue
    },
    formInvalid: {
      handler (value) {
        this.formInvalidChanged(value)
      },
      immediate: true
    }
  },
  methods: {
    editFenceCenter () {
      this.showLocationEditor = true
    }
  },
  validations () {
    return {
      form: {
        name: {
          required
        },
        fenceRange: {
          required,
          numeric,
          between: between(10, 100000) // 10 meters - 100 km
        }
      },
      fenceCenter: {
        required
      }
    }
  }
}
</script>
<style lang="scss" scoped>

.geo-rule-form {

  .geo-fence {
    .geo-fence-display {
      margin-top: calc(0.375rem + 1px); // match label
    }
    .btn {
      padding-left: 0; // align with display
    }
  }
}
</style>
