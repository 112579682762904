import { inflect } from 'inflection'
import _ from 'lodash'

export function getGroupRowNodeSummary ({gridApi, node, resourceName, showHeaderName = true}) {
  const colDef = node.rowGroupColumn?.colDef
  const headerName = colDef.headerName
  // Use actual field value rather than group key, because group key may be custom id.
  const sampleNode = node.allLeafChildren[0]
  const groupValue = gridApi?.getCellValue?.({ colKey: colDef.field, rowNode: sampleNode }) ?? null
  // TODO: What about exportRenderer?
  const valueFormatter = colDef.context?.groupRowValueFormatter || colDef.valueFormatter
  const groupRenderedValue = (groupValue && valueFormatter ? valueFormatter({ value: groupValue, colDef, data: sampleNode.data }) : groupValue) || '<Blank>'
  let summary = groupRenderedValue
  if (!gridApi.isPivotMode()) {
    // For some reason group header uses allChildrenCount, and footer uses allLeafChildren.length.
    const itemCount = node.allChildrenCount || node.allLeafChildren.length
    const resource = inflect(resourceName || 'item', itemCount)
    summary += ` - ${itemCount} ${resource}`
  }
  return showHeaderName ? `${headerName}: ${summary}` : summary
}
