<template>
  <remote-multiselect
    v-bind="$attrs"
    v-model="forwardValue"
    :multiple="multiple"
    label="name"
    :track-by="trackBy"
    select-label=""
    deselect-label=""
    :serviceFetch="fetchItems"
    resourceName="Label"
    :showAddNewItem="enableAddNewItem && canEditOrgSettings"
    @add-new-item="addNewItem"
  />
</template>
<script>
import RemoteMultiselect from '@/components/RemoteMultiselect.vue'
import LabelFormModal from './LabelFormModal.vue'
import LabelService from './services/LabelService'
import { mapGetters } from 'vuex'
import { useModalController } from 'bootstrap-vue-next'
import { computed, ref } from 'vue'

export default {
  name: 'LabelSelect',
  setup () {
    return {
      showModal: useModalController().show
    }
  },
  inheritAttrs: false,
  props: {
    modelValue: [Array, Number, String],
    labelType: String,
    searchInactive: Boolean,
    multiple: {
      type: Boolean,
      default: true
    },
    useTargetId: Boolean,
    enableAddNewItem: {
      type: Boolean,
      default: true
    }
  },
  emits: ['item-added', 'update:modelValue'],
  computed: {
    ...mapGetters(['canEditOrgSettings']),
    forwardValue: {
      get () {
        return this.modelValue
      },
      set (v) {
        this.$emit('update:modelValue', v)
      }
    },
    fetchItems () {
      // returns a function
      return LabelService.fetchForSelect(this.labelType, this.searchInactive)
    },
    trackBy () {
      return this.useTargetId ? 'targetId' : 'id'
    }
  },
  components: {
    RemoteMultiselect
  },
  methods: {
    addNewItem () {
      const itemId = ref(null)
      this.showModal({
          component: LabelFormModal,
          // TODO: computed?
          props: computed(() => ({
            itemId: itemId.value,
            labelType: this.labelType,
            onSaved: item => {
              itemId.value = item.id
              const newValue = item[this.trackBy]
              this.$emit('update:modelValue', this.multiple ? (this.modelValue || []).concat(newValue) : newValue)
              this.$emit('item-added', item)
            }
          }))
        })
    }
  }
}
</script>
