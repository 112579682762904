<template>
  <div class="costing-permissions">
    <div class="banner" v-if="showVersionBanner">
      <b-badge variant="warning">
        Since version 2.14
        <help-text-icon>
          The full permissions capabilities below are supported in the timeclock mobile app version 2.14+.
          <br><br>
          In earlier versions, the device org units, a single worker department, and geo fences are supported.
          Multiple departments, user labels, {{ enableWorkFields ? 'workers, and work fields' : 'and workers' }} are not supported.
        </help-text-icon>
      </b-badge>
    </div>
    <b-card v-if="!singleOrgUnit" sub-title="Device fields">
      <form-group
        :validator="v$.deviceOrgUnits"
        v-bind="labelConfig"
      >
        <template #label>
          {{ fieldLabels.orgUnits }}
          <help-text-icon>
            <slot name="device-org-unit-help-text">
              This setting controls access to the job, based on the device's Org Unit.
              This setting can only be changed to values based on administrator org unit access.
              Otherwise, the form can not be saved.
              <code>Account Owner</code>s always have access to all org units.
            </slot>
          </help-text-icon>
        </template>
        <template #default="slotProps">
          <key-multiselect
            v-bind="slotProps"
            v-model="deviceOrgUnits"
            placeholder="All Org Units allowed"
            :multiple="true"
            label="name"
            track-by="id"
            select-label=""
            deselect-label=""
            :options="sortedOrgUnits">
          </key-multiselect>
        </template>
      </form-group>
    </b-card>
    <div v-if="!singleOrgUnit" class="and-separator">-- AND --</div>
    <b-card :sub-title="`${this.userMode === 'worker' ? 'Worker' : 'User'} fields`">
      <template v-if="enableUserOrgUnit && !singleOrgUnit">
        <form-group
          :validator="v$.userOrgUnits"
          v-bind="labelConfig"
        >
          <template #label>
            {{ fieldLabels.orgUnits }}
            <help-text-icon>
              <slot name="user-org-unit-help-text">
              </slot>
            </help-text-icon>
          </template>
          <template #default="slotProps">
            <key-multiselect
              v-bind="slotProps"
              v-model="userOrgUnits"
              placeholder="All Org Units allowed"
              :multiple="true"
              label="name"
              track-by="id"
              select-label=""
              deselect-label=""
              :options="sortedOrgUnits">
            </key-multiselect>
          </template>
        </form-group>
        <div class="or-separator">-- {{ fieldSeparator }} --</div>
      </template>
      <template v-if="sortedDepartments.length">
          <form-group :validator="v$.departments" v-bind="labelConfig">
          <template #label>
            {{ fieldLabels.department }}
            <help-text-icon>
              <slot name="user-department-help-text">
                This field allows you to restrict which workers can clock IN to this job.
                Departments can be added or edited under <b>Settings -&gt; Organization -&gt; Departments</b>.
              </slot>
            </help-text-icon>
          </template>
          <template #default="slotProps">
            <key-multiselect
              v-bind="slotProps"
              v-model="departments"
              :multiple="true"
              label="name"
              track-by="id"
              select-label=""
              deselect-label=""
              :placeholder="`${hasUserTypes ? 'No' : 'All'} Departments allowed`"
              :options="sortedDepartments">
            </key-multiselect>
          </template>
        </form-group>
        <div class="or-separator">-- {{ fieldSeparator }} --</div>
      </template>
      <form-group :validator="v$.userLabels" label="User Labels" v-bind="labelConfig">
        <template #default="slotProps">
          <label-select
            v-bind="slotProps"
            v-model="userLabels"
            :id="parentId"
            labelType="user"
            :placeholder="`${hasUserTypes ? 'No' : 'All'} User Labels allowed`"
          />
        </template>
      </form-group>
      <div class="or-separator">-- {{ fieldSeparator }} --</div>
      <form-group :validator="v$.orgUsers" label="Workers" v-bind="labelConfig">
        <template #default="slotProps">
          <worker-multiselect
            v-bind="slotProps"
            v-model="orgUsers"
            :multiple="true"
            :placeholder="`${hasUserTypes ? 'No' : 'All'} Workers allowed`"
            :contextId="parentId"
          />
        </template>
      </form-group>
      <template v-if="enableUserCustomFields && userCustomFields.length > 0">
        <template v-for="(customField, index) in userCustomFields" :key="customField.id">
          <div class="or-separator">-- {{ fieldSeparator }} --</div>
          <div class="banner" v-if="showVersionBanner && index === 0" :key="customField.id+'banner'">
            <b-badge variant="warning">
              Since version 3.0
              <help-text-icon>
                Custom Fields are supported in the timeclock mobile app version 3.0+.
                <br><br>
                Older versions will ignore the following conditions.
              </help-text-icon>
            </b-badge>
          </div>
          <custom-field-filter
            :customField="customField"
            v-model="userCustomFieldFilters[index]"
            :labelConfig="labelConfig"
            :placeholder="`${hasUserTypes ? 'No' : 'All'} {fieldPlural} allowed`"
          />
        </template>
      </template>
    </b-card>
    <template v-if="enableWorkFields && (!singleOrgUnit || costingEnabled || scheduleEnabled)">
      <div class="and-separator">-- AND --</div>
      <b-card sub-title="Work fields">
        <template v-if="enableWorkOrgUnit">
          <form-group
            :validator="v$.workOrgUnits"
            v-bind="labelConfig"
          >
            <template #label>
              {{ fieldLabels.orgUnits }}
              <help-text-icon>
                <slot name="work-org-unit-help-text">
                </slot>
              </help-text-icon>
            </template>
            <template #default="slotProps">
              <key-multiselect
                v-bind="slotProps"
                v-model="workOrgUnits"
                placeholder="All Org Units allowed"
                :multiple="true"
                label="name"
                track-by="id"
                select-label=""
                deselect-label=""
                :options="sortedOrgUnits">
              </key-multiselect>
            </template>
          </form-group>
          <div class="or-separator">-- {{ fieldSeparator }} --</div>
        </template>
        <template v-if="costingEnabled">
          <form-group :validator="v$.jobs" label="Jobs" v-bind="labelConfig">
            <template #default="slotProps">
              <job-multiselect
                v-bind="slotProps"
                v-model="jobs"
                :multiple="true"
                :placeholder="`${hasWorkTypes ? 'No' : 'All'} Jobs allowed`"
                :contextId="parentId"
              />
            </template>
          </form-group>
          <div class="or-separator">-- {{ fieldSeparator }} --</div>
          <template v-if="jobPhaseEnabled">
            <form-group :validator="v$.jobPhases" label="Job Phases" v-bind="labelConfig">
              <template #default="slotProps">
                <job-phase-multiselect
                  v-bind="slotProps"
                  v-model="jobPhases"
                  :multiple="true"
                  :placeholder="`${hasWorkTypes ? 'No' : 'All'} Job Phases allowed`"
                  :contextId="parentId"
                />
              </template>
            </form-group>
            <div class="or-separator">-- {{ fieldSeparator }} --</div>
          </template>
        </template>
        <form-group :validator="v$.userLabels" label="Work Labels" v-bind="labelConfig">
          <template #default="slotProps">
            <label-select
              v-bind="slotProps"
              v-model="workLabels"
              :id="parentId"
              labelType="work"
              :placeholder="`${hasWorkTypes ? 'No' : 'All'} Work Labels allowed`"
            />
          </template>
        </form-group>
        <template v-if="enableWorkCustomFields && workCustomFields.length > 0">
          <template v-for="(customField, index) in workCustomFields" :key="customField.id">
            <div class="or-separator">-- {{ fieldSeparator }} --</div>
            <custom-field-filter
              :customField="customField"
              v-model="workCustomFieldFilters[index]"
              :labelConfig="labelConfig"
              :placeholder="`${hasWorkTypes ? 'No' : 'All'} {fieldPlural} allowed`"
            />
          </template>
        </template>
      </b-card>
    </template>
    <div class="invalid-feedback" style="display: block" v-if="v$.modelValue.$invalid">
      {{ v$.modelValue.$silentErrors[0].$message }}
    </div>
  </div>
</template>
<script>
import HelpTextIcon from '@/components/HelpTextIcon.vue'
import KeyMultiselect from '@/components/KeyMultiselect.vue'
import LabelSelect from '@/views/settings/organization/LabelSelect.vue'
import JobMultiselect from '@/components/JobMultiselect.vue'
import JobPhaseMultiselect from '@/components/JobPhaseMultiselect.vue'
import WorkerMultiselect from '@/components/WorkerMultiselect.vue'
import { mapGetters } from 'vuex'
import _ from 'lodash'
import ChildValidation from '@/mixins/ChildValidation'
import { ManagesUserMixedCustomFieldFilters, ManagesWorkMixedCustomFieldFilters } from '@/mixins/ManagesCustomFieldFilters'
import CustomFieldFilter from '@/components/CustomFieldFilter.vue'
import { useVuelidate } from '@vuelidate/core'
import { helpers } from '@vuelidate/validators'

const MAX_VALUES = 30

export default {
  name: 'FieldConditions',
  setup () {
    return { v$: useVuelidate() }
  },
  components: {
    CustomFieldFilter,
    HelpTextIcon,
    KeyMultiselect,
    LabelSelect,
    JobMultiselect,
    JobPhaseMultiselect,
    WorkerMultiselect
  },
  mixins: [
    ChildValidation,
    ManagesUserMixedCustomFieldFilters,
    ManagesWorkMixedCustomFieldFilters
  ],
  props: {
    modelValue: Array,
    parentId: Number,
    enableUserOrgUnit: Boolean,
    enableUserCustomFields: {
      type: Boolean,
      default: true
    },
    enableWorkFields: Boolean,
    enableWorkOrgUnit: Boolean,
    enableJobPhase: Boolean,
    enableWorkCustomFields: Boolean,
    showVersionBanner: Boolean,
    userEnableFilterCustomFields: {
      default: true
    },
    workEnableFilterCustomFields: {
      default: true
    },
    userMode: {
      type: String,
      default: 'worker',
      validator: value => ['worker', 'orguser'].includes(value)
    },
    labelConfig: {
      type: Object,
      default: () => ({
        labelCols: 12,
        labelColsSm: 4,
        labelColLg: 4,
        labelColsXl: 4
      })
    },
    fieldSeparator: {
      type: String,
      default: 'OR'
    },
    setAll: {
      type: Boolean,
      default: true
    }
  },
  emits: ['update:modelValue'],
  data () {
    return {
      departments: [],
      userLabels: [],
      orgUsers: [],
      userCustomFieldsFormData: [],
      deviceOrgUnits: [],
      userOrgUnits: [],
      workOrgUnits: [],
      workLabels: [],
      jobs: [],
      jobPhases: [],
      workCustomFieldsFormData: [],
      fieldLabels: {
        department: 'Departments',
        orgUnits: 'Org Units',
      }
    }
  },
  computed: {
    ...mapGetters(['accessAllOrgUnits', 'enhancedCostingEnabled', 'costingEnabled', 'scheduleEnabled']),
    sortedDepartments () {
      return this.$store.getters['departments/sortedItems']('name').filter(item => item.active)
    },
    sortedOrgUnits () {
      return this.$store.getters['orgUnits/sortedItems']('name').filter(item => item.active)
    },
    singleOrgUnit () {
      return this.accessAllOrgUnits && this.sortedOrgUnits.length === 1
    },
    hasUserTypes () {
      return (this.modelValue || []).some(v => v.startsWith('user:') && v !== 'user:all')
    },
    hasWorkTypes () {
      return (this.modelValue || []).some(v => v.startsWith('work:') && v !== 'work:all')
    },
    userCustomFieldsFormPropertyPath () {
      return 'userCustomFieldsFormData'
    },
    workCustomFieldsFormPropertyPath () {
      return 'workCustomFieldsFormData'
    },
    jobPhaseEnabled () {
      return this.enableJobPhase && this.enhancedCostingEnabled
    }
  },
  watch: {
    departments () {
      this.updateConditions()
    },
    jobs () {
      this.updateConditions()
    },
    jobPhases () {
      this.updateConditions()
    },
    deviceOrgUnits () {
      this.updateConditions()
    },
    userOrgUnits () {
      this.updateConditions()
    },
    workOrgUnits () {
      this.updateConditions()
    },
    userLabels () {
      this.updateConditions()
    },
    workLabels () {
      this.updateConditions()
    },
    orgUsers () {
      this.updateConditions()
    },
    userCustomFieldsFormData () {
      this.updateConditions()
    },
    workCustomFieldsFormData () {
      this.updateConditions()
    },
    modelValue: {
      handler (value) {
        this.deviceOrgUnits = this.getValuesForPrefix('device:orgunit')
        if (this.enableUserOrgUnit) {
          this.userOrgUnits = this.getValuesForPrefix('user:orgunit')
        }
        this.userOrgUnits = this.getValuesForPrefix('user:orgunit')
        this.departments = this.getValuesForPrefix('user:dept')
        this.orgUsers = this.getValuesForPrefix(`user:${this.userMode}`)
        this.userLabels = this.getValuesForPrefix('user:label')
        if (this.enableUserCustomFields) {
          this.userCustomFieldsFormData = this.getValuesForPrefix('user:custom')
        }
        if (this.enableWorkFields) {
          if (this.enableWorkOrgUnit) {
            this.workOrgUnits = this.getValuesForPrefix('work:orgunit')
          }
          this.jobs = this.getValuesForPrefix('work:job')
          if (this.jobPhaseEnabled) {
            this.jobPhases = this.getValuesForPrefix('work:jobphase')
          }
          this.workLabels = this.getValuesForPrefix('work:label')
          if (this.enableWorkCustomFields) {
            this.workCustomFieldsFormData = this.getValuesForPrefix('work:custom')
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    updateConditions() {
      let conditions = []

      // device fields
      if (!_.isEmpty(this.deviceOrgUnits)) {
        conditions = conditions.concat(this.deviceOrgUnits.map(id => `device:orgunit:${id}`))
      } else if(this.setAll) {
        conditions.push('device:all')
      }

      // user fields
      if (this.enableUserOrgUnit && !_.isEmpty(this.userOrgUnits)) {
        conditions = conditions.concat(this.userOrgUnits.map(id => `user:orgunit:${id}`))
      }
      if (!_.isEmpty(this.departments)) {
        conditions = conditions.concat(this.departments.map(id => `user:dept:${id}`))
      }
      if (!_.isEmpty(this.userLabels)) {
        conditions = conditions.concat(this.userLabels.map(id => `user:label:${id}`))
      }
      if (!_.isEmpty(this.orgUsers)) {
        conditions = conditions.concat(this.orgUsers.map(id => `user:${this.userMode}:${id}`))
      }
      if (this.enableUserCustomFields && !_.isEmpty(this.userCustomFieldsFormData)) {
        conditions = conditions.concat(this.userCustomFieldsFormData.map(v => `user:custom:${v}`))
      }
      if (this.setAll && !conditions.some(l => l.startsWith('user:'))) {
        conditions.push('user:all')
      }

      // work fields
      if (this.enableWorkFields) {
        if (this.enableWorkOrgUnit && !_.isEmpty(this.workOrgUnits)) {
          conditions = conditions.concat(this.workOrgUnits.map(id => `work:orgunit:${id}`))
        }
        if (!_.isEmpty(this.jobs)) {
          conditions = conditions.concat(this.jobs.map(id => `work:job:${id}`))
        }
        if (this.jobPhaseEnabled && !_.isEmpty(this.jobPhases)) {
          conditions = conditions.concat(this.jobPhases.map(id => `work:jobphase:${id}`))
        }
        if (!_.isEmpty(this.workLabels)) {
          conditions = conditions.concat(this.workLabels.map(id => `work:label:${id}`))
        }
        if (this.enableWorkCustomFields && !_.isEmpty(this.workCustomFieldsFormData)) {
          conditions = conditions.concat(this.workCustomFieldsFormData.map(v => `work:custom:${v}`))
        }
        if (this.setAll && !conditions.some(l => l.startsWith('work:'))) {
          conditions.push('work:all')
        }
      }

      // TODO: We're not doing this, right?
      // If there are no access restrictions, then leave field empty.
      // if (conditions.every(l => l.endsWith(':all'))) conditions = []

      if (!_.isEqual(_.sortBy(conditions), _.sortBy(this.modelValue || []))) {
        this.$emit('update:modelValue', conditions)
      }
    },
    getValuesForPrefix (prefix) {
      return (this.modelValue || [])
        .filter(label => label.startsWith(`${prefix}:`))
        .map(label => {
          const value = label.replace(`${prefix}:`, '')
          // Custom field values are not an integer, so return raw value if can't be parsed into integer.
          return isNaN(value) ? value : parseInt(value)
        })
    },
    orgUnitAccessValidForAdmin (value) {
      if (this.accessAllOrgUnits) return true
      if (_.isEmpty(value)) return false
      const adminOrgUnits = this.sortedOrgUnits.map(item => item.id)
      return _.without(value, ...adminOrgUnits).length === 0
    }
  },
  created () {
    this.$store.dispatch('departments/load')
    this.$store.dispatch('orgUnits/load')
  },
  validations () {
    return {
      modelValue: {
        maxLength: helpers.withMessage(
          `A maximum of ${MAX_VALUES} permission values above may be set.`,
          value => (value || []).filter(v => !v.endsWith(':all')).length <= MAX_VALUES
        )
      },
      departments: {},
      userLabels: {},
      orgUsers: {},
      workLabels: {},
      jobs: {},
      jobPhases: {},
      deviceOrgUnits: {
        orgUnitAccessValidForAdmin: helpers.withMessage(
          'You do not have permission to allow the currently configured Org Units.',
          value => this.orgUnitAccessValidForAdmin(value)
        )
      },
      userOrgUnits: {
        orgUnitAccessValidForAdmin: helpers.withMessage(
          'You do not have permission to allow the currently configured Org Units.',
          value => this.orgUnitAccessValidForAdmin(value)
        )
      },
      workOrgUnits: {
        orgUnitAccessValidForAdmin: helpers.withMessage(
          'You do not have permission to allow the currently configured Org Units.',
          value => this.orgUnitAccessValidForAdmin(value)
        )
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.costing-permissions {
  .banner {
    margin-bottom: 10px;
    font-size: 1.1rem;
    :deep(svg) {
      color: black;
    }
  }
  :deep(.multiselect, .form-control) {
    max-width: 20rem;
  }
  .or-separator {
    text-align: center;
    margin: 10px 0;
  }
  .and-separator {
    text-align: center;
    margin: 10px 0;
  }
}
</style>
