import payrollClient from '@/services/clients/payroll'

const resourceName = 'Time Off Balance'
const resourcePath = 'timeoff/balance'

export default {
  async list (params) {
    try {
      const response = await payrollClient.get(resourcePath, {
        params: {
          ...params
        }
      })
      return response.data
    } catch (error) {
      console.warn(`Error getting ${resourceName} list`, error)
      throw error
    }
  },
  async listWorker (workerId) {
    try {
      const response = await payrollClient.get(`${resourcePath}/workers/${workerId}`)
      return response.data
    } catch (error) {
      console.warn(`Error getting ${resourceName} list`, error)
      throw error
    }
  }
}
