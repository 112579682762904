<template>
  <multiselect
    v-bind="$attrs"
    v-model="forwardValue"
    :options="options"
    label="label"
    track-by="value"
    select-label=""
    deselect-label=""
    :allowEmpty="false"
    class="end-day-offset-select"
  />
</template>
<script>
import Multiselect from 'vue-multiselect'
import { range } from 'lodash'

export default {
  name: 'EndDayOffsetSelect',
  components: {
    Multiselect
  },
  props: {
    modelValue: Number,
    showPriorDay: Boolean
  },
  emits: ['update:modelValue'],
  inheritAttrs: false,
  data () {
    return {
      options: [
        ...(this.showPriorDay ? [{ label: '-1 day', value: -1 }] : []),
        { label: 'Same day', value: 0 },
        { label: '+1 day', value: 1 },
      ].concat(range(2, 7).map(n => ({ label: `+${n} days`, value: n })))
    }
  },
  computed: {
    forwardValue: {
      get () {
        return this.options.find(o => o.value == this.modelValue)
      },
      set (v) {
        this.$emit('update:modelValue', v && v.value)
      }
    }
  }
}
</script>
