<template>
  <div class="shift-class-form" role="tablist">
    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-btn v-b-toggle.collapseBasic block :variant="v$.$validationGroups.basicSettingsGroup.$invalid ? 'danger' : 'dark'">
          Basic Settings
        </b-btn>
      </b-card-header>
      <b-collapse id="collapseBasic" visible role="tabpanel">
        <b-card-body>
          <b-container>
            <b-row>
              <b-col cols="12" sm="6">
                <form-group :validator="v$.form.name" :label="fieldLabels.name">
                  <template #default="slotProps">
                    <b-form-input
                      v-bind="slotProps"
                      v-model="form.name"
                      trim
                      type="text"
                    />
                  </template>
                </form-group>
              </b-col>
              <b-col cols="12" sm="6">
                <form-group>
                  <b-form-checkbox v-model="form.active">
                    Active
                  </b-form-checkbox>
                </form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" sm="6">
                <form-group :validator="v$.form.shiftType" :label="fieldLabels.shiftType">
                  <template #default="slotProps">
                    <b-form-radio-group v-model="form.shiftType"
                      v-bind="slotProps"
                    >
                      <b-form-radio value="fixedTime">{{ fieldLabels.shiftTypeFixed }}</b-form-radio>
                      <b-form-radio value="flexible">{{ fieldLabels.shiftTypeFlexible }}</b-form-radio>
                    </b-form-radio-group>
                  </template>
                </form-group>
              </b-col>
              <b-col cols="12" sm="6">
                <form-group label="Color on Schedule">
                  <color-picker-button v-model="form.color" />
                </form-group>
              </b-col>
          </b-row>
          </b-container>
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body class="mb-1" v-show="form.shiftType === 'fixedTime'">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-btn v-b-toggle.collapseFixed block :variant="v$.$validationGroups.fixedTimeSettingsGroup.$invalid ? 'danger' : 'dark'">
          {{ fieldLabels.shiftTypeFixed }} Settings
        </b-btn>
      </b-card-header>
      <b-collapse id="collapseFixed" visible role="tabpanel">
        <b-card-body v-if="form.shiftType === 'fixedTime'">
          <b-container>
            <b-row>
              <b-col cols="12" class="complex-field">
                The normal shift time is from
                <form-group :validator="v$.form.start">
                  <template #default="slotProps">
                    <time-picker v-model="form.start"
                      v-bind="slotProps"
                      :step="15"
                      size="sm"
                    />
                  </template>
                </form-group>
                <form-group :validator="v$.form.startDay"
                  v-if="form.shiftCrossesMidnight"
                >
                  <template #default="slotProps">
                    <start-day-offset-select v-model="form.startDay"
                      v-bind="slotProps"
                    />
                  </template>
                </form-group>
                until
                <form-group :validator="v$.form.end">
                  <template #default="slotProps">
                    <time-picker v-model="form.end"
                      v-bind="slotProps"
                      :step="15"
                      size="sm"
                    />
                  </template>
                </form-group>
                <form-group :validator="v$.form.endDay"
                  v-if="form.shiftCrossesMidnight"
                >
                  <template #default="slotProps">
                    <end-day-offset-select v-model="form.endDay"
                      v-bind="slotProps"
                    />
                  </template>
                </form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <form-group>
                  <b-form-checkbox v-model="form.shiftCrossesMidnight">
                    The shift can cross midnight
                    <help-text-icon>
                      If checked, then all the fixed shift times in this section will allow you to select a day offset.
                      The day offset is relative to the scheduled date of the shift.
                    </help-text-icon>
                  </b-form-checkbox>
                </form-group>
              </b-col>
            </b-row>
            <b-row><b-col class="rule-title">Lock-out rules</b-col></b-row>
            <b-row>
              <b-col cols="12" class="rule-field">
                Start Early: The worker can clock IN as early as
                <form-group :validator="v$.form.startEarlyLockout">
                  <template #default="slotProps">
                    <time-picker v-model="form.startEarlyLockout"
                      v-bind="slotProps"
                      :step="15"
                      size="sm"
                    />
                  </template>
                </form-group>
                <form-group :validator="v$.form.startEarlyLockoutDay"
                  v-if="form.shiftCrossesMidnight"
                >
                  <template #default="slotProps">
                    <start-day-offset-select v-model="form.startEarlyLockoutDay"
                      v-bind="slotProps"
                    />
                  </template>
                </form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" class="rule-field]">
                End Late: The worker can clock OUT as late as
                <form-group :validator="v$.form.endLateLockout">
                  <template #default="slotProps">
                    <time-picker v-model="form.endLateLockout"
                      v-bind="slotProps"
                      :step="15"
                      size="sm"
                    />
                  </template>
                </form-group>
                <form-group :validator="v$.form.endLateLockoutDay"
                  v-if="form.shiftCrossesMidnight"
                >
                  <template #default="slotProps">
                    <end-day-offset-select v-model="form.endLateLockoutDay"
                      v-bind="slotProps"
                    />
                  </template>
                </form-group>
              </b-col>
            </b-row>
            <b-row><b-col class="rule-title">Revision rules</b-col></b-row>
            <b-row>
              <revision-rules
                :items="form.revisionRules"
                :shiftCrossesMidnight="form.shiftCrossesMidnight"
                :shiftStartTime="form.start"
                :shiftStartDay="form.startDay || 0"
                :shiftEndTime="form.end"
                :shiftEndDay="form.endDay || 0"
                :startEarlyLockout="form.startEarlyLockout"
                :startEarlyLockoutDay="form.startEarlyLockoutDay || 0"
                :endLateLockout="form.endLateLockout"
                :endLateLockoutDay="form.endLateLockoutDay || 0"
                @validity-changed="revisionRulesValid = $event"
              />
            </b-row>

            <b-row><b-col class="rule-title">Shift threshold rule</b-col></b-row>
            <b-row>
              <b-col cols="12" class="complex-field">
                The threshold to tag a shift as exceeding is
                <form-group :validator="v$.form.exceedThreshold"
                  :description="$t('duration.description')"
                >
                  <template #default="slotProps">
                    <duration-input
                      v-bind="slotProps"
                      v-model="form.exceedThreshold"
                      hourDigits="3" />
                  </template>
                </form-group>
                hours.
                <help-text-icon>
                  This is an optional field used to mark the worker as exceeding the shift duration once they
                  exceed this time. It's only used for fixed time shifts. If not set, it defaults to the start->end duration.
                  <br><br>
                  One example where this field may useful is when paying shift-based overtime.
                  In such a case, the start-end duration might not be sufficient if the employer has a leave early grace
                  period rule based on the end time, but wants to pay overtime before that duration.
                </help-text-icon>
              </b-col>
            </b-row>
          </b-container>
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body class="mb-1" v-show="form.shiftType === 'flexible'">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-btn v-b-toggle.collapseFlexible block :variant="v$.$validationGroups.flexibleTimeSettingsGroup.$invalid ? 'danger' : 'dark'">
          {{ fieldLabels.shiftTypeFlexible }} Settings
        </b-btn>
      </b-card-header>
      <b-collapse id="collapseFlexible" visible role="tabpanel">
        <b-card-body v-if="form.shiftType === 'flexible'">
          <b-container>
            <b-row>
              <b-col cols="12" class="complex-field">
                The shift length is normally
                <form-group :validator="v$.form.flexibleDuration"
                  :description="$t('duration.description')"
                >
                  <template #default="slotProps">
                    <duration-input
                      v-bind="slotProps"
                      v-model="form.flexibleDuration"
                      hourDigits="3" />
                  </template>
                </form-group>
                hours.
                <help-text-icon>
                  The actual shift length is calculated from the first IN punch for the shift,
                  and includes breaks. The two settings below are also calculated in the same way.
                </help-text-icon>
              </b-col>
            </b-row>

            <b-row><b-col class="rule-title">Lock-out rule</b-col></b-row>
            <b-row>
              <b-col cols="12" class="rule-field">
                The worker can clock OUT as late as
                <form-group :validator="v$.form.flexibleLockoutDuration"
                  :description="$t('duration.description')"
                >
                  <template #default="slotProps">
                    <duration-input
                      v-bind="slotProps"
                      v-model="form.flexibleLockoutDuration"
                      hourDigits="3" />
                  </template>
                </form-group>
                hours after the shift started.
                <help-text-icon>
                  The normal shift length is the maximum amount of time that a worker can get paid to work on this shift, including breaks.
                  If they work less, they will be paid the amount of time they actually work.
                  If you also specify this lock-out rule duration, then the worker can work longer past the normal up to this lock-out duration,
                  and will be paid for it, but the time card will be flagged with a warning for working late.
                  This field is also useful when paying overtime by shift. In such a case, the normal shift length will be the overtime threshold.
                  If this lock-out field is not specified, then the worker will not be able to clock OUT after the normal shift duration has passed.
                </help-text-icon>
              </b-col>
            </b-row>

            <b-row><b-col class="rule-title">Revision rule</b-col></b-row>
            <b-row>
              <b-col cols="12" class="rule-field">
                If worker clocks OUT late between {{ formatMinutesAsDuration(form.flexibleDuration) }} and
                <form-group :validator="v$.form.flexibleRevisionDuration"
                  :description="$t('duration.description')"
                >
                  <template #default="slotProps">
                    <duration-input
                      v-bind="slotProps"
                      v-model="form.flexibleRevisionDuration"
                      hourDigits="3" />
                  </template>
                </form-group>
                hours after the shift started, then stop accruing {{ formatMinutesAsDuration(form.flexibleDuration) }} hours after the shift started.
                <help-text-icon>
                  This revision rule is an optional field that is used to allow a worker to work past the normal shift length, but not get paid past
                  the normal length unless an administrator approves it under
                  <router-link :to="{ name: 'timecards' }">Manage => Time Cards</router-link>.
                </help-text-icon>
              </b-col>
            </b-row>

            <b-row><b-col class="rule-title">Leave early rule</b-col></b-row>
            <b-row>
              <b-col cols="12" class="complex-field">
                If the worker works less than
                <form-group :validator="v$.form.flexibleMinDuration"
                  :description="$t('duration.description')"
                >
                  <template #default="slotProps">
                    <duration-input
                      v-bind="slotProps"
                      v-model="form.flexibleMinDuration"
                      hourDigits="3" />
                  </template>
                </form-group>
                hours, then an exception will be flagged for leaving work early.
                <help-text-icon>
                  This setting is optional, and does not affect total hours or pay calculation.
                  It just controls whether an exception is flagged in the reports for disciplinary purposes.
                  If left blank, if the workers clocks OUT before the normal shift length completes, then the exception will be flagged.
                </help-text-icon>
              </b-col>
            </b-row>
          </b-container>
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-btn v-b-toggle.collapseExtra block :variant="v$.$validationGroups.extraSettingsGroup.$invalid ? 'danger' : 'dark'">
          Extra Settings
        </b-btn>
      </b-card-header>
      <b-collapse id="collapseExtra" visible role="tabpanel">
        <b-card-body>
          <b-container>
            <b-row>
              <b-col cols="12" sm="6">
                <form-group label="Breaks" label-cols="3">
                  <break-select
                    v-model="form.breaks"
                    :id="form.id"
                  />
                </form-group>
              </b-col>
              <b-col cols="12" sm="6">
                <form-group>
                  <b-form-checkbox v-model="form.combineBreaks">
                    Breaks may be combined
                    <help-text-icon>
                      If breaks may be combined, then workers may use all their
                      allocated break time during any one of the break periods.
                    </help-text-icon>
                  </b-form-checkbox>
                </form-group>
              </b-col>
            </b-row>
            <b-row><b-col class="rule-title">Rounding rules</b-col></b-row>
            <b-row>
              <b-col cols="12" sm="6">
                <form-group label="Rounding rule when starting shift">
                  <rounding-rule-select
                    v-model="form.startRoundingRule"
                    :id="form.id"
                  />
                </form-group>
              </b-col>
              <b-col cols="12" sm="6">
                <form-group>
                  <template #label>
                    Rounding rule for other IN punches
                    <help-text-icon>
                      The rounding rules for IN punches and OUT punches do not apply either to the first
                      IN punch for shift or the last OUT punch for shift.
                      Those two punches use the rounding rules for starting and ending the shift above.
                    </help-text-icon>
                  </template>
                  <rounding-rule-select
                    v-model="form.inRoundingRule"
                    :id="form.id"
                  />
                </form-group>
              </b-col>
              <b-col cols="12" sm="6">
                <form-group label="Rounding rule when ending shift">
                  <rounding-rule-select
                    v-model="form.endRoundingRule"
                    :id="form.id"
                  />
                </form-group>
              </b-col>
              <b-col cols="12" sm="6">
                <form-group label="Rounding rule for other OUT punches">
                  <rounding-rule-select
                    v-model="form.outRoundingRule"
                    :id="form.id"
                  />
                </form-group>
              </b-col>
            </b-row>
            <b-row><b-col class="rule-title">Miscellaneous</b-col></b-row>
            <b-row>
                <b-col cols="12" sm="6">
                  <form-group>
                    <template #label>
                      Labels
                      <help-text-icon>
                        Labels may be edited under Settings => Organization => Labels.
                      </help-text-icon>
                    </template>
                    <label-select
                      v-model="form.labels"
                      :id="form.id"
                      labelType="work"
                    />
                  </form-group>
                </b-col>
            </b-row>
            <b-row v-if="geoEnabled">
                <b-col cols="12" sm="6">
                  <form-group>
                    <template #label>
                      Geo Fences
                      <help-text-icon>
                        A geo fence is an optional field, used to enforce the geographical location where this user can Clock IN or OUT.
                        Geo fences may be edited under <b>Settings =&gt; Devices =&gt; Geo Fences</b>.
                      </help-text-icon>
                    </template>
                    <geo-rule-select
                      v-model="form.geoRules"
                      :id="form.id"
                    />
                  </form-group>
                </b-col>
            </b-row>
          </b-container>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>
<script>
import { useVuelidate } from '@vuelidate/core'
import { integer, maxLength, required, requiredIf } from '@vuelidate/validators'
import { mapGetters } from 'vuex'
import _ from 'lodash'
import ColorPickerButton from '@/components/ColorPickerButton.vue'
import DurationInput from '@/components/DurationInput.vue'
import HelpTextIcon from '@/components/HelpTextIcon.vue'
import TimePicker from '@/components/TimePicker.vue'
import StartDayOffsetSelect from './StartDayOffsetSelect.vue'
import EndDayOffsetSelect from './EndDayOffsetSelect.vue'
import RevisionRules from './RevisionRules.vue'
import { durationMaxValue, durationMinValue, timeDayMaxValue, timeDayMinValue, time } from '@/utils/validators'
import { DefaultShiftColor, stripSecondsFromTimeString } from '@/utils/misc'
import LabelService from '../organization/services/LabelService'
import DetailBasedForm from '@/mixins/DetailBasedForm'
import BreakSelect from './BreakSelect.vue'
import GeoRuleSelect from '@/views/settings/devices/GeoRuleSelect.vue'
import LabelSelect from '@/views/settings/organization/LabelSelect.vue'
import RoundingRuleSelect from './RoundingRuleSelect.vue'

const NewItem = () => ({
  active: true,
  start: null,
  end: null,
  startEarlyLockout: null,
  endLateLockout: null,
  shiftCrossesMidnight: false,
  startDay: null,
  endDay: null,
  startEarlyLockoutDay: null,
  endLateLockoutDay: null,
  revisionRules: [],
  exceedThreshold: null,
  flexibleDuration: null,
  flexibleRevisionDuration: null,
  flexibleLockoutDuration: null,
  flexibleMinDuration: null,
  breaks: [],
  combineBreaks: false,
  startRoundingRule: null,
  endRoundingRule: null,
  inRoundingRule: null,
  outRoundingRule: null,
  labels: [],
  geoRules: [],
  color: DefaultShiftColor
})

export default {
  name: 'ShiftClassForm',
  setup () {
    return { v$: useVuelidate() }
  },
  inheritAttrs: false,
  mixins: [DetailBasedForm],
  components: {
    BreakSelect,
    ColorPickerButton,
    DurationInput,
    HelpTextIcon,
    GeoRuleSelect,
    LabelSelect,
    RoundingRuleSelect,
    TimePicker,
    StartDayOffsetSelect,
    EndDayOffsetSelect,
    RevisionRules
  },
  data () {
    return {
      form: NewItem(),
      fieldLabels: {
        name: 'Name',
        shiftType: 'Shift Type',
        shiftTypeFixed: 'Fixed Time',
        shiftTypeFlexible: 'Flexible Time',
        exceedThreshold: 'Exceed shift threshold',
        flexibleDuration: 'Shift length',
        flexibleRevisionDuration: 'Shift revision length',
        flexibleLockoutDuration: 'Shift lockout length',
        flexibleMinDuration: 'Shift minimum length'
      },
      revisionRulesValid: true
    }
  },
  computed: {
    ...mapGetters(['geoEnabled']),
    ...mapGetters('formatPreferences', ['modelToFormattedTime', 'formatMinutesAsDuration']),
    ...mapGetters({
      roundingRules: 'roundingRules/sortedItems'
    }),
    sortedRoundingRules () {
      return this.roundingRules('name')
    },
    formInvalid () {
      return !!this.v$?.$invalid
    },
    invalidFields () {
      const fields = Object.keys(this.v$.form.$params)
        .filter(fieldName => this.v$.form[fieldName].$invalid)
      return fields
    }
  },
  watch: {
    originalData: {
      handler (newValue) {
        if (_.isEmpty(newValue)) {
          const formData = NewItem()
          this.formDataChanged(formData)
        } else {
          newValue.active = newValue.active !== false
          newValue.start = stripSecondsFromTimeString(newValue.start)
          newValue.end = stripSecondsFromTimeString(newValue.end)
          newValue.startEarlyLockout = stripSecondsFromTimeString(newValue.startEarlyLockout)
          newValue.endLateLockout = stripSecondsFromTimeString(newValue.endLateLockout)
          newValue.startDay = newValue.startDay || (newValue.shiftCrossesMidnight ? 0 : null)
          newValue.revisionRules = (newValue.revisionRules || [])
            .map(rule => ({
              ...rule,
              startTime: stripSecondsFromTimeString(rule.startTime),
              endTime: stripSecondsFromTimeString(rule.endTime),
              reviseToTime: stripSecondsFromTimeString(rule.reviseToTime),
            }))
          newValue.flexibleRevisionDuration = newValue.flexibleRevisionDuration || null
          newValue.flexibleLockoutDuration = newValue.flexibleLockoutDuration || null
          newValue.flexibleMinDuration = newValue.flexibleMinDuration || null
          newValue.color = newValue.color || DefaultShiftColor
          // TODO: any legacy compatibility
          this.formDataChanged(_.cloneDeep(newValue))
        }
      },
      immediate: true
    },
    formData (newValue) {
      this.form = newValue
      this.revisionRulesValid = true
    },
    formInvalid: {
      handler (value) {
        this.formInvalidChanged(value)
      },
      immediate: true
    },
    'form.shiftType' (shiftType) {
      if (shiftType !== 'fixedTime') {
        this.form.start = null
        this.form.end = null
        this.form.shiftCrossesMidnight = false
        this.form.startEarlyLockout = null
        this.form.endLateLockout = null
        this.form.revisionRules = []
      }
      if (shiftType !== 'flexible') {
        this.form.flexibleDuration = null
        this.form.flexibleRevisionDuration = null
        this.form.flexibleLockoutDuration = null
        this.form.flexibleMinDuration = null
      }
    },
    // populate initial lock-out values
    'form.start' (start) {
      if (start && !this.form.startEarlyLockout) this.form.startEarlyLockout = start
    },
    'form.end' (end) {
      if (end && !this.form.endLateLockout) this.form.endLateLockout = end
    },
    'form.shiftCrossesMidnight' (shiftCrossesMidnight) {
      const resetValue = v => shiftCrossesMidnight ? v || 0 : null

      this.form.startDay = resetValue(this.form.startDay)
      this.form.endDay = resetValue(this.form.endDay)
      this.form.startEarlyLockoutDay = resetValue(this.form.startEarlyLockoutDay)
      this.form.endLateLockoutDay = resetValue(this.form.endLateLockoutDay)
    }
    // TODO: watchers clear unused dependent fields
  },
  methods: {
    fetchLabels: LabelService.fetchForSelect('work'),
  },
  mounted () {
    this.$store.dispatch('roundingRules/load')
  },
  validations () {
    const v = {
      $validationGroups: {
        basicSettingsGroup: ['form.name', 'form.shiftType'],
        fixedTimeSettingsGroup: [],
        flexibleTimeSettingsGroup: [],
        extraSettingsGroup: [],
      },
      form: {
        name: {
          required,
          maxLength: maxLength(40)
        },
        shiftType: {
          required
        }
      }
    }

    if (this.form.shiftType === 'fixedTime') {
      v.$validationGroups.fixedTimeSettingsGroup = [
        'form.start',
        'form.end',
        'form.startEarlyLockout',
        'form.endLateLockout',
        'form.startDay',
        'form.endDay',
        'form.startEarlyLockoutDay',
        'form.endLateLockoutDay',
        'form.revisionRules',
        'form.exceedThreshold'
      ]
      Object.assign(v.form, {
        start: {
          required,
          time
        },
        end: {
          required,
          time,
          minValue: timeDayMinValue(
            this.form.endDay,
            this.form.start,
            this.form.startDay,
            this.modelToFormattedTime)
        },
        startEarlyLockout: {
          required,
          time,
          maxValue: timeDayMaxValue(
            this.form.startEarlyLockoutDay,
            this.form.start,
            this.form.startDay,
            this.modelToFormattedTime)
        },
        endLateLockout: {
          required,
          time,
          minValue: timeDayMinValue(
            this.form.endLateLockoutDay,
            this.form.end,
            this.form.endDay,
            this.modelToFormattedTime)
        },
        revisionRules: {
          valid: () => this.revisionRulesValid
        },
        startDay: {
          required: requiredIf(() => this.form.shiftCrossesMidnight)
        },
        endDay: {
          required: requiredIf(() => this.form.shiftCrossesMidnight)
        },
        startEarlyLockoutDay: {
          required: requiredIf(() => this.form.shiftCrossesMidnight)
        },
        endLateLockoutDay: {
          required: requiredIf(() => this.form.shiftCrossesMidnight)
        },
        exceedThreshold: {
          integer,
          minValue: durationMinValue(0, min => this.formatMinutesAsDuration(min, false)),
          maxValue: durationMaxValue(60 * 168, max => this.formatMinutesAsDuration(max, false))
        }
      })
    }

    if (this.form.shiftType === 'flexible') {
      v.$validationGroups.flexibleTimeSettingsGroup = [
        'form.flexibleDuration',
        'form.flexibleRevisionDuration',
        'form.flexibleLockoutDuration',
        'form.flexibleMinDuration'
      ]
      Object.assign(v.form, {
        flexibleDuration: {
          required,
          integer,
          minValue: durationMinValue(0, min => this.formatMinutesAsDuration(min, false)),
          maxValue: durationMaxValue(60 * 168, max => this.formatMinutesAsDuration(max, false))
        },
        flexibleLockoutDuration: {
          integer,
          minValue: durationMinValue(this.form.flexibleDuration, min => this.formatMinutesAsDuration(min, false)),
          maxValue: durationMaxValue(60 * 168, max => this.formatMinutesAsDuration(max, false))
        },
        flexibleRevisionDuration: {
          integer,
          minValue: durationMinValue(this.form.flexibleDuration, min => this.formatMinutesAsDuration(min, false)),
          maxValue: durationMaxValue(this.form.flexibleLockoutDuration || (60 * 168), max => this.formatMinutesAsDuration(max, false))
        },
        flexibleMinDuration: {
          integer,
          minValue: durationMinValue(0, min => this.formatMinutesAsDuration(min, false)),
          maxValue: durationMaxValue(this.form.flexibleDuration, min => this.formatMinutesAsDuration(min, false))
        }
      })
    }

    return v
  }
}
</script>
<style lang="scss" scoped>
.shift-class-form {
  .complex-field, .rule-field {
    display: flex;
    flex-wrap: wrap;

    .form-group {
      margin-left: .75rem;
      margin-right: .75rem;
    }
  }
  .rule-title {
    text-decoration: underline;
    margin-bottom: 1rem;
  }
  .rule-field {
    margin-bottom: 1rem;
  }
}
</style>
