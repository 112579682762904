import restClient from '@/services/clients/rest'
import _ from 'lodash'

export function baseURL (employeeId) {
  return `employees/${employeeId}/facemodel`
}

export default {
  async list (employeeId) {
    try {
      const response = await restClient.get(baseURL(employeeId))
      return _.get(response, 'data.photos', [])
    } catch (error) {
      console.warn('Error getting employee face model', error)
      throw error
    }
  },
  async doFaceOp (b64Jpeg, { employeeId, faceOp, path }) {
    const url = path || `${baseURL(employeeId)}/${faceOp}`
    const formData = new URLSearchParams({ b64Jpeg }).toString()
    try {
      const response = await restClient.post(url, formData)
      return response.data
    } catch (error) {
      console.warn('Error getting employee face model', error)
      throw error
    }
  },
  async removePhoto (employeeId, photoId) {
    try {
      const response = await restClient.delete(`${baseURL(employeeId)}/photos/${photoId}/delete`)
      return response.data
    } catch (error) {
      console.warn('Error removing photo from face model', error)
      throw error
    }
  },
  async setPhotoAsAvatar (employeeId, photoId) {
    try {
      const response = await restClient.post(`${baseURL(employeeId)}/photos/${photoId}/avatar`)
      return response.data
    } catch (error) {
      console.warn('Error setting photo as avatar', error)
      throw error
    }
  },
  async rebuildFaceTemplate (employeeId) {
    try {
      const response = await restClient.post(`${baseURL(employeeId)}/rebuild`)
      return response.data
    } catch (error) {
      console.warn('Error rebuilding face template', error)
      throw error
    }
  }
}
