<template>
  <b-button
      variant="link"
      size="sm"
      style="margin-left: -10px; font-size: 12px"
      :disabled="params.parent.disabled"
      @click="params.parent.resetToDefaults()"
  >
    Reset to Defaults
  </b-button>
</template>

<script>
export default {
  name: 'ResetToDefaultsHeaderButton'
}
</script>
