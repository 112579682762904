import restClient from '@/services/clients/rest'

export default {
  async list (searchText, limit, active) {
    try {
      const response = await restClient.get('orgusers/readonly', {
        params: {
          searchText,
          active,
          limit
        }
      })
      return response.data.results
    } catch (error) {
      console.warn('Error getting org user selection list', error)
      throw error
    }
  }
}
