
import { utilities } from '@gmap-vue/v3'
import { useGoogleMapsApiPromiseLazy } from '@gmap-vue/v3/composables'
import constants from '@/constants'
import '@gmap-vue/v3/dist/style.css'
import { createGmapVuePlugin } from '@gmap-vue/v3'
import { defineAsyncComponent, getCurrentInstance } from 'vue'

const LIBRARIES = ['drawing', 'geocoding', 'geometry', 'marker']
export const GOOGLE_MAPS_MAP_ID = 'e402c7fe58a50a7f'
const OPTIONS = {
  key: constants().googleApiKey,
  libraries: LIBRARIES.join(',')
}

let pluginInited = false
let promise = null
export async function loadGoogleMapsApi (app) {
  if (!pluginInited) {
    app.use(createGmapVuePlugin({ load: OPTIONS }))
    pluginInited = true
  }
  if (!promise) {
    utilities.googleMapsApiInitializer(OPTIONS)
    promise = useGoogleMapsApiPromiseLazy()
      .then(() => {
        return Promise.all(LIBRARIES.map(library => google.maps.importLibrary(library)))
      })
      .then(() => {
        console.log('Successully loaded google maps api', app)
      })
      .catch(error => {
        console.warn('Failed to load google maps api')
        // reset promise so we can try again
        promise = null
      })
  }
  return promise
}

export function defineAsyncGmapComponent(name) {
  return defineAsyncComponent(async () => {
    const app = getCurrentInstance()
    await loadGoogleMapsApi(app.appContext.app)
    return app.appContext.components[name]
  })
}
