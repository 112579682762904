<template>
  <b-dropdown variant="primary" :disabled="disabled" :size="size">
    <template #button-content>
      <font-awesome-icon icon="file-export" /> Export
    </template>
    <b-dropdown-item @click="$emit('csv')">
      <font-awesome-icon icon="file-csv" /> CSV
    </b-dropdown-item>
    <b-dropdown-item v-if="enableCsvFlat" @click="$emit('csvFlat')">
      <font-awesome-icon icon="file-csv" /> CSV Flat
    </b-dropdown-item>
    <b-dropdown-item @click="$emit('excel')">
      <font-awesome-icon icon="file-excel" /> Excel
    </b-dropdown-item>
    <b-dropdown-item @click="$emit('pdf')">
      <font-awesome-icon icon="file-pdf" /> PDF
    </b-dropdown-item>
    <b-dropdown-item v-if="enablePrint" @click="$emit('print')">
      <font-awesome-icon icon="print" /> Print
    </b-dropdown-item>

    <template v-if="customOptions && customOptions.length > 0">
      <b-dropdown-divider></b-dropdown-divider>
      <b-dropdown-item
        v-for="customOption in customOptions"
        :key="customOption.id"
        @click="$emit('custom', customOption.id)"
      >
        <!-- TODO: Infer specific icon from template attributes. -->
        <font-awesome-icon icon="file-export" />
        {{ customOption.label }}
      </b-dropdown-item>
    </template>
  </b-dropdown>
</template>

<script>
export default {
  name: 'ExportAgGridButton',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    customOptions: Array,
    size: String,
    enableCsvFlat: Boolean,
    // It's hard to print a grid inside a modal.
    enablePrint: {
      type: Boolean,
      default: true
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
