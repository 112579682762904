<template>
  <div :class="['revision-rules', shiftCrossesMidnight ? 'multi-day' : null]">
    <!-- TODO: Why isn't striped working correctly? -->
    <b-table
      small
      striped
      bordered
      responsive
      show-empty
      empty-text="There are no rules to show"
      :fields="fields"
      :items="items"
    >
      <template #cell(type)="data">
        <b-form-select v-model="data.item.type" :options="typeOptions" size="sm" />
        <template v-if="v$.items.$each.$response.$data[data.index].type.$invalid">
          <br><div class="field-error">* Required</div>
        </template>
      </template>

      <template #cell(rule)="data">
        <template v-if="data.item.type">
          <div class="rule-phrase non-empty">
            If worker Clocks {{ data.item.type === 'start' ? 'IN' : 'OUT' }} between
            <time-picker v-model="data.item.startTime" :step="15" size="sm" />
            <end-day-offset-select v-if="shiftCrossesMidnight" v-model="data.item.startDay" :showPriorDay="true" placeholder="" />
            and
            <time-picker v-model="data.item.endTime" :step="15" size="sm" />
            <end-day-offset-select v-if="shiftCrossesMidnight" v-model="data.item.endDay" :showPriorDay="true" placeholder="" />
            then revise time to
            <time-picker v-model="data.item.reviseToTime" :step="15" size="sm" />
            <end-day-offset-select v-if="shiftCrossesMidnight" v-model="data.item.reviseToDay" :showPriorDay="true" placeholder="" />
            <template v-if="isItemInvalid(data.index)">
              <div class="field-error">
                *
                <template v-if="!v$.items.$each.$response.$data[data.index].endTime.afterStart">
                  Rule end time must be after rule start time
                </template>
                <template v-else-if="!v$.items.$each.$response.$data[data.index].reviseToTime.withinLockout">
                  Revision time must be within Start Early and End Late Lock-out rules.
                </template>
                <template v-else-if="!v$.items.$each.$response.$data[data.index].reviseToTime.boundary">
                  <template v-if="data.item.type === 'start'">
                    Clock IN range and revision time must be on same side of the normal shift start time.
                  </template>
                  <template v-else>
                    Clock OUT range and revision time must be on same side of the normal shift end time.
                  </template>
                </template>
                <template v-else>All fields are required</template>
              </div>
            </template>
          </div>
        </template>
        <template v-else>
          <div class="rule-phrase empty specify-type">&lt;Specify type&gt;</div>
        </template>
      </template>

      <template #cell(flagException)="data">
        <b-form-checkbox v-model="data.item.flagException"></b-form-checkbox>
      </template>

      <template #cell(action)="data">
        <span @click="deleteRule(data.index)" :disabled="disabled">
          <font-awesome-icon icon="trash-can" class="delete-item" />
        </span>
      </template>
    </b-table>

    <div v-if="invalid" class="revision-rules-invalid">
      * {{ v$.items.overlap ? 'Revision rules are invalid' : 'Revision rules may not overlap' }}
    </div>

    <b-button size="sm" variant="primary" @click="addRule" class="add-rule">
      <font-awesome-icon icon="plus" />
      New Rule
    </b-button>
  </div>
</template>

<script>
import EndDayOffsetSelect from './EndDayOffsetSelect.vue'
import StartDayOffsetSelect from './StartDayOffsetSelect.vue'
import TimePicker from '@/components/TimePicker.vue'
import { useVuelidate } from '@vuelidate/core'
import { helpers, integer, required } from '@vuelidate/validators'

export default {
  name: 'RevisionRules',
  setup () {
    return {
      v$: useVuelidate({ $scope: false, $stopPropagation: true })
    }
  },
  components: {
    EndDayOffsetSelect,
    StartDayOffsetSelect,
    TimePicker
  },
  props: {
    items: Array,
    shiftCrossesMidnight: Boolean,
    disabled: Boolean,
    shiftStartTime: String,
    shiftStartDay: Number,
    shiftEndTime: String,
    shiftEndDay: Number,
    startEarlyLockout: String,
    startEarlyLockoutDay: Number,
    endLateLockout: String,
    endLateLockoutDay: Number
  },
  data () {
    return {
      typeOptions: [
        { value: 'start', text: 'Start' },
        { value: 'end', text: 'End' }
      ]
    }
  },
  computed: {
    fields () {
      return [
        { key: 'type', class: 'type' },
        // TODO: tdClass callback function not working, so we'll style a div inside the td instead.
        //  https://github.com/bootstrap-vue-next/bootstrap-vue-next/issues/2083
        { key: 'rule', class: 'rule' /*, tdClass: (value, key, item) => item.type ? 'non-empty' : 'empty' */ },
        { key: 'flagException', class: 'flag-exception' },
        { key: 'action', label: ' ', class: 'action'}
      ]
    },
    invalid () {
      return !!this.v$?.$invalid
    }
  },
  watch: {
    shiftCrossesMidnight (shiftCrossesMidnight) {
      if (!shiftCrossesMidnight) {
        this.items.forEach(item => {
          item.startDay = 0
          item.endDay = 0
          item.reviseToDay = 0
        })
      }
    },
    invalid: {
      handler (invalid) {
        this.$emit('validity-changed', !invalid)
      },
      immediate: true
    }
  },
  methods: {
    addRule () {
      this.items.push({
        type: null,
        startTime: null,
        startDay: 0,
        endTime: null,
        endDay: 0,
        reviseToTime: null,
        reviseToDay: 0,
        flagException: false
      })
    },
    deleteRule (index) {
      this.items.splice(index, 1)
    },
    isItemInvalid (index) {
      return false
      const vItem = this.v$.items.$each[index]
      return (
        vItem.startTime.$invalid ||
        vItem.startDay.$invalid ||
        vItem.endTime.$invalid ||
        vItem.endDay.$invalid ||
        vItem.reviseToTime.$invalid ||
        vItem.reviseToDay.$invalid
      )
    }
  },
  validations () {
    return {
      items: {
        $each: helpers.forEach({
          type: {
            required
          },
          startTime: {
            required
          },
          endTime: {
            required,
            afterStart: (endTime, item) => {
              return (
                !endTime ||
                !item.startTime ||
                item.endDay > item.startDay ||
                (item.endDay === item.startDay && endTime > item.startTime)
              )
            }
          },
          reviseToTime: {
            required,
            withinLockout: (reviseToTime, item) => {
              if (!reviseToTime || !this.startEarlyLockout || !this.endLateLockout) return true
              return (
                (this.startEarlyLockoutDay < item.reviseToDay || (this.startEarlyLockoutDay === item.reviseToDay && this.startEarlyLockout < reviseToTime)) &&
                (item.reviseToDay < this.endLateLockoutDay || (item.reviseToDay === this.endLateLockoutDay && reviseToTime < this.endLateLockout))
              )
            },
            boundary: (reviseToTime, item) => {
              // The shift calculator expects revision time not to be on different side than respective shift start/end time
              // than the rule's start range time.
              if (!reviseToTime || !item.type || !item.startTime || !item.endTime || !this.shiftStartTime || !this.shiftEndTime) return true
              // Validate:
              //   if start type has start time before shift start, then can't revise to after shift start
              //   if start type has start time after or equal to shift start, then can't revise to before shift start
              //   if end type has start time before shift end, then can't revise to after shift end
              //   if end type has start time after or equal shift end, then can't revise to before shift end
              if (item.type === 'start') {
                if (item.startDay < this.shiftStartDay || (item.startDay === this.shiftStartDay && item.startTime < this.shiftStartTime)) {
                  return item.reviseToDay < this.shiftStartDay || (item.reviseToDay === this.shiftStartDay && reviseToTime <= this.shiftStartTime)
                } else {
                  return item.reviseToDay > this.shiftStartDay || (item.reviseToDay === this.shiftStartDay && reviseToTime >= this.shiftStartTime)
                }
              } else {
                if (item.startDay < this.shiftEndDay || (item.startDay === this.shiftEndDay && item.startTime < this.shiftEndTime)) {
                  return item.reviseToDay < this.shiftEndDay || (item.reviseToDay === this.shiftEndDay && reviseToTime <= this.shiftEndTime)
                } else {
                  return item.reviseToDay > this.shiftEndDay || (item.reviseToDay === this.shiftEndDay && reviseToTime >= this.shiftEndTime)
                }
              }
            }
          },
          startDay: {
            required,
            integer
          },
          endDay: {
            required,
            integer
          },
          reviseToDay: {
            required,
            integer
          }
        }),
        overlap () {
          // TODO: Reimplement without circular dependency.
          // if (this.v$.items.$each.$invalid) return true
          // Start and end rules are checked separately, since we don't care if a start rule overlaps with an end rule.
          for (const typeRules of Object.values(_.groupBy(this.items, 'type'))) {
            const items = _.orderBy(typeRules, ['startDay', 'startTime'])
            let day = -2
            let time = null
            for (const item of items) {
              if (item.startDay < day || (item.startDay === day && item.startTime < time)) {
                return false
              }
              day = item.endDay
              time = item.endTime
            }
          }
          return true
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_bootstrap-variables';
@import '@/assets/scss/variables';

.revision-rules {
  margin-bottom: 20px;
  margin-left: 15px;

  :deep(th.rule) {
    min-width: 150px;
  }
  :deep(th.action) {
    width: 40px;
  }

  :deep(th.type) {
    min-width: 4.5rem;
  }

  :deep(td.rule) .rule-phrase {

    &.non-empty {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }

    .specify-type {
      position: relative;
      top: 3px;
    }

    .time-picker {
      margin: 0 .25rem;
    }

    .field-error {
      flex-basis: 100%;
    }
  }

  :deep(td.flag-exception) {
    text-align: center;
    padding-top: 10px;
  }

  :deep(td.action) {
    text-align: center;

    .delete-item {
      cursor: pointer;
      color: map-get($theme-colors, danger);
      position: relative;
      bottom: .2rem;
    }
  }

  .start-day-offset-select, .end-day-offset-select {
    max-width: 7rem;
    margin-right: .25rem;
    min-height: 30px;
    :deep() {
      .multiselect__select {
        height: 32px;
      }
      .multiselect__tags {
        min-height: 30px;
        padding: 5px 40px 0 5px;
      }
      .multiselect__single {
        font-size: 12px;
      }
      .multiselect__option {
        min-height: 30px;
        line-height: 12px;
        padding: 8px;
        font-size: 12px;
      }
      .multiselect__content-wrapper {
        // https://github.com/shentao/vue-multiselect/issues/723#issuecomment-859243256
        position: static;
      }
    }
  }

  .revision-rules-invalid {
    margin-top: -10px;
    margin-bottom: 20px;
    color: $flat-ui-alizarin;
  }

  .field-error {
    color: $flat-ui-alizarin;
    font-size: .7rem;
  }

  .add-rule {
    margin-top: -10px;
  }
}
</style>
