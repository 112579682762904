<template>
<div>
  <h4 class="title">
    <font-awesome-icon v-if="icon" class="icon" :icon="icon" />
    {{ titleLabel }}
    <router-link v-if="parent" :to="{name: parent.name, params: {orgId: organizationId}}">
      <font-awesome-icon class="parent icon" icon="level-up-alt" size="xs" />
    </router-link>
    <router-link v-else to="/orgs">
      <font-awesome-icon class="parent switch-org-icon icon" icon="sitemap" size="xs" />
      <span class="switch-org-text">switch organization</span>
    </router-link>
  </h4>
  <ul v-if="children.length" class="content-list">
    <li v-for="child in children" :key="child.name" class="content-item">
      <router-link :to="append($route.path, child.meta.link)">
        <font-awesome-icon v-show="child.meta.icon" class="icon" :icon="child.meta.icon" />
        {{ child.meta.label }}
        <b-badge v-if="child.meta.badge && child.meta.badge.text" :variant="child.meta.badge.variant">{{child.meta.badge.text}}</b-badge>
      </router-link>
      <span class="description">{{ child.meta.description }}</span>
    </li>
  </ul>
  <div v-else>
    There are no items to choose from.
  </div>
</div>
</template>

<script>
import { useRouterViewMeta } from '@/composables/useRouterViewMeta'
import { mapState } from 'vuex'

export default {
  name: 'TableOfContents',
  setup () {
    const routerViewMeta = useRouterViewMeta()
    return {
      ...routerViewMeta
    }
  },
  computed: {
    ...mapState(['organizationId', 'organizationName']),
    titleLabel () {
      return this.parent ? this.label : this.organizationName
    }
  }
}
</script>

<style lang="scss" scoped>

  a {
    &:hover, &:visited, &:link, &:active {
      text-decoration: none;
    }
  }

  .title {
    margin-bottom: 20px;
  }

  .icon {
    margin-right: 5px;
  }

  .badge {
    margin-left: 10px;
  }

  .parent {
    margin-bottom: 5px;
    margin-left: 5px;
  }

  .switch-org-icon {
    font-size: 1rem;
  }

  .switch-org-text {
    position: relative;
    bottom: 7px;
    left: 3px;
    font-size: 0.7rem;
  }

  .content-list {
    list-style: none;

    .content-item {
      font-size: 1.1rem;

      .description {
        display: block; // line break after label title
        margin-left: 25px;
        margin-bottom: 10px;
        font-size: 0.9rem;
        color: #444;
      }
    }
  }

</style>
