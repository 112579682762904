<template>
  <div class="employee-face-model" v-mask="maskOptions">
    <b-container>
      <b-row>
        <b-col>
          <div class="input-box">
            <div class="input-img-display" v-if="file">
              <div>
                <img v-if="imgSrc" :src="imgSrc" :class="['face-photo-img', 'face-photo-input-img', imgIsLandscape ? 'height-based' : 'width-based']" />
              </div>
              <div>
                <h5>Make a selection:</h5>
                <div class="input-img-ops">
                  <hr/>
                  <b-btn variant="link" @click="addToFaceModel" v-if="changesAllowed">Add to face model</b-btn>
                  <b-btn variant="link" @click="clearInputImage">Clear</b-btn>
                  <hr/>
                  <b-btn variant="link" @click="detectFace">Detect a face</b-btn>
                  <b-btn variant="link" @click="verifyEmployee">Verify employee</b-btn>
                  <b-btn variant="link" @click="identifyInOrgUnit">Identify in organization unit</b-btn>
                </div>
                <div v-if="faceOpResult"
                  class="face-op-result"
                  :class="{ success: faceOpResult.success, error: !faceOpResult.success}">
                    <font-awesome-icon icon="circle-check" v-if="faceOpResult.success" />
                    <font-awesome-icon icon="circle-xmark" v-else />
                    {{ faceOpResult.message }}
                </div>
                <multiple-face-matches
                  :matches="faceOpResult && faceOpResult.multipleMatches"
                  :contextUserId="employeeId"
                  style="margin: .5rem"
                />
              </div>
            </div>
            <h4 v-else-if="dropActive" class="drop-title">Drop file here</h4>
            <div class="upload-prompt" v-else>
              <font-awesome-icon :icon="['far', 'file-arrow-up']" />
              <label :for="fileInputId" class="choose-file">Choose file</label>
              <span>or drop file anywhere to upload</span>
            </div>

            <file-upload
              v-if="employeeId"
              v-model="files"
              :input-id="fileInputId"
              :drop="dropContainer"
              :drop-directory="false"
              :multiple="false"
              @input-filter="inputFilter"
              @input-file="inputFile"
              @drop-active-changed="dropActive = $event"
            />
          </div>
        </b-col>
      </b-row>
      <b-row align-h="start">
        <template v-for="(photo, index) in photos" :key="photo">
          <b-col cols="auto">
            <div class="face-model-photo">
              <img :src="`${baseURL()}/employees/${employeeId}/facemodel/photos/${photo}`"
                class="face-photo-img" />
              <div class="face-model-photo-ops" v-if="changesAllowed">
                <font-awesome-icon-with-title
                  icon="circle-user"
                  class="add-to-avatar"
                  title="Make this photo avatar"
                  @click="addToAvatar(photo)" />
                <font-awesome-icon-with-title
                  icon="circle-minus"
                  class="remove-from-face-model"
                  title="Remove from face model"
                  @click="removeFromFaceModel(photo)" />
              </div>
            </div>
          </b-col>
          <div
            class="w-100 d-none d-md-block"
            v-if="index % maxPhotosPerLine === maxPhotosPerLine - 1"
            :key="index">
          </div>
        </template>
        <b-col v-if="photos.length < 1">
          This employee does not currently have any facial photos in the face model.
          Face photos are required in order for Fareclock to verify or identify the employee at the time clock.
          More information about how to initialize the employee's face model can found in a
          <a target="_blank" href="http://support.fareclock.com/entries/21146857-how-to-add-new-employee-set-up-face-recognition">Knowledgebase Article</a>.
        </b-col>
      </b-row>
      <b-row class="face-model-buttons">
        <!-- Not needed now. Add later if becomes necessary. -->
        <!-- <b-col cols="auto">
          <b-button variant="danger" @click="deleteFaceModel">
            Delete entire face model
          </b-button>
        </b-col> -->
        <b-col cols="auto" v-if="loggedInIsAppAdmin">
          <b-button variant="primary" @click="rebuildFaceModel">
            Rebuild face model
          </b-button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import FileUpload from '@/components/FileUpload.vue'
import FontAwesomeIconWithTitle from '@/components/FontAwesomeIconWithTitle.vue'
import MultipleFaceMatches from '@/views/settings/employees/MultipleFaceMatches.vue'
import Maskable from '@/mixins/Maskable'
import service, { baseURL as baseFaceModelURL } from './services/EmployeeFaceModelService'
import { mapGetters, mapState } from 'vuex'
import { getBaseURL } from '@/services/clients/rest'
import { extractErrorMessage } from '@/utils/misc'
import _ from 'lodash'

export default {
  mixins: [Maskable],
  components: {
    FileUpload,
    FontAwesomeIconWithTitle,
    MultipleFaceMatches
  },
  props: {
    employeeId: Number,
    employeeOrgUnit: Number,
    showing: Boolean
  },
  emits: ['avatarChanged'],
  data () {
    return {
      loaded: false,
      dropContainer: null,
      dropActive: false,
      maxPhotosPerLine: 5,
      photos: [],
      files: [],
      imgSrc: null,
      imgIsLandscape: null,
      faceOpResult: null
    }
  },
  computed: {
    ...mapGetters(['canEditSelf']),
    ...mapState(['orgUserId']),
    ...mapState({
      loggedInIsAppAdmin: state => state.userProfile.isAppAdmin
    }),
    isSelf () {
      return this.employeeId === this.orgUserId
    },
    changesAllowed () {
      return !this.isSelf || this.canEditSelf
    },
    file () {
      return this.files.length === 1 ? this.files[0] : null
    },
    base64Jpeg () {
      // strip off prefix "data:image/jpeg;base64," which is length 23
      return this.imgSrc && this.imgSrc.substr(23)
    },
    fileInputId () {
      return `file-input-${this.$.uid}`
    }
  },
  watch: {
    employeeId () {
      this.clearInputImage()
      this.load()
    },
    showing () {
      if (this.showing && !this.loaded) {
        this.load()
      }
    }
  },
  methods: {
    // baseURL is a method, because it's not reactive on vuex orgId
    baseURL () {
      return getBaseURL()
    },
    load () {
      this.photos = []
      this.loaded = false

      if (this.employeeId && this.showing) {
        this.loaded = true
        service.list(this.employeeId)
          .then(photos => { this.photos = photos })
      }
    },
    inputFilter (newFile, oldFile, prevent) {
      if (newFile && !/\.(gif|jpeg|jpg|png|webp)$/i.test(newFile.name)) {
        console.log(`invalid input file name ${newFile.name}`)
        return prevent()
      }
    },
    inputFile (file) {
      if (!file) return // sends dupe event with undefined file for some reason

      import(/* webpackChunkName: "load-image" */ 'blueimp-load-image')
        .then(module => {
          const loadImage = module.default

          // Resize image while ensuring the following conditions are met:
          //  1) Max width is 480px, and max height is 640px.
          //  2) Maintain same aspect ratio.
          //  3) Displayed <img> should be inside a fixed box size,
          //     and the image itself should be styled to center along
          //     the smaller dimension.
          loadImage(
            file.file,
            canvas => {

              const aspectRatio = canvas.width / canvas.height

              let width, height
              if (canvas.width >= canvas.height) {
                width = Math.min(canvas.width, 480)
                height = width / aspectRatio
              } else {
                height = Math.min(canvas.height, 640)
                width = height * aspectRatio
              }

              const resizeCanvas = document.createElement('canvas')
              const context = resizeCanvas.getContext('2d')
              resizeCanvas.width = width
              resizeCanvas.height = height
              context.drawImage(canvas, 0, 0, width, height)

              this.imgSrc = resizeCanvas.toDataURL('image/jpeg')
              this.imgIsLandscape = aspectRatio > 1
            },
            {
              canvas: true,
              orientation: true
            }
          )
        })
    },
    addToFaceModel () {
      this.showSpinningMask('Adding photo to face model...')
      this.doFaceOp({ path: baseFaceModelURL(this.employeeId) })
        .then(result => {
          this.hideMask()
          this.faceOpResult = {
            ...result,
            message: result.success
              ? 'Face added to model'
              : `Photo could not be added to model (${result.error})`
          }

          if (result.success) {
            // if face model is already at max 25 photos, then the server will
            // remove an old photo. we don't know which one it is, so we won't
            // bother here to deal with that.
            this.photos.push(result.photoId)
          }
        })
    },
    clearInputImage () {
      this.files = []
      this.imgSrc = null
    },
    detectFace () {
      this.showSpinningMask('Detecting face in photo...')
      this.doFaceOp({ path: 'detect-face' })
        .then(result => {
          this.hideMask()
          this.faceOpResult = {
            ...result,
            message: result.success
              ? `Face detected with score ${_.get(result, 'faceAttributes.detectionConfidence', 0)} / 100`
              : `Face not detected (${result.error})`
          }
        })
    },
    verifyEmployee () {
      this.showSpinningMask('Verifying face in photo...')
      this.doFaceOp({ faceOp: 'verify' })
        .then(result => {
          this.hideMask()
          this.faceOpResult = {
            ...result,
            message: result.success
              ? `Face verified with score ${result.score}`
              : `Face not verified (${result.error})`
          }
        })
    },
    identifyInOrgUnit () {
      this.showSpinningMask('Identifying face in photo...')
      this.doFaceOp({ path: `orgunits/${this.employeeOrgUnit}/identify` })
        .then(result => {
          this.hideMask()
          this.faceOpResult = {
            ...result,
            message: result.success
              ? `Face identified as ${result.employee} with score ${result.score}`
              : `Face not identified (${result.error})`
          }
        })
    },
    doFaceOp (urlParams) {
      return service.doFaceOp(
        this.base64Jpeg,
        {
          ...urlParams,
          employeeId: this.employeeId
        }
      )
        .catch(error => ({
          success: false,
          error: extractErrorMessage(error)
        }))
        .then(result => ({
          ...result,
          error: (result.error || result.status)
            ? (result.error || result.status).replace(/_/g, ' ')
            : 'unknown reason'
        }))
    },
    addToAvatar (photoId) {
      const employeeId = this.employeeId

      // TODO: on small devices, the mask message might not be visible
      this.showUpdatingMask()
      service.setPhotoAsAvatar(employeeId, photoId)
        .then(result => {
          this.hideMask()

          // delay emitting event to give time for backend to process
          setTimeout(() => this.$emit('avatarChanged', {
            avatar: result.avatar,
            employeeId
          }), 3000)
        })
        .catch(error => {
          this.showErrorMask(error)
        })
    },
    removeFromFaceModel (photoId) {
      // TODO: on small devices, the mask message might not be visible
      this.showUpdatingMask()
      service.removePhoto(this.employeeId, photoId)
        .then(result => {
          this.photos = this.photos.filter(item => item !== photoId)
          this.hideMask()
        })
        .catch(error => {
          this.showErrorMask(error)
        })
    },
    /* deleteFaceModel () {

    }, */
    rebuildFaceModel () {
      this.showUpdatingMask()
      service.rebuildFaceTemplate(this.employeeId)
        .then(result => {
          if (!result.success) return Promise.reject(new Error('Rebuild failed'))
          this.photos = result.photoIds
          this.hideMask()
        })
        .catch(error => {
          this.showErrorMask(error)
        })
    }
  },
  mounted () {
    this.dropContainer = this.$el
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/app/app';

.employee-face-model {

  .input-box {

    display: flex;
    align-items: center;
    justify-content: center;

    border: 1px dashed #333;
    padding: 10px;
    text-align: center;
    margin-bottom: 15px;

    .drop-title {
      margin-bottom: 0;
    }

    .upload-prompt {
      display: flex;
      flex-direction: row;
      justify-content: center;
      svg {
        margin-right: 10px;
        font-size: 1.2rem;
      }
      .choose-file {
        color: $fc-logo-secondary-blue;
        margin-bottom: 0;
        margin-right: 5px;
        &:hover, &:active {
          color: $flat-ui-carrot;
        }
      }
    }

    .input-img-display {
      display: flex;
      flex-wrap: wrap;

      .input-img-ops {
        display: flex;
        flex-direction: column;
        width: 250px;

        .btn {
          padding: 0 .75rem;
        }

        hr {
          border-top: 0;
        }
      }

      .face-op-result {
        margin-top: 2rem;
        margin-left: .75rem;

        &.success {
          color: $flat-ui-emerald;
        }
        &.error {
          color: $flat-ui-alizarin;
        }
      }
    }
  }

  .face-photo-img {
    border: 1px solid #D0D0D0;
    border-radius: 6px;

    &.face-photo-input-img {
      &.height-based {
        max-height: 300px;
      }
      &.width-based {
        max-height: 300px;
      }
    }
  }

  .face-model-photo {
    margin-bottom: 20px;
    position: relative;

    // The ideal image size is 90x120px.
    // But we won't distort the actual image aspect ratio to fit it.
    // Rather we'll fit to the largest dimension, and bottom-center
    // justify the smaller dimension.
    width: 90px;
    height: 120px;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    .face-photo-img {
      max-height: 120px;
      max-width: 90px;
    }

    .face-model-photo-ops {
      .icon-container {
        position: absolute;
        bottom: 5px;
        cursor: pointer;

        &.add-to-avatar {
          left: 5px;
          color: $fc-logo-primary-blue;
        }

        &.remove-from-face-model {
          right: 5px;
          color: $flat-ui-alizarin;
        }
      }
    }
  }

  .face-model-buttons .btn {
    margin: 5px;
  }
}
</style>
