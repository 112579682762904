import moment from 'moment'
import { modelTimeFormat } from './misc'
import * as validators from '@vuelidate/validators'
import { formatIsoDuration } from './duration'
import { i18n } from '@/i18n-setup'

// TODO: Messages with field names
/*
  messages: {
    required: '{attribute} is a required field.',
    email: '{attribute} must be a valid email address.',
    integer: '{attribute} must be a integer value.',
    numeric: '{attribute} must be a numeric value.',
    decimal: '{attribute} must be a decimal value.',
    phoneE164: '{attribute} must be a valid phone number.',
    minValue: '{attribute} must be at least {min}.',
    maxValue: '{attribute} must be no more than {max}.',
    minLength: '{attribute} must be at least {min} characters.',
    maxLength: '{attribute} must be no more than {max} characters.',
    dateTime: '{attribute} must be a valid datetime.',
    timeMinValue: '{attribute} must be at least {min}.',
    timeMaxValue: '{attribute} must be at least {max}.',
    time: '{attribute} must be a valid time.',
    unique: '{attribute} must be unique.',
    maxDecimalAccuracy: '{attribute} must not have more than {max} decimal places.'
  }
*/

// @ts-ignore
const withI18nMessage = validators.createI18nMessage({ t: i18n.global.t.bind(i18n) })

const helpers = validators.helpers

export const between = (min, max, attribute) => withI18nMessage(validators.between(min, max), { withArguments: true, messageParams: params => ({ ...params, attribute })})
export const email = withI18nMessage(validators.email)
export const integer = attribute => withI18nMessage(validators.integer, { messageParams: params => ({ ...params, attribute })})
export const maxLength = withI18nMessage(validators.maxLength, { withArguments: true })
export const minValue = withI18nMessage(validators.minValue, { withArguments: true })
export const numeric = withI18nMessage(validators.numeric)
export const required = attribute => withI18nMessage(validators.required, { messageParams: params => ({ ...params, attribute })})
export const requiredIf = (condition, attribute) => withI18nMessage(validators.requiredIf(condition), { withArguments: true, messageParams: params => ({ ...params, attribute }) })

export function time (s) {
  // IOS 8601 allows 24:00 time, but we don't want to allow it.
  return s ? moment(s, modelTimeFormat, true).isValid() && s !== '24:00' : true
}

export function dateTime (s) {
  return s ? moment(s).isValid() : true
}

// Use lexicographical comparison of time string in modelTimeFormat.

export const timeMinValue = (min, formatter, attribute) => ({
  $message: `${attribute ?? 'Value'} must be at least ${formatter(min)}.`,
  $params: { type: 'timeMinValue', value: min },
  $validator: value => !value || (!time(value) || !time(min) || min <= value)
})

export const timeMaxValue = (max, formatter, attribute) => ({
  $message: `${attribute ?? 'Value'} must be no more than ${formatter(max)}.`,
  $params: { type: 'timeMaxValue', value: max },
  $validator: value => !value || !time(value) || !time(max) || max >= value
})

export const timeDayMinValue = (dayOffset, min, minDayOffset, formatter, attribute) => ({
  $message: `${attribute ?? 'Value'} must be at least ${formatter(min)}.`,
  $params: { type: 'timeDayMinValue', value: { dayOffset, min, minDayOffset } },
  $validator: value =>
    !helpers.req(value) || !time(value) || !time(min)
    || (dayOffset || 0) > (minDayOffset || 0)
    || ((dayOffset || 0) === (minDayOffset || 0) && min <= value)
})

export const timeDayMaxValue = (dayOffset, max, maxDayOffset, formatter, attribute) => ({
  $message: `${attribute ?? 'Value'} must be no more than ${formatter(max)}.`,
  $params: { type: 'timeDayMaxValue', value: { dayOffset, max, maxDayOffset } },
  $validator: value =>
    !helpers.req(value) || !time(value) || !time(max)
    || (dayOffset || 0) < (maxDayOffset || 0)
    || ((dayOffset || 0) === (maxDayOffset || 0) && max >= value)
})

export const durationMinValue = (min, formatter, attribute) => ({
  $message: `${attribute ?? 'Value'} must be at least ${formatter(min)}.`,
  $params: { type: 'durationMinValue', value: min },
  $validator: value =>!Number.isInteger(value) || !Number.isInteger(min) || min <= value
})

export const durationMaxValue = (max, formatter, attribute) => ({
  $message: `${attribute ?? 'Value'} must be no more than ${formatter(max)}.`,
  $params: { type: 'durationMaxValue', value: max },
  $validator: value => !Number.isInteger(value) || !Number.isInteger(max) || max >= value
})

export const dateTimeMinValue = (min, formatter, attribute) => ({
  $message: `${attribute ?? 'Value'} must be at least ${formatter(min)}.`,
  $params: { type: 'dateTimeMinValue', value: min },
  $validator: value => !value || !moment(value).isValid() || !min || !moment(min).isValid() || moment(min).isSameOrBefore(value)
})

export const dateTimeMaxValue = (max, formatter, attribute) => ({
  $message: `${attribute ?? 'Value'} must be no more than ${formatter(max)}.`,
  $params: { type: 'dateTimeMaxValue', value: max },
  $validator: value => !value || !moment(value).isValid() || !max || !moment(max).isValid() || moment(max).isSameOrAfter(value)
})

export const maxDecimalAccuracy = (max, attribute) =>
  helpers.withMessage(
    `${attribute} must not have more than ${max} decimal places.`,
    value =>
      !value ||
      !validators.decimal.$validator(value) ||
      !value.toString().includes('.') ||
      value.toString().split('.')[1].length <= max
  )

export const isoDurationMinValue = (min) =>
  helpers.withParams(
    // formatIsoDuration returns empty string if min is zero duration,
    // so display '0' in such a case.
    { type: 'minValue', min: formatIsoDuration(min) || '0' },
    value =>
      !helpers.req(value)|| moment.duration(min) <= moment.duration(value)
  )

export const isoDurationMaxValue = (max) =>
  helpers.withParams(
    // formatIsoDuration returns empty string if min is zero duration,
    // so display '0' in such a case.
    { type: 'maxValue', max: formatIsoDuration(max) || '0' },
    value =>
      !helpers.req(value) || moment.duration(max) >= moment.duration(value)
  )
