<template>
  <key-multiselect
    v-bind="$attrs"
    v-model="forwardValue"
    label="name"
    track-by="id"
    select-label=""
    deselect-label=""
    :resourceName="resourceName"
    :options="sortedItems"
    :showAddNewItem="canEditOrgSettings"
    @add-new-item="addNewItem"
  />
</template>
<script>
import KeyMultiselect from '@/components/KeyMultiselect.vue'
import { mapGetters } from 'vuex'
import RoundingRuleFormModal from './RoundingRuleFormModal.vue'
import { useModalController } from 'bootstrap-vue-next'
import { computed, ref } from 'vue'

const orchestrator = 'roundingRules'

export default {
  name: 'RoundingRuleSelect',
  setup () {
    return {
      showModal: useModalController().show
    }
  },
  inheritAttrs: false,
  components: {
    KeyMultiselect
  },
  data () {
    return {
      resourceName: 'Rounding Rule'
    }
  },
  props: {
    modelValue: [Array, Number],
  },
  computed: {
    ...mapGetters(['canEditOrgSettings']),
    ...mapGetters({
      sortedItemsFn: `${orchestrator}/sortedItems`
    }),
    sortedItems () {
      return this.sortedItemsFn('name')
    },
    forwardValue: {
      get () {
        return this.modelValue
      },
      set (v) {
        this.$emit('update:modelValue', v)
      }
    }
  },
  methods: {
    addNewItem () {
      const itemId = ref(null)
      this.showModal({
          component: RoundingRuleFormModal,
          props: computed(() => ({
            itemId: itemId.value,
            onSaved: item => {
              itemId.value = item.id
              this.$emit('update:modelValue', item.id)
              this.$emit('item-added', item)
            }
          }))
        })
    }
  },
  mounted () {
    this.$store.dispatch(`${orchestrator}/load`)
  },
}
</script>
