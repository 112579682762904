
export interface Deferred<T> {
  promise: Promise<T>
  resolve: (value: T | PromiseLike<T>) => void
  reject: (reason?: any) => void
  isResolved: boolean
  isRejected: boolean
  isPending: boolean
}

export function createDeferred<T> (): Deferred<T> {
  let externalResolve, externalReject
  let isResolved = false
  let isRejected = false
  let isPending = true
  const promise = new Promise<T>((resolve, reject) => {
    externalResolve = resolve
    externalReject = reject
  })
  return {
    promise,
    resolve: externalResolve!,
    reject: externalReject!,
    get isResolved () {
      return isResolved
    },
    get isRejected () {
      return isRejected
    },
    get isPending () {
      return isPending
    }
  }
}
