<template>
  <multiselect
    v-bind="$attrs"
    v-model="forwardValue"
    :options="options"
    label="label"
    track-by="value"
    select-label=""
    deselect-label=""
    :allowEmpty="false"
    class="start-day-offset-select"
  />
</template>
<script>
import Multiselect from 'vue-multiselect'

export default {
  name: 'StartDayOffsetSelect',
  components: {
    Multiselect
  },
  props: ['modelValue'],
  emits: ['update:modelValue'],
  inheritAttrs: false,
  data () {
    return {
      options: [
        { label: '-1 day', value: -1 },
        { label: 'Same day', value: 0 },
      ]
    }
  },
  computed: {
    forwardValue: {
      get () {
        return this.options.find(o => o.value == this.modelValue)
      },
      set (v) {
        this.$emit('update:modelValue', v && v.value)
      }
    }
  }
}
</script>
