<template>
  <key-multiselect
    v-bind="$attrs"
    v-model="forwardValue"
    :options="options"
    :multiple="true"
    label="label"
    track-by="value"
    placeholder="All Status"
    select-label=""
    deselect-label=""
    @fullValueChange="$emit('fullValueChange', $event)"
  >
  </key-multiselect>
</template>
<script>
import KeyMultiselect from '@/components/KeyMultiselect.vue'
import _ from 'lodash'
import { mapGetters } from 'vuex'

export default {
  name: 'PunchStatusSelect',
  components: {
    KeyMultiselect
  },
  props: ['modelValue'],
  emits: ['fullValueChange', 'update:modelValue'],
  inheritAttrs: false,
  computed: {
     ...mapGetters([
      'scheduleEnabled'
    ]),
    forwardValue: {
      get () {
        return this.modelValue
      },
      set (v) {
        this.$emit('update:modelValue', v)
      }
    },
    options () {
      return [
        { label: 'Approved', value: 'approved' },
        { label: 'Flagged', value: 'flagged' },
        { label: 'Exceptions', value: 'exception' },
        this.scheduleEnabled ? { label: 'Late', value: 'late' } : null,
        { label: 'Missed', value: 'missed' },
        { label: 'Edited', value: 'override' },
        { label: 'Edited Time', value: 'edited_time' },
        this.scheduleEnabled ? { label: 'Missed Break', value: 'missed_break' } : null,
        this.scheduleEnabled ? { label: 'Short Break', value: 'short_break' } : null,
        this.scheduleEnabled ? { label: 'Paid Before Shift', value: 'before_shift' } : null,
        this.scheduleEnabled ? { label: 'Paid After Shift', value: 'after_shift' } : null,
        this.scheduleEnabled ? { label: 'Paid Exceeds Shift', value: 'exceed_shift' } : null
      ].filter(i => i)
    }
  }
}
</script>
