<template>
  <b-form-radio-group
    v-model="forwardValue"
    :options="options"
    button-variant="outline-primary"
    class="boolean-radio-group"
    size="sm"
    buttons
  >
    <b-button v-if="showClearButton && forwardValue !== null" variant="link" size="sm" @click="forwardValue = null">
      Clear
    </b-button>
  </b-form-radio-group>
</template>
<script>
export default {
  name: 'BooleanRadioGroup',
  inheritAttrs: false,
  props: {
    modelValue: Boolean,
    trueText: {
      type: String
    },
    falseText: {
      type: String
    },
    showClearButton: {
      type: Boolean,
      default: true
    }
  },
  emits: ['update:modelValue'],
  computed: {
    forwardValue: {
			get () {
				return this.modelValue
			},
			set (v) {
				this.$emit('update:modelValue', v)
			}
    },
    options () {
      return [
        { text: this.falseText || 'NO', value: false },
        { text: this.trueText || 'YES', value: true },
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
.boolean-radio-group :deep() {
  .btn {
    text-transform: uppercase;

    &.btn-link {
      color: var(--primary-color);
      font-size: .75rem;
      margin-left: .25rem;
      text-transform: lowercase;
    }
  }

  .btn-check:checked + .btn {
    color: #fff;
  }
}
</style>
