<template>
  <panel title="Roll Call Summary" v-mask="maskOptions">
    <template #icons>
      <panel-header-icon icon="sync" @click="refresh" />
    </template>
    <div>
      <div class="item">
        <font-awesome-icon icon="bullhorn" />
        <div class="description">Workers present:</div>
        <div class="value">{{ workersPresentCount }}</div>
      </div>
      <div v-if="costingEnabled" class="item">
        <font-awesome-icon icon="sitemap" />
        <div class="description">Jobs present:</div>
        <div class="value">{{ jobsOccupiedCount }}</div>
      </div>
      <router-link :to="reportLink">View full Roll Call report</router-link>
    </div>
  </panel>
</template>

<script>
import AutoRefresh from '@/mixins/AutoRefresh'
import Maskable from '@/mixins/Maskable'
import Panel from '@/components/Panel.vue'
import PanelHeaderIcon from '@/components/PanelHeaderIcon.vue'
import service from '@/modules/reports/views/rollcall/RollCallService'
import { mapGetters } from 'vuex'

export default {
  mixins: [AutoRefresh, Maskable],
  components: {
    Panel,
    PanelHeaderIcon
  },
  data () {
    return {
      results: [],
      reportLink: {
        name: 'roll-call',
        params: {
          view: JSON.stringify({
            status: 'present'
          })
        }
      }
    }
  },
  computed: {
    ...mapGetters(['costingEnabled']),
    presentResults () {
      return this.results.filter(r => r.present)
    },
    workersPresentCount () {
      return this.presentResults.length
    },
    jobsOccupiedCount () {
      return new Set(this.presentResults.flatMap(r => r.job ? [r.job] : [])).size
    }
  },
  methods: {
    load () {
      this.showLoadingMask()
      return service.list({ status: 'present' })
        .then(data => {
          this.results = data.results
          this.hideMask()
        })
        .catch(error => {
          this.showErrorMask(error)
          return Promise.reject(error)
        })
    },
    refreshImpl () {
      return this.load()
    },
    refresh () {
      this.coordinateWithRefresh(() => this.load())
    }
  }
}
</script>

<style lang="scss" scoped>
  .item {
    display: flex;
    align-items: center;
    margin-bottom: .25rem;

    svg {
      width: 25px;
      margin-right: .5rem;
    }
    .description {
      width: 7rem;
      white-space: nowrap;
      margin-right: .5rem;
    }
    .value {
      width: 3rem;
      text-align: right;
      font-weight: bold;
    }
  }
  a {
    margin-top: .5rem;
    margin-left: .25rem;
    display: inline-block;
  }
</style>
