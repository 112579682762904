
export function openShiftClassFormModal (shiftClassId, showModal) {
  import(/* webpackChunkName: "shift-class-modal" */ './ShiftClassFormModal.vue')
    .then(async module => {
      const ShiftClassFormModal = module.default
      await showModal({
        component: ShiftClassFormModal,
        props: {
          itemId: shiftClassId
        }
      })
    })
}
