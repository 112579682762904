
export function migrateDeviceData (device) {
  if (!device.notifyIfInactive) {
    device.notifyDelayMinutes = null
  }
  device.notifyRepeat = device.notifyRepeat || false
  device.settingsPinPermissions = device.settingsPinPermissions || []
  device.enableShiftSchedule = device.enableShiftSchedule || false
  device.announcementOverride = device.announcementOverride || false
  device.localModeAlways = device.localModeAlways || false
  device.fixCostingIsPartial = device.fixCostingIsPartial || false
  device.geoProvider = device.geoProvider || null
  device.geoOverrideToDisabled = device.geoOverrideToDisabled || false
  device.bgGeoOverrideToDisabled = !!device.bgGeoOverrideToDisabled
  device.enableDebug = device.enableDebug || false
}
