import { finalSet, hasValue } from '@/utils/misc'
import { mapGetters } from 'vuex'
import _ from 'lodash'

const mixinFactory = (prefix, getter, dataProperty, computedProperty, formProperty, enableProperty, includeUnique) => {
  if (!getter) getter = `${prefix}CustomFields`
  if (!dataProperty) dataProperty = `${prefix}CustomFieldFilters`
  if (!computedProperty) computedProperty = `${prefix}CustomFields`
  if (!formProperty) formProperty = `${prefix}CustomFieldsFormPropertyPath`
  if (!enableProperty) enableProperty = `${prefix}EnableFilterCustomFields`

  return {
    props: {
      // The enableProperty prop allows us to mixin this class into a generic reusable component
      // that sometimes might not enable custom fields, e.g., UserReportFilter.
      [enableProperty]: {
        type: Boolean,
        default: false
      },
      filtersRestored: {
        type: Boolean,
        default: true
      },
      // We don't want a custom field's own conditions to include itself.
      // But we're not going to do other checks right now,
      // such as cycle detection and making sure the field usages are compatible.
      excludeCustomField: Number
    },
    data () {
      return {
        [dataProperty]: []
      }
    },
    computed: {
      ...mapGetters(['hasCustomFieldFeature']),
      [computedProperty] () {
        return this.$store.getters[`customFields/${getter}`]
          .filter(item =>
            (item.id !== this.excludeCustomField &&
              (['bool', 'choice'].includes(item.type) || (includeUnique && item.unique)))
          )
      },
      customFieldById () {
        return this.$store.getters['customFields/itemsById']
      },
      [formProperty] () {
        throw new Error(`Mixing component must override ${formProperty} computed value`)
      }
    },
    watch: {
      // TODO: Fix this watcher getting triggered before custom fields set on config object for report.
      [computedProperty] () {
        if (this[enableProperty]) {
          this[`${prefix}PullCustomFieldFilters`]()
        }
      },
      [dataProperty]: {
        handler () {
          this[`${prefix}PushCustomFieldFilters`]()
        },
        deep: true
      },
      // TODO: Fix this watcher not called when form Clear button pressed.
      [formProperty]: {
        handler () {
          if (this[enableProperty]) {
            // Use nextTick to avoid a racing condition with parent FieldConditions
            // value watch handler when component is created.
            this.$nextTick(() => {
              this[`${prefix}PullCustomFieldFilters`]()
            })
          }
        },
        immediate: true
      },
      filtersRestored (filtersRestored) {
        if (filtersRestored) {
          this[`${prefix}PullCustomFieldFilters`]()
        }
      }
    },
    methods: {
      [`${prefix}PullCustomFieldFilters`] () {
        if (!this.filtersRestored || this.$store.state.customFields.loading || this[computedProperty].length < 1) return
        const customFieldFilterTuples = (_.get(this, this[formProperty]) || []).map(f => {
          const [field, value] = f.split(/:(.*)/s)
          const customField = this.customFieldById[parseInt(field)]
          let convertedValue = value
          if (customField.type === 'bool') {
            convertedValue = value === 'true' ? 'yes' : 'no'
          } else if (['choice', 'number'].includes(customField.type)) {
            convertedValue = parseInt(value)
          }
          return [customField.id, convertedValue]
        })
        const customFieldFilters = _(customFieldFilterTuples)
          .groupBy(t => t[0])
          .mapValues(l => l.map(t => t[1]))
          .value()
        this[dataProperty] = this[computedProperty].map(customField => {
          let value = customFieldFilters[customField.id]
          if (!customField.listMultiple && _.isArray(value)) value = value[0]
          return value || null
        })
      },
      [`${prefix}PushCustomFieldFilters`] () {
        if (this.$store.state.customFields.loading || this[computedProperty].length < 1) return
        const customFieldFilterFormData = this[computedProperty]
          .flatMap((customField, index) => {
            const value = this[dataProperty][index]
            if (!hasValue(value)) return []
            let convertedValues = []
            if (customField.type === 'bool') convertedValues = [!!value && value === 'yes']
            else if (_.isArray(value)) convertedValues = value
            else convertedValues = [value]
            return convertedValues.map(v => `${customField.id}:${v}`)
          })
        if (!_.isEqual(customFieldFilterFormData, _.get(this, this[formProperty]))) {
          finalSet(this, this[formProperty], customFieldFilterFormData)
        }
      }
    },
    created () {
      if (this[enableProperty] && this.hasCustomFieldFeature) {
        this.$store.dispatch('customFields/load')
      }
    }
  }
}

export const ManagesUserCustomFieldFilters = mixinFactory('user', 'userCustomFields', 'customFieldFilters', 'customFields', 'customFieldsFormPropertyPath', 'filterCustomFields', true)
export const ManagesCrossIndexCustomFieldFilters = mixinFactory('user', 'crossIndexCustomFields', 'customFieldFilters', 'customFields', 'customFieldsFormPropertyPath', 'filterCustomFields', true)
export const ManagesCrossIndexMixedCustomFieldFilters = mixinFactory('user', 'crossIndexCustomFields', null, null, null, null, false)
export const ManagesPunchMixedCustomFieldFilters = mixinFactory('punch', null, null, null, null, null, false)
export const ManagesShiftMixedCustomFieldFilters = mixinFactory('shift', null, null, null, null, null, false)
export const ManagesUserMixedCustomFieldFilters = mixinFactory('user', null, null, null, null, null, false)
export const ManagesWorkMixedCustomFieldFilters = mixinFactory('work', null, null, null, null, null, false)
export const ManagesAllCustomFieldFilters = mixinFactory('all', 'sortedCustomFields', 'customFieldFilters', 'customFields', 'customFieldsFormPropertyPath', 'filterCustomFields', true)
