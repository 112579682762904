import restClient from '@/services/clients/rest'
import { createRestCrudService } from '@/services/GenericCrudService'

const resourceName = 'Punch'
const resourcePath = 'punches'
const crudService = createRestCrudService(resourceName, resourcePath)

export default Object.assign(crudService, {
  async bulkImport (items, options) {
    try {
      const response = await restClient.post(`${resourcePath}/bulk-import`, {
        items,
        options
      })
      return response.data
    } catch (error) {
      console.warn(`Error submitting punch bulk import`, error, items, options)
      throw error
    }
  },
  async approve (punchId) {
    try {
      const response = await restClient.post(`${resourcePath}/${punchId}/approve`)
      return response.data
    } catch (error) {
      console.warn(`Error submitting approving punch`, error)
      throw error
    }
  },
  async distributeCosting (punchId, data) {
    try {
      const response = await restClient.post(`${resourcePath}/${punchId}/distribute-costing`, data)
      return response.data
    } catch (error) {
      console.warn(`Error distributing costing to punch id ${punchId}`, error, data)
      throw error
    }
  },
  async attachToShift (punchId) {
    try {
      const response = await restClient.post(`${resourcePath}/${punchId}/attach-to-shift`)
      return response.data
    } catch (error) {
      console.warn(`Error attaching punch to shift`, error)
      throw error
    }
  },
  async setEntryState (punchId, entryState) {
    try {
      const response = await restClient.put(`${resourcePath}/${punchId}/entry-state`, { entryState })
      return response.data
    } catch (error) {
      console.warn(`Error setting punch entry state`, error)
      throw error
    }
  },
  async applyBulkAction (actionData, itemIds) {
    try {
      const response = await restClient.post(`${resourcePath}/bulk-action`, {
        itemIds,
        actionData
      })
      return response.data
    } catch (error) {
      console.warn(`Error submitting bulk action`, error, actionData)
      throw error
    }
  }
})
