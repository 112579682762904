<template>
  <div class="iso-duration-single-input">
    <b-form-input
      v-model="cardinality"
      number
      type="number"
      class="cardinality"
    />
    <key-multiselect
      v-model="unit"
      label="label"
      track-by="field"
      select-label=""
      deselect-label=""
      :options="unitOptions"
    />
  </div>
</template>
<script>
import KeyMultiselect from '@/components/KeyMultiselect.vue'
import { useVuelidate } from '@vuelidate/core'
import { integer, requiredIf } from '@vuelidate/validators'
import _ from 'lodash'
import ChildValidation from '@/mixins/ChildValidation'

// We're supporting a very limited syntax: a single duration unit, and sign must be next to the cardinality (not before `PT`).
const PATTERN = /^P(-?)([0-9.]+)([A-Z])$/

export default {
  name: 'IsoDurationSingleInput',
  setup () {
    return {
      v$: useVuelidate({ $scope: false, $stopPropagation: true })
    }
  },
  mixins: [ChildValidation],
  components: {
    KeyMultiselect
  },
  props: {
    modelValue: String
  },
  emits: ['update:modelValue'],
  data () {
    return {
      cardinality: null,
      unit: null,
      // Currently we're not including time components, as we don't have a need for it.
      // In the future, if there is a need, we'll determine whether they all belong in
      // the same component or not, and generally how it would look.
      unitOptions: [
        {
          field: 'Y',
          label: 'Years'
        },
        {
          field: 'M',
          label: 'Months'
        },
        {
          field: 'D',
          label: 'Days'
        }
      ]
    }
  },
  watch: {
    modelValue: {
      handler (value) {
        const match = PATTERN.exec(value)
        let cardinality, unit

        if (match && match.length === 4) {
          const sign = match[1] === '-' ? -1 : 1
          cardinality = sign * parseFloat(match[2])
          unit = match[3]
        } else {
          cardinality = null
          unit = null
        }

        if (this.cardinality !== cardinality) this.cardinality = cardinality
        if (this.unit !== unit) this.unit = unit
      },
      immediate: true
    },
    cardinality () {
      this.emitValue()
    },
    unit () {
      this.emitValue()
    }
  },
  methods: {
    emitValue () {
      let value
      if (!_.isFinite(this.cardinality) || !this.unit) {
        value = null
      } else {
        value = `P${this.cardinality}${this.unit}`
      }

      if (value !== this.modelValue) this.$emit('update:modelValue', value)
    }
  },
  validations () {
    return {
      cardinality: {
        required: requiredIf(() => this.unit),
        // Pendulum duration doesn't support float years or months.
        integer
      },
      unit: {
        required: requiredIf(() => _.isFinite(this.cardinality))
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.iso-duration-single-input {
  display: flex;
  flex-direction: row;

  .cardinality {
    width: 7rem;
    margin-right: .25rem;
  }
}
</style>
