<template>
  <key-multiselect
    v-bind="$attrs"
    v-model="forwardValue"
    :multiple="multiple"
    label="name"
    track-by="id"
    select-label=""
    deselect-label=""
    :placeholder="placeholder"
    :disabled="disabled"
    :options="orgUnits"
  />
</template>
<script>
import _ from 'lodash'
import KeyMultiselect from '@/components/KeyMultiselect.vue'

export default {
  name: 'OrgUnitSelect',
  inheritAttrs: false,
  components: {
    KeyMultiselect
  },
  props: {
    modelValue: [Array, Number],
    multiple: Boolean,
    labelType: String,
    placeholder: String,
    disabled: Boolean
  },
  emits: ['update:modelValue'],
  computed: {
    forwardValue: {
      get () {
        return this.modelValue
      },
      set (v) {
        this.$emit('update:modelValue', v)
      }
    },
    orgUnits () {
      const adminAllowOrgUnits = (this.$store.state.adminAllowLabels || [])
        .filter(label => !this.labelType || this.labelType === 'any' ? label.includes(':orgunit:') : label.startsWith(`${this.labelType}:orgunit:`))
        .map(label => parseInt(label.split(':')[2]))
      return this.$store.getters['orgUnits/sortedItems']('name').filter(item => item.active && (_.isEmpty(adminAllowOrgUnits) || adminAllowOrgUnits.includes(item.id)))
    }
  },
  created () {
    this.$store.dispatch('orgUnits/load')
  }
}
</script>
