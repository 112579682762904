<template>
  <b-modal
    v-bind="$attrs"
    title="Shift Summary"
    size="lg"
    no-close-on-backdrop
    hide-footer
    no-fade
    centered
    dialog-class="shift-summary-modal-dialog"
  >
    <spinner v-if="loading" key="loading" />
    <div v-else-if="loadErrorMessage" key="error">
      <h6 class="load-failed">
        <font-awesome-icon icon="triangle-exclamation" /> Load Failed
      </h6>
      <b-btn @click="load" class="reload">
        <font-awesome-icon icon="arrow-rotate-right" /> Try again
      </b-btn>
    </div>
    <div v-else key="summary" class="summary">
      <div class="title">
        {{ formatNaiveDate(shiftData.date) }} -
        {{ workerName }} -
        <template v-if="shiftClassName">
          <b-button @click="openShiftClassFormModal" variant="link" style="padding:0">{{ shiftClassName }}</b-button>
        </template>
        <template v-else>Open Shift</template>
        <id-icon :id="shiftCardId" />
        <audit-icon
          :instanceId="shiftCardId"
          kind="SHIFTCARD"
          resourceName="Shift Card"
          @link-opened="close()"
        />
      </div>
      <b-table
        :fields="[
          'segments', // title on left
          'segmentTitle',
          'start',
          'startExceptions',
          'end',
          'endExceptions',
          'annotation'
        ]"
        :items="segments"
        :tbody-tr-class="rowClass"
        borderless
        small
      >
        <template #head(segmentTitle)></template>
        <template #head(start)>IN</template>
        <template #head(startExceptions)></template>
        <template #head(end)>OUT</template>
        <template #head(endExceptions)></template>
        <template #head(annotation)></template>

        <template #cell(segmentTitle)="data">
          <template v-if="data.item.type === 'punch'">
            <b-button variant="link" style="padding:0" @click="openPunchFormModal(data.item.punchId)">{{ formatSegmentTitle(data.item) }}</b-button>
          </template>
          <template v-else>
            {{ formatSegmentTitle(data.item) }}
          </template>
        </template>
        <template #cell(start)="data">
          {{ data.value && formatDateTime(data.value, timezone) }}
        </template>
        <template #(startExceptions)="data">
          <span class="exceptions">{{ formatExceptions(data.value) }}</span>
        </template>
        <template #cell(end)="data">
          {{ data.value && formatDateTime(data.value, timezone) }}
        </template>
        <template #cell(endExceptions)="data">
          <span class="exceptions">{{ formatExceptions(data.value) }}</span>
        </template>
        <template #cell(annotation)="data">
          {{ formatSegmentAnnotation(data.item) }}
        </template>
      </b-table>

      <b-table
        :fields="['totals', 'raw', 'processed', 'break', 'late']"
        :items="[{raw: null}]"
        borderless
        small
      >
        <template #cell(totals)></template>
        <template #cell()="data">
          {{ formatMinutesAsDuration(shiftData[data.field.key + 'Total'], false) }} hours
        </template>
      </b-table>

      <b-button @click="recalculateShift" :disabled="recalculateDisabled" variant="link" style="padding:0">
        <font-awesome-icon icon="arrow-rotate-right" style="margin-right: .25rem" />
        Recalculate shift totals
        <help-text-icon>
          Recalculating shift totals may be useful if the shift class or other shift settings were changed
          after this shift was already completed. Since shift settings changes are not automatically retroactive
          on previous shifts, we can force recalculation this way.
        </help-text-icon>
      </b-button>

      <b-card class="overrides" title="Overrides">
        <form-group label="Shift Class">
          <shift-class-select
            v-model="overrides.shiftClass"
            placeholder="Open Shift"
            :activeOnly="true"
          />
        </form-group>
        <b-alert :show="showSettingsWarning" variant="warning">
          <font-awesome-icon icon="triangle-exclamation" />
            The Shift Defaults settings currently do not allow shift extension overrides.
            To configure your settings to allow these overrides,
            go Settings => Shifts => Defaults => Advanced Settings, and configure
            <code>Maximum time administrator can extend shift early</code> and/or
            <code>Maximum time administrator can extend shift late</code>.
        </b-alert>
        <form-group label="Shift Earliest Paid"
          :validator="v$.overrides.shiftMinStart"
        >
          <template #default="slotProps">
            <date-time-picker
              v-bind="slotProps"
              v-model="overrides.shiftMinStart"
              :timezone="timezone"
            />
          </template>
        </form-group>
        <form-group label="Shift Latest Paid"
          :validator="v$.overrides.shiftMaxEnd"
        >
          <template #default="slotProps">
            <date-time-picker
              v-bind="slotProps"
              v-model="overrides.shiftMaxEnd"
              :timezone="timezone"
            />
          </template>
        </form-group>
        <form-group :validator="v$.overrides.shiftMaxDuration">
          <template #label>
            {{ fieldLabels.shiftMaxDuration }}
            <help-text-icon>
              This maximum duration is calculated from the first IN punch for the shift,
              and includes breaks.
            </help-text-icon>
          </template>
          <template #default="slotProps">
            <duration-input
              v-bind="slotProps"
              v-model="overrides.shiftMaxDuration"
              hourDigits="3"
            />
          </template>
        </form-group>
        <form-group class="allow-maximum">
          <b-button @click="setOverridesToDefault" variant="link" style="padding:0">
            <font-awesome-icon icon="undo" />
            Reset overrides to default
          </b-button>
        </form-group>
        <form-group class="allow-maximum">
          <b-button @click="allowMaximum" variant="link" style="padding:0">
            <font-awesome-icon icon="expand-alt" />
            Extend shift to maximum allowed
            <help-text-icon>
              Clicking on this link will set the three fields above to the maximum allowed
              acccording to the shift extension override settings.
              To edit those settings to allow greater or less shift extensions,
              go Settings => Shifts => Defaults => Advanced Settings, and configure
              <code>Maximum time administrator can extend shift early</code> and/or
              <code>Maximum time administrator can extend shift late</code>.
            </help-text-icon>
          </b-button>
        </form-group>
        <b-form-checkbox v-model="overrides.skipBreaks">
          Skip breaks
        </b-form-checkbox>

        <custom-field-inputs
          v-if="shiftCustomFields.length > 0"
          appliesTo="shift"
          :showEndCondition="!isClockedIn"
          :enableUserConditions="true"
          :orgUser="shiftData.employee"
          :orgUnit="shiftData.orgUnit"
          :department="shiftData.department"
          :userLabels="userLabels"
          :userCustomFieldValues="userCustomFieldValues"
          v-model="overrides.customFieldValues"
          @validityChanged="customFieldValuesValid = $event"
        />

        <div class="footer-above-buttons" v-if="saveErrorMessage">
          <div class="error-message" v-if="saveErrorMessage">
            <font-awesome-icon icon="triangle-exclamation" class="icon" />
            {{ saveErrorMessage }}
          </div>
        </div>

        <b-button-group class="footer-buttons">
          <b-button
            @click="save"
            :disabled="saveDisabled"
            variant="primary"
            class="active save"
            size="sm"
          >
            <font-awesome-icon :icon="saving ? 'circle-notch' : 'circle-check'" :spin="saving" />
            Save
          </b-button>
          <b-button
            @click="cancel"
            :disabled="cancelDisabled"
            variant="secondary"
            class="active cancel"
            size="sm"
          >
            <font-awesome-icon icon="circle-xmark" />
            Cancel
          </b-button>
        </b-button-group>
      </b-card>
    </div>
  </b-modal>
</template>
<script>
import service from './services/ShiftCardService'
import { extractErrorMessage } from '@/utils/misc'
import AuditIcon from '@/components/AuditIcon.vue'
import CustomFieldInputs from '@/components/CustomFieldInputs.vue'
import IdIcon from '@/components/IdIcon.vue'
import DateTimePicker from '@/components/DateTimePicker.vue'
import DurationInput from '@/components/DurationInput.vue'
import HelpTextIcon from '@/components/HelpTextIcon.vue'
import Spinner from '@/components/Spinner.vue'
import Maskable from '@/mixins/Maskable'
import { mapGetters, mapState } from 'vuex'
import _ from 'lodash'
import { TimeEntryExceptionMap } from '@/utils/TimeEntryExceptions'
import { useVuelidate } from '@vuelidate/core'
import { integer, required } from '@vuelidate/validators'
import { dateTime, dateTimeMinValue, dateTimeMaxValue, durationMaxValue, durationMinValue } from '@/utils/validators'
import ShiftSummaryMixin from './ShiftSummaryMixin'
import ShiftClassSelect from '@/views/settings/shifts/ShiftClassSelect.vue'
import { openPunchFormModal } from '@/views/manage/punches/openPunchFormModal'
import { openShiftClassFormModal } from '@/views/settings/shifts/openShiftClassFormModal'
import { useModalController } from 'bootstrap-vue-next'

export default {
  name: 'ShiftSummaryModal',
  inheritAttrs: false,
  setup () {
    const modalController = useModalController()
    return {
      v$: useVuelidate({ $scope: false, $stopPropagation: true }),
      hideModal: modalController.hide,
      showModal: modalController.show
    }
  },
  components: {
    AuditIcon,
    CustomFieldInputs,
    DateTimePicker,
    DurationInput,
    IdIcon,
    HelpTextIcon,
    ShiftClassSelect,
    Spinner
  },
  mixins: [Maskable, ShiftSummaryMixin],
  props: {
    shiftCardId: Number,
    onTotalHoursUpdated: Function
  },
  data () {
    return {
      shiftData: {},
      supplementalData: {},
      overrides: {},
      loading: false,
      saving: false,
      loadErrorMessage: null,
      saveErrorMessage: null,
      ignoreNextShiftClassChange: false,
      fieldLabels: {
        shiftMaxDuration: 'Shift Maximum Duration Paid'
      },
      customFieldValuesValid: true
    }
  },
  computed: {
    ...mapGetters('formatPreferences', [
      'formatNaiveDate',
      'formatDateTime',
      'formatMinutesAsDuration',
      'formatName'
    ]),
    ...mapGetters({
      shiftClasses: 'shiftClasses/sortedItems'
    }),
    ...mapGetters('customFields', ['shiftCustomFields']),
    ...mapState([
      'maxExtendShiftEarly',
      'maxExtendShiftLate',
      'openShiftType',
      'openShiftStartsAt',
      'openShiftMaxDuration'
    ]),
    sortedShiftClasses () {
      return this.shiftClasses('name')
    },
    // Punches and segments are stored in separate flat lists, but we want to combine them for display.
    segments () {
      const punches = (this.shiftData.punches || [])
        .map(punch => ({
          // key used in v-for
          key: `punch-${punch.id}`,
          type: 'punch',
          punchId: punch.id,
          start: punch.inDt || punch.in,
          end: punch.outDt || punch.out,
          startExceptions: punch.inExceptions,
          endExceptions: punch.outExceptions,
          tags: []
        }))
      const shiftSegments = (this.shiftData.segments || [])
        .map((segment, index) => ({
          key: `segment-${index}`,
          type: 'segment',
					punchId: segment.punch,
					start: segment.segment[0],
          end: segment.segment[1],
          tags: segment.tags
        }))
      const segments = punches.concat(shiftSegments)

			// sort segments according to the following precedence:
			//    1) punch type if punch id's the same
			//    3) by start/end
      return segments.sort((a ,b) => {
				const aDt = new Date(a.start || b.end)
        const bDt = new Date(b.start || b.end)

        // If both items belong to same punch, then punch goes before segment.
				if (a.punchId && a.punchId === b.punchId) {
					if (a.type === 'punch') return - 1
					if (b.type === 'punch') return 1
				}

				if (aDt === bDt) {
					return 0
				}

				return aDt < bDt ? -1 : 1
      })
    },
    shiftClassName () {
      return _.get(this.savedShiftClass, 'name')
    },
    selectedShiftClass () {
      return this.shiftClassForId(this.overrides.shiftClass)
    },
    selectedShiftClassId () {
      return this.overrides.shiftClass
    },
    savedShiftClass () {
      return this.shiftClassForId(this.shiftData.shiftClass)
    },
    scheduledPaidStartForSelectedShiftClass () {
      return this.scheduledPaidStart(this.selectedShiftClass)
    },
    scheduledPaidEndForSelectedShiftClass () {
      return this.scheduledPaidEnd(this.selectedShiftClass)
    },
    scheduledMaxDurationForSelectedShiftClass () {
      return this.scheduledPaidEndForSelectedShiftClass.diff(this.scheduledPaidStartForSelectedShiftClass, 'minutes')
    },
    scheduledPaidStartForSavedShiftClass () {
      return this.scheduledPaidStart(this.savedShiftClass)
    },
    scheduledPaidEndForSavedShiftClass () {
      return this.scheduledPaidEnd(this.savedShiftClass)
    },
    scheduledMaxDurationForSavedShiftClass () {
      return this.scheduledPaidEndForSavedShiftClass.diff(this.scheduledPaidStartForSavedShiftClass, 'minutes')
    },
    savedOverrides () {
      const data = this.shiftData
      return {
        shiftClass: data.shiftClass,
        // Set overrides from shift card. Otherwise, use derived values.
        shiftMinStart: data.overrideShiftMinStart || this.scheduledPaidStartForSavedShiftClass.format(),
        shiftMaxEnd: data.overrideShiftMaxEnd || this.scheduledPaidEndForSavedShiftClass.format(),
        shiftMaxDuration: data.overrideShiftMaxDuration || this.scheduledMaxDurationForSavedShiftClass,
        skipBreaks: data.skipBreaks,
        customFieldValues: data.customFieldValues
      }
    },
    isDirty () {
      return !_.isEqual(this.overrides, this.savedOverrides)
    },
    recalculateDisabled () {
      return this.isDirty || this.loading || this.saving
    },
    saveDisabled () {
      return !this.isDirty || this.v$.$invalid || this.loading || this.saving
    },
    cancelDisabled () {
      return !this.isDirty || this.loading || this.saving
    },
    showSettingsWarning () {
      const invalid = (
        this.v$.overrides.shiftMinStart.$invalid ||
        this.v$.overrides.shiftMaxEnd.$invalid ||
        this.v$.overrides.shiftMaxDuration.$invalid
      )
      return invalid && !this.maxExtendShiftEarly && !this.maxExtendShiftLate
    },
    minAllowedShiftMinStart () {
      return this.scheduledPaidStartForSelectedShiftClass.clone().subtract(this.maxExtendShiftEarly, 'minutes')
    },
    maxAllowedShiftMaxEnd () {
      return this.scheduledPaidEndForSelectedShiftClass.clone().add(this.maxExtendShiftLate, 'minutes')
    },
    maxAllowedShiftMaxDuration () {
      return this.scheduledMaxDurationForSelectedShiftClass + this.maxExtendShiftEarly + this.maxExtendShiftLate
    },
    orgUserSupplementalData () {
      return this.supplementalData.orgUser?.[this.shiftData.employee]
    },
    workerName () {
      const worker = this.orgUserSupplementalData
      return worker && this.formatName(worker.firstName, worker.lastName)
    },
    timezone () {
      const orgUnit = _.get(this.supplementalData.orgUnit, this.shiftData.orgUnit)
      return orgUnit && orgUnit.timezone
    },
    isClockedIn () {
      const lastPunch = _.last(this.shiftData.punches)
      return !!lastPunch && !lastPunch.outDt
    },
    userLabels () {
      return (_.get(this.shiftData, 'labels') || [])
        .flatMap(label => {
          const match = label.match(/user:(\d+)/)
          return match ? [parseInt(match[1])] : []
        })
    },
    userCustomFieldValues () {
      return this.orgUserSupplementalData?.customFieldValues || null
    }
  },
  watch: {
    // We watch selectedShiftClassId to trigger resetting shift overrides. If we had instead watched selectedShiftClass,
    // then if shift card is initially open shift, i.e. null shift class, then such a watcher would not be called on initial load,
    // because the shift class hasn't changed. But we need ignoreNextShiftClassChange to be reset to false, or else the next user
    // change to shift class won't reset the overrides. In contrast, selectedShiftClassId works, because its mounted value
    // is undefined, so when an open shift changes its value to null, then this watcher is triggered.
    selectedShiftClassId (newValue, oldValue) {
      // console.log(`selectedShiftClassId changed from ${oldValue} to ${newValue}, and ignoreNextShiftClassChange is ${this.ignoreNextShiftClassChange}`)

      // We want to reset shift range overrides when user changes shift class,
      // but not when data is loaded, saved, or when user cancels changes.
      if (this.ignoreNextShiftClassChange) {
        this.ignoreNextShiftClassChange = false
        return
      }

      this.setOverridesToDefault()
    },
    'shiftData.processedTotal' (processedTotal) {
      if (this.onTotalHoursUpdated) this.onTotalHoursUpdated(processedTotal)
    }
  },
  methods: {
    close () {
      this.hideModal()
    },
    applyShiftData (data) {
      this.shiftData = data.results[0]
      this.supplementalData = data.supplementalData
      // ignoreNextShiftClassChange prevents selectedShiftClass watcher from resetting
      // overrides to natural shift values.
      this.ignoreNextShiftClassChange = true

      // This event is emitted both on load and save.
      this.$emit('shift-updated', {
        shiftData: this.shiftData,
        supplementalData: this.supplementalData
      })

      this.restoreToSaved()
    },
    load () {
      this.loading = true
      this.loadErrorMessage = null

      Promise.all([
        service.get(this.shiftCardId),
        // We need shift classes loaded when we update and compute component state.
        this.$store.dispatch('shiftClasses/load')
      ]).then(results => results[0]) // pass along shift card
        .then(this.applyShiftData)
        .catch(error => { this.loadErrorMessage = extractErrorMessage(error) })
        .finally(() => { this.loading = false })
    },
    formatExceptions (exceptions) {
      if (_.isEmpty(exceptions)) return ''
      const codes = exceptions.map(e => TimeEntryExceptionMap[e] || e)
      return codes.join(' ')
    },
    formatSegmentTitle (segment) {
      return segment.type === 'punch' ? 'Punch' : '    ==>'
    },
    formatSegmentAnnotation (segment) {
      const tags = []
      if (segment.tags.includes('PAID')) tags.push('Paid')
      if (segment.tags.includes('UNPAID')) tags.push('Unpaid')
      if (segment.tags.includes('NON_WORKED')) tags.push('Non-worked')
      if (segment.tags.includes('BREAK')) tags.push('Break')
      if (segment.tags.includes('BEFORE_SHIFT')) tags.push('Before shift')
      if (segment.tags.includes('AFTER_SHIFT')) tags.push('After shift')
      if (segment.tags.includes('EXCEED_SHIFT')) tags.push('Exceed duration')
      if (segment.tags.includes('REVISION')) tags.push('Revision')
      if (segment.tags.includes('GRACE')) tags.push('Grace')
      if (segment.tags.includes('ROUNDING')) tags.push('Rounded')
      if (segment.tags.includes('LOCK_OUT')) tags.push('Locked out')

      return tags.length > 0 ? tags.join(', ') : ''
    },
    rowClass (item, type) {
      const segment = item
      if (item.type == 'punch') return
      else return 'segment-row'
    },
    save () {
      this.saving = true
      this.saveErrorMessage = null

      // Clear shift range override values that equal their natural value.
      const overrides = _.cloneDeep(this.overrides)
      if (overrides.shiftMinStart === this.scheduledPaidStartForSelectedShiftClass.format()) overrides.shiftMinStart = null
      if (overrides.shiftMaxEnd === this.scheduledPaidEndForSelectedShiftClass.format()) overrides.shiftMaxEnd = null
      if (overrides.shiftMaxDuration === this.scheduledMaxDurationForSelectedShiftClass) overrides.shiftMaxDuration = null

      service.override(this.shiftCardId, overrides)
        .then(this.applyShiftData)
        .catch(error => {
          this.saveErrorMessage = extractErrorMessage(error)
        })
        .finally(() => this.saving = false)
    },
    cancel () {
      this.saveErrorMessage = null
      this.ignoreNextShiftClassChange = true
      this.restoreToSaved()
    },
    restoreToSaved () {
      this.overrides = _.cloneDeep(this.savedOverrides)
    },
    shiftClassForId (shiftClassId) {
      return this.sortedShiftClasses.find(shiftClass => shiftClass.id === shiftClassId)
    },
    scheduledPaidStart (shiftClass) {
      const shiftDate = this.shiftData.date
      const timezone = this.timezone
      const firstClockIN = _.get(this.shiftData.punches, '0.inDt') || _.get(this.shiftData.punches, '0.in')

      return this.calculateScheduledPaidStart(
        shiftDate,
        timezone,
        firstClockIN,
        shiftClass
      )
    },
    scheduledPaidEnd (shiftClass) {
      const shiftDate = this.shiftData.date
      const timezone = this.timezone
      const firstClockIN = _.get(this.shiftData.punches, '0.inDt') || _.get(this.shiftData.punches, '0.in')
      const lastPunch = _.last(this.shiftData.punches)
      const lastClockOUT = lastPunch ? lastPunch.outDt || lastPunch.out : null

      return this.calculateScheduledPaidEnd(
        shiftDate,
        timezone,
        firstClockIN,
        lastClockOUT,
        shiftClass
      )
    },
    recalculateShift () {
      this.saving = true
      this.saveErrorMessage = null

      service.recalculate(this.shiftCardId)
        .then(this.applyShiftData)
        .catch(error => {
          this.saveErrorMessage = extractErrorMessage(error)
        })
        .finally(() => this.saving = false)
    },
    allowMaximum () {
      Object.assign(this.overrides, {
        shiftMinStart: this.minAllowedShiftMinStart.format(),
        shiftMaxEnd: this.maxAllowedShiftMaxEnd.format(),
        shiftMaxDuration: this.maxAllowedShiftMaxDuration
      })
    },
    setOverridesToDefault () {
      this.overrides.shiftMinStart = this.scheduledPaidStartForSelectedShiftClass.format()
      this.overrides.shiftMaxEnd = this.scheduledPaidEndForSelectedShiftClass.format()
      this.overrides.shiftMaxDuration = this.scheduledMaxDurationForSelectedShiftClass
    },
    openPunchFormModal (punchId) {
      openPunchFormModal(punchId, this.showModal)
        .then(saved => {
          if (saved) {
            // Refresh shift summary if punch was edited.
            this.load()
          }
        })
    },
    openShiftClassFormModal () {
      openShiftClassFormModal(this.shiftData.shiftClass, this.showModal)
    }
  },
  mounted () {
    this.load()
    if (this.$store.getters.hasCustomFieldFeature) this.$store.dispatch('customFields/load')
  },
  validations () {
    return {
      overrides: {
        shiftMinStart: {
          required,
          dateTime,
          minValue: dateTimeMinValue(
            this.minAllowedShiftMinStart,
            min => this.formatDateTime(min, this.timezone)
          ),
          maxValue: dateTimeMaxValue(
            this.overrides.shiftMaxEnd,
            max => this.formatDateTime(max, this.timezone)
          )
        },
        shiftMaxEnd: {
          required,
          dateTime,
          minValue: dateTimeMinValue(
            this.overrides.shiftMinStart,
            min => this.formatDateTime(min, this.timezone)
          ),
          maxValue: dateTimeMaxValue(
            this.maxAllowedShiftMaxEnd,
            max => this.formatDateTime(max, this.timezone)
          )
        },
        shiftMaxDuration: {
          required,
          integer,
          // TODO: We made a mistake on the backend to default overrideShiftMaxDuration to 0, and interpret that
          // TODO: as meaning value is not set. We should have made only null to mean not set, and 0 would mean
          // TODO: shift doesn't accrue hours. So for now, we need to validate that value is at least 1 minute.
          minValue: durationMinValue(1, min => this.formatMinutesAsDuration(min, false)),
          maxValue: durationMaxValue(
            // TODO: Is the following max value correct?
            this.maxAllowedShiftMaxDuration,
            max => this.formatMinutesAsDuration(max, false)
          )
        },
        customFieldValues: {
          childValid: function () { return !!this.customFieldValuesValid }
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/_bootstrap-variables';

.shift-summary-modal-dialog {
  .summary {
    .title {
      text-align: center;
      font-weight: bold;
    }
    :deep() .b-table thead th:first-of-type {
      text-decoration: underline;
    }
    .segment-row {
      font-style: italic;
      font-size: .75rem;
    }
    .exceptions {
      color: map-get($theme-colors, danger);
    }

    // TODO: Put disabled link styling in global css.
    :deep(a.disabled) {
      pointer-events: none;
      opacity: 0.5;
    }

    .overrides {
      max-width: 400px;
      margin-top: 1rem;

      :deep(.e-datetime-wrapper), :deep(.multiselect) {
        max-width: 15rem;
      }

      .allow-maximum {
        margin-bottom: .5rem;
      }

      :deep(.custom-field-inputs) {
        margin-top: 1rem;
        .title {
          font-weight: bold;
        }
      }

      .footer-above-buttons {
        margin: 1.5rem 0 -1rem 0;

        .error-message {
          text-align: left;
        }
      }

      .footer-buttons > button {
        margin: 1.5rem 1rem 0px 0px;
      }
    }
  }
}
</style>
