<template>
  <div class="break-form" role="tablist">
    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-btn v-b-toggle.collapseBasic block :variant="v$.$validationGroups.basicSettingsGroup.$invalid ? 'danger' : 'dark'">
          Basic Settings
        </b-btn>
      </b-card-header>
      <b-collapse id="collapseBasic" visible role="tabpanel">
        <b-card-body>
          <b-container>
            <b-row>
              <b-col cols="12" sm="6">
                <form-group :validator="v$.form.name" :label="fieldLabels.name">
                  <template #default="slotProps">
                    <b-form-input
                      v-bind="slotProps"
                      v-model="form.name"
                      trim
                      type="text"
                    />
                  </template>
                </form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" sm="6">
                <form-group :validator="v$.windowType" :label="fieldLabels.windowType">
                  <template #default="slotProps">
                    <b-form-radio-group v-model="windowType"
                      v-bind="slotProps"
                    >
                      <b-form-radio value="time">{{ fieldLabels.windowTypeFixed }}</b-form-radio>
                      <b-form-radio value="relative">{{ breakType === 'punched' ? fieldLabels.windowTypeRelativeToStart : (breakType === 'auto' ? fieldLabels.windowTypeRelativeToWorked : fieldLabels.windowTypeRelative) }}</b-form-radio>
                    </b-form-radio-group>
                  </template>
                </form-group>
              </b-col>
            </b-row>

            <b-row v-if="windowType === 'time'">
              <b-col cols="12" class="complex-field">
                <form-group :validator="v$.startFixedTime" label="The break normally starts at" label-cols="auto">
                  <template #default="slotProps">
                    <time-picker
                      v-bind="slotProps"
                      v-model="startFixedTime"
                      :step="15"
                    />
                  </template>
                </form-group>
                <help-text-icon>
                  Worker must work past this time in order to automatically deduct break, or for break to be paid.
                </help-text-icon>
              </b-col>
            </b-row>

            <b-row v-if="windowType === 'relative'">
              <b-col cols="12" class="complex-field">
                <form-group :validator="v$.startRelativeDuration" label="The break normally starts after" label-cols="auto">
                  <template #default="slotProps">
                    <duration-input
                      v-bind="slotProps"
                      v-model="startRelativeDuration"
                      hourDigits="3" />
                    </template>
                </form-group>
                hours {{ breakType === 'punched' ? 'after shift begins' : (breakType === 'auto' ? 'of shift worked' : '') }}.
                <help-text-icon>
                  Worker must work past this time in order to automatically deduct break, or for break to be paid.
                </help-text-icon>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12">
                <form-group :validator="v$.duration" label="The break lasts for a duration of" label-cols="auto">
                  <template #default="slotProps">
                    <duration-input
                      v-bind="slotProps"
                      v-model="duration" />
                  </template>
                </form-group>
              </b-col>
            </b-row>

            <b-row v-if="isManual && windowType === 'time'">
              <b-col>
                <form-group>
                  <b-form-checkbox v-model="isRepeating">
                    Repeat every day {{ startFixedTime ? `at ${modelToFormattedTime(startFixedTime)}` : '' }}
                  </b-form-checkbox>
                </form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <form-group>
                  <b-form-checkbox v-model="isPaid">
                    The break is paid
                  </b-form-checkbox>
                </form-group>
              </b-col>
            </b-row>

          </b-container>
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-btn block :variant="v$.$validationGroups.manualSettingsGroup.$invalid ? 'danger' : 'outline-dark'" class="checkbox-header-button">
          <b-form-checkbox v-model="isManual">
            Manual Break - Worker can Clock OUT for this Break
          </b-form-checkbox>
        </b-btn>
      </b-card-header>
      <b-collapse id="collapseManual" v-model="isManual" role="tabpanel">
        <b-card-body>
          <b-container>
            <b-row v-if="windowType === 'time'">
              <b-col cols="12" class="complex-field">
                The break may begin as early as
                <form-group :validator="v$.form.punchStartsMin">
                  <template #default="slotProps">
                    <time-picker v-model="form.punchStartsMin"
                      v-bind="slotProps"
                      :step="15"
                    />
                  </template>
                </form-group>
                or as late as
                <form-group :validator="v$.form.punchStartsMax">
                  <template #default="slotProps">
                    <time-picker v-model="form.punchStartsMax"
                      v-bind="slotProps"
                      :step="15"
                    />
                  </template>
                </form-group>
              </b-col>
            </b-row>
            <b-row v-else-if="windowType === 'relative'">
              <b-col cols="12" class="complex-field">
                The break may begin as early as
                <form-group :validator="v$.form.punchStartsMinDuration">
                  <template #default="slotProps">
                    <duration-input
                      v-bind="slotProps"
                      v-model="form.punchStartsMinDuration"
                      hourDigits="3" />
                  </template>
                </form-group>
                hours, or as late as
                <form-group :validator="v$.form.punchStartsMaxDuration">
                  <template #default="slotProps">
                    <duration-input
                      v-bind="slotProps"
                      v-model="form.punchStartsMaxDuration"
                      hourDigits="3" />
                  </template>
                </form-group>
                hours after shift begins.
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" class="complex-field">
                If worker clocks OUT for shorter than a duration of
                <form-group :validator="v$.form.punchMinDuration">
                  <template #default="slotProps">
                    <duration-input
                      v-bind="slotProps"
                      v-model="form.punchMinDuration" />
                  </template>
                </form-group>
                hours, then it's not considered a break.
                <help-text-icon>
                  If a worker clocks OUT during the break period for a shorter duration than this value,
                  then it won't match this break.
                  Consequently, if the break normally paid, then this worker will not be paid for the break.
                  This setting can be useful if workers may clock OUT for a shorter duration before their regular break,
                  and you want to make sure the breaks matches with the correct punch.
                </help-text-icon>
              </b-col>
              <b-col cols="12" class="complex-field">
                If worker clocks OUT for longer than a duration of
                <form-group :validator="v$.form.punchMaxDuration">
                  <template #default="slotProps">
                    <duration-input
                      v-bind="slotProps"
                      v-model="form.punchMaxDuration" />
                  </template>
                </form-group>
                hours, then it's not considered a break.
                <help-text-icon>
                  If a worker clocks OUT during the break period for a longer duration than this value,
                  then it's considered an absence. Consequently, if the break normally paid, then
                  this worker will not be paid for the break.
                  If this value is left blank, then worker is permitted to clock OUT for a break and not return to work.
                </help-text-icon>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <form-group>
                  <b-form-checkbox v-model="form.punchLockOut">
                    Lock out if break not over yet
                    <help-text-icon>
                      If checked, then employee will not be able to clock IN from the break until it is over.
                      This setting is different from the <code>Deduct automatically if not used</code> setting,
                      but they may be used together.
                    </help-text-icon>
                  </b-form-checkbox>
                </form-group>
              </b-col>
            </b-row>
          </b-container>
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-btn block :variant="v$.$validationGroups.automaticSettingsGroup.$invalid ? 'danger' : 'outline-dark'" class="checkbox-header-button">
          <b-form-checkbox v-model="isAutomatic">
            Automatic Break - This break will be deducted automatically {{ isManual ? 'if worker does not Clock OUT for full break duration' : ''}}
          </b-form-checkbox>
        </b-btn>
      </b-card-header>
      <b-collapse id="collapseAutomatic" :visible="false" role="tabpanel">
        <b-card-body>
        </b-card-body>
      </b-collapse>
    </b-card>

    <div v-if="v$?.breakTypeSelected?.$invalid" class="error-message">
      <font-awesome-icon icon="triangle-exclamation" />
      <span class="text">Either Manual or Automatic Break must be selected (both may be selected too).</span>
    </div>

  </div>
</template>
<script>
import { integer, maxLength, required, requiredIf } from '@vuelidate/validators'
import { mapGetters} from 'vuex'
import _ from 'lodash'
import DurationInput from '@/components/DurationInput.vue'
import HelpTextIcon from '@/components/HelpTextIcon.vue'
import TimePicker from '@/components/TimePicker.vue'
import { useVuelidate } from '@vuelidate/core'
import { durationMaxValue, durationMinValue, timeMinValue, time } from '@/utils/validators'
import { stripSecondsFromTimeString } from '@/utils/misc'
import DetailBasedForm from '@/mixins/DetailBasedForm'

const NewItem = () => ({
  name: null,
  isPunched: false,
  punchType: null,
  punchStartsMin: null,
  punchStartsMax: null,
  punchStartsNormal: null,
  punchStartsMinDuration: null,
  punchStartsMaxDuration: null,
  punchStartsNormalDuration: null,
  punchTimeIsDaily: false,
  isPaid: false,
  punchMinDuration: null,
  punchMaxDuration: null,
  punchRequired: false,
  punchLockOut: false,
  isAuto: false,
  autoType: null,
  autoStartsTime: null,
  autoStartsDuration: null,
  autoDuration: null,
  autoIsPaid: false
})

export default {
  name: 'BreakForm',
  setup () {
    return { v$: useVuelidate() }
  },
  inheritAttrs: false,
  mixins: [DetailBasedForm],
  components: {
    DurationInput,
    HelpTextIcon,
    TimePicker
  },
  data () {
    return {
      form: NewItem(),
      fieldLabels: {
        name: 'Name',
        windowType: 'Break window',
        windowTypeFixed: 'Time of day',
        windowTypeRelative: 'Relative duration',
        windowTypeRelativeToStart: 'Relative to start of shift',
        windowTypeRelativeToWorked: 'Relative to hours worked',
        punchMinDuration: 'Minimum break duration',
        punchMaxDuration: 'Maximum break duration'
      },
      // shared properties that need to be multiplexed between punched and auto properties
      windowType: null,
      startFixedTime: null,
      startRelativeDuration: null,
      duration: null,
      isPaid: false,
      // TODO: Why does new vuelidate require a ref for corresponding validation?
      breakTypeSelected: false
    }
  },
  computed: {
    ...mapGetters('formatPreferences', ['formatMinutesAsDuration', 'modelToFormattedTime']),
    formInvalid () {
      return this.v$.$invalid
    },
    invalidFields () {
      const fields = Object.keys(this.v$.form.$params)
        .filter(fieldName => this.v$.form[fieldName].$invalid)
      return fields
    },
    breakType: {
      get () {
        if (this.form.isPunched) return 'punched'
        if (this.form.isAuto) return 'auto'
        return null
      },
      set (v) {
        this.form.isPunched = v === 'punched'
        this.form.isAuto = v === 'auto'
      }
    },
    isManual: {
      get () {
        return Boolean(this.form.isPunched)
      },
      set (v) {
        if (this.isAutomatic) {
          this.form.isAuto = !v
          this.form.punchRequired = !!v
        }
        this.form.isPunched = v
      }
    },
    isAutomatic: {
      get () {
        return Boolean(this.form.isAuto || (this.form.isPunched && this.form.punchRequired))
      },
      set (v) {
        if (this.form.isPunched) {
          this.form.punchRequired = v
          this.form.isAuto = false
        }
        else this.form.isAuto = v
      }
    },
    isRepeating: {
      get () {
        return this.form.punchTimeIsDaily
      },
      set (v) {
        this.form.punchTimeIsDaily = v
      }
    }
  },
  watch: {
    originalData: {
      handler (newValue) {
        if (_.isEmpty(newValue)) {
          const formData = NewItem()
          this.formDataChanged(formData)
        } else {
          if (newValue.isAuto && !newValue.autoType) {
            // autoType was added more recently, and legacy breaks will always be relative.
            newValue.autoType = 'relative'
          }
          if (newValue.isPunched) {
            if (newValue.punchType === 'time') {
              if (newValue.punchStartsMin === newValue.punchStartsMax) {
                newValue.punchStartsNormal = newValue.punchStartsMin
              }
            } else {
              if (newValue.punchStartsMinDuration === newValue.punchStartsMaxDuration) {
                newValue.punchStartsNormalDuration = newValue.punchStartsMinDuration
              }
            }
          }
          newValue.punchStartsNormal = stripSecondsFromTimeString(newValue.punchStartsNormal)
          newValue.punchStartsMin = stripSecondsFromTimeString(newValue.punchStartsMin)
          newValue.punchStartsMax = stripSecondsFromTimeString(newValue.punchStartsMax)
          newValue.autoStartsTime = stripSecondsFromTimeString(newValue.autoStartsTime)
          newValue.punchLockOut = Boolean(newValue.punchLockOut)
          newValue.punchTimeIsDaily = Boolean(newValue.punchTimeIsDaily)
          this.formDataChanged(_.cloneDeep(newValue))

          // Work around a bug for legacy breaks that don't have start time/duration set,
          // but doesn't display vuelidate error. I don't know why this happens.
          if (!this.startFixedTime) {
            this.v$.startFixedTime.$touch()
          }
          if (!this.startRelativeDuration) {
            this.v$.startRelativeDuration.$touch()
          }
        }
      },
      immediate: true
    },
    formData (newValue) {
      this.form = newValue
      this.copyFormToSharedProperties()
    },
    formInvalid: {
      handler (value) {
        this.formInvalidChanged(value)
      },
      immediate: true
    },
    breakType (breakType) {
      if (breakType !== 'punched') {
        this.form.isPaid = false
        this.form.punchRequired = false
        this.form.punchLockOut = false
      }
      if (breakType !== 'auto') {
        this.form.autoDuration = null
        this.form.autoIsPaid = false
      }

      this.copySharedPropertiesToForm()
    },
    'form.isPunched' (isPunched) {
      if (!isPunched) {
        this.form.punchMinDuration = null
        this.form.punchMaxDuration = null
      }
    },
    'form.punchType' (punchType) {
      if (punchType !== 'time') {
        this.form.punchStartsNormal = null
        this.form.punchStartsMin = null
        this.form.punchStartsMax = null
        this.form.punchTimeIsDaily = false
      }
      if (punchType !== 'relative') {
        this.form.punchStartsNormalDuration = null
        this.form.punchStartsMinDuration = null
        this.form.punchStartsMaxDuration = null
      }
    },
    'form.autoType' (punchType) {
      if (punchType === 'time') {
        this.form.autoStartsTime = this.startFixedTime
      } else {
        this.form.autoStartsTime = null
      }
      if (punchType !== 'relative') {
        this.form.autoStartsDuration = null
      }
    },
    'form.isPaid' (isPaid) {
      if (isPaid) this.form.punchRequired = false
    },
    // shared property watchers
    windowType (v) {
      this.form.punchType = this.breakType === 'punched' ? v : null
      this.form.autoType = this.breakType === 'auto' ? v : null
    },
    startFixedTime (v) {
      this.form.punchStartsNormal = this.breakType === 'punched' ? v : null
      this.form.autoStartsTime = this.breakType === 'auto' ? v : null
    },
    startRelativeDuration (v) {
      this.form.punchStartsNormalDuration = this.breakType === 'punched' ? v : null
      this.form.autoStartsDuration = this.breakType === 'auto' ? v : null
    },
    duration (v) {
      this.form.punchDuration = this.breakType === 'punched' ? v : null
      this.form.autoDuration = this.breakType === 'auto' ? v : null
    },
    isPaid (v) {
      this.form.isPaid = this.breakType === 'punched' && v || false
      this.form.autoIsPaid = this.breakType === 'auto' && v || false
    }
  },
  methods: {
    copyFormToSharedProperties () {
      if (this.breakType === 'punched') {
        this.windowType = this.form.punchType
        this.startFixedTime = this.form.punchStartsNormal
        this.startRelativeDuration = this.form.punchStartsNormalDuration
        this.duration = this.form.punchDuration
        this.isPaid = this.form.isPaid
      } else if (this.breakType === 'auto') {
        this.windowType = this.form.autoType
        this.startFixedTime = this.form.autoStartsTime
        this.startRelativeDuration = this.form.autoStartsDuration
        this.duration = this.form.autoDuration
        this.isPaid = this.form.autoIsPaid
      } else {
        this.windowType = null
        this.startFixedTime = null
        this.startRelativeDuration = null
        this.duration = null
        this.isPaid = false
      }
    },
    copySharedPropertiesToForm () {
      // punched properties
      this.form.punchType = this.breakType === 'punched' ? this.windowType : null
      this.form.punchStartsNormal = this.breakType === 'punched' ? this.startFixedTime : null
      this.form.punchStartsNormalDuration = this.breakType === 'punched' ? this.startRelativeDuration : null
      this.form.punchDuration = this.breakType === 'punched' ? this.duration : null
      this.form.isPaid = this.breakType === 'punched' && this.isPaid || false

      // auto properties
      this.form.autoType = this.breakType === 'auto' ? this.windowType : null
      this.form.autoStartsTime = this.breakType === 'auto' ? this.startFixedTime : null
      this.form.autoStartsDuration = this.breakType === 'auto' ? this.startRelativeDuration : null
      this.form.autoDuration = this.breakType === 'auto' ? this.duration : null
      this.form.isPaid = this.breakType === 'auto' && this.autoIsPaid || false
    }
  },
  validations () {
    const v = {
      $validationGroups: {
        basicSettingsGroup: [
          'form.name',
          'windowType',
          'duration',
          'startFixedTime',
          'startRelativeDuration',
        ],
        manualSettingsGroup: [
          'breakTypeSelected',
          'form.punchStartsMin',
          'form.punchStartsMax',
          'form.punchStartsMinDuration',
          'form.punchStartsMaxDuration',
          'form.punchMinDuration',
          'form.punchMaxDuration'
        ],
        automaticSettingsGroup: [
          'breakTypeSelected'
        ]
      },
      breakTypeSelected: {
        // At least one must be selected. Else, both groups above are invalid.
        required: () => this.isManual || this.isAutomatic
      },
      windowType: {
        required
      },
      startFixedTime: {
        required: requiredIf(() => this.windowType === 'time'),
        time
      },
      startRelativeDuration: {
        required: requiredIf(() => this.windowType === 'relative'),
        integer,
        minValue: durationMinValue(0, min => this.formatMinutesAsDuration(min, false)),
        maxValue: durationMaxValue(60 * 168, max => this.formatMinutesAsDuration(max, false))
      },
      duration: {
        required,
        minValue: durationMinValue(0, min => this.formatMinutesAsDuration(min, false)),
        maxValue: durationMaxValue(60 * 24, max => this.formatMinutesAsDuration(max, false))
      },
      form: {
        name: {
          required,
          maxLength: maxLength(40)
        },
        punchStartsMin: {
          required: requiredIf(() => this.isManual && this.form.punchType === 'time'),
          time
        },
        punchStartsMax: {
          required: requiredIf(() => this.isManual && this.form.punchType === 'time'),
          time,
          minValue: timeMinValue(this.form.punchStartsMin, this.modelToFormattedTime)
        },
        punchStartsMinDuration: {
          required: requiredIf(() => this.isManual && this.form.punchType === 'relative'),
          integer,
          minValue: durationMinValue(0, min => this.formatMinutesAsDuration(min, false)),
          maxValue: durationMaxValue(60 * 168, max => this.formatMinutesAsDuration(max, false))
        },
        punchStartsMaxDuration: {
          required: requiredIf(() => this.isManual && this.form.punchType === 'relative'),
          integer,
          minValue: durationMinValue(this.form.punchStartsMinDuration, min => this.formatMinutesAsDuration(min, false)),
          maxValue: durationMaxValue(60 * 168, max => this.formatMinutesAsDuration(max, false))
        },
        punchMinDuration: {
          integer,
          minValue: durationMinValue(0, min => this.formatMinutesAsDuration(min, false)),
          maxValue: durationMaxValue(this.form.punchDuration, max => this.formatMinutesAsDuration(max, false)),
        },
        punchMaxDuration: {
          integer,
          minValue: durationMinValue(this.form.punchDuration, min => this.formatMinutesAsDuration(min, false)),
          maxValue: durationMaxValue(60 * 24, max => this.formatMinutesAsDuration(max, false))
        }
      }
    }

    return v
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables';

.break-form {

  .checkbox-header-button {
    text-align: left;
    padding-left: 1rem !important;
  }

  .complex-field {
    display: flex;
    flex-wrap: wrap;

    .form-group {
      margin-left: .75rem;
      margin-right: .75rem;
    }
  }

  .error-message {
    margin-top: 1rem;
    margin-left: 1rem;
    color: $flat-ui-alizarin;
    .text {
      margin-left: .5rem;
    }
  }
}
</style>
