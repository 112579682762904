import _ from 'lodash'
import { hasValue } from '@/utils/misc'

export default {
  props: {
    actionId: {
      type: String,
      required: true
    },
    items: Array
  },
  data () {
    return {
      // customFieldType should be set by mixing component, e.g., 'punch', 'shift', etc.
      customFieldType: null,
      customFieldValues: [],
      customFieldValuesValid: true
    }
  },
  computed: {
    customFields () {
      return this.$store.getters[`customFields/${this.customFieldType}CustomFields`]
    },
    customFieldId () {
      // Compute custom field id without relying on actionOptions,
      // which could cause a circular dependency.
      const match = this.actionId.match(/setCustomField(\d+)/)
      if (!match) return null
      return parseInt(match[1])
    },
    customField () {
      return this.customFieldId
        ? this.customFields.find(customField => customField.id === this.customFieldId)
        : null
    },
    customFieldValue () {
      if (!this.customFieldId) return null
      const customFieldValue = this.customFieldValues.find(v => v.field === this.customFieldId)
      // CustomFieldInputs does not add value to customFieldValues if not set, but we need to set
      // a null value in order for backend to clear existing value.
      return customFieldValue || {
        field: this.customFieldId,
        value: null
      }
    },
    // Mixing component should concatenate customFieldActionOptions to its overall actionOptions array.
    customFieldActionOptions () {
      return Object.fromEntries(this.customFields.map(customField => ([
        `setCustomField${customField.id}`,
        {
          label: `Set ${customField.name}`,
          postValidate: item => {
            const itemValue = (item.customFieldValues || []).find(v => v.field === this.customFieldId)
            // Make sure value changed. Note that itemValue itself is null if not set, whereas customFieldValue
            // computed value sets null on value property.
            return itemValue
              ? (this.customFieldValue.value === null || !_.isEqual(itemValue, this.customFieldValue))
              : (this.customFieldValue.value !== null)
          },
          postEligiblePhrase: () => hasValue(this.customFieldValue.value, true) ? `can be assigned this value for ${customField.name}`  : `can be assigned to no value for ${customField.name}`,
          postIneligiblePhrase: () => hasValue(this.customFieldValue.value, true) ? `already assigned to this value for ${customField.name}` : `already not assigned a value for value for ${customField.name}`,
          data: {
            action: 'updateCustomFieldValue',
            customFieldValue: this.customFieldValue
          },
          validateData: () => this.customFieldValuesValid,
          invalidDataMessage: 'Value is invalid.',
          customField,
          ...this.extraCustomFieldActionOptionProperties?.(customField),
        }
      ])))
    }
  }
}
