
export default {
  name: 'manage-messaging',
  label: 'Messaging',
  path: 'messaging',
  link: 'messaging',
  props: true,
  icon: 'messages',
  description: 'View history and status of sent messages.',
  component: () => import('@/components/TabPanel.vue'),
  requireGetter: 'canViewAudit',
  breadcrumbLeaf: true,
  children: [
    {
      name: 'message-list',
      label: 'Messages',
      title: 'Messages',
      path: 'messages/:view?',
      component: () => import(/* webpackChunkName: "messages-manage" */ './MessageList.vue')
    },
    {
      name: 'message-recipient-list',
      label: 'Recipients',
      title: 'Recipients',
      path: 'message-recipients/:view?',
      component: () => import(/* webpackChunkName: "message-recipients-manage" */ './MessageRecipientList.vue')
    },
    {
      name: 'manage-messaging-index',
      path: '',
      redirect: { name: 'message-list' }
    }
  ]
}
