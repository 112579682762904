import { pluralize } from 'inflection'
import filterOrchestrator from './filterOrchestrator'
import _ from 'lodash'

export function registerGridOrchestratorResourceWithStore (moduleName, store, options) {
  if (store.state[moduleName]) throw new Error(`${moduleName} module already registered with store`)

  store.registerModule(moduleName, {
    namespaced: true,
    state () {
      return {
        resourceName: options.resourceName,
        masterItems: [],
        cursor: null,
        supplementalData: {}
      }
    },
    getters: {
      pluralResourceName: state => pluralize(state.resourceName)
    },
    mutations: {
      masterItemsChanged (state, { masterItems, cursor }) {
        state.masterItems = masterItems
        state.cursor = cursor
      },
      supplementalDataAdded (state, supplementalData) {
        state.supplementalData = _.merge(supplementalData, state.supplementalData)
      },
      supplementalDataCleared (state) {
        state.supplementalData = {}
      }
    },
    modules: {
      filter: filterOrchestrator({
        filterDefaults: options.filterDefaults,
        searchAffectsActive: options.searchAffectsActive
      })
    }
  })
}
