<template>
  <div class="balance-summary">
    <div v-if="loading" class="loading">
      <spinner />
      <div class="message">Loading balances...</div>
    </div>
    <div v-else-if="errorMessage" key="error">
      <h6 class="load-error">
        <font-awesome-icon icon="triangle-exclamation" /> Load Failed
      </h6>
      <b-btn @click="loadBalances" class="reload">
        <font-awesome-icon icon="arrow-rotate-right" /> Try again
      </b-btn>
    </div>
    <div v-if="loaded && balances.length < 1" class="no-results">
      No balances
    </div>
    <div v-if="balances.length > 0">
      <div class="balance-table">
        <div v-for="balance in balances" :key="balance.timeOffCodeId"
          class="balance-item"
        >
          <div class="time-off-code">{{ supplementalData.timeOffCode[balance.timeOffCodeId].name }}:</div>
          <div class="balance-duration">{{ formatSecondsAsDuration(balance.duration) }} hours</div>
        </div>
      </div>
      <div class="disclaimer">
        * These are current balances. When entering time off, future
          time off and accrual projections will be considered in order to validate.
      </div>
    </div>
  </div>
</template>
<script>
import Spinner from '@/components/Spinner.vue'
import service from './services/TimeOffBalanceService'
import { extractErrorMessage } from '@/utils/misc'
import { mapGetters } from 'vuex'

export default {
  name: 'TimeOffBalanceSummary',
  components: {
    Spinner
  },
  props: {
    workerId: Number,
    updatedBalances: Object
  },
  data () {
    return {
      balances: [],
      supplementalData: null,
      loading: false,
      errorMessage: null
    }
  },
  computed: {
    ...mapGetters('formatPreferences', ['formatSecondsAsDuration']),
    loaded () {
      return !this.loading && !this.errorMessage
    }
  },
  watch: {
    workerId: {
      handler () {
        this.loadBalances()
      },
      immediate: true
    },
    updatedBalances (updatedBalances) {
      if (!updatedBalances) return
      this.balances.forEach(balance => {
        const updatedDuration = updatedBalances[balance.timeOffCodeId]
        if (Number.isFinite(updatedDuration)) {
          balance.duration = updatedDuration
        }
      })
    }
  },
  methods: {
    loadBalances () {
      if (!this.workerId) return
      this.loading = true
      this.errorMessage = null
      this.balances = []
      service.listWorker(this.workerId)
        .then(data => {
          this.balances = data.results
          this.supplementalData = data.supplementalData
        })
        .catch(error => { this.errorMessage = extractErrorMessage(error) })
        .finally(() => { this.loading = false })
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables';

.balance-summary {
  .loading {
    display: flex;
    flex-direction: row;
    color: $flat-ui-peter-river;

    .spinner-container .spinner {
      color: $flat-ui-peter-river;
      font-size: 18px;
    }
    .message {
      margin-left: .5rem;
    }
  }
  .load-error {
    color: $flat-ui-alizarin;
    font-size: .9rem;
  }
  .reload {
    font-size: .8rem;
  }
  .no-results {
    font-style: italic;
    color: #777;
  }
  .disclaimer {
    color: $fc-logo-orange;
    font-size: .7rem;
    margin-top: .25rem;
  }
  .balance-table {
    display: table;
    font-size: .8rem;
    .balance-item {
      display: table-row;
      .time-off-code {
        display: table-cell;
      }
      .balance-duration {
        display: table-cell;
        padding-left: 1rem;
        text-align: right;
      }
    }
  }
}
</style>
