<template>
  <key-multiselect
    v-bind="$attrs"
    v-model="forwardValue"
    :options="options"
    label="label"
    track-by="value"
    select-label=""
    deselect-label=""
  >
  </key-multiselect>
</template>
<script>
import KeyMultiselect from '@/components/KeyMultiselect.vue'
import { NET_PAY_SCHEDULE_OPTIONS } from './util'

export default {
  name: 'NetPayScheduleSelect',
  components: {
    KeyMultiselect
  },
  props: ['modelValue'],
  emits: ['update:modelValue'],
  inheritAttrs: false,
  computed: {
    forwardValue: {
      get () {
        return this.modelValue
      },
      set (v) {
        this.$emit('update:modelValue', v)
      }
    },
    options () {
      return NET_PAY_SCHEDULE_OPTIONS
    }
  }
}
</script>
