import { registerSingletonResourceWithStore } from '@/services/GenericSingletonResourceService'
import { registerListResourceWithStore } from '@/services/GenericListResourceService'
import store from '@/store'
import _ from 'lodash'
import { updatedDiff } from 'deep-object-diff'

// TODO: Move to separate file
registerSingletonResourceWithStore('employeeDefaults', 'employees/defaults', store, {
  getters: {
    // The following getters are used by the EmployeeForm to control access.
    // We didn't put them in the root vuex state, because they're only used within the employee routes.
    authDomain: state => _.get(state.originalData, 'authDomain', null),
    canCreateAllEmployeeSensitiveRequiredFields: (state, getters) =>
      _.get(state.originalData, 'requiredFields', []).every(requiredField =>
        getters.canCreateEmployeeSensitiveField(requiredField)),
    canCreateEmployeeSensitiveField: (state, getters, rootState, rootGetters) => field =>
      getters.employeeSensitiveCanCreateFields ||
      rootGetters.canAccessEmployeeSensitiveField(field),
    employeeSensitiveCanCreateFields: state => _.get(state.originalData, 'employeeSensitiveCanCreateFields', false),
    employeeSensitiveCanDelete: state => _.get(state.originalData, 'employeeSensitiveCanDelete', false),
    employeeSensitivePayClassReadOnly: state => _.get(state.originalData, 'employeeSensitivePayClassReadOnly', true),
    pinLengthMax: state => _.get(state.originalData, 'pinLengthMax', 0),
    pinLengthMin: state => _.get(state.originalData, 'pinLengthMin', 12),
  }
})
registerListResourceWithStore('credentialTypes', 'credential/types', store)
registerListResourceWithStore('credentialPolicies', 'credential/policies', store)

setTimeout(() => {
  // Sync changes from user defaults to root store module.
  store.watch(
    state => _.pick(state.employeeDefaults.originalData, [
      'credentialsEnabled', 'employeeSensitiveFields', 'enableBriefings'
    ]),
    syncSettings => {
      if (syncSettings.employeeSensitiveFields === undefined) {
        // This is a litmus test to determine whether Vue is just loading,
        // or whether something actually changed.
        return
      }
      // Get same keys in root state
      const rootSettings = _.pick(store.state, Object.keys(syncSettings))
      const updated = updatedDiff(rootSettings, syncSettings)
      // updatedDiff doesn't work right for diffing arrays in different orders. It returns an object instead of an array.
      // So we'll perform custom logic to handle employeeSensitiveFields.
      if (_.isEqual(_.sortBy(syncSettings.employeeSensitiveFields), _.sortBy(rootSettings.employeeSensitiveFields))) {
        updated.employeeSensitiveFields = syncSettings.employeeSensitiveFields
      }
      if (!_.isEmpty(updated)) {
        store.dispatch('updateSession', updated)
      }
    }
  )
})

export default {
  name: 'users',
  label: 'Users',
  link: 'users',
  path: 'users',
  props: true,
  icon: 'users',
  description: 'View and edit your users.',
  component: () => import('@/components/TabPanel.vue'),
  requirePerm: 'edit_employees',
  breadcrumbLeaf: true,
  children: [
    {
      name: 'employee-list',
      label: 'List',
      title: 'Users',
      path: 'list/:view?',
      props: true,
      component: () => import(/* webpackChunkName: "employees" */ './EmployeeList.vue')
    },
    {
      name: 'employee-defaults',
      label: 'Defaults',
      title: 'User Defaults',
      path: 'defaults/:view?',
      requirePerm: 'edit_settings',
      component: () => import(/* webpackChunkName: "employees" */ './EmployeeDefaults.vue')
    },
    {
      name: 'health-safety',
      label: 'Health & Safety',
      path: 'health-safety/:view?',
      requireGetter: 'healthAndSafetyEnabled',
      requirePerm: 'edit_settings',
      component: () => import(/* webpackChunkName: "healthy-safety" */ './credential/HealthSafety.vue')
        .catch(error => {
          console.error('Failed to route to HealthSafety', error)
          throw error
        })
    },
    {
      name: 'briefing-type-list',
      label: 'Briefing Types',
      path: 'briefing-types/:view?',
      // TODO: Fix enableBriefings getter not reactive.
      requireGetter: 'enableBriefings',
      requirePerm: 'edit_settings',
      component: () => import(/* webpackChunkName: "briefing-types" */ './credential/BriefingTypeList.vue')
        .catch(error => {
          console.error('Failed to route to BriefingTypeList', error)
          throw error
        })
    },
    {
      name: 'users-index',
      path: '',
      redirect: { name: 'employee-list' }
    }
  ]
}
