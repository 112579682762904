
export function openDeviceFormModal (deviceId, showModal) {
  import(/* webpackChunkName: "device-form-modal" */ './DeviceFormModal.vue')
    .then(async module => {
      const DeviceFormModal = module.default
      await showModal({
        component: DeviceFormModal,
        props: {
          itemId: deviceId
        }
      })
    })
}
