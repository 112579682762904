<template>
  <pay-period title="Last Pay Period"
    whichPeriod="lastperiod"
    barBackgroundColor="#ca7079"
    barBorderColor="#a61120"
  />
</template>
<script>
import PayPeriod from './PayPeriod.vue'

export default {
  name: 'LastPayPeriod',
  components: {
    PayPeriod
  },
  data () {
    return {
      // Lower the refresh interval for last pay period,
      // because it shouldn't change so much.
      refreshInterval: 24 * 60 * 60 * 1000 // 24 hours
    }
  }
}
</script>
