<template>
  <remote-multiselect
    v-bind="$attrs"
    v-model="forwardValue"
    :multiple="true"
    label="name"
    track-by="id"
    select-label=""
    deselect-label=""
    :serviceFetch="fetchItems"
    resourceName="Geo Fence"
    :showAddNewItem="canEditDevices"
    @add-new-item="addNewItem"
  />
</template>
<script>
import RemoteMultiselect from '@/components/RemoteMultiselect.vue'
import GeoRuleFormModal from './GeoRuleFormModal.vue'
import GeoRuleService from './services/GeoRuleService'
import { mapGetters } from 'vuex'
import { useModalController } from 'bootstrap-vue-next'
import { computed, ref } from 'vue'

export default {
  name: 'GeoRuleSelect',
  setup () {
    return {
      showModal: useModalController().show
    }
  },
  inheritAttrs: false,
  props: {
    modelValue: [Array, Number]
  },
  emits: ['update:modelValue'],
  computed: {
    ...mapGetters(['canEditDevices']),
    forwardValue: {
      get () {
        return this.modelValue
      },
      set (v) {
        this.$emit('update:modelValue', v)
      }
    }
  },
  components: {
    RemoteMultiselect
  },
  methods: {
    fetchItems: GeoRuleService.fetchForSelect,
    addNewItem () {
      const itemId = ref(null)
      this.showModal({
          component: GeoRuleFormModal,
          props: computed(() => ({
            itemId: itemId.value,
            onSaved: item => {
              itemId.value = item.id
              this.$emit('update:modelValue', this.value.concat(item.id))
              this.$emit('item-added', item)
            }
          }))
        })
    }
  }
}
</script>
