import { itemResult, registerListResourceWithStore } from '@/services/GenericListResourceService'
import store from '@/store'
import reusePromise from 'reuse-promise'

function listConnections () {
  const connectionStore = window.Deft.Injector.resolve('integrationConnectionStore')
  // Since the store can be directly loaded from outside this vuex module, we need
  // to check whether its currently loading.
  if (!connectionStore.isLoading()) {
    connectionStore.load()
  }
  return new Promise ((resolve, reject) => {
    connectionStore.on('load', (extStore, records, success) => {
      if (success) {
        resolve(records.map(record => record.raw))
      } else {
        reject(new Error('Failed to load integration connections'))
      }
    }, this, { single: true })
  })
}

const listConnectionsReusedPromise = reusePromise(listConnections)

registerListResourceWithStore('integrationConnections', 'integration/connections', store, {
  actions: {
    async load (context, forceRefresh) {
      // Very similar to GenericListResourceService's implementation, but instead of
      // using restClient, we proxy to legacy connections store. Because loading this
      // connection list causes a refresh of each connection, it's slow, and so we
      // are careful not to load the list twice.
      if (context.state.loaded && !forceRefresh) {
        return itemResult(context.state.items)
      }
      const items = await listConnectionsReusedPromise()
      context.commit('load', items)
      return itemResult(items)
    }
  }
})

setTimeout(() => {
  const unwatch = store.watch(
    state => state.legacyInitialized,
    legacyInitialized => {
      if (!legacyInitialized) return
      unwatch()
      // Observe legacy store load/write events in order to update vuex state.
      // const connectionStore = window.Deft.Injector.resolve('integrationConnectionStore')
      // connectionStore.on('load', (extStore, records, success) => {
      //   if (success) store.dispatch(`integrationConnections/sideLoad`, records.map(record => record.raw))
      // })
      // connectionStore.on('write', (extStore, operation) => {
      //   store.dispatch(`integrationConnections/sideLoad`, connectionStore.getRange().map(record => record.raw))
      // })
    }
  )
})

registerListResourceWithStore('integrationTemplates', 'integration/templates', store)

export default {
  name: 'integrations',
  label: 'Integrations',
  link: 'integrations',
  path: 'integrations',
  props: true,
  icon: 'plug',
  description: 'View and edit your third party integrations.',
  component: () => import('@/components/TabPanel.vue'),
  requirePerm: 'edit_settings',
  requireFeature: 'integration',
  breadcrumbLeaf: true,
  children: [
    {
      name: 'integrations-apikeys',
      label: 'API Keys',
      path: 'apiKeys',
      requireFeature: 'api',
      component: () => import(/* webpackChunkName: "apikeys" */ './apikeys/ApiKeyList.vue')
    },
    {
      name: 'integrations-connections',
      label: 'Connections',
      path: 'connections',
      props: true,
      component: () => import(/* webpackChunkName: "integrations" */ './Connections')
    },
    {
      name: 'integrations-template-list',
      label: 'Templates',
      path: 'templates/:view?',
      props: true,
      component: () => import(/* webpackChunkName: "integrationtemplates" */ './templates/TemplateList.vue')
    },
    {
      name: 'integrations-index',
      path: '',
      redirect: { name: 'integrations-apikeys' }
    }
  ]
}
