<template>
  <key-multiselect
    v-bind="$attrs"
    v-model="forwardValue"
    :multiple="multiple"
    label="name"
    track-by="id"
    select-label=""
    deselect-label=""
    resourceName="Credential Type"
    :options="sortedCredentialTypes"
  />
</template>
<script>
import KeyMultiselect from '@/components/KeyMultiselect.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'CredentialTypeSelect',
  inheritAttrs: false,
  components: {
    KeyMultiselect
  },
  props: {
    modelValue: [Number, Array],
    multiple: {
      type: Boolean,
      default: false
    },
    activeOnly: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  computed: {
    ...mapGetters({
      credentialTypes: 'credentialTypes/sortedItems'
    }),
    sortedCredentialTypes () {
      return this.credentialTypes('name')
        .filter(item => item.active || this.modelValue?.includes(item.id))
    },
    forwardValue: {
      get () {
        return this.modelValue
      },
      set (v) {
        this.$emit('update:modelValue', v)
      }
    }
  },
  created () {
    this.$store.dispatch('credentialTypes/load')
  }
}
</script>
