<template>
  <div class="group-row-inner-renderer">
    {{ summary }}
  </div>
</template>
<script>
import { getGroupRowNodeSummary } from '@/utils/grid'

export default {
  name: 'GroupRowInnerRenderer',
  computed: {
    summary () {
      return getGroupRowNodeSummary({
        gridApi: this.params.api,
        node: this.params.node,
        resourceName: this.params.resourceName
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.group-row-inner-renderer {
  border-bottom: 1px solid #555;
}
</style>
