import payrollClient from '@/services/clients/payroll'

const resourceName = 'analytics'

async function listPayAnalytics (name, params) {
  try {
    const response = await payrollClient.get(`${resourceName}/${name}`, {
      params: {
        ...params
      }
    })
    return response.data
  } catch (error) {
    console.warn(`Error getting pay analytics for ${name}`, error)
    throw error
  }
}

export default {
  listPayRunAnalytics: params => listPayAnalytics('payruns', params),
  listPaySummaryAnalytics: params => listPayAnalytics('paysummaries', params),
  listPaySegmentAnalytics: params => listPayAnalytics('paysegments', params)
}
