import restClient from '@/services/clients/rest'

export default {
  async pollBulkActionStatus (pollKey) {
    try {
      const response = await restClient.get(`bulk-action/${pollKey}`)
      return response.data
    } catch (error) {
      console.warn(`Error polling bulk action status for poll key ${pollKey}`, error)
      throw error
    }
  }
}
