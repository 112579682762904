import { useRoute, type RouteLocationNormalizedLoadedGeneric } from 'vue-router'
import { onActivated, onDeactivated, onUnmounted, ref, watch, type Ref } from 'vue'

export function useActiveRoute () {
  const route = useRoute()

  // assume it gets created only when route is active
  let routeIsActive = true
  // keep state on full path, because route is a reactive object that doesn't change as a whole
  const activeRouteFullPath: Ref<string | null> = ref(route.fullPath)
  const activeRoute: Ref<RouteLocationNormalizedLoadedGeneric | null> = ref(route)

  watch(() => route.fullPath, async (newRouteFullPath) => {
    if (routeIsActive) {
      activeRouteFullPath.value = newRouteFullPath
      activeRoute.value = route
    }
  })

  onActivated(() => {
    routeIsActive = true
    activeRouteFullPath.value = route.fullPath
    activeRoute.value = route
  })

  onDeactivated(() => {
    routeIsActive = false
    activeRouteFullPath.value = null
    activeRoute.value = null
  })

  // onUnmounted(() => {
  //   routeIsActive = false
  //   activeRouteFullPath.value = null
  //   activeRoute.value = null
  // })

  return {
    activeRoute,
    activeRouteFullPath,
    route
  }
}
