import moment from 'moment-timezone'
import { getPayPeriodDateRangeAsPickerDates } from './PayPeriod'

export function generatePresets ({ timezone, payPeriodType, payPeriodStarts, includeFuture }) {
  const presets = [
    ['today', {
      label: 'Today',
      start: () => moment.tz(timezone).startOf('day'),
      end: () => moment.tz(timezone).startOf('day')
    }],
    includeFuture ? ['tomorrow', {
      label: 'Tomorrow',
      start: () => moment.tz(timezone).startOf('day').add(1, 'day'),
      end: () => moment.tz(timezone).startOf('day').add(1, 'day'),
    }] : null,
    ['yesterday', {
      label: 'Yesterday',
      start: () => moment.tz(timezone).startOf('day').subtract(1, 'day'),
      end: () => moment.tz(timezone).startOf('day').subtract(1, 'day')
    }],
    payPeriodType ? ['thisperiod', {
      label: 'This Pay Period',
      start: () => getPayPeriodDateRangeAsPickerDates(timezone, payPeriodType, payPeriodStarts, 'thisperiod')[0],
      end: () => getPayPeriodDateRangeAsPickerDates(timezone, payPeriodType, payPeriodStarts, 'thisperiod', null)[1]
    }] : null,
    includeFuture ? ['nextperiod', {
      label: 'Next Pay Period',
      start: () => getPayPeriodDateRangeAsPickerDates(timezone, payPeriodType, payPeriodStarts, 'nextperiod')[0],
      end: () => getPayPeriodDateRangeAsPickerDates(timezone, payPeriodType, payPeriodStarts, 'nextperiod', null)[1]
    }] : null,
    payPeriodType ? ['lastperiod', {
      label: 'Last Pay Period',
      start: () => getPayPeriodDateRangeAsPickerDates(timezone, payPeriodType, payPeriodStarts, 'lastperiod', null)[0],
      end: () => getPayPeriodDateRangeAsPickerDates(timezone, payPeriodType, payPeriodStarts, 'lastperiod', null)[1]
    }] : null,
    ['thisweek', {
      label: 'This Week',
      start: () => moment.tz(timezone).startOf('week'),
      end: () => moment.tz(timezone).endOf('week')
    }],
    includeFuture ? ['nextweek', {
      label: 'Next Week',
      start: () => moment.tz(timezone).startOf('week').add(1, 'week'),
      end: () => moment.tz(timezone).endOf('week').add(1, 'week')
    }] : null,
    ['lastweek', {
      label: 'Last Week',
      start: () => moment.tz(timezone).startOf('week').subtract(1, 'week'),
      end: () => moment.tz(timezone).endOf('week').subtract(1, 'week')
    }],
    includeFuture ? ['next7days', {
      label: 'Next 7 Days',
      start: () => moment.tz(timezone).startOf('day').add(1, 'day'),
      end: () => moment.tz(timezone).startOf('day').add(7, 'day'),
    }] : null,
    includeFuture ? ['next14days', {
      label: 'Next 14 Days',
      start: () => moment.tz(timezone).startOf('day').add(1, 'day'),
      end: () => moment.tz(timezone).startOf('day').add(14, 'day'),
    }] : null,
    ['thismonth', {
      label: 'This Month',
      start: () => moment.tz(timezone).startOf('month'),
      end: () => moment.tz(timezone).endOf('month')
    }],
    includeFuture ? ['nextmonth', {
      label: 'Next Month',
      start: () => moment.tz(timezone).add(1, 'month').startOf('month'),
      end: () => moment.tz(timezone).add(1, 'month').endOf('month')
    }] : null,
    ['lastmonth', {
      label: 'Last Month',
      start: () => moment.tz(timezone).subtract(1, 'month').startOf('month'),
      end: () => moment.tz(timezone).subtract(1, 'month').endOf('month')
    }],
    ['thisyear', {
      label: 'This Year',
      start: () => moment.tz(timezone).startOf('year'),
      end: () => moment.tz(timezone).endOf('year')
    }],
    ['lastyear', {
      label: 'Last Year',
      start: () => moment.tz(timezone).subtract(1, 'year').startOf('year'),
      end: () => moment.tz(timezone).subtract(1, 'year').endOf('year')
    }]
  ]

  return Object.fromEntries(presets.filter(preset => !!preset))
}
