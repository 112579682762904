import { mapGetters } from 'vuex'
import { requiredIf } from '@vuelidate/validators'
import { forceArray, hasValue } from '@/utils/misc'
import { payClassesAreIntercompatible } from '../../views/settings/payroll/util'
import ReportFilterMixin from './ReportFilterMixin'
import { helpers } from '@vuelidate/validators'

export default {
  mixins: [ReportFilterMixin],
  props: {
    filterPayClass: {
      type: Boolean,
      default: false
    },
    requirePayClassIntercompatible: {
      type: Boolean,
      default: false
    },
    salaryMode: {
      type: Boolean,
      default: false
    },
    requireSingleTimezone: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      workerData: null
    }
  },
  computed: {
    ...mapGetters(['payrollEnabled']),
    ...mapGetters({
      payClasses: 'payClasses/sortedItems',
      payClassesAreIntercompatible: 'payClasses/payClassesAreIntercompatible'
    }),
    sortedPayClasses () {
      return this.payClasses('name').filter(payClass => !this.salaryMode || payClass.payMode === 'salary')
    },
    filterPayClassEnabled () {
      return this.filterPayClass && this.payrollEnabled && (this.sortedPayClasses.length > 1 || !this.payClassesAreIntercompatible)
    },
    employeeValidation () {
      return {
        requireSingleTimezone: helpers.withMessage('You may only choose Workers in Org Units in the same timezone.', value => {
          if (!this.requireSingleTimezone) return true
          if (!hasValue(this.config.employee)) return true
          return new Set(forceArray(this.workerData).map(w => w.timezone)).size <= 1
        }),
        requireSalary: helpers.withMessage('You may only choose Workers in a Salary-based Pay Class.', value => {
          if (!this.filterPayClassEnabled || !this.salaryMode) return true
          const selectedWorkerPayClassIds = forceArray(this.workerData).map(worker => worker.payClass)
          const salaryPayClassIds = new Set(this.sortedPayClasses.map(payClass => payClass.id))
          return selectedWorkerPayClassIds.every(payClassId => salaryPayClassIds.has(payClassId))
        }),
        requirePayClassIntercompatible: helpers.withMessage('You may only choose Workers in different Pay Classes that are inter-compatible.', value => {
          if (!this.filterPayClassEnabled || !this.requirePayClassIntercompatible) return true
          const selectedWorkerPayClassIds = new Set(forceArray(this.workerData).map(worker => worker.payClass))
          if (selectedWorkerPayClassIds.size < 2) return true
          const payClasses = this.sortedPayClasses.filter(payClass => selectedWorkerPayClassIds.has(payClass.id))
          const hasWorkerWithoutPayClass = selectedWorkerPayClassIds.has(null)
          return payClassesAreIntercompatible(payClasses, hasWorkerWithoutPayClass ? this.$store.state : null)
        })
      }
    },
    isPayClassRequired () {
      return this.filterPayClassEnabled &&
        this.requirePayClassIntercompatible &&
        !this.payClassesAreIntercompatible &&
        forceArray(this.config.employee).length < 1
    },
    payClassValidation () {
      return {
        required: helpers.withMessage('Pay Class is a required field when their settings are not inter-compatible.', requiredIf(() => this.isPayClassRequired)),
        requireInterCompatible: helpers.withMessage('The selected Pay Classes are not inter-compatible.', (value, vm) => {
          const selectedPayClassIds = forceArray(this.config.payClass)
          if (
            (this.isPayClassRequired && !this.config.payClass) ||
            !this.filterPayClassEnabled ||
            !this.requirePayClassIntercompatible ||
            selectedPayClassIds.length < 2
          ) {
            return true
          }
          const selectedPayClasses = this.sortedPayClasses
            .filter(payClass => selectedPayClassIds.includes(payClass.id))
          return payClassesAreIntercompatible(selectedPayClasses)
        })
      }
    }
  },
  methods: {
    workerChanged (workerData, subtitleOrder) {
      this.workerData = workerData
      if (this.salaryMode) {
        // Add worker pay class to pay class filter.
        forceArray(workerData).forEach(worker => {
          if (worker.payClass){
            // Need to force pay class filter value to array for backward compatibility
            // with reports memorized before we added support for filtering multiple
            // pay classes.
            this.config.payClass = forceArray(this.config.payClass)
            if (!this.config.payClass) this.config.payClass = []
            if (!this.config.payClass.includes(worker.payClass)) {
              this.config.payClass = this.config.payClass.concat(worker.payClass)
            }
          }
        })
      }
      this.filterChanged('Worker', 'name', subtitleOrder, workerData)
      // this.$emit('worker-changed', workerData)
    }
  },
  mounted () {
    if (this.filterPayClass && this.payrollEnabled) this.$store.dispatch('payClasses/load')
  }
}
