import _ from 'lodash'
import { forceArray } from '@/utils/misc'

export default {
  props: {
    subtitlesChanged: Function
  },
  data () {
    return {
      displayValues: {}
    }
  },
  watch: {
    displayValues (displayValues) {
      const subtitles = _(displayValues)
          .toPairs()
          .filter(([k, v]) => !_.isEmpty(v.value))
          .orderBy(([k, v]) => v.order)
          .map(([displayName, v]) => {
          // Truncate display value.
          const displayValue = v.value.length > 3
              ? v.value.slice(0, 3).join(', ') + ` + ${v.value.length - 3} more`
              : v.value.join(', ')
          return `${displayName}: ${displayValue}`
          })
          .value()
      this.subtitlesChanged(subtitles)
    }
  },
  methods: {
    // TODO: Sometimes a filter value may get cleared, but filterChanged is not invoked by design,
    // TODO: because that filter's component is not rendered due to v-if condition not met. For example,
    // TODO: in the Audit Report if we filter for Actor User, then switch to Actor Device, then the Actor
    // TODO: User is still in displayValues, and thus will remain in the subtitles. We need a way to remove
    // TODO: it.
    filterChanged (displayName, displayField, order, value) {
      this.displayValues = Object.assign({}, this.displayValues, {
        [displayName]: {
        value: forceArray(value).map(v => v[displayField]),
        order
        }
      })
    }
  }
}
