<template>
  <remote-multiselect
    ref="multiselect"
    class="job-phase-multiselect"
    v-model="forwardValue"
    :id="contextId"
    label="name"
    track-by="id"
    :placeholder="placeholder"
    select-label=""
    deselect-label=""
    :serviceFetch="fetchJobPhases"
    :multiple="multiple"
    :disabled="disabled"
    @fullValueChange="$emit('fullValueChange', $event)"
  >
  </remote-multiselect>
</template>
<script>
import RemoteMultiselect from '@/components/RemoteMultiselect.vue'
import JobPhaseService from '@/views/settings/costing/services/JobPhaseService'
import _ from 'lodash'

export default {
  name: 'JobMultiselect',
  components: {
    RemoteMultiselect
  },
  props: {
    contextId: {},
    modelValue: [Number, Array],
    placeholder: {
      type: String,
      default: 'Select a job phase'
    },
    multiple: Boolean,
    disabled: Boolean,
    activeOption: Boolean
  },
  emits: ['fullValueChange', 'update:modelValue'],
  data () {
    return {
      includeInactive: false
    }
  },
  computed: {
    forwardValue: {
      get () {
        return this.modelValue
      },
      set (v) {
        this.$emit('update:modelValue', v)
      }
    }
  },
  methods: {
    fetchJobPhases (searchText, value, limit) {
      const active = value || (this.activeOption && this.includeInactive) ? 'all' : 'active'
      return JobPhaseService
        .list({
          searchText,
          active,
          id: value,
          limit
        })
        .then(data => _.get(data, 'results', []))
    },
    onChangeCheckbox () {
      // Prevent multiselect from closing after clicking on checkbox.
      // I couldn't figure out how to block the blur event in the first place.
      this.$refs.multiselect.$refs.select.$refs.select.activate()
    }
  }
}
</script>
<style lang="scss" scoped>
.job-phase-multiselect {
  .show-inactive {
    padding: 0.5rem 1rem;
    font-size: .8rem;
  }
}
</style>
