export default {
  data () {
    return {
      activeRoute: null
    }
  },
  watch: {
    '$route': {
      handler (to, from) {
        if (this.routeIsActive$) {
          this.activeRoute = this.$route
        }
      },
      immediate: true
    }
  },
  beforeCreate () {
    // assume it gets created only when route is active
    this.routeIsActive$ = true
  },
  activated () {
    this.routeIsActive$ = true
    this.activeRoute = this.$route
  },
  deactivated () {
    this.routeIsActive$ = false
    this.activeRoute = null
  }
}
