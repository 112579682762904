import ActiveRoute from './ActiveRoute'

export default {
  data () {
    return {
      routeUpdateNext: null,
      routeUpdateTo: null,
      routeLeaveNext: null
    }
  },
  mixins: [
    ActiveRoute
  ],
  beforeRouteUpdate (to, from, next) {
    this.routeUpdateTo = to
    this.routeUpdateNext = next
  },
  beforeRouteLeave (to, from, next) {
    this.routeLeaveNext = next
  },
  computed: {
    // The way we compute an entire routeHooks object here,
    // seems that it can cause consumer watchers to fire on
    // activeRoute changing even though only routeLeaveNext
    // changes. See AbstractReport.vue.
    // TODO: Is there a better way to do this?
    routeHooks () {
      return {
        activeRoute: this.activeRoute,
        routeUpdateTo: this.routeUpdateTo,
        routeUpdateNext: this.routeUpdateNext,
        routeLeaveNext: this.routeLeaveNext
      }
    }
  },
  // Component guards not working when defined on mixins,
  // so we need to manually attach them.
  // Assume this mixin is only defined on components, and not extended from another mixin.
  // https://github.com/vuejs/router/issues/454#issuecomment-1003191017
  created () {
    const mixin = this.$.type.mixins.find(mixin => !!(mixin.beforeRouteUpdate && mixin.beforeRouteLeave))
    const route = this.$route.matched.slice(-1)[0]
    if(!route) return
    route.leaveGuards = new Set([mixin.beforeRouteLeave.bind(this)])
    route.updateGuards = new Set([mixin.beforeRouteUpdate.bind(this)])
  }
  // TODO: Do we need to remove the guards from the route, or is the route getting disposed anyway?
  // beforeUnmount () {}
}
