<template>
  <router-link :to="url" custom>
    <li class="nav-item nav-dropdown" @mouseenter="mouseEnter" @mouseleave="mouseLeave">
      <div class="nav-link nav-dropdown-toggle" @click="handleClick"><font-awesome-icon :icon="icon" /> {{name}}</div>
      <ul class="nav-dropdown-items">
        <slot></slot>
      </ul>
    </li>
  </router-link>
</template>

<script>
export default {
  name: 'SidebarNavDropdown',
  props: {
    name: {
      type: String,
      default: ''
    },
    url: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    }
  },
  methods: {
    handleClick (e) {
      e.preventDefault()
      const parentElement = e.target.parentElement
      if (parentElement) parentElement.classList.toggle('open')
    },
    // Need to set menu list max height each time it's opened in order for
    // scroll bar to work.
    mouseEnter (event) {
      if (!document.body.classList.contains('sidebar-minimized')) return

      const el = event.target
      const menu = el.querySelector('ul.nav-dropdown-items')
      menu.style.maxHeight=`calc(100vh - ${menu.getBoundingClientRect().y}px)`
    },
    mouseLeave (event) {
      const el = event.target
      const menu = el.querySelector('ul.nav-dropdown-items')
      menu.style.maxHeight = null
    }
  }
}
</script>
