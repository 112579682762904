<template>
  <panel title="Face Recognition Failures" v-mask="maskOptions">
    <template #icons>
      <panel-header-icon icon="circle-chevron-left" @click="after" />
      <panel-header-icon icon="circle-chevron-right" @click="before" />
      <panel-header-icon icon="sync" @click="refresh" />
    </template>
    <face-failure-verify v-if="employee"
      v-bind="$data"
      @approve="approve"
      @reject="reject" />
    <face-failure-identify v-else-if="clockLogId"
      v-bind="$data"
      @approve="approve"
      @reject="reject" />
    <p v-else-if="error" class="card-text">
      <font-awesome-icon icon="triangle-exclamation" aria-hidden="true" />
      There was an error trying to retrieve recent face recognition failures.
    </p>
    <p v-else class="card-text">
      <font-awesome-icon icon="thumbs-up" aria-hidden="true" />
      There are no recent face recognition failures.
    </p>
  </panel>
</template>
<script>
import service from './services/FaceFailureService'
import Panel from '@/components/Panel.vue'
import PanelHeaderIcon from '@/components/PanelHeaderIcon.vue'
import FaceFailureIdentify from './FaceFailureIdentify.vue'
import FaceFailureVerify from './FaceFailureVerify.vue'
import ConvertPunchModal from '@/views/manage/clocklogs/ConvertPunchModal.vue'
import AutoRefresh from '@/mixins/AutoRefresh'
import Maskable from '@/mixins/Maskable'
import _ from 'lodash'
import { useModalController } from 'bootstrap-vue-next'

function cleanData () {
  return {
    clockLogId: 0,
    date: null,
    timezone: null,
    punchType: null,
    faceLogId: 0,
    faceMode: null,
    clockOrgUnit: 0,
    clockName: null,
    employee: 0,
    employeeFirstName: null,
    employeeLastName: null,
    employeeAvatar: null,
    faceStatus: null,
    multipleMatches: null,
    error: false,
    job: null,
    jobCategory: null,
    jobPhase: null,
    costCode: null
  }
}

export default {
  setup () {
    return {
      showModal: useModalController().show
    }
  },
  mixins: [AutoRefresh, Maskable],
  data: cleanData,
  components: {
    FaceFailureIdentify,
    FaceFailureVerify,
    Panel,
    PanelHeaderIcon
  },
  methods: {
    load (direction, date) {
      this.showLoadingMask()

      return service.retrieveFaceFailure(direction, date)
        .then(faceFailure => {
          if (faceFailure || !direction) {
            Object.assign(this.$data, cleanData(), faceFailure || {})
            this.error = false
            this.hideMask()
          } else if (direction === 'before') {
            this.showInfoMask('This is the oldest failure')
          } else if (direction === 'after') {
            this.showInfoMask('This is the newest failure')
          }
        })
        .catch(error => {
          Object.assign(this.$data, cleanData())
          this.error = true
          this.showErrorMask(error)
          throw error
        })
    },
    refreshImpl () {
      return this.load()
    },
    after () {
      this.coordinateWithRefresh(() => this.load('after', this.date))
    },
    before () {
      this.coordinateWithRefresh(() => this.load('before', this.date))
    },
    refresh () {
      this.coordinateWithRefresh(() => this.load())
    },
    approve (employee) {
      this.decideFaceFailure('approve', employee)
    },
    reject () {
      this.decideFaceFailure('reject')
    },
    decideFaceFailure (decision, employee) {
      this.showUpdatingMask()

      this.coordinateWithRefresh(() =>
        service.decideFaceFailure(this.clockLogId, decision, employee && employee.id)
          .then(result => {
            // if employee did not punch around time of failure, then prompt to generate punch
            if (_.get(result.shiftData, 'mayConvertToPunch') && employee) {
              this.showModal({
                component: ConvertPunchModal,
                props: {
                  initialData: {
                    punches: result.shiftData.punches,
                    clockLogId: this.clockLogId,
                    deviceOrgUnit: this.clockOrgUnit,
                    date: this.date,
                    timezone: this.timezone,
                    worker: result.department,
                    userLabels: result.labels,
                    employeeName: employee.name,
                    job: this.job,
                    jobCategory: this.jobCategory,
                    jobPhase: this.jobPhase,
                    costCode: this.costCode
                  }
                }
              })
            }

            // display next face failure
            Object.assign(this.$data, cleanData(), result.faceFailure || {})

            this.hideMask()
          })
      )
        .catch(error => {
          this.showErrorMask(error)
          throw error
        })
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
