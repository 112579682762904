<template>
  <key-multiselect
    v-bind="$attrs"
    v-model="forwardValue"
    :options="options"
    label="label"
    track-by="value"
    select-label=""
    deselect-label=""
  >
  </key-multiselect>
</template>
<script>
import KeyMultiselect from '@/components/KeyMultiselect.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'UserRoleSelect',
  components: {
    KeyMultiselect
  },
  props: ['modelValue'],
  emits: ['update:modelValue'],
  inheritAttrs: false,
  computed: {
    forwardValue: {
      get () {
        return this.modelValue
      },
      set (v) {
        this.$emit('update:modelValue', v)
      }
    },
    ...mapGetters(['isAccountOwner', 'canEditAdministrators']),
    options () {
      const options = [{
        value: 'worker',
        label: 'Worker'
      }]

      if (this.canEditAdministrators) {
        options.push({
          value: 'admin',
          label: 'Administrator'
        })

        if (this.isAccountOwner) {
          options.push({
            value: 'owner',
            label: 'Account Owner'
          })
        }
      }

      return options
    }
  }
}
</script>
