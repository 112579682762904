<template>
  <div :class="['date-time-picker', readonly ? 'readonly' : null]">
    <VueDatePicker
      v-model="forwardValue"
      time-picker-inline
      minutes-grid-increment="1"
      :is-24="!is12HourFormat"
      :format="dfDateTimeFormat"
      :disabled="readonly"
      :clearable="!readonly"
      class="vue-date-picker"
    />
    <div v-if="showTimezone" :class="['timezone-label']">{{ timezoneLabel }}</div>
  </div>
</template>
<script>
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import { mapGetters, mapState } from 'vuex'
import moment from 'moment-timezone'
import { modelDateTimeFormat } from '@/utils/misc'

// VueDatePicker uses date-fns and its format syntax:
// https://date-fns.org/v3.6.0/docs/format
export default {
  name: 'DateTimePicker',
  inheritAttrs: false,
  components: {
    VueDatePicker
  },
  props: {
    modelValue: String,
    timezone: String,
    readonly: Boolean,
    showTimezone: {
      type: Boolean,
      default: true
    }
  },
  emits: ['update:modelValue'],
  computed: {
    ...mapGetters('formatPreferences', ['dfDateTimeFormat', 'is12HourFormat']),
    ...mapState({
      orgTimezone: 'timezone'
    }),
    useTimezone () {
      return this.timezone || this.orgTimezone
    },
    timezoneLabel () {
      const m = moment.tz(this.modelValue, this.useTimezone)
      if (!m.isValid()) return ''
      // zoneAbbr() seems to be either an alphabetic abbreviation such as 'EST',
      // or a number such as '+08' (Asia/Singapore). In the latter case,
      // we don't want to display "+08 +08:00".
      const hasAbbr = isNaN(Number.parseInt(m.zoneAbbr()))
      return m.format(hasAbbr ? "z Z" : 'Z')
    },
    forwardValue: {
      get () {
        return this.modelValue
          // Pass in naive datetime string to Date constructor so that same wall time is used,
          // since Date uses system timezone.
          ? new Date(moment.tz(this.modelValue, this.useTimezone).format('YYYY-MM-DDTHH:mm:ss'))
          : null
      },
      set (v) {
        // The ejs-daterangepicker passes in a native date object localized to system time.
        // So we need to replace the timezone with the configured one, without changing the
        // wall time.
        // TODO: Dedupe?
        this.$emit('update:modelValue', v
          ? moment.tz(moment(v).format('YYYY-MM-DD HH:mm:ss'), this.useTimezone).format(modelDateTimeFormat)
          : null
        )
      }
    }
  }
}
</script>
<style>

</style>
<style lang="scss" scoped>
.date-time-picker {
  display: flex;
  flex-direction: row;

  .vue-date-picker {
    max-width: 250px;
  }

  .timezone-label {
    margin-top: 5px;
    margin-left: .5rem;
  }
}
</style>
