<template>
  <div>
    <b-form-checkbox
      v-if="faceRecognitionEnabled"
      v-model="value.allowOverrideVerifyFail"
      class="form-group"
    >
      Allow employee to submit online face verify failure to manager for approval
      <help-text-icon>
        If checked, employee will be given option to submit online face verification failure to manager for approval along with punch time.
      </help-text-icon>
    </b-form-checkbox>

    <b-form-checkbox
      v-if="enableOfflineFaceFailSavePunch && faceRecognitionEnabled"
      v-model="value.offlineFaceFailSavePunch"
      class="form-group"
    >
      Save punch if offline face recognition failure
      <help-text-icon>
        When the time clock operates offline, the worker will be prompted to enter their PIN in addition to taking the facial photo.
        Later when the device comes back online and the punch data is uploaded to the cloud, the face recognition will verify the photo.
        If the face verification fails and this setting is checked, then the punch time will be saved with an exception flag for the manager
        to review. If this setting is not checked, then the punch will not be saved. Instead, there will just be a flagged Clock Log that the
        manager can review and decide whether to approve and convert to a Punch.
      </help-text-icon>
    </b-form-checkbox>

    <form-group
      v-if="enableMinFaceScore && faceRecognitionEnabled && loggedInIsAppAdmin"
      :validator="v$.value.minFaceScore"
    >
      <template #label>
        {{ fieldLabels.minFaceScore }}
        <help-text-icon>
          ** THIS VALUE SHOULD ONLY BE CHANGED UNDER RECOMMENDATION OF FARECLOCK CUSTOMER SUPPORT. **
          <br/><br/>
          Valid range is 48-70. Default value is 60.
        </help-text-icon>
      </template>
      <number-input
        v-model="value.minFaceScore"
        :min="48"
        :max="70"
        inline controls
        size="small"
        placeholder=""
      />
    </form-group>
  </div>
</template>
<script>
import HelpTextIcon from '@/components/HelpTextIcon.vue'
// TODO: Replace NumberInput.
import NumberInput from '@chenfengyuan/vue-number-input'
import ChildValidation from '@/mixins/ChildValidation'
import { useVuelidate } from '@vuelidate/core'
import { between, helpers, requiredIf } from '@vuelidate/validators'
import { mapState } from 'vuex'

export default {
  setup () {
    return {
      v$: useVuelidate({ $scope: false, $stopPropagation: true })
    }
  },
  components: {
    HelpTextIcon,
    NumberInput
  },
  mixins: [ChildValidation],
  props: {
    value: Object,
    faceMode: String,
    enableMinFaceScore: {
      type: Boolean,
      default: true
    },
    enableOfflineFaceFailSavePunch: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      fieldLabels: {
        minFaceScore: 'Minimum face score'
      }
    }
  },
  computed: {
    ...mapState({
      loggedInIsAppAdmin: state => state.userProfile.isAppAdmin
    }),
    faceRecognitionEnabled () {
      return ['verify', 'identify'].includes(this.faceMode)
    }
  },
  validations () {
    return {
      value: {
        minFaceScore: {
          required: requiredIf(() => this.enableMinFaceScore && this.faceRecognitionEnabled),
          between: helpers.withMessage(`${this.fieldLabels.minFaceScore} must be between 48 and 72.`, between(48, 70))
        }
      }
    }
  }
}
</script>
