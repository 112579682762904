<template>
  <div :class="['abstract-ag-grid-report', { 'customize-pinned': customizePinned, 'pivot-grid': pivotMode }]">
    <b-card class="quick-filter">
      <slot name="quick-filter" />

      <b-btn variant="primary" @click="runReport" :disabled="!filtersAreValid || loading" size="sm">
        <font-awesome-icon icon="search" />
        Run Report
      </b-btn>

      <b-btn :variant="customizeIsValid ? 'secondary' : 'danger'" @click="toggleAdvancedFilter" size="sm">
        <font-awesome-icon icon="filter" />
        Customize
      </b-btn>

      <export-ag-grid-button
        class="btn-group"
        :title="reportTitle"
        :subtitles="subtitles"
        :fileName="exportFileName"
        :disabled="loading || rowItems.length < 1"
        :customOptions="customExportOptions"
        :enableCsvFlat="containsAggregate && !pivotMode"
        size="sm"
        @print="print()"
        @csv="csv()"
        @csv-flat="csvFlat()"
        @excel="excel()"
        @pdf="pdf()"
        @custom="exportToCustom"
      />
    </b-card>
    <form-drawer
      v-model:visible="advancedFilterVisible"
      :no-close-on-route-change="customizePinned"
    >
      <div class="header">
        <div>
          <b-button size="sm" variant="link" @click="customizePinned = !customizePinned">
            <font-awesome-icon icon="thumbtack" :class="customizePinned ? 'untack' : null" />
          </b-button>
        </div>
        <div class="title">Customize</div>
        <div></div>
      </div>
      <hr>
      <slot name="customize-report" :subtitlesChanged="onCustomizeSubtitles" :filtersRestored="prefsRestored" />
      <hr>
      <b-form-group label="Columns">
        <column-menu
          v-if="prefsRestored"
          class="report-column-menu"
          :allColumns="allColumnDefsInPreferredOrderForMenu"
          :columnLabelKey="columnLabelKey"
          :columnIsSelected="columnIsVisible"
          @selectedColumnsUpdated="columnPrefsUpdated"
          @reset-to-defaults="resetColumnsToDefaults"
        />
      </b-form-group>

      <slot name="after-column-menu" />

      <template #footer-buttons>
        <div v-if="serverFiltersAreDirty && filtersAreValid && !loading" class="memorize-dirty">
          You must Run Report first before you can Memorize.
        </div>
        <b-button
        :disabled="!filtersAreValid || serverFiltersAreDirty || loading"
        @click="memorize"
          variant="primary"
          class="action save"
          size="sm"
        >
          <font-awesome-icon icon="lightbulb" /> Memorize
        </b-button>
        <b-button
          @click="toggleAdvancedFilter"
          variant="secondary"
          class="action cancel"
          size="sm"
        >Close</b-button>
      </template>
    </form-drawer>

    <ag-grid-vue
      class="report-ag-grid"
      :gridOptions="gridOptions"
      :groupDisplayType="groupDisplayType"
      :rowData="rowItems"
      :columnDefs="gridColumnDefs"
      :pivotMode="pivotMode"
      pivotRowTotals="after"
      :removePivotHeaderRowWhenSingleValueColumn="true"
      :enableFilter="false"
      :sideBar="false"
      :suppressCellFocus="true"
      :suppressMenuHide="false"
      :suppressAggFuncInHeader="true"
      :suppressExpandablePivotGroups="true"
      :groupRowRendererParams="groupRowRendererParams"
      :defaultExcelExportParams="defaultExcelExportParams"
      :defaultCsvExportParams="defaultExcelExportParams"
      :defaultColDef="defaultColDef"
      :defaultGroupOrderComparator="defaultGroupOrderComparator"
      :groupTotalRow="containsAggregate && !pivotMode ? 'bottom' : null"
      :grandTotalRow="containsAggregate ? 'bottom' : null"
      :autoGroupColumnDef="autoGroupColumnDef"
      :processPivotResultColGroupDef="processPivotResultColGroupDef"
      :processPivotResultColDef="processPivotResultColDef"
      :excelStyles="excelStyles"
      loadingOverlayComponent="OverlayLoading"
      :loadingOverlayComponentParams="loadingOverlayComponentParams"
      :suppressMovableColumns="pivotMode"
      :groupDefaultExpanded="groupDefaultExpanded"
      @grid-ready="onGridReady"
      @column-moved="columnMoved"
      @column-visible="columnVisible"
      @column-resized="columnResized"
    />

    <more-results
      class="more-results"
      :loading="loading"
      :showLoading="false"
      :canLoadMore="canLoadMore && !isPrinting"
      @load="loadMoreClicked"
    />
    <!-- <div class="error-message" v-if="errorMessage">
      <font-awesome-icon icon="exclamation-triangle" class="icon" />
      {{ errorMessage }}
    </div> -->
  </div>
</template>
<script>
import "ag-grid-enterprise";
import AbstractReportMixin from './AbstractReportMixin'
import AgGridVue from '@/components/grid/ag-grid-vue'
import FormDrawer from '@/components/FormDrawer.vue'
import MoreResults from '@/components/grid/MoreResults.vue'
import ColumnMenu from '@/components/ColumnMenu.vue'
import { escapeHtml, extractErrorMessage, jsonParseSafe } from '@/utils/misc'
import ExportAgGridButton from '@/components/report/ExportAgGridButton.vue'
import ExportsCustom from '@/mixins/ExportsCustom'
import ExportsGrid from '@/mixins/ExportsGrid'
import FontAwesomeCellRenderer from '@/components/grid/FontAwesomeCellRenderer.vue'
import ManagesAgGridColumns from '@/mixins/ManagesAgGridColumns'
import _ from 'lodash'
import GroupRowInnerRenderer from '@/components/report/GroupRowInnerRenderer.vue'
import { getGroupRowNodeSummary } from '@/utils/grid'
import OverlayLoading from '@/components/grid/OverlayLoading.vue'
import { useModalController } from 'bootstrap-vue-next'

export default {
  name: 'AbstractAgGridReport',
  setup () {
    return {
      showModal: useModalController().show
    }
  },
  mixins: [
    AbstractReportMixin,
    ExportsCustom,
    ExportsGrid,
    ManagesAgGridColumns
  ],
  components: {
    AgGridVue,
    ColumnMenu,
    ExportAgGridButton,
    FontAwesomeCellRenderer,
    FormDrawer,
    GroupRowInnerRenderer,
    MoreResults,
    OverlayLoading
  },
  props: {
    routeHooks: Object,
    pivotMode: Boolean,
    groupColumns: {
      type: Array,
      default: () => []
    },
    pivotColumns: Array,
    sortOrder: {
      type: Array
    },
    fetchReport: {
      type: Function
    },
    // It's imperative that parent components ensure that restoreReportPrefsToState
    // will bring forward all columns to proper available and default visible state.
    // After the restoreReportPrefsToState promise resolves, then AbstractReport will
    // initialize the columns preferences from the route view param.
    restoreReportPrefsToState: {
      type: Function
    },
    getReportPrefs: {
      type: Function
    },
    filtersAreValid: {
      type: Boolean,
      default: true
    },
    customizeIsValid: {
      type: Boolean,
      default: true
    },
    canLoadMore: {
      type: Boolean,
      default: false
    },
    loadMore: {
      type: Function
    },
    resourceName: String,
    minimizeGroups: Boolean
  },
  data () {
    return {
      // modules: [...AllCommunityModules, ExcelExportModule, RowGroupingModule],
      enableStorage: false,
      gridOptions: {
        context: {
          componentParent: this
        },
      },
      defaultColDef: {
        resizable: true,
        sortable: true,
        suppressHeaderMenuButton: true,
        cellClassRules: {
          odd: params => params.rowIndex % 2 === 1,
          even: params => params.rowIndex % 2 === 0
        }
      },
      loading: false,
      loadingFromRoute: false,
      errorMessage: null,
      rowItems: [],
      advancedFilterVisible: false
    }
  },
  computed: {
    fileName () {
      // Used by ExportsGrid mixin.
      return this.exportFileName
    },
    exportRowItems () {
      return this.rowItems
    },
    aggregateColumns () {
      return this.allColumnDefsInPreferredOrder.filter(col => this.columnIsVisible(col) && !!col.aggFunc)
    },
    aggregateColumnCount () {
      return this.aggregateColumns.length
    },
    containsAggregate () {
      return this.aggregateColumnCount > 0
    },
    groupDisplayType () {
      return this.pivotMode && this.groupColumns.length < 1 && this.pivotColumns.length < 1 ? 'custom' : 'groupRows'
    },
    gridColumnDefs () {
      let columns = _.cloneDeep(
        this.allColumnDefsInPreferredOrder
          // In pivot mode, column visible has no impact.
          // So we need to filter out hidden columns.
          // https://www.ag-grid.com/vue-data-grid/pivoting/#pivot-mode--visible-columns
          .filter(col => !this.pivotMode || (this.columnIsVisible(col) && col.aggFunc))
          .map(col => ({ rowGroupIndex: null, ...col }))
      )

      // In pivot mode, columns should include group and pivot columns.
      if (this.pivotMode) {
        columns = columns.concat(this.groupColumns.flatMap(groupColumn => {
          const columnDef = this.allColumnsByField[groupColumn]?.columnDef
          return columnDef ? [{ rowGroup: true, ...columnDef }] : []
        }))
        columns = columns.concat(this.pivotColumns.flatMap(pivotColumn => {
          const columnDef = this.allColumnsByField[pivotColumn]?.columnDef
          return columnDef ? [{ pivot: true, ...columnDef }] : []
        }))
      }

      this.groupColumns.forEach((groupColumn, index) => {
        const column = columns.find(column => column.field === groupColumn)
        if (column) {
          const originalValueGetter = column.valueGetter
          Object.assign(column, {
            rowGroupIndex: index,
            // Use value getter to convert empty values to space character.
            // https://www.ag-grid.com/vue-data-grid/grouping-unbalanced-groups/
            valueGetter: params => {
              if (params.node.group) return null
              const value = originalValueGetter ? originalValueGetter(params) : params.data[params.colDef.field]
              return value || '<Blank>'
            }
          })
        }
      })

      if (!this.pivotMode) {
        const visibleCols = columns.filter(col => this.columnIsVisible(col))

        // First visible column will be used for row grouping. We should bypass value formatting in such a case.
        // TODO

        // If there's at least one visible column containing aggregate, and it's not the first column,
        // then add footer title.
        const aggColIndex = visibleCols.findIndex(col => !!col.aggFunc)
        if (aggColIndex > 0) {
          const cellRenderer = visibleCols[0].cellRenderer
          const exportRenderer = visibleCols[0].exportRenderer
          Object.assign(visibleCols[0], {
            // TODO: Style, perhaps top border?
            aggFunc: params => {
              // console.log('aggFunc', params)
              if (params.rowNode.level < 0) return 'Grand Total'
              else {
                const groupValue = getGroupRowNodeSummary({ gridApi: params.api, node: params.rowNode, resourceName: this.resourceName })
                return `Subtotal for ${groupValue}`
              }
            },
            // Skip cellRenderer/exportRenderer for group row, because the text does not contain
            // its native value, e.g., naiveDateCellRenderer can't render "Grand Total".
            cellRendererSelector: params => {
              if (params.node.group) return escapeHtml(params.value)
              // TODO: What do we do here when cellRenderer is string name of cell renderer component?
              else if (cellRenderer) return {
                component: cellRenderer,
                params
              }
              else return escapeHtml(params.valueFormatted || params.value)
            },
            // TODO: What is exportRenderer and where is it used?
            // exportRenderer: params => {
            //   return !params.node.group && exportRenderer ? exportRenderer(params) : params.value
            // },
            // Set footer colSpan up to column containing aggFunc.
            // TODO: Make sure ag-grid ignores hidden columns in calculation.
            colSpan: params => {
              if (params.node.group) {
                return params.node.footer ? aggColIndex : visibleCols.length
              } else {
                return 1
              }
            },
            context: {
              groupRowSummaryCol: true,
              ...visibleCols[0].context
            }
          })
        }

        // Add style to group rows.
        if (this.groupColumns.length > 0 && visibleCols.length > 0) {
          Object.assign(visibleCols[0], {
            cellClassRules: {
                headerGroup: params => {
                  // console.log('headerGroup cellClassRules', params)
                  return params.node.group
                },
                // TODO: Fix this for pivot table.
                // Add different style id for each group, so we can indent each one separately.
                ...Object.fromEntries(_.range(this.groupColumns.length).map(rowGroupIndex => {
                  return [`group-row-${rowGroupIndex}`, params => {
                    return params.node.rowGroupIndex === rowGroupIndex
                  }]
                }))
              }
          })
        }

        visibleCols.forEach(col => {
          col.cellClassRules = {
            footerCell: params => {
              return params.node.group && params.node.footer
            },
            ...col.cellClassRules
          }
        })
      }

      if (this.groupDisplayType === 'custom') {
        // Special case column to show when pivot table has no groups or pivot columns.
        columns.unshift(
          {
            headerName: '',
            aggFunc: () => {},
            valueFormatter: () => 'Grand Total'
          },
        )
      }

      return columns
    },
    groupRowRendererParams () {
      return {
        innerRenderer: 'GroupRowInnerRenderer',
        suppressCount: true, // renderer will display count
        resourceName: this.resourceName
      }
    },
    defaultExcelExportParams () {
      return {
        rowGroupExpandState: 'match',
        // Ag-grid is not calling valueFormatter on aggregate columns,
        // so we need to do that. But not really sure why we need to do this,
        // since useValueFormatterForExport should be true by default?
        // https://www.ag-grid.com/vue-data-grid/value-formatters/#formatting-for-export
        processCellCallback: params => {
          if (params.node.group && !params.api.isPivotMode()) {
            if (params.column.colDef.context?.groupRowSummaryCol) return params.value
            // TODO: The following condition breaks Roll Call report summary row.
            // TODO: But what case did it fix?
            // else if (!params.node.footer) return ''
          }
          return params.formatValue(params.value)
        },
        // This function is called on group header and footer rows,
        // and grand total row.
        processRowGroupCallback: params => {
          if (params.node.level < 0) return 'Grand total'
          const summary = getGroupRowNodeSummary({
            gridApi: this.gridApi,
            node: params.node,
            resourceName: this.resourceName,
            showHeaderName: false
          })
          return params.node.footer
            ? `Subtotal for ${summary}`
            : summary
        }
      }
    },
    autoGroupColumnDef () {
      // https://www.ag-grid.com/vue-data-grid/group-cell-renderer/
      return this.pivotMode
        ? {
          headerName: '',
          cellRendererParams: {
            suppressCount: true,
            innerRenderer: params => {
              // console.log('autoGroupColumnDef', params)
              return escapeHtml(params.valueFormatted || params.value || 'Grand Total')
            }
          },
          cellClass: params => {
            // console.log('autoGroupColumnDef.cellClass', params)
            return [`group-row-${params.node.level}`]
          }
        }
        : null
    },
    loadingOverlayComponentParams () {
      return {
        errorMessage: this.errorMessage,
        retry: () => this.runReport()
      }
    },
    groupDefaultExpanded () {
      // Used in pivot mode when minimizing groups
      return this.pivotMode && this.minimizeGroups
        ? Math.max(0, this.groupColumns.length - 2)
        : -1
    }
  },
  watch: {
    activeRoute (newRoute, oldRoute) {
      // I don't know why, but this watcher can fire even if the
      // new and old routes are the same. It might have to do with
      // that we bind to routeHooks, which recomputes activeRoute
      // even when it doesn't change. So we only handle new route
      // if it actually changes. If we didn't check this condition,
      // then this component would re-fetch the report when navigating
      // away.
      if (newRoute !== oldRoute) {
        this.handleNewRoute(newRoute)
      }
    },
    'routeHooks.routeUpdateNext' (next) {
      // we don't need to guard route updates
      next()
    },
    'routeHooks.routeLeaveNext' (next) {
      next()
    },
    // gridColumnDefs (columnDefs) {
    //   const api = this.gridApi
    //   if (api) {
    //     // https://stackoverflow.com/a/53763562/1237919
    //     api.setColumnDefs([])
    //     api.setColumnDefs(columnDefs)
    //   }
    // },
    loading (newValue) {
      this.applyLoadingState()
    },
    sortOrder () {
      this.setDefaultSortOrder()
    },
    gridColumnDefs () {
      // TODO: Fix not working for pivot table.
      // https://github.com/ag-grid/ag-grid/issues/4375
      this.gridApi?.refreshCells?.({ force: true, columns: this.gridColumnDefs.map(colDef => colDef.field) })
    }
  },
  mounted () {
    // Try to mitigate grid and column menu not ready yet.
    // TODO: We need a better solution, because visible columns not set properly.
    setTimeout(() => this.handleNewRoute(this.activeRoute), 50)
  },
  methods: {
    onGridReady (params) {
      this.gridApi = params.api
      this.applyLoadingState()
      this.setDefaultSortOrder()
    },
    applyLoadingState () {
      if (!this.gridApi) return // ag-grid still initializing

      if (this.loading) {
        // Delay showLoadingOverlay to avoid racing condition with runReportFromState clearing rowItems.
        this.$nextTick(() => this.gridApi.setGridOption('loading', true))
      } else {
        this.gridApi.setGridOption('loading', false)
      }
    },
    loadMoreClicked (limit) {
      this.loading = true
      this.errorMessage = null

      this.loadMore(limit)
        .then(rowItems => { this.rowItems = this.rowItems.concat(rowItems) })
        .catch(error => { this.errorMessage = extractErrorMessage(error) })
        .finally(() => {
          this.loading = false
        })
    },
    toggleAdvancedFilter () {
      this.advancedFilterVisible = !this.advancedFilterVisible
    },
    defaultGroupOrderComparator (nodeA, nodeB) {
      // Sort in alphabetical order.
      // We use field value, not key which may be custom key using id.
      const nodeKey = node => node.allLeafChildren[0].data[node.field]
      const a = nodeKey(nodeA)
      const b = nodeKey(nodeB)
      // TODO: case-insensitive
      return a < b ? -1 : a > b ? 1 : 0
    },
    setDefaultSortOrder () {
      if (!this.gridApi) return // ag-grid still initializing

      this.gridApi.applyColumnState({ state: this.sortOrder })
    },
    processPivotResultColGroupDef (colGroupDef) {
      // console.log('processPivotResultColGroupDef', colGroupDef)
      const pivotKeys = colGroupDef.pivotKeys
      if (pivotKeys.length > 0) {
        // TODO: All double underscore means if that the pivot value is null, in which case we should set header name to "<Blank>".
        // if (!colGroupDef.groupId.includes('__')) {
          const pivotKey = pivotKeys.slice(-1)[0]
          const pivotField = this.pivotColumns[pivotKeys.length - 1]
          // TODO: Array search could be slow. Maybe instead use dict lookup, or call into grid api.
          const pivotColDef = this.gridColumnDefs.find(columnDef => columnDef.field === pivotField)
          const headerName = (pivotColDef.valueFormatter?.({ value: pivotKey, colDef: pivotColDef }) ?? pivotKey) || '<Blank>'
          // console.log(`Changed colGroupDef.headerName from ${colGroupDef.headerName} to ${headerName}`)
          colGroupDef.headerName = headerName
        // }
      } else {
        // This condition only occurs when there are multiple aggregate columns with a pivot field.
        // In such a case, we only want to set Grand Total on first aggregate column.
        if (colGroupDef.groupId.split('_').slice(-1)[0] === this.aggregateColumns[0].field) {
          colGroupDef.headerName = 'Grand Total'
        }
      }
    },
    processPivotResultColDef (colDef) {
      // ag-grid doesn't use columnDef.valueFormatter to format a pivot column header.
      // So we'll do it ourselves.
      // We only need to do this when there is one aggregate column such that the header name takes on the pivot column name,
      // due to removePivotHeaderRowWhenSingleValueColumn being true.
      // console.log('processPivotResultColDef', colDef)
      if (this.aggregateColumnCount > 1) return
      const pivotKeys = colDef.pivotKeys
      if (pivotKeys.length > 0) {
        // TODO: All double underscore means if that the pivot value is null, in which case we should set header name to "<Blank>".
        // if (!colDef.colId.includes('__')) {
          const pivotKey = pivotKeys.slice(-1)[0]
          const pivotField = this.pivotColumns[pivotKeys.length - 1]
          // TODO: Array search could be slow. Maybe instead use dict lookup, or call into grid api.
          const pivotColDef = this.gridColumnDefs.find(columnDef => columnDef.field === pivotField)
          let headerName = (pivotColDef.valueFormatter?.({ value: pivotKey, colDef: pivotColDef }) ?? pivotKey) || '<Blank>'
          if (pivotKeys.length < this.pivotColumns.length) {
            headerName += ' Total'
          }
          // console.log(`Changed colDef.headerName from ${colDef.headerName} to ${headerName}`)
          colDef.headerName = headerName
        // }
      } else {
        colDef.headerName = 'Grand Total'
      }
    },
    customData () {
      return Promise.resolve({
        items: this.rowItems,
        supplementalData: this.supplementalData
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '@/assets/scss/_bootstrap-variables';
@import '@/assets/scss/variables';

.abstract-ag-grid-report {

  &.customize-pinned {
    // When sidebar is pinned, then we need to adjust main page width accordingly.
    // On xs screen, we need to hide all children except the sidebar.
    // https://bootstrap-vue.org/docs/components/sidebar#width
    @include media-breakpoint-up(sm) {
      width: calc(100% - 320px);
    }
    @include media-breakpoint-only(xs) {
      > :not(.b-sidebar-outer) {
        visibility: hidden;
      }
    }
  }

  :deep(.ag-root-wrapper) {
    .ag-center-cols-container {
      // Match width to the ag-full-width-container so that background color of footer rows match all the way across with headers.
      width: 100% !important;
    }
  }

  :deep(.ag-row) {
    --ag-odd-row-background-color: #fff;

    .align-right {
      text-align: right;
    }
  }

  :deep(.ag-row-group) {
    font-weight: bold;
    background-color: var(--ag-header-background-color);
  }

  :deep(.ag-row-footer) {
    margin-bottom: 25px;
    font-weight: bold;
    background-color: var(--ag-header-background-color);
  }

  // TODO: Some of this may need to move into print-grid.scss, or maybe we need a more general scss
  // TODO: that we can put into AgGridVueWrapper component?
  :deep(.ag-row-footer), &.pivot-grid :deep(.ag-row) {

    .ag-cell {
      color: #000;
    }

    &.ag-row-level-1 {
      --ag-indentation-level: 1;
    }
    &.ag-row-level-2 {
      --ag-indentation-level: 2;
    }
    &.ag-row-level-3 {
      --ag-indentation-level: 3;
    }
    &.ag-row-level-4 {
      --ag-indentation-level: 4;
    }
    &.ag-row-level-5 {
      --ag-indentation-level: 5;
    }
    &.ag-row-level-6 {
      --ag-indentation-level: 6;
    }
    &.ag-row-level-7 {
      --ag-indentation-level: 7;
    }
    &.ag-row-level-8 {
      --ag-indentation-level: 8;
    }
    &.ag-row-level-9 {
      --ag-indentation-level: 9;
    }
    &.ag-row-level-10 {
      --ag-indentation-level: 10;
    }
  }

  &.pivot-grid {
    :deep(.ag-root), :deep(.print-root) {
      .ag-column-first {
        // Decrease amount of pivot table group indentation.
        padding: 0;
        padding-left: 15px;
        .ag-pivot-leaf-group {
          --ag-row-group-indent-size: 28px;
        }
      }
      .ag-row {
        // In pivot table, all cells except first in row should be numbers and thus right aligned.
        .ag-cell-value:not(:first-child) {
          text-align: right;
        }
      }
    }
    :deep(.print-root) {
      .ag-cell {
        padding-left: 0;
      }
    }
  }
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  .btn {
    text-align: left;
    svg {
      margin: auto;
      &.untack {
        transform: rotate(45deg);
      }
    }
  }
  .title {
    font-size: 1.1rem;
  }
  > * {
    flex: 1;
  }
}

.quick-filter {
  .btn, .btn-group {
    margin: .5rem;
  }
}

.memorize-dirty {
  font-style: italic;
  font-size: .75rem;
  margin-bottom: -.75rem;
  &:before {
    content: '*'
  }
}

.report-column-menu.ag-grid :deep() {
  width: 100%;
  .ag-header {
    background-color: inherit;
  }
}

.report-ag-grid {
  margin-bottom: 1rem;

  // Grid will take entire height of screen, minus header, report filter card, footer, and margins.
  height: calc(100vh - 55px - 165px - 50px - 75px);
}

.more-results {
  margin-bottom: 1rem;
}

.error-message {
  margin-top: 2rem;
  margin-bottom: 1rem;

  text-align: center;
  color: map-get($theme-colors, danger);
  .icon {
    margin-right: .5rem;
  }

  &.item-error-message {
    margin-top: .5rem;
  }
}

</style>
