<template>
  <div>
    <template v-if="filterDepartment && sortedDepartments.length > 0">
      <form-group label="Department" v-if="filterDepartment">
          <key-multiselect
            v-model="config.department"
            :disabled="disabled"
            :multiple="filterMultipleDepartments"
            label="name"
            track-by="id"
            placeholder="All Departments"
            select-label=""
            deselect-label=""
            :options="sortedDepartments"
            @fullValueChange="filterChanged('Department', 'name', 10, $event)"
          >
          </key-multiselect>
      </form-group>
      <hr>
    </template>

    <template v-if="filterLabel">
      <form-group label="Label">
        <remote-multiselect
          v-model="config.labels"
          :disabled="disabled"
          :multiple="true"
          label="name"
          track-by="targetId"
          placeholder="All Labels"
          select-label=""
          deselect-label=""
          :serviceFetch="fetchLabels"
          @fullValueChange="filterChanged('Label', 'name', 20, $event)"
        />
      </form-group>
      <hr>
    </template>

    <template v-if="filterOrgUnit && (requireSingleTimezone || sortedOrgUnits.length > 1)">
      <form-group label="User Org Unit"
        :validator="v$.config.orgUnit"
      >
        <template #default="slotProps">
          <key-multiselect
            v-bind="slotProps"
            v-model="config.orgUnit"
            :disabled="disabled"
            :multiple="filterMultipleOrgUnits"
            label="name"
            track-by="id"
            placeholder="All Org Units"
            select-label=""
            deselect-label=""
            :options="sortedOrgUnits"
            @fullValueChange="filterChanged('Org Unit', 'name', 30, $event)"
          />
        </template>
      </form-group>
      <hr>
    </template>

    <template v-if="filterPayClassEnabled">
      <form-group :label="salaryMode ? 'Salary Pay Class' : 'Pay Class'"
        :validator="v$.config.payClass"
      >
        <template #default="slotProps">
          <pay-class-select
            v-bind="slotProps"
            v-model="config.payClass"
            :disabled="disabled"
            :activeOnly="true"
            :salaryOnly="salaryMode"
            :multiple="true"
            placeholder="All Pay Classes"
            @fullValueChange="filterChanged('Pay Class', 'name', 40, $event)"
          />
        </template>
      </form-group>
      <hr>
    </template>

    <template v-if="filterWorker">
      <form-group label="Worker"
        :validator="v$.config.employee"
      >
        <template #default="slotProps">
          <worker-multiselect
            v-bind="slotProps"
            v-model="config.employee"
            ref='workerSelect'
            :disabled="disabled"
            placeholder="All Workers"
            :activeOption="true"
            :multiple="filterMultipleWorkers"
            :queryParams="{ department: config.department, orgUnit: config.orgUnit, payClass: config.payClass }"
            @fullValueChange="workerChanged($event, 50)"
          />
        </template>
      </form-group>
      <hr>
    </template>

    <template v-if="filterUserCustomFields">
      <custom-field-filter
        v-for="(customField, index) in userCustomFields"
        :key="customField.id"
        :customField="customField"
        v-model="userCustomFieldFilters[index]"
        :disabled="disabled"
        @fullValueChange="filterChanged(customField.name, 'name', 1000+index, $event)"
      />
      <hr>
    </template>

    <template v-if="filterPunchCustomFields">
      <custom-field-filter
        v-for="(customField, index) in punchCustomFields"
        :key="customField.id"
        :customField="customField"
        v-model="punchCustomFieldFilters[index]"
        :disabled="disabled"
      />
      <hr>
    </template>

    <template v-if="filterShiftCustomFields">
      <custom-field-filter
        v-for="(customField, index) in shiftCustomFields"
        :key="customField.id"
        :customField="customField"
        v-model="shiftCustomFieldFilters[index]"
        :disabled="disabled"
      />
      <slot name="after-shift-custom-fields" />
      <hr>
    </template>

    <template v-if="filterJob && costingEnabled">
      <form-group label="Job">
        <job-multiselect
          :disabled="disabled"
          :multiple="true"
          v-model="config.job"
          placeholder="All Jobs"
          :activeOption="true"
          @fullValueChange="filterChanged('Job', 'name', 60, $event)"
        />
      </form-group>
      <slot name="after-job" />

      <template v-if="filterCostCode && enhancedCostingEnabled">
        <form-group label="Cost Code">
          <cost-code-multiselect
            :disabled="disabled"
            :multiple="true"
            v-model="config.costCode"
            placeholder="All Cost Codes"
            :activeOption="true"
            @fullValueChange="filterChanged('Cost Code', 'name', 65, $event)"
          />
        </form-group>

        <slot name="after-cost-code" />
      </template>

      <hr>
    </template>

    <slot name="extra-options" :filterChanged="filterChanged" :baseOrder="70" />

    <template v-if="pivotOptions.length > 0">
      <form-group label="Pivot By">
        <key-multiselect
          v-model="config.pivotBy"
          :disabled="disabled"
          :multiple="true"
          label="label"
          track-by="value"
          select-label=""
          deselect-label=""
          :options="pivotOptions"
          @fullValueChange="filterChanged('Pivot By', 'label', 80, $event)"
        />
      </form-group>
      <hr>
    </template>

    <form-group label="Group By">
      <key-multiselect
        v-model="config.groupBy"
        :disabled="disabled"
        :multiple="true"
        label="label"
        track-by="value"
        select-label=""
        deselect-label=""
        :options="groupByOptions"
        @fullValueChange="filterChanged('Group By', 'label', 90, $event)"
      />
    </form-group>

    <form-group v-if="groupPayrollId">
      <template #label>
        Group Payroll ID
        <help-text-icon>
          If enabled, Payroll ID will be included on applicable grouped fields (department, job, worker).
          If the field is not grouped, then its Payroll ID can be added separately as a visible column.
        </help-text-icon>
      </template>
      <key-multiselect
        v-model="config.groupPayrollId"
        :disabled="disabled"
        placeholder="No"
        label="label"
        track-by="value"
        select-label=""
        deselect-label=""
        :options="groupPayrollIdOptions"
      />
    </form-group>

    <form-group v-if="canMinimizeGroups" label="Minimize groups" label-cols="auto">
      <b-form-checkbox v-model="config.minimizeGroups" />
    </form-group>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import CostCodeMultiselect from '@/components/CostCodeMultiselect.vue'
import CustomFieldFilter from '@/components/CustomFieldFilter.vue'
import HelpTextIcon from '@/components/HelpTextIcon.vue'
import KeyMultiselect from '@/components/KeyMultiselect.vue'
import RemoteMultiselect from '@/components/RemoteMultiselect.vue'
import JobMultiselect from '@/components/JobMultiselect.vue'
import WorkerMultiselect from '@/components/WorkerMultiselect.vue'
import ChildValidation from '@/mixins/ChildValidation'
import { ManagesCrossIndexMixedCustomFieldFilters, ManagesPunchMixedCustomFieldFilters, ManagesShiftMixedCustomFieldFilters } from '@/mixins/ManagesCustomFieldFilters'
import PayClassSelect from '@/views/settings/payroll/PayClassSelect.vue'
import PayClassReportFilterMixin from '@/components/report/PayClassReportFilterMixin'
import LabelService from '@/views/settings/organization/services/LabelService'
import _ from 'lodash'
import { forceArray, hasValue } from '@/utils/misc'
import { useVuelidate } from '@vuelidate/core'
import { helpers } from '@vuelidate/validators'

export default {
  name: 'UserReportFilter',
  setup () {
    return {
      v$: useVuelidate({ $scope: false, $stopPropagation: true })
    }
  },
  mixins: [
    ChildValidation,
    ManagesCrossIndexMixedCustomFieldFilters,
    ManagesPunchMixedCustomFieldFilters,
    ManagesShiftMixedCustomFieldFilters,
    PayClassReportFilterMixin
  ],
  components: {
    CostCodeMultiselect,
    CustomFieldFilter,
    HelpTextIcon,
    JobMultiselect,
    KeyMultiselect,
    PayClassSelect,
    RemoteMultiselect,
    WorkerMultiselect
  },
  props: {
    config: Object,
    disabled: Boolean,
    extraGroupByOptions: {
      type: Array,
      default: () => []
    },
    groupByDate: {
      type: Boolean,
      default: false
    },
    groupByWorker: {
      type: Boolean,
      default: true
    },
    groupByLabels: {
      type: Boolean,
      default: false
    },
    groupByPartitionedLabels: {
      type: Boolean,
      default: false
    },
    groupByJobs: {
      type: Boolean,
      default: false
    },
    filterDepartment: {
      type: Boolean,
      default: true
    },
    filterLabel: {
      type: Boolean,
      default: true
    },
    filterLabelType: {
      type: String,
      default: 'user'
    },
    filterMultipleDepartments: {
      type: Boolean,
      default: false
    },
    filterMultipleOrgUnits: {
      type: Boolean,
      default: false
    },
    filterMultipleWorkers: {
      type: Boolean,
      default: false
    },
    filterOrgUnit: {
      type: Boolean,
      default: true
    },
    filterWorker: {
      type: Boolean,
      default: true
    },
    filterJob: {
      type: Boolean,
      default: false
    },
    filterCostCode: {
      type: Boolean,
      default: false
    },
    groupPayrollId: {
      type: Boolean,
      default: false
    },
    pivotOptions: {
      type: Array,
      default: () => []
    },
    canMinimizeGroups: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      groupPayrollIdOptions: [
        { label: 'Prepend', value: 'prepend' },
        { label: 'Append', value: 'append' }
      ],
      customFieldGetter: 'crossIndexCustomFields',
      customFieldFormProperty: 'userCustomFields'
    }
  },
  computed: {
    ...mapState(['timezone']),
    ...mapGetters(['costingEnabled', 'enhancedCostingEnabled']),
    ...mapGetters('formatPreferences', ['formatName']),
    ...mapGetters({
      departments: 'departments/sortedItems',
      orgUnits: 'orgUnits/sortedItems',
      crossIndexCustomFields: 'customFields/crossIndexCustomFields'
    }),
    sortedDepartments () {
      return this.departments('name').filter(item => item.active)
    },
    sortedOrgUnits () {
      return this.orgUnits('name').filter(item => item.active)
    },
    groupByOptions () {
      const options = [
        ...(this.groupByDate ? [{ label: 'Date', value: 'date' }] : []),
        this.filterDepartment && this.sortedDepartments.length > 0 ? { label: 'Department', value: 'departmentName' } : null,
        ...(this.groupByJobs && this.costingEnabled ? [
          { label: 'Job', value: 'jobName' },
          { label: 'Job Payroll ID', value: 'jobPayrollId' }
        ] : []),
        ...(this.groupByLabels ? [{ label: 'Labels', value: 'labelNames' }] : []),
        ...(this.groupByPartitionedLabels ? [
          { label: 'Labels (Combined)', value: 'allLabelNames' },
          { label: 'Labels (User)', value: 'userLabelNames' },
          { label: 'Labels (Work)', value: 'workLabelNames' }
        ] : []),
        this.filterOrgUnit && this.sortedOrgUnits.length > 1 ? { label: 'Org Unit', value: 'orgUnitName' } : null,
        ...(this.groupByWorker ? [{ label: 'Worker', value: 'employeeName' }] : []),
        // TODO: Use separate prop for enabling grouping custom fields.
        ...(this.filterUserCustomFields
          ? this.userCustomFields.map(customField => ({ label: customField.name, value: `customField${customField.id}`}))
          : []
        ),
        ...(this.filterPunchCustomFields
          ? this.punchCustomFields.map(customField => ({ label: customField.name, value: `customField${customField.id}`}))
          : []
        ),
        ...(this.filterShiftCustomFields
          ? this.shiftCustomFields.map(customField => ({ label: customField.name, value: `customField${customField.id}`}))
          : []
        ),
      ].concat(this.extraGroupByOptions).filter(option => option && (_.isEmpty(this.config.pivotBy) || !this.config.pivotBy.includes(option.value)))
      return _.sortBy(options, 'label')
    },
    userCustomFieldsFormPropertyPath () {
      return 'config.userCustomFields'
    },
    punchCustomFieldsFormPropertyPath () {
      return 'config.customFields'
    },
    shiftCustomFieldsFormPropertyPath () {
      return 'config.customFields'
    },
    filterUserCustomFields () {
      return this.userEnableFilterCustomFields && this.userCustomFields.length > 0
    },
    filterPunchCustomFields () {
      return this.punchEnableFilterCustomFields && this.punchCustomFields.length > 0
    },
    filterShiftCustomFields () {
      return this.shiftEnableFilterCustomFields && this.shiftCustomFields.length > 0
    }
  },
  watch: {
    // When one of the parameters on which we filter workers changes,
    // we need to reset the employee selection list.
    'config.department': function (department) {
      if (!hasValue(department)) return
      const departentIds = new Set(forceArray(department))
      const workerDepartmentIds = forceArray(this.workerData).map(worker => worker.department)
      if (workerDepartmentIds.some(id => !departentIds.has(id))) {
        this.$refs.workerSelect.clear()
      }
    },
    'config.orgUnit': function (orgUnit) {
      if (!hasValue(orgUnit)) return
      const orgUnitIds = new Set(forceArray(orgUnit))
      const workerOrgUnitIds = forceArray(this.workerData).map(worker => worker.orgUnit)
      if (workerOrgUnitIds.some(id => !orgUnitIds.has(id))) {
        this.$refs.workerSelect.clear()
      }
    },
    'config.payClass': function (payClass) {
      if (!hasValue(payClass)) return
      const payClassIds = new Set(forceArray(payClass))
      const workerPayClassIds = forceArray(this.workerData).map(worker => worker.payClass)
      if (workerPayClassIds.some(id => !payClassIds.has(id))) {
        this.$refs.workerSelect.clear()
      }
    }
  },
  methods: {
    fetchLabels (searchText, value, limit) {
      return LabelService.fetchForSelect(this.filterLabelType, true)(searchText, value, limit)
    }
  },
  created () {
    if (this.filterDepartment) this.$store.dispatch('departments/load')
    if (this.filterOrgUnit) this.$store.dispatch('orgUnits/load')
  },
  validations () {
    return {
      config: {
        orgUnit: {
          // If requireSingleTimezone is true, then require an org unit specified,
          // if not all org units have same timezone, or if the org unit timezone
          // if different than the org timezone.
          required: helpers.withAsync(helpers.withMessage('Org Unit is a required field when there are multiple timezones.', async value => {
            if (hasValue(value) || !this.requireSingleTimezone || hasValue(this.config.employee)) return true
            const orgTimezone = this.timezone
            return this.$store.dispatch('orgUnits/load')
              .then(data => {
                const orgUnits = data.results
                const timezones = orgUnits.map(orgUnit => orgUnit.timezone)
                return (new Set(timezones)).size <= 1 &&
                  (timezones.length === 0 || timezones[0] === orgTimezone)
              })
          })),
          requireSingleTimezone: helpers.withAsync(helpers.withMessage('You may only choose Org Units in the same timezone.', async value => {
            if (!this.requireSingleTimezone) return true
            if (!hasValue(this.config.orgUnit)) return true
            return this.$store.dispatch('orgUnits/load')
              .then(data => {
                const orgUnits = _.keyBy(data.results, 'id')
                const timezones = forceArray(value).map(orgUnitId => orgUnits[orgUnitId].timezone)
                return new Set(timezones).size <= 1
              })
          }))
        },
        employee: this.employeeValidation,
        payClass: this.payClassValidation
      }
    }
  }
}
</script>
<style lang="scss" scoped>
// Undo RemoteMultiselect styling max-width.
// See note there about being unsure of the right approach.
.remote-multiselect {
  max-width: inherit;
}
.custom-checkbox {
  margin-top: 6px;
  margin-left: 10px;
}
</style>
