import { createRestCrudService } from '@/services/GenericCrudService'
import restClient from '@/services/clients/rest'
import { get } from 'lodash'

const resourceName = 'Device'
const resourcePath = 'clocks'
const crudService = createRestCrudService(resourceName, resourcePath)

export default Object.assign(crudService, {
  async retrievePersonalSettings (deviceId) {
    try {
      const response = await restClient.get(`${resourcePath}/${deviceId}/personal-settings`)
      return response.data
    } catch (error) {
      console.warn(`Error retrieving personal settings for device ${deviceId}`, error)
      throw error
    }
  },
  async disconnectDevice (deviceId) {
    try {
      await restClient.post(`${resourcePath}/${deviceId}/reset`)
    } catch (error) {
      console.warn(`Error disconnecting device ${deviceId}`, error)
      throw error
    }
  },
  fetchForSelect (searchText, value, limit) {
    return crudService
      .list({
        searchText,
        active: value ? 'all' : 'active',
        id: value,
        limit
      })
      .then(data => get(data, 'results', []))
  }
})
