
export function openClockLogFormModal (clockLogId, showModal) {
  import(/* webpackChunkName: "clock-log-form-modal" */ './ClockLogFormModal.vue')
    .then(async module => {
      const ClockLogFormModal = module.default
      await showModal({
        component: ClockLogFormModal,
        props: {
          itemId: clockLogId
        }
      })
    })
}
