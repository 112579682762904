import { createRestCrudService } from '@/services/GenericCrudService'
import { get } from 'lodash'

const resourceName = 'Briefing Type'
const resourcePath = 'briefing/types'
const crudService = createRestCrudService(resourceName, resourcePath)

export default Object.assign(crudService, {
  fetchForSelect (searchText, value, limit) {
    return crudService
      .list({
        searchText,
        active: value ? 'all' : 'active',
        id: value,
        limit
      })
      .then(data => get(data, 'results', []))
  }
})
