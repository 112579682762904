<template>
  <span>
    <b-popover
      triggers="hover focus"
      :custom-class="['hover-popover', extraClass]"
      :placement="placement"
    >
      <template #target>
        <font-awesome-icon :icon="icon" class="icon" />
      </template>
      <slot>{{ hoverText }}</slot>
    </b-popover>
  </span>
</template>
<script>

export default {
  name: 'HoverIcon',
  props: {
    icon: {
      type: [String, Array],
      required: true
    },
    hoverText: {
      type: String,
      default: ''
    },
    extraClass: String,
    placement: String
  }
}
</script>
<style lang="scss" scoped>
  .hover-popover {
    :deep(.popover-body) {
      overflow-y: auto;
      white-space:pre-wrap;
      max-height: 80vh;
    }
  }
  .icon {
    margin-left: 5px;
    color: #555;
    cursor: help;
  }
</style>
