import { get } from 'lodash'

// We need this because vue doesn't have custom v-model modifiers,
// or any other trivial way to convert empty string input to null.
// https://codepen.io/Hiws/pen/rNaJXZR
export default {
  methods: {
    setNullIfEmpty (value, property) {
      if (value === '') {
        this._setDeepest(this, property, null)
      }
    },
    // We implement _setDeepest, because lodash _.set mutates
    // all properties on path, for which Vue logs error if
    // a parent property is a computed property. We really only
    // want to mutate the last parent on the path.
    _setDeepest (object, path, value) {
      const pathList = path.split('.')
      if (pathList.length > 1) {
        const immediateParent = get(object, pathList.slice(0, -1))
        immediateParent[pathList.slice(-1)[0]] = value
      } else {
        object[path] = value
      }
    }
  }
}
