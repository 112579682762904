<template>
  <div class="document-upload">
    <div class="upload-box">
      <h4 v-if="dropActive" class="drop-title">{{ dropTitle }}</h4>
      <div class="upload-prompt" v-else>
        <font-awesome-icon :icon="['far', 'file-arrow-up']" />
        <label :for="fileInputId" class="choose-file">Choose file</label>
        <span>or drop file anywhere to upload</span>
      </div>

      <file-upload
        v-model="files"
        :input-id="fileInputId"
        :drop="dropContainer"
        :drop-directory="false"
        :extensions="extensions"
        @input-filter="inputFilter"
        @input-file="inputFile"
        @drop-active-changed="dropActive = $event"
      />

      <div v-if="dropTitleErrorMessage" class="file-prohibited">
        <font-awesome-icon icon="triangle-exclamation" /> {{ dropTitleErrorMessage }}
      </div>
      <div v-if="dropPdfCompression">
        <font-awesome-icon icon="circle-info" />
        Try compressing your PDF file first at
        <!-- TODO: Other options? -- https://www.ilovepdf.com -->
        <a href="https://www.pdf2go.com/compress-pdf" target="_blank">pdf2go.com</a>
      </div>
    </div>
  </div>
</template>
<script>
import FileUpload from '@/components/FileUpload.vue'
import moment from 'moment'
import { formatFileSize } from '@/utils/misc'
import { mimeTypeCanBeCompressed } from '@/utils/image'
import _ from 'lodash'

const PROHIBITED_MIME_TYPES = new Set([
  'application/x-msdos-program',
  'application/x-msdownload',
  'application/vnd.microsoft.portable-executable'
])
const PROHIBITED_FILE_NAME_SUFFIXES = new Set([
  'app',
  'bat',
  'cmd',
  'com',
  'dll',
  'exe',
  'jar',
  'js',
  'msi',
  'php',
  'pl',
  'py',
  'sh',
  'vb',
  'vbs'
])
const PROHIBITED_MAGIC_NUMBERS = [
  0x7f454c46, // Linux ELF executable
  0xcafebabe, // Mach Universal Binaries / Object files
  0xcffaedfe, // MacOS executable
  0xfeedface, // Mach-O 32-bit
  0xfeadfacf, // Mach-O 64-bit
]

export default {
  name: 'DocumentUpload',
  components: {
    FileUpload
  },
  props: {
    showing: Boolean,
    parentKey: [Number, String],
    extensions: Array,
    maxFileSize: Number
  },
  data () {
    return {
      files: [],
      dropContainer: null,
      dropActive: false,
      dropTitleError: null,
    }
  },
  computed: {
    fileInputId () {
      return `file-input-${this.$.uid}`
    },
    dropTitle () {
      return this.dropTitleErrorMessage || 'Drop file here'
    },
    dropTitleErrorMessage () {
      return this.dropTitleError?.message ?? null
    },
    dropPdfCompression () {
      return this.dropTitleError?.special === 'pdf-compression'
    }
  },
  watch: {
    dropActive (dropActive) {
      if (dropActive) this.dropTitleError = null
    },
    parentKey () {
      this.dropTitleError = null
    },
    showing () {
      this.dropTitleError = null
    }
  },
  methods: {
    fileNameSuffix (file) {
      return file.name.split('.').slice(1).pop() || null
    },
    async getFileError (file) {
      // Maximum file size validation is done in form vuelidate.
      // console.log('inputFilter', newFile)
      const mimeType = file.file.type
      const fileNameSuffix = this.fileNameSuffix(file)
      // Do not allow executable or other unknown file types.
      const fileProhibited = (
        !mimeType ||
        PROHIBITED_MIME_TYPES.has(mimeType) ||
        PROHIBITED_FILE_NAME_SUFFIXES.has(this.fileNameSuffix(file))
      )
      if (fileProhibited) {
        return 'File type is prohibited'
      }

      // Now check for magic numbers.
      const arrayBuffer = await file.file.arrayBuffer()
      const hexHeader = Array.prototype.map.call(
        new Uint8Array(arrayBuffer.slice(0, 4)),
        x => x.toString(16)
      ).join('')
      if (PROHIBITED_MAGIC_NUMBERS.some(magicNumber => magicNumber.toString(16) === hexHeader)) {
        return 'File contains prohibited content'
      }

      if (this.extensions && !this.extensions.includes(fileNameSuffix)) {
        return this.extensions.length > 1
          ? `File must be one of these extensions: ${this.extensions.join(' ')}`
          : `File must have ${this.extensions[0]} extension`
      }

      if (this.maxFileSize && file.file.size > this.maxFileSize && !mimeTypeCanBeCompressed(mimeType)) {
        return {
          message: `File size ${formatFileSize(file.file.size)} is greater than allowed ${formatFileSize(this.maxFileSize)}`,
          special: mimeType === 'application/pdf' ? 'pdf-compression' : null
        }
      }

      return null
    },
    async isFileAllowed (file) {
      const fileError = await this.getFileError(file)
      this.dropTitleError = _.isString(fileError) ? { message: fileError, special: null } : fileError
      return !fileError
    },
    async inputFilter (newFile, oldFile, prevent) {
      // Note that this @input-filter is only called when file is dropped, not during drag.
      // TODO: Unfortunately, the vue-upload-component does not currently support asynchronous @input-filter callback to prevent().
      // TODO: https://github.com/lian-yue/vue-upload-component/issues/455
      // TODO: We currently rely on the @input-file callback to filter out files not allowed.
      if (!await this.isFileAllowed(newFile)) {
        prevent()
      }
    },
    async inputFile (file) {
      // TODO: vue-upload-component has a bug that it seems to sometimes ignore large pdf files, because
      // TODO: these larger files return null for webkitGetAsEntry(). It may be a timing issue.
      // TODO: We should fix this by instead handling the direct file objects.
      if (!file) return // sends dupe event with undefined file for some reason
      if (!await this.isFileAllowed(file)) return

      // console.log('inputFile', file)

      const itemData = {
        name: file.name,
        suffix: this.fileNameSuffix(file),
        mimeType: file.file.type,
        file: file.file,
        size: file.size,
        modified: moment(file.file.lastModified).format()
      }

      this.$emit('input', itemData)
    },
  },
  mounted () {
    this.dropContainer = this.$el
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/app/app';

.document-upload {
  .upload-box {
    border: 1px dashed #333;
    padding: 10px;
    text-align: center;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .drop-title {
      margin-bottom: 0;
    }

    .upload-prompt {
      display: flex;
      flex-direction: row;
      justify-content: center;
      svg {
        margin-right: 10px;
        font-size: 1.2rem;
      }
      .choose-file {
        color: $fc-logo-secondary-blue;
        margin-bottom: 0;
        margin-right: 5px;
        &:hover, &:active {
          color: $flat-ui-carrot;
        }
      }
    }

    .file-prohibited {
      margin-top: 10px;
      color: $flat-ui-alizarin;
      svg {
        margin-right: 5px;
      }
    }
  }
}
</style>
