import { defineComponent } from 'vue'
import { createDeferred } from '@/utils/deferred'

export function promptPdfExportDialog (parent, params) {
  const dialog = parent.$dialog
  const { showFitWidthToPage = true, okLabel = 'Export', outerGroupName } = params
  const deferred = createDeferred()

  const DialogContent = defineComponent({
    data () {
      return {
        pageOrientation: 'Portrait',
        includeTitles: true,
        repeatTitles: false,
        pageBreakPerGroup: false,
        fitWidthToPage: true
      }
    },
    emits: ['cancel', 'ok'],
    render () {
      return <div class="pdf-export-dialog-content">

        <div class="mb-1">Layout</div>
        <div class="flex flex-wrap gap-5 mb-3">
            <div class="flex align-items-center">
                <RadioButton vModel={this.pageOrientation} inputId="portrait" name="pizza" value="Portrait" />
                <label for="portrait" class="ml-2">
                  <span class="mr-2">Portrait</span>
                  <font-awesome-icon icon="file-image" />
                </label>
            </div>
            <div class="flex align-items-center">
                <RadioButton vModel={this.pageOrientation} inputId="landscape" name="pizza" value="Landscape" />
                <label for="landscape" class="ml-2">
                  <span class="mr-2">Landscape</span>
                  <font-awesome-icon icon="image" />
                </label>
            </div>
        </div>

        { showFitWidthToPage &&
        <div class="flex mt-2">
          <Checkbox v-model={this.fitWidthToPage} inputId="fitWidthToPage" binary />
          <label for="fitWidthToPage" class="ml-2">Fit width to page</label>
        </div>
        }

        <div class="flex mt-2">
          <Checkbox v-model={this.includeTitles} inputId="includeTitles" binary />
          <label for="includeTitles" class="ml-2">Include titles</label>
        </div>

        { this.includeTitles &&
        <div class="flex mt-2">
          <Checkbox v-model={this.repeatTitles} inputId="repeatTitles" binary />
          <label for="repeatTitles" class="ml-2">Repeat titles on every page</label>
        </div>
        }

        { outerGroupName &&
        <div class="flex mt-2">
          <Checkbox v-model={this.pageBreakPerGroup} inputId="pageBreakPerGroup" binary />
          <label for="pageBreakPerGroup" class="ml-2">Print separate page per { outerGroupName }</label>
        </div>
        }

        <div class="flex justify-content-end gap-2 mt-4">
          <Button type="button" size="small" label="Cancel" severity="secondary" onClick={() => this.$emit('cancel')}></Button>
          <Button type="button" size="small" label={okLabel} onClick={() => this.ok()}></Button>
        </div>

      </div>
    },
    methods: {
      ok () {
        this.$emit('ok', {
          pageOrientation: this.pageOrientation,
          includeTitles: this.includeTitles,
          repeatTitles: this.repeatTitles,
          pageBreakPerGroup: this.pageBreakPerGroup,
          fitWidthToPage: this.showFitWidthToPage ? this.fitWidthToPage : undefined
        })
      }
    },
    // TODO: Doesn't seem to get called with hot reload.
    // beforeUnmount () {
    //   if (deferred.isPending) {
    //     deferred.reject()
    //     dialogRef.close()
    //   }
    // }
  })

  const dialogRef = dialog.open(DialogContent, {
    props: {
        header: 'PDF Export Preferences',
        style: {
            width: '400px',
        },
        breakpoints:{
            '425px': '90vw',
        },
        modal: true,
        closable: false,
        dismissableMask: false,
        closeOnEscape: false
    },
    // Documented example for PrimeVue 3 is incorrect:
    // https://github.com/primefaces/primevue/issues/5932
    emits: {
      onOk (exportPreferences) {
        deferred.resolve(exportPreferences)
        dialogRef.close()
      },
      onCancel () {
        deferred.reject()
        dialogRef.close()
      }
    }
  })

  return deferred.promise
}
