<template>
  <form-group v-bind="labelConfig"
    :description="customField.helpText"
    :label="getCustomFieldLabel(customField)"
    :label-for="`filter-custom-field-${customField.id}`"
  >
    <custom-field-item-select
      v-if="customField.type === 'choice'"
      v-model="forwardValue"
      :customField="customField.id"
      :name="customField.name"
      :multiple="true"
      :disabled="disabled"
      :placeholder="formattedPlaceholder"
      :id="`filter-custom-field-${customField.id}`"
      @fullValueChange="$emit('fullValueChange', $event)"
    />
    <key-multiselect
      v-else-if="customField.type === 'bool'"
      v-model="forwardValue"
      label="name"
      track-by="value"
      select-label=""
      deselect-label=""
      :options="boolOptions"
      :disabled="disabled"
      :placeholder="formattedPlaceholder"
      :id="`filter-custom-field-${customField.id}`"
      @fullValueChange="$emit('fullValueChange', $event)"
    />
    <b-form-input
      v-else
      v-model.trim="forwardValue"
      :type="customField.type === 'number' ? 'number' : 'text'"
      :number="customField.type === 'number'"
      :placeholder="formattedPlaceholder"
      :disabled="disabled"
      :id="`filter-custom-field-${customField.id}`"
    />
  </form-group>
</template>
<script>
import CustomFieldItemSelect from '@/components/CustomFieldItemSelect.vue'
import KeyMultiselect from '@/components/KeyMultiselect.vue'
import { pluralize } from 'inflection'

export default {
  name: 'CustomFieldFilter',
  components: {
    CustomFieldItemSelect,
    KeyMultiselect
  },
  props: {
    customField: Object,
    modelValue: [String, Number, Array],
    labelConfig: Object,
    customFieldLabelGetter: Function,
    disabled: Boolean,
    placeholder: String
  },
  emits: ['fullValueChange', 'update:modelValue'],
  data () {
    return {}
  },
  computed: {
    forwardValue: {
			get () {
				return this.modelValue
			},
			set (v) {
				this.$emit('update:modelValue', v)
			}
    },
    placeholderFieldName () {
      return this.customField.name + (this.customField.type === 'bool' ? ' status' : '')
    },
    formattedPlaceholder () {
      return this.placeholder
        ? this.placeholder
            .replace('{field}', this.placeholderFieldName)
            .replace('{fieldPlural}', pluralize(this.placeholderFieldName))
        : null
    },
    boolOptions () {
      return [
        // Use `name` for property instead of the typical `label`,
        // because report filters always assume `name` property when
        // updating subtitles.
        { name: this.customField.boolTrueLabel || 'YES', value: 'yes' },
        { name: this.customField.boolFalseLabel || 'NO', value: 'no' }
      ]
    }
  },
  methods: {
    getCustomFieldLabel (customField) {
      return this.customFieldLabelGetter
        ? this.customFieldLabelGetter(customField)
        : customField.name
    }
  }
}
</script>
