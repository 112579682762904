<template>
  <img
    v-if="url"
    :src="imgSrc"
    class="avatar custom"
    :style="[$attrs.style || {}, { width: size, height: size }]"
  />
  <font-awesome-icon
    v-else
    icon="circle-user"
    class="avatar generic"
    :style="{ fontSize: size }"
  />
</template>

<script setup>
import { computed } from 'vue'
import { useImage } from '@vueuse/core'

const LOADING_IMAGE = '/img/fa-user-circle.png'
const ERROR_IMAGE = LOADING_IMAGE

defineOptions({
  inheritAttrs: false
})

const props = defineProps({
  url: String,
  size: {
    type: String,
    default: '3rem'
  }
})
const imageOptions = computed(() => props.url ? { src: props.url } : null)

const { error, isLoading } = useImage(imageOptions)
const imgSrc = computed(() => {
  if (!props.url) return null
  if (isLoading.value) return LOADING_IMAGE
  if (error.value) return ERROR_IMAGE
  return props.url
})

</script>

<style lang="scss" scoped>
.avatar {
  vertical-align: middle;

  &.custom {
    border-radius: 50%;
  }

  &.generic {
    color: #aaa;
  }

  :deep(.img) {
    border-radius: 50%;
  }
}
</style>
