<template>
  <div class="legacy-container animated fadeIn">
    <Spinner v-if="!componentReady" height="100vh" centerVOffset="-25vh" />
    <div ref="legacy"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Spinner from '../components/Spinner.vue'
import _ from 'lodash'

let responsiveViewportContent = null

function removeResponsivenessOnPhone () {
  // We need to remove responsivness on phones, i.e., on widths < 640 px,
  // when displaying legacy Ext JS components, because they are not
  // able to be responsive or usable otherwise.
  if (window.screen.width < 640 && !responsiveViewportContent) {
    const viewport = document.getElementById('viewport')
    responsiveViewportContent = viewport.getAttribute('content')
    viewport.setAttribute('content', '')
    console.log('removed responsiveness')
  }
}

function restoreResponsiveness () {
  if (responsiveViewportContent) {
    const viewport = document.getElementById('viewport')
    viewport.setAttribute('content', responsiveViewportContent)
    responsiveViewportContent = null
    console.log('restored responsiveness')
  }
}

export default {
  name: 'legacy-container',
  components: {
    Spinner
  },
  props: {
    legacyClassName: {
      type: String,
      required: true
    },
    view: {
      type: String
    },
    method: {
      type: String
    }
  },
  data () {
    return {
      componentReady: false,
      fullSize: false
    }
  },
  computed: {
    ...mapState(['legacyInitialized'])
  },
  watch: {
    legacyInitialized (newVal, oldVal) {
      if (!newVal) {
        return
      }

      this.renderLegacy()
    }
  },
  mounted () {
    removeResponsivenessOnPhone()
    this.componentReady = false
    window.addEventListener('resize', this.onResize)
    if (this.legacyInitialized) {
      this.$nextTick(() => { this.renderLegacy() })
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)

    // Destroy the Ext.container, but not the component,
    // because we'll reuse it next time LegacyContainer gets created.
    if (this.container) {
      this.container.removeAll()
      this.container.destroy() // TODO: safe to call this private method?
    }
  },
  beforeRouteEnter (to, from, next) {
    removeResponsivenessOnPhone()
    next()
  },
  beforeRouteLeave (to, from, next) {
    this.notifyDeactivate(() => {
      const toLegacyContainer = _.get(to.matched.slice(-1)[0], 'components.default.extends.name') === 'legacy-container'
      if (!toLegacyContainer) restoreResponsiveness()
      next()
    })
  },
  beforeRouteUpdate (to, from, next) {
    this.pathChanged(to)
    next()
  },
  methods: {
    createContainer () {
      if (this.container) {
        return
      }
      this.container = window.Ext.create('Ext.container.Container', { autoDestroy: false })
      this.container.render(this.$refs.legacy)
    },
    renderLegacy () {
      if (this.component && this.component.$className === this.legacyClassName) {
        this.onResize()
        return
      }

      // console.log(`render legacy for ${this.legacyClassName}`)

      // Render the legacy component in an Ext container. I first tried rendering the component directly in the dom,
      // but I couldn't figure out how to get Ext to render a component in multiple dom elements
      // (i.e., either move it, or render a second time in same element). Using an Ext container is the documented way to do it.
      this.createContainer()

      this.component = window.Ext.ComponentQuery.query(`[$className=${this.legacyClassName}]`).pop() || window.Ext.create(this.legacyClassName)
      this.fullSize = !!(this.component && this.component.fullSize)
      this.container[this.fullSize ? 'addCls' : 'removeCls']('fullSize')
      this.container.add(this.component)

      const afterRender = () => {
        this.pathChanged(this.$route)
        this.onResize()
      }

      if (this.component.rendered) {
        afterRender()
      } else {
        this.component.on('render', afterRender, this, { single: true })
      }
    },
    notifyDeactivate (callback) {
      if (!this.component || !this.component.rendered || !(this.component.getController instanceof Function)) {
        callback()
        return
      }

      const controller = this.component.getController()

      if (!(controller.notifyDeactivate instanceof Function)) {
        callback()
        return
      }

      this.component.getController().notifyDeactivate(callback)
    },
    pathChanged (route) {
      const controller = this.component.getController()

      if (controller && controller.doAction instanceof Function) {
        // Vue router url-decodes params. In order to find view in path, we need to decode the path before comparing.
        const view = route.params.view
        const fullPath = decodeURIComponent(route.path)
        const path = view
          ? fullPath.substr(0, fullPath.lastIndexOf(view) - 1)
          : fullPath.slice(-1) === '/' ? fullPath.slice(0, -1) : fullPath

        controller.doAction(path, route.params)
      }

      // It would seem to be best to set componentReady true in an Ext JS event callback when component
      // is ready to display. But for some reason, I could not get that to work. In any event, setting it
      // here seems to work well empirically.
      this.componentReady = true
    },
    onResize () {
      if (!this.component || !this.component.rendered) return

      if (this.fullSize) {
        const spacingElement = this.$refs.legacy
        this.component.setHeight(spacingElement.clientHeight)
      } else {
        this.component.updateLayout()
      }
    }
  }
}
</script>

<style lang="scss">

.fullSize {
  height: 80vh;
}

/*
bootstrap.css which is imported by App.vue has the following declaration:
  [hidden] {
    display: none !important;
  }
And the <path /> elements in the Ext JS chart svg's have the attribute hidden="false",
which is matching that bootstrap declaration. So let's undo that here.
*/
svg [hidden="false"] {
  display: inline !important
}

/*
_reboot.scss sets body line-height to 1.5, which cuts off bottom of panel headers.
So undo that.
*/
.x-panel-header div, .x-window-header div {
  line-height: 1.0
}

/*
fix layout of fieldset toggle tool
*/
.x-fieldset .x-tool-toggle {
  margin-bottom: 25px
}

/*
tab view buttons should not use bootstrap's link style
*/
.pill a.x-tab:hover {
  color: initial;
  text-decoration: none;
}

.x-container.x-border-box, .x-container.x-border-layout, .x-container.x-border-item {
  display: block;
}

</style>
