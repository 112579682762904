<template>
  <form-group
    :label="customField.name"
    :description="customField.helpText"
    :validator="v$.modelValue"
    :disabled="disabled"
  >
    <template #default="slotProps">
      <div>
        <boolean-radio-group
          v-if="customField.type === 'bool'"
          v-bind="slotProps"
          v-model="forwardValue"
          :showClearButton="!required && customField.default.value === null"
          :trueText="customField.boolTrueLabel"
          :falseText="customField.boolFalseLabel"
        />
        <custom-field-item-select
          v-else-if="customField.type === 'choice'"
          v-bind="slotProps"
          v-model="forwardValue"
          :customField="customField.id"
          :name="customField.name"
          :multiple="customField.listMultiple"
        />
        <b-form-textarea
          v-else-if="customField.stringMultiline"
          v-bind="slotProps"
          v-model.trim="forwardValue"
          rows="3"
          max-rows="3"
        />
        <b-form-input
          v-else
          v-bind="slotProps"
          v-model.trim="forwardValue"
          :type="customField.type === 'number' ? 'number' : 'text'"
          :number="customField.type === 'number'"
        />
        <div v-if="v$.modelValue.$pending" class="invalid-feedback d-block">
          <font-awesome-icon icon="circle-notch" spin /> Checking if {{ customField.name }} is unique...
        </div>
      </div>
    </template>
  </form-group>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { helpers } from '@vuelidate/validators'
import employeeService from '@/views/settings/employees/services/EmployeeService'
import { debounceAsyncValidator } from '@/utils/debounce'
import { hasValue} from '@/utils/misc'
import { mapGetters } from 'vuex'

export default {
  setup () {
    return {
      v$: useVuelidate({ $scope: false, $stopPropagation: true })
    }
  },
  props: {
    customField: Object,
    modelValue: [String, Number, Boolean, Array],
    originalValue: [String, Number, Boolean, Array],
    orgUserId: Number,
    enforceRequired: Boolean,
    disabled: Boolean
  },
  emits: ['update:modelValue', 'validityChanged'],
  computed: {
    ...mapGetters('formatPreferences', ['formatName']),
    forwardValue: {
      get () {
        return this.modelValue
      },
      set (v) {
        this.$emit('update:modelValue', v)
      }
    },
    required () {
      return !!this.enforceRequired && this.customField.required
    },
    maxLength () {
      return this.customField.stringMultiline ? 200 : 100
    }
  },
  watch: {
    'v$.$invalid' (invalid) {
      this.$emit('validityChanged', !invalid)
    }
  },
  validations () {
    const customField = this.customField
    const validations = {
      modelValue: {
        required: helpers.withMessage(
          params => this.$t('validations.required', { attribute: params.$response.attribute }),
          value => {
            return {
              // Vuelidate's required function doesn't check booleans, so we need to do it ourselves.
              $valid: !this.required || hasValue(value, true),
              attribute: customField.name
            }
          }
        ),
        numeric: helpers.withMessage(
          props => this.$t('validations.numeric', { attribute: props.$response.attribute }),
          value => {
            if (customField.type !== 'number') return true
            if (!value) return true
            return {
              $valid: !isNaN(value),
              attribute: customField.name
            }
          }
        ),
        maxLength: helpers.withMessage(
          props => this.$t('validations.maxLength', { attribute: props.$response.attribute, max: this.maxLength }),
          value => {
            if (!value) return true
            if (customField.type !== 'string') return true
            return {
              $valid: value.length <= this.maxLength,
              attribute: customField.name
            }
          }
        )
      }
    }

    if (customField.unique) {
      validations.modelValue.isUnique = helpers.withMessage(
        params => params.$response?.message,
        debounceAsyncValidator(function (value, parentVm, debounce) {
          // synchronous validations
          if (!value) return true
          if (value === this.originalValue) return true
          if (customField.type === 'number' && isNaN(value)) return true
          if (customField.type === 'string' && value.length > this.maxLength) return true
          return debounce()
            .then(() => employeeService.lookupByCustomField(customField.id, value))
            .then(employee => {
              const isUnique = !employee || employee.id === this.orgUserId
              return {
                $valid: isUnique,
                message: isUnique ? null : `"${this.formatName(employee.firstName, employee.lastName)}" already has this ${customField.name}.`
              }
            })
            .catch(error => {
              // console.log('Custom field unique check failed', error, customField)
              // could be caused by either rest api failure or by debounce
              return {
                $valid: false,
                message: `Unable to determine if ${customField.name} is unique.`
              }
            })
        }, 500)
      )
    }

    return validations
  }
}
</script>
