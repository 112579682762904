<template>
  <div class="singleton-form">
    <div
      v-mask="maskOptions"
      v-if="loaded"
    >
      <div class="header">
        <audit-icon
          v-if="showAudit"
          :instanceId="dataId"
          :kind="auditResourceName"
          :resourceName="resourceName"
        />
        <id-icon :id="dataId" />
      </div>

      <slot></slot>
      <hr/>

      <b-form-invalid-feedback class="summary-form-invalid-feedback"
        :force-show="formInvalid"
      >
        ** Fix errors above before saving.
      </b-form-invalid-feedback>

      <b-button-group class="footer-buttons">
        <b-button
          size="sm"
          @click="save"
          :disabled="saveDisabled"
          variant="primary"
          class="action save"
        >
          <font-awesome-icon :icon="saving ? 'circle-notch' : 'circle-check'" :spin="saving" />
          Save
        </b-button>
        <b-button
          size="sm"
          @click="cancel"
          :disabled="cancelDisabled"
          variant="secondary"
          class="action cancel"
        >
          <font-awesome-icon icon="circle-xmark" />
          Cancel
        </b-button>
      </b-button-group>
    </div>
    <div v-else-if="loadFailed">
      <h6 class="load-failed">
        <font-awesome-icon icon="triangle-exclamation" /> Load Failed
      </h6>
      <b-btn @click="loadForm" class="reload">
        <font-awesome-icon icon="arrow-rotate-right" /> Try again
      </b-btn>
    </div>
    <spinner v-else />
  </div>
</template>
<script>
import AuditIcon from '@/components/AuditIcon.vue'
import IdIcon from '@/components/IdIcon.vue'
import Spinner from '@/components/Spinner.vue'
import Maskable from '@/mixins/Maskable'
import SavePrompt from '@/components/SavePrompt.vue'
import { mapGetters } from 'vuex'
import { useModalController } from 'bootstrap-vue-next'

export default {
  setup () {
    return {
      showModal: useModalController().show
    }
  },
  components: {
    AuditIcon,
    IdIcon,
    Spinner
  },
  mixins: [Maskable],
  data () {
    return {
      loaded: false,
      loadFailed: false,
      saving: false
    }
  },
  props: {
    orchestrator: {
      type: String,
      required: true
    },
    routeUpdateNext: Function,
    routeLeaveNext: Function,
    resourceName: String,
    auditResourceName: String
  },
  computed: {
    ...mapGetters(['canViewAudit']),
    formInvalid () {
      return this.$store.state[this.orchestrator].formInvalid
    },
    formDirty () {
      return this.$store.getters[`${this.orchestrator}/formDirty`]
    },
    saveDisabled () {
      return !this.formDirty || this.formInvalid || this.saving
    },
    cancelDisabled () {
      return !this.formDirty || this.saving
    },
    dataId () {
      return this.$store.state[this.orchestrator].formData.id
    },
    showAudit () {
      return Boolean(this.auditResourceName && this.canViewAudit)
    }
  },
  watch: {
    routeUpdateNext (next) {
      // we don't need to guard route updates
      next()
    },

    // routeLeaveNext changes before leaving this route
    routeLeaveNext (next) {
      if (!this.formDirty) {
        next()
        return
      }

      if (this.hasPendingRouteChange) {
        // ignore, since there's already a pending route change
        next(false)
        return
      }

      this.hasPendingRouteChange = true

      this.showModal({
        component: SavePrompt,
        props: {
          onClose: decision => {
            if (decision === true) {
              // save first
              this.save(true)
                .then(() => next())
                .catch(() => next(false))
                .finally(() => { this.hasPendingRouteChange = false })
            } else if (decision === false) {
              // proceed without saving
              this.hasPendingRouteChange = false
              next()
            } else {
              // cancel route change
              this.hasPendingRouteChange = false
              next(false)
            }
          }
        }
      })
    }
  },
  methods: {
    loadForm () {
      this.loaded = false
      this.loadFailed = false

      this.$store.dispatch(`${this.orchestrator}/load`, true)
        .then(data => {
          this.$router.replace({ params: { view: data.id } }).catch(() => {})
          this.loaded = true
        })
        .catch(() => { this.loadFailed = true })
    },
    save (reraiseError) {
      // route guard can call save even though form is invalid
      if (this.formInvalid) return Promise.reject(new Error('Form has invalid values.'))

      this.showUpdatingMask()
      this.saving = true

      return this.$store.dispatch(`${this.orchestrator}/update`)
        .then(() => this.hideMask())
        .catch(error => {
          this.showErrorMask(error)
          if (reraiseError) throw error
        })
        .finally(() => this.saving = false)
    },
    cancel () {
      this.$store.dispatch(`${this.orchestrator}/cancelChanges`)
    }
  },
  mounted () {
    // We're assuming this component is not kept alive.
    // If we ever decide to enable keep-alive, then we'll need
    // to hook into entering route to load form (e.g., watch activeRoute).
    this.loadForm()
  }
}
</script>
<style lang="scss" scoped>
@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/_mixins";

.load-failed {
  color: map-get($theme-colors, danger);
  svg {
    margin-right: 5px;
  }
}

.reload {
  margin-top: 10px;
}

.footer-buttons > button {
  margin: 10px;
}

.singleton-form {
  // position relative so that its div child can be masked
  // with absolute position.
  position: relative;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: .25rem;

    > * {
      margin-left: .25rem;
    }
  }
}
</style>
