export default function () {
  return {
    version: import.meta.env.VITE_BUILD_VERSION,
    development: import.meta.env.VITE_DEVELOPMENT === 'true',
    legacyMinify: import.meta.env.VITE_LEGACY_MINIFY === 'true',
    staticPath: import.meta.env.VITE_LEGACY_URL,
    auditServer: import.meta.env.VITE_AUDIT_SERVER,
    billingServer: import.meta.env.VITE_BILLING_SERVER,
    loginServer: import.meta.env.VITE_LOGIN_SERVER,
    messagingServer: import.meta.env.VITE_MESSAGING_SERVER,
    notificationServer: import.meta.env.VITE_NOTIFICATION_SERVER,
    payrollServer: import.meta.env.VITE_PAYROLL_SERVER,
    restServer: import.meta.env.VITE_REST_SERVER,
    wwwServer: import.meta.env.VITE_WWW_SERVER,
    firebaseApiKey: import.meta.env.VITE_FIREBASE_API_KEY,
    firebaseAuthDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
    firebaseAppId: import.meta.env.VITE_FIREBASE_APP_ID,
    firebaseMeasurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
    firebaseMessagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
    firebaseVapidKey: import.meta.env.VITE_FIREBASE_VAPID_KEY,
    googleApiKey: import.meta.env.VITE_GOOGLE_API_KEY,
    projectId: import.meta.env.VITE_PROJECT_ID,
    stripePublishableKey: import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY,
    bootstrap: {
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200
    }
  }
}
