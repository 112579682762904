<template>
  <div class="user-credential-form">
    <template v-if="isPolicyCredential">
      <form-group label="Name" disabled>
        <b-form-input
          v-model="name"
          type="text"
        />
      </form-group>
      <form-group>
        <b-form-checkbox v-model="value._hasUserCredential">
          User has credential?
        </b-form-checkbox>
      </form-group>
    </template>
    <template v-else-if="value._hasUserCredential">
      <!-- isAdHocCredential -->
      <form-group
        :validator="v$.adHocMode"
        :disabled="userCredentialId"
      >
        <template #label>
          {{ fieldLabels.adHocMode }}
          <help-text-icon>
            You can create an an Ad Hoc Credential with or without a formal Credential Type.
            Using a Credential Type allows you to filter reports by Credential Type.
            Once the credential is saved, you can not change the mode or type.
            You can delete a credential, and create a new one.
          </help-text-icon>
        </template>
        <template #default="slotProps">
          <b-form-radio-group v-model="adHocMode"
            v-bind="slotProps"
          >
            <b-form-radio value="name">Name</b-form-radio>
            <b-form-radio value="credentialType">Credential type</b-form-radio>
          </b-form-radio-group>
        </template>
      </form-group>

      <form-group v-if="adHocMode === 'name'" key="ad-hoc-name"
        :validator="v$.value.userCredential.name"
        :disabled="isPolicyCredential || adHocMode !== 'name'"
      >
        <template #default="slotProps">
          <b-form-input
            v-bind="slotProps"
            v-model="value.userCredential.name"
            type="text"
            :required="true"
            trim
          />
        </template>
      </form-group>

      <form-group v-else-if="adHocMode === 'credentialType'" key="ad-hoc-credential-type"
        :validator="v$.value.userCredential.credentialType"
        :disabled="userCredentialId"
      >
        <template #default="slotProps">
          <key-multiselect
            v-bind="slotProps"
            v-model="value.userCredential.credentialType"
            label="name"
            track-by="id"
            select-label=""
            deselect-label=""
            class="credential-type"
            :options="sortedCredentialTypes">
          </key-multiselect>
        </template>
      </form-group>

      <hr>

    </template>

    <template v-if="value._hasUserCredential">

      <form-group>
        <b-form-checkbox v-model="value.userCredential.needsApproval">
          Needs approval
          <help-text-icon>
            A credential updated by a worker in the mobile will automatically be marked as needing approval.
          </help-text-icon>
        </b-form-checkbox>
      </form-group>

      <form-group label="Issued ID" :validator="v$.value.userCredential.issuedId">
        <template #default="slotProps">
          <b-form-input
            v-bind="slotProps"
            v-model="value.userCredential.issuedId"
            trim
            type="text"
          />
        </template>
      </form-group>
      <form-group :validator="v$.value.userCredential.issueDate">
        <template #label>
           {{ fieldLabels.issueDate }}
        </template>
        <template #default="slotProps">
          <date-picker
            v-bind="slotProps"
            v-model="value.userCredential.issueDate"
            :insideBootstrapOverlay="true"
          />
        </template>
      </form-group>
      <form-group :validator="v$.value.userCredential.expireDate">
        <template #label>
           {{ fieldLabels.expireDate }}
        </template>
        <template #default="slotProps">
          <date-picker
            v-bind="slotProps"
            v-model="value.userCredential.expireDate"
            :insideBootstrapOverlay="true"
          />
        </template>
      </form-group>
    <hr>
      <div class="documents">
        <div class="title">Documents</div>
        <b-alert v-if="!v$.value.documents.maxDocumentsPerUser" show variant="danger" class="max-documents-warning">
          <font-awesome-icon icon="triangle-exclamation" />
          A user may not have more than {{ maxDocumentsPerUser }} total documents.
        </b-alert>
        <worker-documents
          :orgUserId="orgUserId"
          :isActiveWorker="true"
          :showing="showing"
          :hasDocuments="hasDocuments"
          :documents="documents"
          :isFormOpen="showing"
          attachToType="CREDENTIAL"
          :attachToId="userCredentialId"
          :useForm="false"
          stacked
        />
      </div>
    </template>
    <template v-else-if="hasDocuments">
      <b-alert show variant="warning" class="delete-documents-warning">
        <font-awesome-icon icon="triangle-exclamation" />
        The documents associated with this credential will be deleted upon saving.
      </b-alert>
    </template>
  </div>
</template>
<script>
import _ from 'lodash'
import { useVuelidate } from '@vuelidate/core'
import { helpers, maxLength, required, requiredIf } from '@vuelidate/validators'
import DatePicker from '@/components/DatePicker.vue'
import { dateTimeMaxValue } from '@/utils/validators'
import { mapGetters, mapState } from 'vuex'
import WorkerDocuments from '../WorkerDocuments.vue'
import HelpTextIcon from '@/components/HelpTextIcon.vue'
import KeyMultiselect from '@/components/KeyMultiselect.vue'

export default {
  name: 'UserCredentialForm',
  setup () {
    return { v$: useVuelidate({ $scope: false, $stopPropagation: true }) }
  },
  components: {
    DatePicker,
    HelpTextIcon,
    KeyMultiselect,
    WorkerDocuments
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    orgUserId: Number,
    isPolicyCredential: Boolean,
    credentialTypesInUse: Array,
    documentCount: Number,
    showing: Boolean
  },
  data () {
    return {
      fieldLabels: {
        adHocMode: 'Mode',
        issueDate: 'Issued date',
        expireDate: 'Expire date'
      },
      adHocMode: 'name',
      savedCredentialDocumentCount: 0
    }
  },
  computed: {
    ...mapState(['maxDocumentFileSize', 'maxDocumentsPerUser']),
    ...mapGetters('formatPreferences', ['formatNaiveDate']),
    ...mapGetters({
      credentialTypes: 'credentialTypes/sortedItems'
    }),
    name () {
      return _.get(this.value, 'credentialType.name')
    },
    hasDocuments () {
      return _.get(this.value, 'userCredential.hasDocument') || false
    },
    userCredentialId () {
      return _.get(this.value, 'userCredential.id', null)
    },
    documents () {
      return _.get(this.value, 'documents')
    },
    isAdHocCredential () {
      return !this.isPolicyCredential
    },
    formInvalid () {
      return this.v$.$invalid
    },
    sortedCredentialTypes () {
      return this.credentialTypes('name').filter(item =>
        item.id === _.get(this.value.userCredential, 'credentialType') ||
        (
          item.active && !this.credentialTypesInUse.includes(item.id)
        )
      )
    },
    netDocumentCount () {
      return (this.documents || []).length - this.savedCredentialDocumentCount
    },
    hasNewDocument () {
      return (this.documents || []).some(document => !document.id)
    }
  },
  watch: {
    formInvalid: {
      handler (formInvalid) {
        this.$emit('invalid-changed', formInvalid)
      },
      immediate: true
    },
    isAdHocCredential (isAdHocCredential) {
      if (isAdHocCredential) this.$store.dispatch('credentialTypes/load')
    },
    'value.userCredential.credentialType' (credentialTypeId) {
      const credentialType = this.sortedCredentialTypes.find(ct => ct.id === credentialTypeId)
      this.value.credentialType = credentialType
      if (credentialType) this.value.userCredential.name = credentialType.name
    },
    value (value) {
      if (this.isAdHocCredential && value.userCredential) {
        if (value.userCredential.id) {
          this.adHocMode = value.userCredential.credentialType ? 'credentialType' : 'name'
        } else {
          // default
          this.adHocMode = this.sortedCredentialTypes.length > 1 ? 'credentialType' : 'name'
        }
      }

      this.savedCredentialDocumentCount = value && value.documents ? value.documents.length : 0
    },
    adHocMode (adHocMode) {
      if (adHocMode === 'name') {
        this.value.userCredential.credentialType = null
      }
    },
  },
  validations () {
    return {
      adHocMode: {
        required
      },
      value: {
        userCredential: {
          name: {
            required: requiredIf(() => this.isAdHocCredential && this.adHocMode === 'name'),
            maxLength: maxLength(50)
          },
          credentialType: {
            required: requiredIf(() => this.isPolicyCredential || this.adHocMode === 'credentialType'),
            // Validate that only one user credential per type can be created.
            unique: helpers.withMessage(
              'There may only be one credential per type.',
              credentialType => {
                if (this.isPolicyCredential || _.get(this.value, 'userCredential.id') || !credentialType || this.adHocMode !== 'credentialType') return true
                return !this.credentialTypesInUse.includes(credentialType)
              }
            )
          },
          issuedId: {
            maxLength: maxLength(50)
          },
          issueDate: {
            maxValue: dateTimeMaxValue(_.get(this.value.userCredential, 'expireDate'), max => this.formatNaiveDate(max))
          },
          expireDate: {

          }
        },
        documents: {
          // Don't show max documents error if there are no new documents, e.g., backend somehow got more documents than allowed.
          maxDocumentsPerUser: () => !this.hasNewDocument || (this.documentCount + this.netDocumentCount <= this.maxDocumentsPerUser),
          // WorkerDocuments will display the error for the following validation.
          maxDocumentFileSize: () => !_.isArray(this.documents) || this.documents.every(document => document.size <= this.maxDocumentFileSize)
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables';

.user-credential-form {

  .documents {
    .title {
      margin-bottom: 15px;
      text-decoration: underline;
      font-size: 1rem;
    }
  }
  .max-documents-warning, .delete-documents-warning {
    svg {
      margin-right: 5px;
    }
  }

  :deep(.worker-documents) {
    td.action {
      margin-top: 0;
      padding-top: 25px;
    }
  }
}
</style>
