<template>
  <div :class="['time-picker', 'input-group', size ? `time-picker-${size}` : null, { 'picker-showing': pickerShowing }]">
    <b-form-input
      :modelValue="inputValue"
      :disabled="disabled"
      :size="size"
      ref="input" />
    <font-awesome-icon :icon="['far', 'clock']" :class="['clock', size]" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
// TODO: Eventually we're going to need a jquery-less solution.
import $ from 'jquery'
import 'timepicker'

export default {
  name: 'TimePicker',
  data () {
    return {
      pickerShowing: false
    }
  },
  components: {
  },
  inheritAttrs: false,
  props: {
    modelValue: String,
    step: {
      type: Number,
      default: 30
    },
    disabled: Boolean,
    size: String
  },
  emits: ['update:modelValue'],
  computed: {
    ...mapGetters('formatPreferences', ['modelToFormattedTime', 'formattedToModelTime', 'phpTimeFormat']),
    inputValue: {
      get () {
        return this.modelToFormattedTime(this.modelValue)
      },
      set (v) {
        this.$emit('update:modelValue', this.formattedToModelTime(v))
      }
    }
  },
  mounted () {
    $(this.$refs.input.$el).timepicker({
      // phpTimeFormat is the display format, based on user preference.
      // But we need to convert to/from iso-8601 format for the model,
      // wihch is done in the inputValue getter/setter above.
      timeFormat: this.phpTimeFormat,
      step: this.step,
      className: this.size ? `size-${this.size}` : null
    })
    $(this.$refs.input.$el).on('changeTime', () => { this.inputValue = $(this.$refs.input.$el).val() })
    $(this.$refs.input.$el).on('showTimepicker', () => { this.pickerShowing = true })
    $(this.$refs.input.$el).on('hideTimepicker', () => { this.pickerShowing = false })
  },
  beforeDestroy () {
    $(this.$refs.input.$el).off('changeTime')
    $(this.$refs.input.$el).off('showTimepicker')
    $(this.$refs.input.$el).off('hideTimepicker')
    $(this.$refs.input.$el).timepicker('remove')
  }
}
</script>
<style lang="scss" scoped>
.input-group {
  width: 7rem;

  :deep(.clock) {
    position: relative;
    top: .7rem;
    right: 25px;
    font-size: 1rem;
    color: #bbb;
    pointer-events: none; // don't steal clicks away from input
    // since we're doing relative positioning, remove extra width that
    // icon takes in normal document flow, so that the overall component
    // does not use up extra unnecessary space.
    margin-right: -1rem;
  }

  &.picker-showing :deep(.clock) {
    z-index: 3; // so that icon is still visibile when input has focus
  }
}
</style>
<style lang="css">
@import "timepicker/jquery.timepicker.min.css";
</style>
<style lang="scss">
.ui-timepicker-wrapper {
  max-height: 175px;
  width: 7rem; // match width of input group

  &.size-sm {
    width: 6.5rem;
  }
}

.ui-timepicker-list li {
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5rem;
}
</style>
<style lang="scss" scoped>

.time-picker-sm {
  width: 6.5rem;
  svg {
    top: .5rem !important;
  }
}


</style>
