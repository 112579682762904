<template>
  <simple-form-modal v-slot="slotProps"
    v-bind="$attrs"
    :resourceName="resourceName"
    :auditResourceName="auditResourceName"
    :orchestrator="orchestrator"
    :newItemFactory="newItemFactory"
    :enableDelete="false"
    @saved="$emit('saved', $event)"
    @close="$emit('close', $event)"
  >
    <rounding-rule-form v-bind="slotProps" />
  </simple-form-modal>
</template>
<script>
import RoundingRuleForm from './RoundingRuleForm.vue'
import SimpleFormModal from '@/components/SimpleFormModal.vue'

export default {
  name: 'RoundingRuleFormModal',
  inheritAttrs: false,
  components: {
    RoundingRuleForm,
    SimpleFormModal
  },
  data () {
    return {
      auditResourceName: 'ROUNDINGRULE',
      orchestrator: 'roundingRules',
      resourceName: 'Rounding Rule',
      // TODO: import new item factory
      newItemFactory: () => ({})
    }
  }
}
</script>
