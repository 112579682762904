<template>
  <div role="tablist">
    <b-card no-body class="mb-1">
      <b-card-body>
        <b-container>
          <b-row>
            <b-col cols="12" sm="6">
              <form-group :validator="v$.form.name" :label="fieldLabels.name">
                <template #default="slotProps">
                  <b-form-input
                    v-bind="slotProps"
                    v-model.trim="form.name"
                    type="text"
                  />
                </template>
              </form-group>
            </b-col>
            <b-col cols="12" sm="6">
              <form-group>
                <b-form-checkbox v-model="form.active">
                  Active
                </b-form-checkbox>
              </form-group>
            </b-col>
            <b-col cols="12" sm="6">
              <form-group label="Type" :validator="v$.form.type">
                <template #default="slotProps">
                  <b-form-radio-group
                    v-model="form.type"
                    v-bind="slotProps"
                    :disabled="!!lockLabelType"
                  >
                    <b-form-radio value="user">User</b-form-radio>
                    <b-form-radio value="work">
                      Work
                      <help-text-icon>
                        A <code>User</code> label may be applied to a user.
                        For example, if you hire subcontractors, you may want to label users by subcontracting firm.
                        You can also filter time cards for users with a specific label.
                        <br><br>
                        A <code>Work</code> label is specifically applied to work time, such as labelling jobs or shifts.
                        Thus, a single user may have punches with different labels.
                      </help-text-icon>
                    </b-form-radio>
                  </b-form-radio-group>
                </template>
              </form-group>
            </b-col>
          </b-row>
        </b-container>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import Accordion from '@/mixins/Accordion'
import HelpTextIcon from '@/components/HelpTextIcon.vue'
import _ from 'lodash'
import { required } from '@vuelidate/validators'
import DetailBasedForm from '@/mixins/DetailBasedForm'
import { useVuelidate } from '@vuelidate/core'

const NewItem = () => ({
  active: true,
  type: null
})

export default {
  name: 'LabelForm',
  setup () {
    return { v$: useVuelidate() }
  },
  inheritAttrs: false,
  mixins: [
    Accordion,
    DetailBasedForm
  ],
  components: {
    HelpTextIcon
  },
  props: {
    lockLabelType: String
  },
  data () {
    return {
      form: NewItem(),
      fieldLabels: {
        name: 'Name'
      }
    }
  },
  computed: {
    formInvalid () {
      return this.v$.form?.$invalid ?? true
    },
    invalidFields () {
      return Object.keys(this.v$.form.$params)
        .filter(fieldName => this.v$.form[fieldName].$invalid)
    }
  },
  watch: {
    originalData: {
      handler (newValue) {
        if (_.isEmpty(newValue)) {
          this.formDataChanged(Object.assign(NewItem(), { type: this.lockLabelType || null }))
        } else {
          this.formDataChanged(_.cloneDeep(newValue))
        }
      },
      immediate: true
    },
    formData (newValue) {
      this.form = newValue
    },
    formInvalid: {
      handler (value) {
        this.formInvalidChanged(value)
      },
      immediate: true
    }
  },
  validations () {
    return {
      form: {
        name: {
          required
        },
        type: {
          required
        }
      }
    }
  }
}
</script>
