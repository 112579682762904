<template>
  <div class="face-mode-selector">
    <form-group :validator="v$.faceMode"  label="Face mode">
      <template #default="slotProps">
        <b-form-radio-group
          v-bind="slotProps"
          v-model="faceMode"
        >
          <b-form-radio value="off">Off</b-form-radio>
          <b-form-radio value="detect">Detect any human face</b-form-radio>
          <b-form-radio value="recognize">Recognize exact worker face</b-form-radio>
        </b-form-radio-group>
      </template>
      </form-group>
    <b-form-checkbox
      v-if="faceMode === 'recognize'"
      v-model="identifyFace"
      class="form-group identify-face"
    >
      Try to identify worker face without PIN
      <help-text-icon>
        If checked, Fareclock will try to use the face to identify who the worker is.
        If not checked, Fareclock will use the face as a second factor to verify that the person
        standing in front of the camera is genuine.
        If the time clock is offline or has a slow internet connection, or if the person can not be identified only by face,
        then Fareclock will always prompt for PIN, in addition to the face.
      </help-text-icon>
    </b-form-checkbox>
  </div>
</template>
<script>
import HelpTextIcon from '@/components/HelpTextIcon.vue'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default {
  name: 'FaceModeSelector',
  setup () {
    return {
      v$: useVuelidate({ $scope: false, $stopPropagation: true })
    }
  },
  components: {
    HelpTextIcon
  },
  inheritAttrs: false,
  props: ['modelValue'],
  emits: ['update:modelValue'],
  computed: {
    faceMode: {
      get () {
        return ['verify', 'identify'].includes(this.modelValue) ? 'recognize' : this.modelValue
      },
      set (faceMode) {
        if (faceMode === 'recognize') {
          this.emitValue(this.identifyFace ? 'identify' : 'verify')
        } else {
          this.emitValue(faceMode)
        }
      }
    },
    identifyFace: {
      get () {
        return this.modelValue === 'identify'
      },
      set (identifyFace) {
        this.emitValue(identifyFace ? 'identify' : 'verify')
      }
    }
  },
  methods: {
    emitValue (value) {
      this.$emit('update:modelValue', value)
    }
  },
  validations () {
    return {
      faceMode: {
        required
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.identify-face {
  margin-left: 50px;
}
</style>
