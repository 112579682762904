<template>
  <panel title="No Access">
      <font-awesome-icon icon="triangle-exclamation" aria-hidden="true" />
      You are not granted permission to access any Console features.
      You have been only been granted permission set up the Fareclock time clock app on mobile devices.
  </panel>
</template>
<script>
import Panel from '@/components/Panel.vue'

export default {
  name: 'NoConsoleAccess',
  components: {
    Panel
  }
}
</script>
