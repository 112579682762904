import { mapGetters } from 'vuex'
import { forceArray } from '@/utils/misc'
import { payClassesAreIntercompatible } from '@/views/settings/payroll/util'
import FormatPrefsModal from '@/components/report/FormatPrefsModal.vue'
import { getCustomFieldValue, getSupplementalUserCustomFieldValue, useCustomFieldPivotValue } from '@/utils/customFields'

export default {
  data () {
    return {
      serverFiltersAreDirty: true
    }
  },
  computed: {
    ...mapGetters(['payrollEnabled']),
    ...mapGetters('payClasses', {
      payClassesById: 'itemsById'
    }),
    // serviceParams should be overriden by mixing report component
    serviceParams () {
      return {}
    },
    serverFilters () {
      return _.omit(this.serviceParams, ['compare', 'cursor', 'extraColumns', 'group', 'groupPayrollId', 'reportKey'])
    },
    referencePayClass () {
      const payClasses = forceArray(this.config.payClass)
        .flatMap(payClassId => this.payClassesById[payClassId] ? [this.payClassesById[payClassId]] : [])
      const referencePayClass = payClasses.length > 0 && payClassesAreIntercompatible(payClasses)
        ? payClasses[0] : null
      return referencePayClass
    }
  },
  watch: {
    serverFilters (newValue, oldValue) {
      if (!_.isEqual(newValue, oldValue)) {
        this.serverFiltersAreDirty = true
      }
    },
  },
  methods: {
    openFormatPrefs () {
      this.showModal({
        component: FormatPrefsModal,
        props: {
          value: this.config.formatPrefs
        }
      })
    },
    enrichItemWithSupplementalUserCustomFields (item, workerId, supplementalData, isPivotReport) {
      for (const customField of this.supplementalUserCustomFields) {
        const value = getSupplementalUserCustomFieldValue(customField, workerId, supplementalData)
        item[`customField${customField.id}`] = isPivotReport ? useCustomFieldPivotValue(value, customField) : value
      }
      return item
    },
    enrichItemWithCustomFields (item, supplementalData, appliesTo, ordinal, isPivotReport) {
      for (const customField of this.$store.getters[`customFields/${appliesTo}CustomFields`]) {
        const value = getCustomFieldValue(customField, item[`customFieldValues${ordinal || ''}`], supplementalData)
        item[`customField${customField.id}${ordinal || ''}`] = isPivotReport ? useCustomFieldPivotValue(value, customField) : value
      }
      return item
    },
    enrichItemWithPunchCustomFields (item, supplementalData, ordinal, isPivotReport) {
      return this.enrichItemWithCustomFields(item, supplementalData, 'punch', ordinal, isPivotReport)
    },
    enrichItemWithShiftCustomFields (item, supplementalData) {
      return this.enrichItemWithCustomFields(item, supplementalData, 'shift')
    },
    // TODO: These functions potentially interfere with the AgGridColumnHelpers mixin implementation.
    // TODO: We should rename them.
    getSupplementalUserCustomFieldColumns () {
      return this.getCustomFieldColumns('supplementalUser')
    },
    getPunchCustomFieldColumns (ordinal, headerPrefix) {
      return this.getCustomFieldColumns('punch', ordinal, headerPrefix)
    },
    getShiftCustomFieldColumns () {
      return this.getCustomFieldColumns('shift')
    },
    getCustomFieldColumns (appliesTo, ordinal, headerPrefix) {
      if (this.$store.getters.hasCustomFieldFeature) {
        this.$store.dispatch('customFields/load')
      }

      return this.$store.getters[`customFields/${appliesTo}CustomFields`].map(customField => ({
        columnDef: {
          field: `customField${customField.id}${ordinal || ''}`,
          headerName: customField.name,
          width: 125,
          hide: true,
          type: customField.type === 'number' ? 'numericColumn' : undefined,
          cellDataType: customField.type === 'number' ? 'number' : undefined,
          aggFunc: customField.type === 'number' ? "sum" : undefined
          // TODO: Should we add a decimal format preference to number custom field?
          // TODO: Reimplement for Excel?
          // excelNumberFormat: customField.type === 'number' ? '0.00' : undefined
        },
        available: !customField.sensitive || this.$store.getters.canAccessEmployeeSensitive,
        columnMenuHeaderName: `${headerPrefix || ''}${customField.name}`
      }))
    },
    formatPayrollIdValue (value, payrollId, groupPayrollId) {
      if (groupPayrollId && value && payrollId) {
        return groupPayrollId === 'prepend'
          ? `${payrollId} : ${value}`
          : `${value} : ${payrollId}`
      } else {
        return value
      }
    },
    // createPayrollIdValueFormatter is used directly by pivot report as a valueGetter so it gets sorted.
    createPayrollIdValueFormatter (payrollIdField) {
      // TODO: The group header row is reactive on the groupPayrollId inside the inner function,
      // TODO: whereas the group aggregate footer row is reactive on the groupPayrollId in the
      // TODO: outer function. Why?
      return params => this.formatPayrollIdValue(
        params.value || params.data?.[params?.colDef?.field],
        params.data?.[payrollIdField],
        this.config.groupPayrollId
      )
    },
    createPayrollIdGroupRowValueFormatter (payrollIdField) {
      const valueFormatter = this.createPayrollIdValueFormatter(payrollIdField)
      return params => {
        const value = params.colDef.valueFormatter?.(params) ?? params.value
        return valueFormatter({ value, ...params })
      }
    },
    createPayrollIdValueComparator (payrollIdField) {
      const formatter = this.createPayrollIdValueFormatter(payrollIdField)
      return (valueA, valueB, nodeA, nodeB, isDescending) => {
        const a = formatter({ value: valueA, data: nodeA.data || nodeA.aggData, colDef: nodeA.colDef})
        const b = formatter({ value: valueB, data: nodeB.data || nodeB.aggData, colDef: nodeB.colDef})
        const order = (a?.toLowerCase?.() || '').localeCompare(b?.toLowerCase?.() || '')
        return isDescending ? -order : order
      }
    },
    getCustomFieldPivotAggregates (appliesTo) {
      if (this.$store.getters.hasCustomFieldFeature) {
        this.$store.dispatch('customFields/load')
      }
      return this.$store.getters[`customFields/${appliesTo}CustomFields`]
        .filter(customField => customField.type === 'number')
        .map(customField => ({
          columnDef: {
            field: `customField${customField.id}`,
            headerName: customField.name,
            valueFormatter: value => _.isNumber(params.value) ? params.value : '0',
            aggFunc: 'sum',
            hide: true
            // TODO: excelNumberFormat?
          }
        }))
    },
    getSupplementalUserCustomFieldColumns () {
      return this.getCustomFieldColumns('supplementalUser')
    },
    getSupplementalUserCustomFieldPivotGroups () {
      return this.getCustomFieldPivotGroups('supplementalUser')
    },
    getCustomFieldPivotGroups (appliesTo) {
      if (this.$store.getters.hasCustomFieldFeature) {
        this.$store.dispatch('customFields/load')
      }

      return this.$store.getters[`customFields/${appliesTo}CustomFields`]
        .filter(customField => ['bool', 'choice'].includes(customField.type))
        .map(customField => ({
          columnDef: {
            field: `customField${customField.id}`,
            headerName: customField.name
          }
        }))
    },
    getLastGroupCountColumn (groupAndPivotColumns) {
      // Used on pivot reports
      const lastGroupBy = _.last(forceArray(this.config.groupBy))
      const lastGroupCaption = groupAndPivotColumns.find(col => col.columnDef.field === lastGroupBy)?.columnDef?.headerName ?? 'Group'
      return {
        columnDef: {
          field: 'count',
          headerName: lastGroupCaption ? `${lastGroupCaption} Count` : 'Count',
          type: 'numericColumn',
          cellDataType: 'number',
          aggFunc: params => {
            // console.log('count', params)
            if (params.values.length === 0) return 0
            return params.values[0] === undefined
              ? 1
              : params.values.reduce((a, b) => a + b, 0)
          },
          valueFormatter: params => {
            return params.node.field === lastGroupBy ? '' : params.value
          },
          width: 125,
          hide: true
        },
        available: !!lastGroupBy
      }
    }
  }
}
