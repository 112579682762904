import { RRule } from 'rrule'
import _ from 'lodash'
import moment from 'moment'
import { modelDateFormat } from './misc'

// The bynweekday option in RRule contains weekday as value
// [0-6] corresponding to [MO-SU]. That set of numeric values
// is the default weekday order, when wkst is not explicitly set.
export const rruleWeekDays = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU']

// Real order of days in the week
export const trueWeekDays = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA']

export const dayOptions = trueWeekDays
  .map((value, i) => ({
    value,
    minLabel: moment.weekdaysMin()[i],
    shortLabel: moment.weekdaysShort()[i],
    longLabel: moment.weekdays()[i]
  }))

export const weekOptions = [
  { value: 1, label: '1st' },
  { value: 2, label: '2nd' },
  { value: 3, label: '3rd' },
  { value: 4, label: '4th' },
  { value: -1, label: 'Last' }
]

export const monthOptions = _.range(0, 12)
  .map(i => ({
    value: i + 1,
    label: moment([2000, i]).format('MMMM')
  }))

export function ruleDescription (ruleString) {
  const rule = RRule.fromString(ruleString)
  return _.startCase(rule.toText())
}

export function rruleDescriptionWithTime (ruleString) {
  const rule = RRule.fromString(ruleString)
  let description = _.startCase(rule.toText())

  const hour = _.get(rule.options, 'byhour.0')
  const minute = _.get(rule.options, 'byminute.0')
  if (Number.isInteger(hour) && Number.isInteger(minute)) {
    // TODO: time format preference
    description += ' @ ' + moment().hours(hour).minutes(minute).format('hh:mm A')
  }

  return description
}


// Use UTC date to avoid unexpected timezone offsets being applied:
// https://github.com/jakubroztocil/rrule#timezone-support
export function momentToRRuleDate (m) {
  //return m ? new Date(Date.UTC(m.year(), m.month(), m.date())) : null
  return m ? moment(m).utc().add(m.utcOffset(), 'm').toDate() : null
}

export const MaxDate = '9999-12-31'

export function ruleStringUntilMoment (ruleString) {
  if (!ruleString) return null
  const options = RRule.parseString(ruleString)
  if (!options.until) return moment(MaxDate)
  // until is UTC, but we need to re-interpret it in local timezone.
  const until = moment.utc(options.until)
  return moment({ year: until.year(), month: until.month(), date: until.date() })
}

export function ruleWithDtStartMoment (ruleString, m) {
  if (!ruleString) return null
  const options = RRule.parseString(ruleString)
  options.dtstart = momentToRRuleDate(m)
  return stripTimezoneFromRuleString(RRule.optionsToString(options))
}

export function ruleWithDefaultUntilMaxDate (ruleString) {
  if (!ruleString) return null
  const options = RRule.parseString(ruleString)
  if (!options.until) options.until = momentToRRuleDate(moment(MaxDate))
  return stripTimezoneFromRuleString(RRule.optionsToString(options))
}

const RuleStringDateTimezoneMatchPattern = fieldName => new RegExp(String.raw`(\.*${fieldName}\d{8}T\d{6})\w*(\.*)`, 'i')

// The backend works better without timezone in the rrule, whereas the frontend works better with UTC. So we'll need to strip off
// timezone when sending to the backend.
export function stripTimezoneFromRuleString (ruleString) {
  if (!ruleString) return null
  const updated = ruleString
    .replace(RuleStringDateTimezoneMatchPattern('DTSTART:'), '$1$2')
    .replace(RuleStringDateTimezoneMatchPattern('UNTIL='), '$1$2')
  return updated
}
