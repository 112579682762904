<template>
  <key-multiselect
    v-bind="$attrs"
    v-model="forwardValue"
    :multiple="multiple"
    label="name"
    track-by="id"
    select-label=""
    deselect-label=""
    resourceName="Pay Class"
    :options="sortedPayClasses"
  />
</template>
<script>
import KeyMultiselect from '@/components/KeyMultiselect.vue'
import { mapGetters } from 'vuex'
import { forceArray } from '@/utils/misc'

export default {
  name: 'PayClassSelect',
  inheritAttrs: false,
  components: {
    KeyMultiselect
  },
  props: {
    modelValue: [Number, Array],
    multiple: {
      type: Boolean,
      default: false
    },
    activeOnly: {
      type: Boolean,
      default: false
    },
    salaryOnly: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  computed: {
    ...mapGetters(['canEditOrgSettings']),
    ...mapGetters({
      payClasses: 'payClasses/sortedItems'
    }),
    sortedPayClasses () {
      const payClasses = this.payClasses('name')
      if (this.activeOnly) {
        const values = new Set(forceArray(this.modelValue))
        return payClasses
          .filter(payClass => payClass.active !== false || values.has(payClass.id))
          .filter(payClass => !this.salaryOnly || payClass.payMode === 'salary')
      } else return payClasses
    },
    forwardValue: {
      get () {
        return this.modelValue
      },
      set (v) {
        this.$emit('update:modelValue', v)
      }
    }
  },
  created () {
    this.$store.dispatch('payClasses/load')
  }
}
</script>
