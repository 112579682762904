import reusePromise from 'reuse-promise'

const importQrCode = reusePromise(
  () => import(/* webpackChunkName: "qrcode" */ 'qrcode'),
  { memoize: true }
)

export function toQrCodeDataURL (text) {
  return importQrCode()
    .then(QRCode => {
      return new Promise((resolve, reject) => {
        QRCode.toDataURL(text, { errorCorrectionLevel: 'H' },
          (error, url) => {
            if (error) reject(error)
            else resolve(url)
          }
        )
      })
    })
}
