import _ from 'lodash'
import moment from 'moment-timezone'
import store from '@/store'

function momentWithClearedSeconds (dt) {
  return dt ? moment(dt).seconds(0).milliseconds(0) : null
}

export function punchRawMinutes (punchData, shouldClearSeconds) {
  const inDt = shouldClearSeconds ? momentWithClearedSeconds(punchData.inDt) : punchData.inDt
  const outDt = shouldClearSeconds ? momentWithClearedSeconds(punchData.outDt) : punchData.outDt
  if (!inDt || !outDt) return 0
  const minutes = moment(outDt).diff(inDt, 'minutes', true)
  return minutes
}

export function punchPaidWorkedMinutes (punchData, shouldClearSeconds) {
  if (!store.getters.scheduleEnabled || !punchData.shiftCard) return punchRawMinutes(punchData, shouldClearSeconds)
  return _.sum(
    punchData.segments
      .filter(segment => segment.start && segment.end && segment.tags.includes('PAID') && !segment.tags.includes('NON_WORKED'))
      .map((segment, index, array) => {
        // May clear seconds on start of first segment and end of last segment.
        const start = shouldClearSeconds && index === 0 ? momentWithClearedSeconds(segment.start) : segment.start
        const end = shouldClearSeconds && index === array.length - 1 ? momentWithClearedSeconds(segment.end) : segment.end
        return moment(end).diff(start, 'minutes', true)
      })
  )
}
