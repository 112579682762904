<template>
  <div class="user-credentials">
    <div v-if="credentialPolicyId" class="policy-credentials">
      <div class="title">Policy Credentials</div>
      <master-detail-table
        ref="masterDetailTable"
        resourceName="Policy Credential"
        auditResource="USERCREDENTIAL"
        :crudService="policyCredentialCrudService"
        :fields="credentialFields"
        :parentKey="orgUserId"
        :formInvalid="policyCredentialFormInvalid"
        :autoLoad="true"
        :allowEdit="true"
        :allowDelete="false"
        :isFormOpen="isFormOpen"
        :getItemId="item => item.userCredential && item.userCredential.id"
        :getItemKey="item => item.credentialType.id"
        :itemsSortBy="[{ key: 'credentialType.name', order: 'asc' }]"
      >
        <template v-slot:cell(status)="data">
          <font-awesome-icon icon="circle" :class="['status-icon', statusIconClass(data.item)]" />
        </template>

        <template v-slot:cell(needsApproval)="data">
          <font-awesome-icon v-if="!!data.item.userCredential && data.item.userCredential.needsApproval" icon="check" />
        </template>

        <template #detail-form="{ value, showing }">
          <user-credential-form
            :value="value"
            :orgUserId="orgUserId"
            :isPolicyCredential="true"
            :showing="showing"
            :credentialTypesInUse="credentialTypesInUse"
            :documentCount="documentCount"
            @invalid-changed="policyCredentialFormInvalid = $event"
          />
        </template>
      </master-detail-table>
    </div>
    <div class="ad-hoc-credentials">
      <div class="title">Ad Hoc Credentials</div>
      <master-detail-table
        ref="masterDetailTable"
        resourceName="Ad Hoc Credential"
        auditResource="USERCREDENTIAL"
        :crudService="adHocCredentialCrudService"
        :fields="credentialFields"
        :parentKey="`${orgUserId}-${credentialPolicyId}`"
        :formInvalid="adHocCredentialFormInvalid"
        :autoLoad="true"
        :allowEdit="true"
        :allowDelete="true"
        :showNewItemButton="true"
        :isFormOpen="isFormOpen"
        :newItemFactory="newAdHocCredentialFactory"
        :getItemId="item => item.userCredential && item.userCredential.id"
        :itemsSortBy="[{ key: 'userCredential.name', order: 'asc' }]"
      >
        <template v-slot:cell(status)="data">
          <font-awesome-icon icon="circle" :class="['status-icon', statusIconClass(data.item)]" />
        </template>

        <template v-slot:cell(needsApproval)="data">
          <font-awesome-icon v-if="!!data.item.userCredential && data.item.userCredential.needsApproval" icon="check" />
        </template>

        <template #detail-form="{ value, showing }">
          <user-credential-form
            :value="value"
            :orgUserId="orgUserId"
            :isPolicyCredential="false"
            :showing="showing"
            :credentialTypesInUse="credentialTypesInUse"
            :documentCount="documentCount"
            @invalid-changed="adHocCredentialFormInvalid = $event"
          />
        </template>
      </master-detail-table>
    </div>
  </div>
</template>
<script>
import restClient from '@/services/clients/rest'
import MasterDetailTable from '@/components/MasterDetailTable.vue'
import UserCredentialForm from './UserCredentialForm.vue'
import { mapGetters } from 'vuex'
import _ from 'lodash'
import moment from 'moment-timezone'
import { extractErrorMessage, modelDateFormat } from '@/utils/misc'

export default {
  name: 'UserCredentials',
  props: {
    orgUserId: Number,
    credentialPolicyId: Number,
    hasCredentials: Boolean,
    isFormOpen: Boolean
  },
  components: {
    MasterDetailTable,
    UserCredentialForm,
  },
  data () {
    return {
      credentialPoliciesPromise: null,
      credentialTypesPromise: null,
      userCredentialsPromise: null,
      documentsPromise: null,
      credentialTypesInUse: [],
      documentCount: 0,
      credentialPolicy: null,
      policyCredentialFormInvalid: false,
      adHocCredentialFormInvalid: false,
      policyCredentialCrudService: {
        list: this.listPolicyCredentials,
        update: this.updatePolicyCredential,
      },
      adHocCredentialCrudService: {
        list: this.listAdHocCredentials,
        create: this.createAdHocCredential,
        update: this.updateAdHocCredential,
        delete: this.deleteAdHocCredential
      },
      credentialFields: [
        { key: 'status', label: '', tdClass: 'status-td'},
        {
          key: 'name',
          thStyle: 'width: 20rem',
          // For saved user credentials, we show the name. For policy credentials without a user credential,
          // we show the credential type name.
          formatter: (value, key, item) => _.get(item, 'userCredential.name') || _.get(item, 'credentialType.name')
        },
        {
          key: 'userCredential.expireDate',
          label: 'Expires',
          thStyle: 'width: 10rem',
          formatter: value => value ? this.formatNaiveDate(value) : ''
        },
        { key: 'needsApproval' },
        { key: 'action', label: ' ', class: 'action' }
      ]
    }
  },
  computed: {
    ...mapGetters('formatPreferences', ['formatNaiveDate'])
  },
  watch: {
    orgUserId () {
      this.load()
    },
    credentialPolicyId (credentialPolicyId) {
      this.load()
    },
    credentialPolicy () {
      this.calculateCredentialTypesInUse()
    },
    credentialTypesPromise () {
      this.calculateCredentialTypesInUse()
    },
    userCredentialsPromise () {
      this.calculateCredentialTypesInUse()
      if (this.userCredentialsPromise) {
        this.userCredentialsPromise.then(userCredentials =>
          this.$emit('user-credentials', userCredentials))
      }
    },
    documentsPromise (documentsPromise) {
      documentsPromise.then(documents => this.documentCount = documents.length)
    }
  },
  methods: {
    load () {
      // Hold onto loading promises so they can be used by master detail tables' crud services.
      this.credentialPoliciesPromise = this.orgUserId && this.credentialPolicyId
        ? this.$store.dispatch('credentialPolicies/load').then(data => data.results)
        : Promise.resolve([])
      this.credentialTypesPromise = this.$store.dispatch('credentialTypes/load').then(data => data.results)
      this.userCredentialsPromise = this.hasCredentials
        ? restClient.get(`employees/${this.orgUserId}/credentials`).then(response => response.data.results)
        : Promise.resolve([])
      // We'll just get all documents, and allow the list methods below to associate with matching credentials.
      this.documentsPromise = this.hasCredentials
        ? restClient.get(`employees/${this.orgUserId}/documents`, { params: { attachToType: 'CREDENTIAL' }}).then(response => response.data.results)
        : Promise.resolve([])

      this.credentialPoliciesPromise
        .then(credentialPolicies => {
          this.credentialPolicy = credentialPolicies.find(cp => cp.id === this.credentialPolicyId)
        })
    },
    listPolicyCredentials (params) {
      return Promise.all([
        this.credentialPoliciesPromise,
        this.credentialTypesPromise,
        this.userCredentialsPromise,
        this.documentsPromise
      ])
        .then(([credentialPolicies, credentialTypes, userCredentials, documents]) => {
          return credentialTypes
            .filter(credentialType => this.credentialPolicy.credentialTypes.includes(credentialType.id))
            .map(credentialType => {
              const userCredential = userCredentials.find(uc => uc.credentialType === credentialType.id) || {
                credentialType: credentialType.id,
                name: credentialType.name,
                orgUser: this.orgUserId,
              }
              return {
                credentialType,
                userCredential,
                documents: documents.filter(document => userCredential && document.attachToId === userCredential.id),
                _hasUserCredential: !!userCredential.id
              }
            })
        })
    },
    updatePolicyCredential (item, config) {
      const userCredential = item.userCredential

      if (!item._hasUserCredential) {
         if (_.get(userCredential, 'id')) {
            // Delete credential, and return cleared item.
            // Also get all promise-wrapped credentials, so we can update our local value and also check overall user credentialing validity.
            return Promise.all([this.userCredentialsPromise, restClient.delete(`employees/${this.orgUserId}/credentials/${userCredential.id}`)])
              .then(([userCredentials, response]) => {
                // When deleting, for sure the org user's credentialing is now invalid.
                if (this.credentialPolicy.credentialTypes.includes(userCredential.credentialType)) {
                  this.$emit('credentialing-validity-changed', false)
                }
                this.userCredentialsPromise = Promise.resolve(userCredentials.filter(cred => cred.id !== userCredential.id))
                return {
                  credentialType: item.credentialType,
                  userCredential: {
                    credentialType: item.credentialType.id,
                    name: item.credentialType.name,
                    orgUser: this.orgUserId
                  },
                  documents: [],
                  _hasUserCredential: false
                }
              })
         } else {
            // Not sure this case can happen, unless dirty check doesn't work.
            // Just return cleared item.
            // TODO: Make sure dirty check avoids this case.
            if (this.credentialPolicy.credentialTypes.includes(userCredential.credentialType)) {
              this.$emit('credentialing-validity-changed', false)
            }
            return {
                credentialType: item.credentialType,
                userCredential: {
                  credentialType: item.credentialType.id,
                  name: item.credentialType.name,
                  orgUser: this.orgUserId
                },
                documents: [],
                _hasUserCredential: false
              }
         }
      }

      return this.saveCredential(item, true)
    },
    listAdHocCredentials (params) {
      return Promise.all([
        this.credentialPoliciesPromise,
        this.credentialTypesPromise,
        this.userCredentialsPromise,
        this.documentsPromise
      ])
        .then(([credentialPolicies, credentialTypes, userCredentials, documents]) => {
          const credentialPolicy = this.credentialPolicyId && credentialPolicies.find(cp => cp.id === this.credentialPolicyId)
          return userCredentials
            .filter(userCredential => !credentialPolicy || !userCredential.credentialType || !credentialPolicy.credentialTypes.includes(userCredential.credentialType))
            .map(userCredential => {
              const credentialType = credentialTypes.find(ct => ct.id === userCredential.credentialType)
              return {
                credentialType,
                userCredential,
                documents: documents.filter(document => userCredential && document.attachToId === userCredential.id),
                _hasUserCredential: true
              }
            })
        })
    },
    createAdHocCredential (item, config) {
      return this.saveCredential(item, false)
    },
    updateAdHocCredential (item, config) {
      return this.saveCredential(item, false)
    },
    deleteAdHocCredential (itemId, itemIndex, item) {
      // Delete credential, and update our local promise-wrapped value.
      const userCredentialId = itemId
      return Promise.all([this.userCredentialsPromise, restClient.delete(`employees/${this.orgUserId}/credentials/${userCredentialId}`)])
        .then(([userCredentials, response]) => {
          this.userCredentialsPromise = Promise.resolve(userCredentials.filter(cred => cred.id !== userCredentialId))
        })
    },
    statusIconClass (item) {
      const isValid = _.get(item, 'userCredential.isValid') || false
      return isValid ? 'status-icon-valid' : 'status-icon-invalid'
    },
    newAdHocCredentialFactory () {
      return {
        credentialType: null,
        userCredential: {
          credentialType: null,
          name: null,
          orgUser: this.orgUserId
        },
        documents: [],
        _hasUserCredential: true
      }
    },
    calculateCredentialTypesInUse () {
      // Need union of policy credential types and ad hoc user credential types.
      this.userCredentialsPromise
        .then(userCredentials => {
          this.credentialTypesInUse = Array.from(new Set(
            userCredentials
              .filter(userCredential => userCredential.credentialType)
              .map(userCredential=> userCredential.credentialType)
              .concat( this.credentialPolicy ? this.credentialPolicy.credentialTypes : [])
          ))
        })
    },
    async saveCredential (item, isPolicyCredential) {
      const isNewUserCredential = !item.userCredential.id

      // Also get all promise-wrapped credentials, so we can update our local value and also check overall user credentialing validity.
      const userCredentials = await this.userCredentialsPromise

      // Save credential.
      // Skip saving user credential if not dirty, and go straight to saving documents.
      let savedCredential = isNewUserCredential ? null : userCredentials.find(uc => uc.id === item.userCredential.id)
      if (!_.isEqual(item.userCredential, savedCredential)) {
        const op = isNewUserCredential
          ? restClient.post(`employees/${this.orgUserId}/credentials`, item.userCredential)
          : restClient.put(`employees/${this.orgUserId}/credentials/${item.userCredential.id}`, item.userCredential)
        const response = await op
        savedCredential = response.data.results[0]
      }

      const userCredential = savedCredential
      const credentialType = item.credentialType

      // Update credentials promise. We'll still mutate the userCredentials object later.
      if (isNewUserCredential) {
        userCredentials.push(userCredential)
      } else {
        userCredentials[userCredentials.findIndex(cred => cred.id === userCredential.id)] = userCredential
      }
      this.userCredentialsPromise = Promise.resolve(userCredentials)

      // Update documents.
      item.documents.forEach(document => {
        document.attachToType = 'CREDENTIAL'
        document.attachToId = userCredential.id
      })

      const documents = await this.documentsPromise

      const savedCredentialDocuments = documents
        .filter(document => document.attachToId === userCredential.id)
      const otherDocuments = documents
        .filter(document => document.attachToId !== userCredential.id)

      const removedDocuments = savedCredentialDocuments
        .filter(document => !item.documents.some(d => d.id === document.id))
      const addedDocuments = item.documents.filter(document => !document.id)
      // Credential documents can't be edited, so we don't have to worry about submitting updates.

      // Make remote calls to delete and add documents.
      // Perform serially to avoid consistency issues, first delete, then add.
      // Handle partial failure and updating local state,
      // e.g., saved credential but not documents, saved some documents but not all, etc.

      // Keep list of latestCredentialDocuments that will be updated as items are saved, in order to handle
      // a failure in the middle.
      let latestCredentialDocuments = savedCredentialDocuments

      const updateDependencies = () => {
        this.documentsPromise = Promise.resolve(otherDocuments.concat(latestCredentialDocuments))

        // Update user credential validity based on document changes.
        userCredential.hasDocument = latestCredentialDocuments.length > 0
        userCredential.isValid = (
          (userCredential.hasDocument || !credentialType || !credentialType.documentRequired) &&
          (!userCredential.expireDate || moment.tz(this.timezone).format(modelDateFormat) <= userCredential.expireDate)
        )

        // Update overall org user credentialing validity based on credential and documents changes.
        if (latestCredentialDocuments.length > 0) {
          this.$emit('has-documents-changed', true)
        }
        // We never emit 'has-documents-changed' false, because maybe there are credential documents.
        // It doesn't really matter if it's incorrectly true.
        this.$emit('has-credentials-changed', true)

        if (isPolicyCredential && this.credentialPolicy.credentialTypes.includes(userCredential.credentialType)) {
          const userCredentialingValid = userCredentials.every(cred =>
            !this.credentialPolicy.credentialTypes.includes(cred.credentialType) ||
            cred.isValid
          )
          this.$emit('credentialing-validity-changed', userCredentialingValid)
        }
      }

      const handleError = (error, currentAddedIndex) => {
          updateDependencies()
          // Enrich displayed error message.
          const finalError = new Error(`The credential was saved, but not all documents were updated due to this error: ${extractErrorMessage(error)}`)
          // TODO: It would be nice to annotate the actual documents that were not saved.
          finalError._item = {
            credentialType,
            userCredential,
            documents: latestCredentialDocuments.concat(addedDocuments.slice(currentAddedIndex)),
            _hasUserCredential: true
          }
          throw finalError
      }

      for (const document of removedDocuments) {
        try {
          await restClient.delete(`employees/${this.orgUserId}/documents/${document.id}`)
        } catch (error) {
          handleError(error, 0)
        }
        latestCredentialDocuments = latestCredentialDocuments.filter(d => d.id !== document.id)
      }

      for (const [index, document] of addedDocuments.entries()) {
        const { file, ...data } = document
        const formData = new FormData()
        formData.append('data', JSON.stringify(data))
        formData.append('file', file)

        let newDocument
        try {
          newDocument = (await restClient.post(`employees/${this.orgUserId}/documents`, formData)).data.results[0]
        } catch (error) {
          handleError(error, index)
        }
        latestCredentialDocuments.push(newDocument)
      }

      updateDependencies()

      return {
        credentialType,
        userCredential,
        documents: latestCredentialDocuments,
        _hasUserCredential: true
      }
    }
  },
  beforeMount () {
    this.load()
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables';

.user-credentials {
  .policy-credentials, .ad-hoc-credentials {
    .title {
      margin-top: 15px;
      font-size: 1rem;
      text-decoration: underline;
    }

    :deep(.status-td) {
      width: 50px;

      .status-icon {
        &.status-icon-valid {
          color: $flat-ui-emerald;
        }
        &.status-icon-invalid {
          color: $flat-ui-alizarin;
        }
      }
    }
  }
  .ad-hoc-credentials {
    .title {
      margin-top: 25px;
    }
  }
}
</style>
