<template>
  <input-mask
    v-bind="$attrs"
    v-model="forwardValue"
    :mask="mask"
    masked
    class="form-control duration-input"
    :placeholder="placeholder"
  />
</template>
<script>
import _ from 'lodash'

// TODO: Allow editing string without retyping all of it.
export default {
  inheritAttrs: false,
  props: {
    modelValue: Number, // modelValue is ordinal, and unit is based on valueUnit
    valueUnit: {
      type: String,
      default: 'minute',
      validator: value => ['minute', 'second'].includes(value)
    },
    hourDigits: {
      value: Number,
      default: 2
    }
  },
  emits: ['update:modelValue'],
  data () {
    return {
      partialValue: null
    }
  },
  computed: {
    forwardValue: {
      get () {
        if (this.partialValue) return this.partialValue
        return Number.isInteger(this.totalMinutes) ? `${this.hours}:${this.minutes}` : ''
      },
      set (v) {
        this.handleInput(v)
      }
    },
    totalMinutes () {
      return _.isNumber(this.modelValue) ? Math.floor(this.modelValue / (this.valueUnit === 'minute' ? 1 : 60)) : null
    },
    hours () {
      if (!Number.isInteger(this.totalMinutes)) return null
      const hours = Math.floor(this.totalMinutes / 60)
      return this.timePartAsString(hours, this.hourDigits)
    },
    minutes () {
      if (!Number.isInteger(this.totalMinutes)) return null
      const minutes = this.totalMinutes % 60
      return this.timePartAsString(minutes, 2)
    },
    mask () {
      return `${'9'.repeat(this.hourDigits)}:99`
    },
    placeholder () {
      return `${'H'.repeat(this.hourDigits)}:MM`
    },
    partialOrValidRegex () {
      return new RegExp(`[\\d_]{${this.hourDigits}}:[\\d_]{2}`)
    },
    validRegex () {
      return new RegExp(`\\d{${this.hourDigits}}:\\d\\d`)
    }
  },
  methods: {
    timePartAsString (v, targetLength) {
      return v.toString().padStart(targetLength, '0')
    },
    handleInput (v) {
      let valueToEmit = null

      if (this.isPartialValue(v)) {
        this.partialValue = v
        valueToEmit = NaN
      }

      if (this.isValidValue(v)) {
        this.partialValue = null
        const parts = v.split(':').map(part => parseInt(part))
        const minutes = 60 * parts[0] + parts[1]
        valueToEmit = (this.valueUnit === 'minute' ? 1 : 60) * minutes
      }

      this.$emit('update:modelValue', valueToEmit)
    },
    isValidValue (v) {
      return this.validRegex.test(v)
    },
    isPartialValue (v) {
      return !!v && this.partialOrValidRegex.test(v) && !this.validRegex.test(v)
    }
  }
}
</script>
<style lang="scss" scoped>
  .duration-input {
    width: 6rem;
  }
</style>
