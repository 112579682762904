<template>
  <detail-form-modal v-slot="slotProps"
    resourceName="Shift Class"
    auditResourceName="SHIFTCLASS"
    :crudService="crudService"
    :itemId="itemId"
    dialog-class="shift-class-detail-form-modal-dialog"
    @close="$emit('close')"
    @saved="saved"
    @apply-grid-transaction="$emit('apply-grid-transaction', $event)"
  >
    <shift-class-form v-bind="slotProps" />
  </detail-form-modal>
</template>
<script>
import DetailFormModal from '@/components/DetailFormModal.vue'
import ShiftClassForm from './ShiftClassForm.vue'
import crudService from './services/ShiftClassService'

export default {
  name: 'ShiftClassFormModal',
  components: {
    DetailFormModal,
    ShiftClassForm
  },
  props: {
    itemId: Number
  },
  data () {
    return {
      crudService
    }
  },
  methods: {
    saved (item) {
      this.$store.commit('shiftClasses/create', item)
      this.$emit('saved', item)
    }
  }
}
</script>
<style lang="scss">
.shift-class-detail-form-modal-dialog {
  max-width: 800px !important;
}
</style>
