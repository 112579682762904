<template>
  <remote-multiselect
    v-bind="$attrs"
    v-model="forwardValue"
    :multiple="multiple"
    label="name"
    track-by="id"
    select-label=""
    deselect-label=""
    :serviceFetch="fetchItems"
    resourceName="Briefing Type"
    :showAddNewItem="canEditOrgSettings"
    @add-new-item="addNewItem"
  />
</template>
<script>
import RemoteMultiselect from '@/components/RemoteMultiselect.vue'
import BriefingTypeFormModal from './BriefingTypeFormModal.vue'
import BriefingTypeService from './BriefingTypeService'
import { mapGetters } from 'vuex'
import { useModalController } from 'bootstrap-vue-next'
import { computed, ref } from 'vue'

export default {
  name: 'BriefingTypeSelect',
  setup () {
    return {
      showModal: useModalController().show
    }
  },
  inheritAttrs: false,
  props: {
    modelValue: [Array, Number],
    multiple: Boolean
  },
  emits: ['item-added', 'update:modelValue'],
  computed: {
    ...mapGetters(['canEditOrgSettings']),
    forwardValue: {
      get () {
        return this.modelValue
      },
      set (v) {
        this.$emit('update:modelValue', v)
      }
    }
  },
  components: {
    RemoteMultiselect
  },
  methods: {
    fetchItems: BriefingTypeService.fetchForSelect,
    addNewItem () {
      const itemId = ref(null)
      this.showModal({
          component: BriefingTypeFormModal,
          props: computed(() => ({
            itemId: itemId.value,
            onSaved: item => {
              itemId.value = item.id
              this.$emit('update:modelValue', this.multiple ? (this.modelValue || []).concat(newValue) : newValue)
              this.$emit('item-added', item)
            }
          }))
        })
    }
  }
}
</script>
