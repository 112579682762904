<template>
  <remote-multiselect
    :modelValue="modelValue"
    label="name"
    track-by="id"
    :placeholder="placeholder || `Select a ${name}`"
    select-label=""
    deselect-label=""
    :serviceFetch="fetchItems"
    :multiple="multiple"
    :internalSearch="true"
    :limit="500"
    :disabled="disabled"
    @update:modelValue="$emit('update:modelValue', $event)"
    @fullValueChange="$emit('fullValueChange', $event)"
  />
</template>
<script>
import RemoteMultiselect from '@/components/RemoteMultiselect.vue'
import { mapGetters } from 'vuex'
import _ from 'lodash'
import crudServiceFactory from '@/views/settings/customfields/services/CustomFieldItemService'

export default {
  name: 'CustomFieldItemSelect',
  components: {
    RemoteMultiselect
  },
  props: {
    customField: Number,
    name: {
      type: String,
      default: 'value'
    },
    modelValue: [Number, Array],
    multiple: Boolean,
    placeholder: String,
    disabled: Boolean
  },
  emits: ['fullValueChange', 'update:modelValue'],
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters('formatPreferences', ['formatName'])
  },
  methods: {
    fetchItems (searchText, value, limit) {
      return crudServiceFactory(this.customField)
        .list()
        .then(data => _.get(data, 'results', []))
    }
  }
}
</script>
