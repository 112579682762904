import { createI18n } from 'vue-i18n'
import messages from '@/lang/en.json'

const DEFAULT_LOCALE = 'en'


export const i18n = createI18n({
  locale: DEFAULT_LOCALE, // set locale
  fallbackLocale: DEFAULT_LOCALE,
  messages: { en: messages } // set locale messages
})
