
export function openPunchFormModal (punchId, showModal) {
  return import(/* webpackChunkName: "punch-form-modal" */ './PunchFormModal.vue')
    .then(async module => {
      const PunchFormModal = module.default
      let saved = false
      await showModal({
        component: PunchFormModal,
        props: {
          itemId: punchId,
          onSaved: () => {
            saved = true
          },
          onDeleted: () => {
            saved = true
          }
        }
      })
      return saved
    })
}
