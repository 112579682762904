<template>
  <div class="advanced-info">
    <form-group label="Source" v-bind="formGroupConfig">
      <div class="source vertical-center-form-field-text">
        <b-button v-if="clockLog" @click="openClockLogFormModal(clockLog.id)" variant="link" style="padding:0">{{ source }}</b-button>
        <template v-else>{{ source }}</template>
        <id-icon :id="clockLog.id" v-if="clockLog" />
      </div>
    </form-group>
    <form-group label="Device" v-bind="formGroupConfig">
      <div class="vertical-center-form-field-text">
        <b-button v-if="canEditDevices && deviceId" @click="openDeviceFormModal(deviceId)" variant="link" style="padding:0">{{ deviceName }}</b-button>
        <template v-else>{{ deviceName }}</template>
        <id-icon :id="deviceId" v-if="deviceId" />
      </div>
    </form-group>
    <form-group label="Original Date/Time" v-bind="formGroupConfig">
      <div class="vertical-center-form-field-text">
        {{ origDt }}
      </div>
    </form-group>
  </div>
</template>
<script>
import IdIcon from '@/components/IdIcon.vue'
import { mapGetters } from 'vuex'
import { openClockLogFormModal } from '@/views/manage/clocklogs/openClockLogFormModal'
import { openDeviceFormModal } from '@/views/settings/devices/openDeviceFormModal'
import { useModalController } from 'bootstrap-vue-next'

export default {
  name: 'PunchAdvancedInfo',
  setup () {
    return {
      showModal: useModalController().show
    }
  },
  components: {
    IdIcon
  },
  props: {
    value: Object,
    action: {
      type: String,
      validator: () => value => ['in', 'out'].includes(value)
    },
    formGroupConfig: Object
  },
  computed: {
    ...mapGetters(['canEditDevices']),
    ...mapGetters('formatPreferences', ['formatDateTime']),
    clockLog () {
      return this.value[this.action + 'ClockLog']
    },
    source () {
      const origDt = this.value[this.action + 'Orig']
      if (!origDt) return ''
      const clockLog = this.clockLog
      if (!clockLog) return 'admin'
      if (clockLog.delegated) return 'manager'
      if (clockLog.offline) return 'offline'
      return 'online'
    },
    deviceId () {
      return this.value[this.action + 'Clock']
    },
    deviceName () {
      return this.value[this.action + 'ClockName']
    },
    origDt () {
      const origDt = this.value[this.action + 'Orig']
      return origDt ? this.formatDateTime(origDt, this.value.timezone) : ''
    }
  },
  methods: {
    openDeviceFormModal (deviceId) {
      openDeviceFormModal (deviceId, this.showModal)
    },
    openClockLogFormModal (clockLogId) {
      openClockLogFormModal (clockLogId, this.showModal)
    }
  }
}
</script>
<style lang="scss" scoped>
.advanced-info {

  .form-group {
    // tighten up the space, since there's no form fields
    margin-bottom: 0;
  }

  .source {
    text-transform: capitalize;
  }

  .vertical-center-form-field-text {
    padding-top: 5px;
  }
}
</style>
