<template>
  <b-container fluid class="grid-tools header not-printable">
    <b-row>
      <slot name="quick-filter" v-bind="{ applyFilter, disableControls }"  />
      <b-col cols="auto" v-if="searchEnabled" class="search-col">
        <span class="search-box">
          <font-awesome-icon icon="search" class="search-icon" />
          <b-form-input type="search"
                        ref="search"
                        size="sm"
                        :placeholder="`Search ${pluralResourceName}`"
                        :modelValue="searchText"
                        :disabled="disableControls"
                        :lazy="true"
                        @change="applySearch" />
        </span>
      </b-col>
      <b-col cols="auto" v-if="refreshEnabled" class="refresh-col">
        <b-btn :disabled="disableControls" @click="$emit('refresh')" variant="secondary" size="sm">
          <font-awesome-icon icon="arrow-rotate-right" />
        </b-btn>
      </b-col>
      <b-col sm="auto" cols="2" v-if="filterEnabled">
        <b-btn @click="$emit('update:filterOpen', !filterOpen)"
          :variant="filterInUse ? 'warning' : 'secondary'"
          class="button-minimize-on-sm"
          :disabled="disableControls"
          size="sm"
        >
          <font-awesome-icon icon="filter" />
            Filter
        </b-btn>

        <filter-drawer
          :visible="filterOpen"
          :disabled="disableControls"
          @update:visible="$emit('update:filterOpen', $event)"
          @pinned="$emit('customize-pinned', $event)"
          @apply="$emit('applyFilter')"
          @clear="$emit('clearFilter')"
        >
          <slot name="filter-form" />
        </filter-drawer>

      </b-col>
      <b-col sm="auto" cols="2">
        <column-menu-button
          :allColumns="allColumns"
          :columnLabelKey="columnLabelKey"
          :columnIsSelected="columnIsSelected"
          :disabled="disableControls"
          size="sm"
          @selectedColumnsUpdated="$emit('selectedColumnsUpdated', $event)"
          @reset-to-defaults="$emit('reset-columns-to-defaults')"
        />
      </b-col>
      <b-col sm="auto" cols="7" :class="allowBulkSelect ? null : 'mr-auto'">
        <b-dropdown text="Export" :disabled="disableControls || !exportEnabled" size="sm">
          <template #button-content>
            <font-awesome-icon icon="file-export" /> Export
          </template>
          <b-dropdown-item @click="$emit('csv')"><font-awesome-icon icon="file-csv" /> CSV</b-dropdown-item>
          <b-dropdown-item @click="$emit('excel')"><font-awesome-icon icon="file-excel" /> Excel</b-dropdown-item>
          <b-dropdown-item  @click="$emit('pdf')"><font-awesome-icon icon="file-pdf" /> PDF</b-dropdown-item>
          <b-dropdown-item @click="$emit('print')" v-if="printEnabled"><font-awesome-icon icon="print" /> Print</b-dropdown-item>

          <template v-if="isAppAdmin">
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item @click="reloadTemplates">
              <font-awesome-icon icon="rotate-right" />
              Reload templates
            </b-dropdown-item>
          </template>

          <template v-if="customExportOptions && customExportOptions.length > 0">
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item
              v-for="customExportOption in customExportOptions"
              :key="customExportOption.id"
              @click="exportToCustom(customExportOption.id)"
            >
              <!-- TODO: Infer specific icon from template attributes. -->
              <font-awesome-icon icon="file-export" />
              {{ customExportOption.label }}
            </b-dropdown-item>
          </template>
        </b-dropdown>
      </b-col>
      <b-col sm="auto" cols="7" class="mr-auto" v-if="allowBulkSelect">
        <b-btn v-if="!bulkSelectOn" :disabled="disableControls" size="sm"
          @click="$emit('bulk-select-clicked')"
        >
          <font-awesome-icon icon="check-double" />
          Bulk Select
        </b-btn>
        <b-dropdown v-else
          size="sm"
          :split="true"
          variant="warning"
          class="bulk-action-dropdown"
        >
          <template #button-content>
            <div @click="$emit('bulk-select-clicked')">
              <font-awesome-icon icon="check-double" />
              Bulk Action
            </div>
          </template>
          <b-dropdown-item v-for="bulkSelectOption in bulkSelectOptions" :key="bulkSelectOption.id"
            :disabled="bulkSelectOptionsDisabled"
            @click="$emit('bulk-action-selected', bulkSelectOption.id)"
          >
            {{ bulkSelectOption.label }}
          </b-dropdown-item>
        </b-dropdown>
      </b-col>
      <b-col sm="auto" cols="10" v-if="addItemEnabled">
        <b-dropdown :split="importEnabled" :no-caret="!importEnabled" variant="primary" :disabled="disableControls" size="sm">
          <template #button-content>
            <div @click="$emit('addItem')" class="add-button-content">
              <font-awesome-layers class="fa-lg" style="margin-left: .25rem; margin-right: 1rem" v-if="icon">
                <font-awesome-icon :icon="icon" transform="down-1" />
                <font-awesome-icon icon="plus" transform="shrink-6 right-14" />
              </font-awesome-layers>
              <font-awesome-icon icon="plus" v-else />
              {{ addResourceTextComputed }}
            </div>
          </template>
          <b-dropdown-item @click="importFromSpreadsheet" v-if="importEnabled">
            <font-awesome-icon icon="file-import" /> Import from spreadsheet
          </b-dropdown-item>
        </b-dropdown>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { pluralize } from 'inflection'
import ColumnMenuButton from '@/components/ColumnMenuButton.vue'
import FilterDrawer from '@/components/FilterDrawer.vue'
import _ from 'lodash'
import { startBulkImport } from '@/bulkImport'
import { mapState } from 'vuex'
import { useModalController } from 'bootstrap-vue-next'

export default {
  setup () {
    const { hide, show } = useModalController()
    return {
      showModal: show,
      hideModal: hide
    }
  },
  components: {
    ColumnMenuButton,
    FilterDrawer
  },
  props: {
    searchEnabled: {
      type: Boolean,
      default: true
    },
    searchText: String,
    filterEnabled: {
      type: Boolean,
      default: true
    },
    refreshEnabled: {
      type: Boolean,
      default: false
    },
    filterOpen: {
      type: Boolean,
      default: false
    },
    filterInUse: {
      type: Boolean,
      default: false
    },
    exportEnabled: {
      type: Boolean,
      default: true
    },
    printEnabled: {
      type: Boolean,
      default: true
    },
    resourceName: {
      type: String,
      default: 'Item'
    },
    addItemEnabled: {
      type: Boolean,
      default: true
    },
    importEnabled: {
      type: Boolean,
      default: true
    },
    // If importEnabled but no importEnabled is defined, then a generic modal
    // will be displayed instructing the user to email support with a spreadsheet.
    bulkImportConfig: Function,
    addResourceText: {
      type: String
    },
    icon: String,
    allColumns: {
      type: Array,
      default: () => []
    },
    columnLabelKey: String,
    columnIsSelected: Function,
    // TODO: Bind disableControls to all buttons.
    disableControls: {
      type: Boolean,
      default: false
    },
    allowBulkSelect: Boolean,
    bulkSelectOptions: Array,
    bulkSelectOptionsDisabled: Boolean,
    bulkSelectOn: Boolean,
    customExportOptions: Array
  },
  emits: [
    'addItem',
    'applyFilter',
    'applySearch',
    'bulk-action-selected',
    'bulk-import-completed',
    'bulk-select-clicked',
    'clearFilter',
    'csv',
    'customize-pinned',
    'excel',
    'export-to-custom',
    'pdf',
    'print',
    'refresh',
    'reset-columns-to-defaults',
    'selectedColumnsUpdated',
    'update:filterOpen'
  ],
  computed: {
    ...mapState('userProfile', ['isAppAdmin']),
    pluralResourceName () {
      return pluralize(this.resourceName)
    },
    addResourceTextComputed () {
      return this.addResourceText || `Add ${this.resourceName}`
    }
  },
  methods: {
    applyFilter () {
      this.$emit('applyFilter')
    },
    applySearch (event) {
      this.$emit('applySearch', event.target.value)
    },
    async importFromSpreadsheet () {
      if (this.bulkImportConfig) {
        const bulkImportConfig = await this.bulkImportConfig()
        startBulkImport(Object.assign({
          showModal: this.showModal,
          resourceName: this.pluralResourceName
        }, bulkImportConfig))
          .then(dataWasImported => {
            if (dataWasImported) this.$emit('bulk-import-completed')
          })
      } else {
        this.showModal({
          component: (await import('./ImportListModal.vue')).default
        })
      }
    },
    exportToCustom (customOptionId) {
      this.$emit('export-to-custom', customOptionId)
    },
    reloadTemplates () {
      this.$store.dispatch('integrationTemplates/load', true)
    }
  },
  beforeDestroy () {
    if (this.$refs.search) this.$refs.search.$off('input')
  }
}
</script>
<style lang="scss" scoped>

.header {
  padding: 0;

  :deep(.row) {
    margin-top: -10px;
    & > div {
      margin-top: 10px;
    }
  }
}

.search-col {
  padding-right: 0;
}

.refresh-col {
  padding-right: 0;
  .btn {
    padding-left: 7px;
    padding-right: 0;
  }
}

.search-box {
  display: flex;
  background-color: white;
  border: 1px solid #999;
  color: #999;
  // padding: 0px 10px;

  :deep(input) {
    border: none;

    &::placeholder {
      color: #bbb;
    }

    &:focus {
      border: none;
      outline: none;
      box-shadow: none;
    }
  }

  .search-icon, .fa-layers {
    margin: auto 10px;
  }
}

.grid-tools {
  .add-button-content {
    margin-left: 5px;
  }
  .bulk-action-dropdown {
    :deep(.dropdown-menu) {
      // Bulk action list can get quite long, so make it scrollable.
      max-height: calc(100vh - 250px);
      overflow-y: auto;
    }
  }
}
</style>
