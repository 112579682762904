<template>
  <div class="flag-explanation">
    <b-button id="flag-explanation-target" variant="link" class="flag-explanation-link">
      What do the red letters in the report below mean?
    </b-button>
    <b-popover target="flag-explanation-target" triggers="hover" placement="bottom">
      Any flag codes will show in red on the report, separated by dashes.
      The codes are abbreviated as follows:
      <ul>
        <li>A = Added by administrator</li>
        <li>E = Edited by administrator</li>
        <li>F = Face error</li>
        <li>G = Geo error</li>
        <li>M = Missed punch</li>
        <li>C = Credentialing Invalid</li>
        <li>SE = Started Early</li>
        <li>SL = Started Late</li>
        <li>MB = Missed Break</li>
        <li>SB = Short Break</li>
        <li>LB = Long Break</li>
        <li>LE = Left Early</li>
        <li>LL = Left Late</li>
        <li>OS = Out of Shift</li>
        <li>PS = Prior Shift Conflict</li>
        <li>NS = Next Shift Conflict</li>
        <li>MS = Multiple Shifts</li>
        <li>DO = Day off Required</li>
        <li>P<i>n</i> = Punch date is <i>n</i> days after <i>Date</i> column</li>
        <li>&#9733; = Requires approval</li>
      </ul>
    </b-popover>
  </div>
</template>
<script>
export default {
  name: 'FlagExplanation'
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables';

.flag-explanation {
  .flag-explanation-link {
    color: $flat-ui-alizarin;
    font-size: 0.75rem;
    text-decoration-line: underline;
    text-decoration-style: dotted;
    padding: 0;
    text-align: left;
  }
}
</style>
