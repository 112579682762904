<template>
  <hover-icon class="audit-icon not-printable" icon="cctv" :placement="placement" v-show="showAudit">
    <audit-links
      :instanceId="instanceId"
      :kind="kind"
      :affectedTitle="`View audit log of changes made to ${resourceName}`"
      @link-opened="$emit('link-opened')"
    />
  </hover-icon>
</template>
<script>
import AuditLinks from './AuditLinks.vue'
import HoverIcon from './HoverIcon.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'AuditIcon',
  components: {
    AuditLinks,
    HoverIcon
  },
  props: {
    instanceId: Number,
    kind: String,
    resourceName: String,
    placement: String
  },
  computed: {
    ...mapGetters(['canViewAudit']),
    showAudit () {
      return Boolean(this.canViewAudit && this.instanceId && this.kind && this.resourceName)
    }
  }
}
</script>
