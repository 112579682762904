import { createCrudService } from '@/services/GenericCrudService'
import restClient from '@/services/clients/rest'

const resourceName = 'Custom Field Items'

// Export cache so we can access it synchronously when doing bulk updates.
// Admittedly, this approach smells a bit.
export const cache = {}
const requestsInProgress = {}

export default function crudServiceFactory (customFieldId) {
  const resourcePath = `customfields/${customFieldId}/items`
  const service = createCrudService(restClient, resourceName, resourcePath)

  // Implement local cache backend.
  // TODO: Implement a way for the CustomFieldItemList component to force a refresh from remote backend.
  const list = service.list
  service.list = async params => {
    // Look in cache first.
    if (!cache[customFieldId]) {
      // Make remote call, and store in cache.
      // Handle racing condition by using a separate request cache.
      if (customFieldId in requestsInProgress) {
        await requestsInProgress[customFieldId]
      } else {
        const promise = list({ active: 'all' })
        requestsInProgress[customFieldId] = promise
        try {
          const data = await promise
          cache[customFieldId] = data.results
        } finally {
          delete requestsInProgress[customFieldId]
        }
      }
    }

    const active = params && params.active
    const items = cache[customFieldId]
      .filter(item => {
        if (active === 'all') return true
        else if (active === 'inactive') return !item.active
        return item.active
      })
    return { results: items }
  }

  // Need list function that returns array to populate bulk import remote options.
  // Params are not used.
  service.flatList = () => service.list().then(data => data.results)

  const create = service.create
  service.create = async (item, config) => {
    const data = await create(item, config)
    customFieldItemUpdated(data.results[0])
    return data
  }

  const update = service.update
  service.update = async (item, config) => {
    const data = await update(item, config)
    customFieldItemUpdated(data.results[0])
    return data
  }

  service.bulkImport = async (items, options) => {
    try {
      const response = await restClient.post(`${resourcePath}/bulk-import`, {
        items,
        options
      })
      return response.data
    } catch (error) {
      console.warn(`Error submitting custom field item bulk import`, error, items, options)
      throw error
    }
  }

  service.clearCache = customFieldId => {
    delete cache[customFieldId]
  }

  return service
}

function customFieldItemUpdated (item) {
  const itemList = cache[item.customField]
  if (itemList === undefined) return // nothing to do
  const itemIndex = itemList.findIndex(i => i.id === item.id)
  if (itemIndex > -1) {
    itemList[itemIndex] = item
  } else {
    itemList.push(item)
  }
}
