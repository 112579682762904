<template>
  <div class="face-result">
    <form-group label="Mode" v-bind="formGroupConfig">
      <div class="vertical-center-form-field-text">
        {{ value.faceMode || 'none' }}
      </div>
    </form-group>
    <form-group label="Status" v-bind="formGroupConfig" v-if="faceStatus">
      <div class="vertical-center-form-field-text">
        <font-awesome-icon icon="triangle-exclamation" v-if="faceStatus === 'failed'" />
        {{ faceStatus }}
      </div>
    </form-group>
    <form-group label="Detected" v-bind="formGroupConfig" v-if="faceStatus">
      <div class="vertical-center-form-field-text">
        {{ value.faceDetected ? 'yes' : 'no' }}
      </div>
    </form-group>
    <form-group label="Photo" v-bind="formGroupConfig" v-if="faceLogId">
      <face-log-photo :faceLogId="faceLogId" @load="onPhotoLoaded" ref="faceLogPhoto" />
      <template v-if="value.faceLog && value.employee">
        <div class="photo-overlay">
          <div v-if="value.faceLog.score" :class="['score', {'three-digit-score': value.faceLog.score > 99}]">{{ value.faceLog.score }}</div>
          <div
            :class="['photo-op', value.faceLog.addedToModel ? 'remove' : 'add']"
            :style="{left: `${photoWidth - 23}px`}"
            @click="doPhotoOp"
          >
            <font-awesome-icon icon="circle-notch" spin v-if="updating" />
            <font-awesome-icon-with-title v-else
              :icon="value.faceLog.addedToModel ? 'circle-minus' : 'circle-plus'"
              :title="value.faceLog.addedToModel ? 'Remove from Face Model' : 'Add to Face Model'"
            />
          </div>
        </div>
        <face-status-error :face-status="value.faceLog.status" style="margin-top: .5rem" />
        <multiple-face-matches
          :matches="value.faceLog.multipleMatches"
          style="margin-top: .5rem"
        />
        <div v-if="livenessConfidence !== null" class="liveness-confidence">
          <font-awesome-icon icon="circle-info" />
          <div class="label">Liveness confidence</div>
          <div class="value">{{ livenessConfidence }}</div>
        </div>
      </template>
    </form-group>
  </div>
</template>
<script>
import _ from 'lodash'
import FaceLogPhoto from './FaceLogPhoto.vue'
import FaceStatusError from '@/views/settings/employees/FaceStatusError.vue'
import MultipleFaceMatches from '@/views/settings/employees/MultipleFaceMatches.vue'
import FontAwesomeIconWithTitle from '@/components/FontAwesomeIconWithTitle.vue'
import restClient from '@/services/clients/rest'
import { extractErrorMessage } from '@/utils/misc'

export default {
  name: 'ClockLogFaceResult',
  components: {
    FaceLogPhoto,
    FaceStatusError,
    MultipleFaceMatches,
    FontAwesomeIconWithTitle
  },
  props: {
    value: Object,
    formGroupConfig: Object
  },
  data () {
    return {
      updating: false,
      photoWidth: 0
    }
  },
  computed: {
    faceStatus () {
      if (!this.value.faceMode) return null
      if (this.value.faceSuccess) return 'success'
      if (this.value.faceProcessing) return 'processing'
      if (this.value.facePending) return 'pending'
      return 'failed'
    },
    faceLogId () {
      return this.value.faceLogId || _.get(this.value.faceLog, 'id')
    },
    livenessConfidence () {
      return _.get(this.value, 'faceLog.faceAttributes.livenessConfidence', null)
    }
  },
  methods: {
    doPhotoOp () {
      if (this.updating) return
      this.updating = true

      const op = this.value.faceLog.addedToModel ? 'remove-from-model' : 'add-to-model'
      restClient.post(`facelogs/${this.faceLogId}/${op}`)
        .then(() => this.value.faceLog.addedToModel = !this.value.faceLog.addedToModel)
        .catch(error => {
          this.$toast.error(extractErrorMessage(error))
        })
        .finally(() => this.updating = false)
    },
    onPhotoLoaded () {
      const photoWidth = this.$refs.faceLogPhoto?.$el?.offsetWidth
      if (photoWidth) this.photoWidth = photoWidth
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables';

.face-result {
  text-transform: capitalize;

  .form-group {
    // tighten up the space, since there's no form fields
    margin-bottom: 0;
  }

  .vertical-center-form-field-text {
    padding-top: 5px;
  }

  .photo-overlay {
    position: relative;

    > div {
      position: absolute;
      bottom: 8px;
      color: $flat-ui-clouds;
      border-radius: 4px;
      width: 18px;
      height: 18px;
    }

    .score {
      left: 5px;
      font-weight: bold;
      font-size: 12px;
      background-color: $flat-ui-emerald;
      text-align: center;
      padding-top: 1px;

      // 3 digit score needs more width.
      &.three-digit-score {
        width: 27px;
      }
    }

    .photo-op {
      cursor: pointer;

      display: flex;
      align-items: center;
      justify-content: center;

      :deep(svg) {
        margin: 2px 0 0 0;
      }

      &.remove {
        background-color: $flat-ui-alizarin;
      }
      &.add {
        background-color: $flat-ui-emerald;
      }
    }
  }

  .liveness-confidence {
    margin-top: .25rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: .75rem;
    svg {
      margin-right: .75rem;
    }
    .label {
      margin-right: .25rem;
      &::after {
        content: ':';
      }
    }
  }
}
</style>
