<template>
  <div class="color-status">
    <font-awesome-icon icon="circle" :class="['status-icon', statusColor]" />
    <div v-if="statusText" class="text">{{ statusText }}</div>
    <div class="partial-results" v-if="hasCount">
      <span class="success">{{ successCount }}</span>
      <span v-if="pendingCount" class="pending">{{ pendingCount }}</span>
      <span class="failure">{{ failureCount }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ColorStatus',
  props: {
    statusColor: String,
    statusText: String,
    successCount: Number,
    pendingCount: Number,
    failureCount: Number
  },
  computed: {
    hasCount () {
      return !!(this.successCount || this.pendingCount || this.failureCount)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables';

.color-status {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  .status-icon {
    margin-right: .75rem;
  }
  .partial-results {
    margin-left: .5rem;
    &::before {
      content: '(';
    }
    &::after {
      content: ')';
    }
    .success, .pending {
      margin-right: .5rem;
    }
  }

  .success {
    color: $flat-ui-emerald;
  }
  .failure {
    color: $flat-ui-alizarin;
  }
  .pending {
    color: $flat-ui-sunflower;
  }
  .partial {
    color: $flat-ui-carrot;
  }
  .unknown {
    color: $flat-ui-concrete;
  }
}
</style>
