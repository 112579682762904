
// item is an object containing the properties that we used in WorkerDocumentForm and WorkerDocuments.
// TODO: Standardize the interface, or use typescript. Perhaps return new values instead of mutating.
export function compressImage (item, targetSize) {
  import(/* webpackChunkName: "load-image" */ 'blueimp-load-image')
    .then(module => {
      const loadImage = module.default
      return loadImage(item.file, { orientation: true })
        .then(image => {
          // We reduce by an extra 10%, because for some reason the theoretical compression factor
          // is still a little too high. Maybe there's overhead?
          const compressFactor = Math.sqrt(targetSize / item.size) * .9
          return loadImage.scale(image.image, {
            maxWidth: image.originalWidth * compressFactor,
            maxHeight: image.originalHeight * compressFactor,
            canvas: true })
        })
    })
    .then(canvas => {
      canvas.toBlob(
        blob => {
          Object.assign(item, {
            size: blob.size,
            file: blob
          })
        },
        item.mimeType
      )
    })
}

export function mimeTypeCanBeCompressed (mimeType) {
  return ['image/gif', 'image/jpeg', 'image/png'].includes(mimeType)
}
