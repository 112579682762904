<template>
  <div class="weekly-input">
    <form-group
      :label="showLabel ? label : null"
      :validator="v$.daysOfWeek"
      :label-size="size"
    >
      <div class="weekday-picker">
        <div v-for="dayOption in dayOptions" :key="dayOption.value"
          class="weekday"
          :class="{ selected: daysOfWeek.includes(dayOption.value) }"
          @click="toggleDaySelected(dayOption.value)"
        >
          {{ dayOption.minLabel }}
        </div>
      </div>
    </form-group>
    <div v-if="enableInterval" class="week-interval">
        <div>Repeat every</div>
        <form-group
          :validator="v$.weekInterval"
          :label-size="size"
        >
          <template #default="slotProps">
            <b-form-input
              v-bind="slotProps"
              v-model="weekInterval"
              type="number"
              min="1"
              max="52"
              :size="size"
            />
          </template>
        </form-group>
        <div>{{ inflectedWeekLabel }}</div>
    </div>
    <div v-if="startDateEnabled" class="start-date">
      <form-group
        label="Starting"
        label-cols
        :validator="v$.startDate"
        :label-size="size"
      >
        <template #default="slotProps">
          <date-picker
            v-bind="slotProps"
            v-model="startDate"
            :insideBootstrapOverlay="true"
          />
        </template>
      </form-group>
    </div>
    <div v-if="enableEndDate" class="end-date">
      <form-group label="Ending" label-cols :label-size="size">
        <b-form-radio-group v-model="hasEnd" :size="size">
          <b-form-radio :value="false" >Never</b-form-radio>
          <b-form-radio :value="true">
            <form-group label="On" label-cols="auto"
              :validator="v$.endDate"
            >
              <template #default="slotProps">
                <date-picker
                  v-bind="slotProps"
                  v-model="endDate"
                  :disabled="!hasEnd"
                  :insideBootstrapOverlay="true"
                />
              </template>
            </form-group>
          </b-form-radio>
        </b-form-radio-group>
      </form-group>
    </div>
  </div>
</template>
<script>
import _ from 'lodash'
import { RRule } from 'rrule'
import { dayOptions, MaxDate, rruleWeekDays, ruleWithDefaultUntilMaxDate } from '@/utils/rrule'
import { inflect } from 'inflection'
import { between, helpers, numeric, required, requiredIf } from '@vuelidate/validators'
import DatePicker from '@/components/DatePicker.vue'
import moment from 'moment-timezone'
import momentFormats from '@/utils/MomentFormats'
import ChildValidation from '@/mixins/ChildValidation'
import { useVuelidate } from '@vuelidate/core'

export default {
  name: 'WeeklyInput',
  setup () {
    return { v$: useVuelidate() }
  },
  mixins: [ChildValidation],
  components: {
    DatePicker
  },
  props: {
    modelValue: String,
    enableInterval: {
      type: Boolean,
      default: false
    },
    enableRepeatingStartDate: {
      type: Boolean,
      default: true
    },
    enableEndDate: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: 'Days of Week'
    },
    showLabel: {
      type: Boolean,
      default: false
    },
    size: String
  },
  emits: ['update:modelValue'],
  data () {
    return {
      dayOptions,
      daysOfWeek: [],
      weekInterval: 1,
      startDate: null,
      hasEnd: false,
      endDate: null
    }
  },
  computed: {
    inflectedWeekLabel () {
      return inflect('Week', this.weekInterval)
    },
    startDateEnabled () {
      return this.enableInterval && this.weekInterval > 1 && this.enableRepeatingStartDate
    }
  },
  watch: {
    modelValue: {
      handler (value) {
        if (value) {
          const rule = RRule.fromString(value)
          this.daysOfWeek = (_.get(rule, 'origOptions.byweekday') || [])
            .map(day => rruleWeekDays[day.weekday])
          this.weekInterval = _.get(rule, 'origOptions.interval') || 1

          const startDateString = this.startDateEnabled ? _.get(rule, 'origOptions.dtstart') : null
          this.startDate = startDateString ? moment(startDateString).utc().format(momentFormats.Iso8601Short) : null

          const endDateString = this.enableEndDate ? _.get(rule, 'origOptions.until') : null
          let endDate = endDateString ? moment(endDateString).utc().format(momentFormats.Iso8601Short) : null
          // If end date is max date, then treat as no end date.
          if (endDate === MaxDate) endDate = null
          this.endDate = endDate
          this.hasEnd = !!this.endDate
        } else {
          this.daysOfWeek = []
          this.weekInterval = 1
          this.startDate = null
          this.hasEnd = false
          this.endDate = null
        }
      },
      immediate: true
    },
    daysOfWeek () {
      this.emitValue()
    },
    weekInterval () {
      this.emitValue()
    },
    startDate () {
      this.emitValue()
    },
    hasEnd (hasEnd) {
      if (!hasEnd) this.endDate = null
    },
    endDate () {
      this.emitValue()
    }
  },
  methods: {
    toggleDaySelected (day) {
      if (this.daysOfWeek.includes(day)) {
        this.daysOfWeek = _.without(this.daysOfWeek, day)
      } else {
        this.daysOfWeek = [...this.daysOfWeek, day]
      }
    },
    emitValue () {
      const byweekday = _.sortBy(
        this.daysOfWeek.map(day => RRule[day]),
        ['weekday']
      )
      const dtstart = this.startDateEnabled
        ? moment.tz(this.startDate || undefined, 'UTC').toDate()
        : null

      const until = this.hasEnd
        ? moment.tz(this.endDate || undefined, 'UTC').endOf('day').toDate()
        : null

      let rruleString = RRule.optionsToString({
        freq: RRule.WEEKLY,
        interval: this.weekInterval,
        byweekday,
        dtstart,
        until
      })

      if (this.enableEndDate) rruleString = ruleWithDefaultUntilMaxDate(rruleString)

      this.$emit('update:modelValue', rruleString)
    }
  },
  validations () {
    return {
      daysOfWeek: {
        required
      },
      weekInterval: {
        required,
        numeric,
        between: between(1, 52)
      },
      startDate: {
        current: helpers.withMessage('Start Date may not be before today.', value => {
          if (!value) return true
          return !moment(value).isBefore(moment().startOf('day'))
        })
      },
      endDate: {
        required: requiredIf(() => this.hasEnd)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables';

.weekday-picker {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .weekday {
    width: 35px;
    padding: 3px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 3px;
    cursor: pointer;
    user-select: none;

    &.selected {
      color: $flat-ui-clouds;
      background-color: $flat-ui-peter-river;
    }
  }
}

.week-interval, .start-date {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.weekly-input {
  fieldset {
    width: 16.8rem;
  }
}

.week-interval {
  fieldset {
    margin: 0 1rem;
    width: 6rem;
  }
}

.start-date, .end-date {
  .mx-datepicker {
    margin: 0 1rem;
    width: 8rem;
  }
}

</style>
