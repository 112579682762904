import { registerListResourceWithStore } from '@/services/GenericListResourceService'
import store from '@/store'

registerListResourceWithStore('departments', 'departments', store)
registerListResourceWithStore('orgUnits', 'orgunits', store)

registerListResourceWithStore('customFields', 'customfields', store, {
  getters: {
    sortedCustomFields: (state, getters, rootState, rootGetters) => getters.sortedItems(['appliesTo', 'sortOrder', 'name'])
      .filter(item =>
        item.active &&
        (!item.sensitive || rootGetters.canAccessEmployeeSensitive) &&
        (rootGetters.scheduleEnabled || item.appliesTo !== 'shift')
      ),
    punchCustomFields: (state, getters) =>
      getters.sortedCustomFields.filter(item => item.appliesTo === 'punch'),
    shiftCustomFields: (state, getters) =>
      getters.sortedCustomFields.filter(item => item.appliesTo === 'shift'),
    userCustomFields: (state, getters) =>
      getters.sortedCustomFields.filter(item => item.appliesTo === 'user'),
    workCustomFields: (state, getters) =>
      getters.sortedCustomFields.filter(item => ['punch', 'shift'].includes(item.appliesTo)),
    crossIndexCustomFields: (state, getters) => getters.userCustomFields.filter(item => item.crossIndex),
    // Cross indexing reports can display columns for all user custom fields except non-cross indexed choice fields,
    // because we don't have the custom field item names for them.
    supplementalUserCustomFields: (state, getters) => getters.userCustomFields.filter(item => item.crossIndex || item.type !== 'choice')
  }
})

export default {
  name: 'organization',
  label: 'Organization',
  link: 'organization',
  path: 'organization',
  props: true,
  icon: 'sitemap',
  description: 'View and edit your organization\'s information.',
  component: () => import('@/components/TabPanel.vue'),
  requirePerm: 'edit_settings',
  breadcrumbLeaf: true,
  children: [
    {
      name: 'org-defaults',
      label: 'Defaults',
      title: 'Org Defaults',
      path: 'defaults',
      component: () => import(/* webpackChunkName: "org" */ './OrgDefaultsForm.vue')
      .catch(error => {
        console.error('Failed to route to OrgDefaultsForm', error)
        throw error
      })
    },
    {
      name: 'org-formats',
      label: 'Formats',
      title: 'Org Formats',
      path: 'formats',
      props: true,
      component: () => import(/* webpackChunkName: "org" */ './OrgFormatsForm.vue')
    },
    {
      name: 'customfields',
      label: 'Custom Fields',
      path: 'customfields/:view?',
      requireGetter: 'hasCustomFieldFeature',
      requirePerm: 'edit_settings',
      component: () => import(/* webpackChunkName: "customfields" */ '../customfields/CustomFieldList.vue')
        .catch(error => {
          console.error('Failed to route to CustomFieldList', error)
          throw error
        })
    },
    {
      name: 'department-list',
      label: 'Departments',
      path: 'departments/:view?',
      props: true,
      component: () => import(/* webpackChunkName: "org" */ './DepartmentList.vue')
        .catch(error => {
          console.error('Failed to route to DepartmentList', error)
          throw error
        })
    },
    {
      name: 'label-list',
      label: 'Labels',
      path: 'labels/:view?',
      props: true,
      component: () => import(/* webpackChunkName: "org" */ './LabelList.vue')
    },
    {
      name: 'org-unit-list',
      label: 'Org Units',
      path: 'orgunits/:view?',
      props: true,
      component: () => import(/* webpackChunkName: "org" */ './OrgUnitList.vue')
    },
    {
      name: 'organization-index',
      path: '',
      redirect: { name: 'org-defaults' }
    }
  ]
}
