import {isEmpty} from 'lodash'
import {forceArray} from '@/utils/misc'

function hasAccessToRouteMeta (meta, store, itemName) {
  if (meta.requireActive !== false && !store.getters.orgReady) {
    if (itemName) {
      console.debug(`access denied to route "${itemName}" because organization not active`)
    }
    return false
  }

  if (meta.requireFeature && !store.state.features.includes(meta.requireFeature)) {
    if (itemName) {
      console.debug(`access denied to route "${itemName}" because feature ${meta.requireFeature} is required`)
    }
    return false
  }

  if (meta.requirePerm && !store.state.permissions.includes(meta.requirePerm)) {
    if (itemName) {
      console.debug(`access denied to route "${itemName}" because permission ${meta.requirePerm} is required`)
    }
    return false
  }

  if (!isEmpty(meta.requireGetter) && !forceArray(meta.requireGetter).every(requireGetter => store.getters[requireGetter])) {
    // TODO: If there are multiple requirements, log which requirement failed.
    if (itemName) {
      console.debug(`access denied to route "${itemName}" because property ${meta.requireGetter} is required`)
    }
    return false
  }

  if (meta.require instanceof Function && !meta.require(store.state, store.getters)) {
    if (itemName) {
      console.debug(`access denied to route "${itemName}" because require function rejected it`)
    }
    return false
  }

  return true
}

function hasAccessToRoute (route, store) {
  return hasAccessToRouteMeta(route.meta, store, route.name)
}

function guardRouteAccess (router, store) {
  router.beforeEach(async (to, from) => {
    const promise = new Promise((resolve, reject) => {
      // We park the pending route until app is bootstrapped. Basically, we watch for the same state values
      // as App.vue's <router-view> element.
      const isReady = (state, getters) => state.settingsLoaded || state.fatalError || getters['orgService/userInterventionNeeded']

      if (isReady(store.state, store.getters)) {
        resolve()
        return
      }

      store.commit('setPendingRoute', to)

      // It's weird, but the history api or vue router don't seem to handle multiple pending redirects well.
      // If this route guard is pending while a fatal error occurs, then we need to resolve the pending guard
      // in order to redirect to error state.
      const unwatch = store.watch(
        isReady,
        ready => {
          if (!ready) return
          unwatch()
          resolve()
        })
    })

    await promise

    store.commit('setPendingRoute', null)

    // TODO: handle to.params.orgId different than store.state.organizationId

    for (const route of to.matched) {
      if (!hasAccessToRoute(route, store)) {
        console.warn(`Access denied to route ${route.name}`)
        // TODO: redirect somewhere else
        return false
      }
    }
  })
}

export {
  guardRouteAccess,
  hasAccessToRoute,
  hasAccessToRouteMeta
}
