<template>
  <div class="date-range-picker">
    <div ref="valueDisplay" :class="['value-display', { disabled, 'form-style': formStyle }]" @click="onClick">
      <div class="pi pi-calendar"></div>
      <div :class="['value', { empty: !displayValue }]">{{ displayValue || 'No date range specified' }}</div>
      <div v-if="presetLabel" class="preset">{{ presetLabel }}</div>
      <Button
        :disabled=disabled
        :class="['clear', showClearButton && hasValue ? 'show-clear' : 'hide-clear']"
        icon="pi pi-times"
        severity="danger"
        size="small"
        text rounded
        aria-label="Clear"
        @click.stop="clear"
      />
    </div>
    <DateRangeDialog
      :value="value"
      :visible="visible"
      @update:visible="visible = $event"
      :presets="presets"
      :target="overlayTarget"
      :dateFormat="dateFormat"
      :timeFormat="timeFormat"
      :timeIncrement="timeIncrement"
      :startKey="startKey"
      :endKey="endKey"
      :timezone="timezone"
      :baseZIndex="baseZIndex"
      :enableTime="enableTime"
      @commit="commit"
      />
  </div>
</template>

<script>
import moment from 'moment-timezone'
import DateRangeDialog from "./DateRangeDialog.vue"
import { modelDateFormat as DATE_MODEL_FORMAT } from '@/utils/misc'

export default {
  name: 'DateRangePickerField',
  components: {
    DateRangeDialog
  },
  props: {
    value: Object,
    presets: Array,
    dateFormat: {
      type: String,
      default: 'MM/DD/YYYY'
    },
    timeFormat: {
      type: String,
      default: 'hh:mm A'
    },
    timeIncrement: Number,
    enableTime: Boolean,
    startKey: {
      type: String,
      default: 'start'
    },
    endKey: {
      type: String,
      default: 'end'
    },
    timezone: String,
    disabled: Boolean,
    baseZIndex: Number,
    showClearButton: Boolean,
    formStyle: Boolean,
    // TODO: Do we need a size="narrow" prop?
  },
  data() {
    return {
      visible: false,
      overlayTarget: null
    }
  },
  computed: {
    presetByValue () {
      return Object.fromEntries(this.presets.map(preset => ([preset.value, preset])))
    },
    presetLabel () {
      return this.presetByValue[this.value.preset]?.label
    },
    startDt () {
      const dtString = this.value[this.startKey]
      return dtString ? moment.tz(dtString, this.timezone) : null
    },
    endDt () {
      const dtString = this.value[this.endKey]
      return dtString ? moment.tz(dtString, this.timezone) : null
    },
    displayValue () {
      const startDt = this.startDt ? moment(this.startDt) : null
      const endDt = this.endDt ? moment(this.endDt) : null
      if (!startDt || !endDt) return ''
      // We'll try hard to make the display value as short and simple as possible.
      if (this.enableTime) {
        if (startDt.isSame(endDt)) return startDt.format(this.dateFormat)
        // End time is exclusive, but we want to display it was inclusive, so we'll subtract a minute.
        endDt.subtract(1, 'minute')
        let displayValue = startDt.format(this.dateFormat + ' ' + this.timeFormat)
        if (startDt.isSame(endDt)) {
          return displayValue
        }
        const isSameDay = moment(startDt).startOf('day').isSame(moment(endDt).startOf('day'))
        if (startDt.hour() === 0 && startDt.minute() === 0 && endDt.hour() === 23 && endDt.minute() === 59) {
          displayValue = startDt.format(this.dateFormat)
          if (!isSameDay) {
            displayValue += ' - ' + endDt.format(this.dateFormat)
          }
          return displayValue
        }
        if (isSameDay) {
          return displayValue + ' - ' + endDt.format(this.timeFormat)
        }
        return displayValue + ' - ' + endDt.format(this.dateFormat + ' ' + this.timeFormat)
      } else {
        let displayValue = startDt.format(this.dateFormat)
        if (!startDt.isSame(endDt)) {
          displayValue += ' - ' + endDt.format(this.dateFormat)
        }
        return displayValue
      }
    },
    hasValue () {
      return this.value.preset || this.value[this.startKey] || this.value[this.endKey]
    }
  },
  watch: {
    value: {
      handler () {
        this.fillInValue()
      },
      immediate: true
    }
  },
  methods: {
    onClick () {
      if (!this.disabled) {
        this.visible = !this.visible
      }
    },
    fillInValue () {
      if (this.value.preset) {
        // Allow parent to specify preset without dates, and we'll generate them.
        if (!this.startDt && !this.endDt) {
          const preset = this.presetByValue[this.value.preset]
          if (preset) {
            const range = preset.factory()
            this.value[this.startKey] = range[0].format(this.enableTime ? null: DATE_MODEL_FORMAT)
            // Factory function creates end datetime as exclusive.
            // Therefore, if time is not enabled, then we need to subtract a day before formatting date only.
            this.value[this.endKey] = range[1].subtract(this.enableTime ? 0 : 1, 'days').format(this.enableTime ? null : DATE_MODEL_FORMAT)
          }
        }
      } else if (this.startDt && this.endDt) {
        // If dates specified without preset, then try to match to a preset label.
        for (const preset of this.presets) {
          const presetRange = preset.factory()
          if (this.startDt.isSame(presetRange[0]) && this.endDt.isSame(presetRange[1])) {
            this.value.preset = preset.value
            break
          }
        }
      }
    },
    clear () {
      Object.assign(this.value, {
        preset: null,
        [this.startKey]: null,
        [this.endKey]: null,
      })
      this.commit()
    },
    commit () {
      this.$emit('commit')
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.overlayTarget = this.$refs.valueDisplay
    })
  }
}
</script>

<style lang="scss" scoped>
.date-range-picker {
  .value-display {
    font-family: Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
    display: flex;
    align-items: center;
    background-color: #e8f4fb;
    color: #3296dc;
    font-weight: 500;
    font-size: 12px;
    display: flex;
    padding: 10px;
    max-width: 425px;
    cursor: pointer;

    // In form, it should look more like a form field.
    &.form-style {
      background-color: #fff;
    }

    .pi {
      margin-right: 10px;
      font-size: 22px;
      width: 25px;
    }
    .value {
      max-width: 300px;
      &.empty {
        font-style: italic;
        opacity: .8;
      }
    }
    .preset {
      margin-left: 2cqmin;
      font-weight: normal;
      max-width: 100px;
    }
    &.disabled {
      cursor: auto;
    }

    .clear {
      padding: 0;
      height: 1.5rem;
      width: 1.5rem;
      margin-left: auto;

      &.hide-clear {
        visibility: hidden;
      }
    }
  }
}
</style>
