import { createRestCrudService } from '@/services/GenericCrudService'
import restClient from '@/services/clients/rest'
import { MaxQueryBranches, merge } from '@/utils/misc'
import { chunk } from 'lodash'

const resourceName = 'Time Off Request'
const resourcePath = 'timeoff/requests'
const crudService = createRestCrudService(resourceName, resourcePath)

export default Object.assign(crudService, {
  approve (timeoffRequestId, data) {
    return this.decide('approve', timeoffRequestId, data)
  },
  reject (timeoffRequestId) {
    return this.decide('reject', timeoffRequestId)
  },
  decide (decision, timeoffRequestId, data) {
    return restClient.post(`${resourcePath}/${timeoffRequestId}/${decision}`, data)
      .catch(error => {
        console.warn(`time off request ${decision} failed with error`, error)
        return Promise.reject(error)
      })
  },
  // query is an alternative to list using POST instead of GET to avoid
  // an error if the query string is too long due to many ids.
  // The backend limits query string to 2000 characters, and also many other
  // web servers have hard limits on max size of http headers.
  // We also split into multiple queries due to a backend limit.
  async query (params, workerIds) {
    const workerIdChunks = chunk(workerIds, MaxQueryBranches)

    const dataChunks = await Promise.all(
      workerIdChunks.map(async workerIdChunk => {
        try {
          const response = await restClient.post(`${resourcePath}/query`, { worker: workerIdChunk }, { params })
          return response.data
        } catch (error) {
          console.warn(`Error querying ${resourceName}`, error)
          throw error
        }
      })
    )
    return merge({}, ...dataChunks)
  },
})
