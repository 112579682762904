import { modelTimeFormat } from '@/utils/misc'
import moment from 'moment-timezone'
import _ from 'lodash'
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState([
      'maxExtendShiftEarly',
      'maxExtendShiftLate',
      'openShiftType',
      'openShiftStartsAt',
      'openShiftMaxDuration'
    ]),
  },
  methods: {
    fixedShiftMoment(shiftDate, timezone, time, dayOffset) {
      if (!time) return null
      const timeMoment = moment(time, modelTimeFormat)
      return moment.tz(shiftDate, timezone)
        .hours(timeMoment.hours())
        .minutes(timeMoment.minutes())
        .add(dayOffset || 0, 'days')
    },

    calculateScheduledPaidStart (shiftDate, timezone, firstClockIN, shiftClass) {

      firstClockIN = moment.tz(firstClockIN, timezone).seconds(0).millisecond(0)

      if (shiftClass) {
        if (shiftClass.shiftType === 'fixedTime') {
          const startEarlyLockout = this.fixedShiftMoment(shiftDate, timezone, shiftClass.startEarlyLockout, shiftClass.startEarlyLockoutDay)
          const revisionRules = _.orderBy(_.groupBy(shiftClass.revisionRules, 'type')['start'], ['startDay', 'startTime'])
          const revisionRule = firstClockIN
            ? revisionRules.find(rule => firstClockIN.isBetween(
                this.fixedShiftMoment(shiftDate, timezone, rule.startTime, rule.startDay),
                this.fixedShiftMoment(shiftDate, timezone, rule.endTime, rule.endDay),
                null,
                '[]'
            ))
            : null
          const reviseTo = revisionRule ? this.fixedShiftMoment(shiftDate, timezone, revisionRule.reviseToTime, revisionRule.reviseToDay) : null
          return reviseTo || startEarlyLockout

        } else { // shiftClass.shiftType === 'flexible'
          return firstClockIN
        }
      } else {
        if (this.openShiftType === 'day') {
          const time = moment(this.openShiftStartsAt, modelTimeFormat)
          return moment.tz(shiftDate, timezone)
            .hours(time.hours())
            .minutes(time.minutes())
        } else {
          return firstClockIN
        }
      }
    },
    calculateScheduledPaidEnd (shiftDate, timezone, firstClockIN, lastClockOUT, shiftClass) {

      firstClockIN = moment.tz(firstClockIN, timezone).seconds(0).millisecond(0)
      lastClockOUT = lastClockOUT && moment.tz(lastClockOUT, timezone).seconds(0).millisecond(0)

      if (shiftClass) {
        let reviseTo, endLateLockout
        if (shiftClass.shiftType === 'fixedTime') {
          const revisionRules = _.orderBy(_.groupBy(shiftClass.revisionRules, 'type')['end'], ['startDay', 'startTime'])
          const revisionRule = lastClockOUT
            ? revisionRules.find(rule => lastClockOUT.isBetween(
                this.fixedShiftMoment(shiftDate, timezone, rule.startTime, rule.startDay),
                this.fixedShiftMoment(shiftDate, timezone, rule.endTime, rule.endDay),
                null,
                '[]'
            ))
            : null
          reviseTo = revisionRule ? this.fixedShiftMoment(shiftDate, timezone, revisionRule.reviseToTime, revisionRule.reviseToDay) : null
          endLateLockout = this.fixedShiftMoment(shiftDate, timezone, shiftClass.endLateLockout, shiftClass.endLateLockoutDay)
        } else { // shiftClass.shiftType === 'flexible'
          reviseTo = shiftClass.flexibleRevisionDuration
            ? moment.tz(firstClockIN, timezone).add(shiftClass.flexibleRevisionDuration, 'minutes')
            : null
          endLateLockout = moment.tz(firstClockIN, timezone)
            .add(shiftClass.flexibleLockoutDuration || shiftClass.flexibleRevisionDuration || shiftClass.flexibleDuration, 'minutes')
        }

        return reviseTo || endLateLockout

      } else {
        if (this.openShiftType === 'day') {
          const time = moment(this.openShiftStartsAt, modelTimeFormat)
          return moment.tz(shiftDate, timezone)
            .hours(time.hours())
            .minutes(time.minutes())
            .add(1, 'day')
        } else { // this.openShiftType === 'custom'
          return moment.tz(firstClockIN, timezone)
            .add(this.openShiftMaxDuration, 'minutes')
        }
      }
    }
  }
}
