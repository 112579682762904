import { oneLine } from 'common-tags'

export default Object.freeze({
  admin: {
    label: 'Admin',
    helpText: oneLine`
      The user's personal device will normally function in administrator mode
      to enable easy access to admin functions. It can be toggled into a group
      clock mode that will require an admin login or pin to toggle back to admin mode.
      Admin mode is supported on time clock mobile app version 3.2+.
    `,
    available: orgUser => orgUser?.roles?.includes?.('admin') === true
  },
  clockAuth: {
    label: 'Personal time clock — Face or pin required',
    helpText: oneLine`
      Only this user will be able to Clock IN/OUT on this device,
      AND the usual face recognition or pin will be required.
    `,
    available: orgUser => orgUser?.roles?.includes?.('worker') === true
  },
  clockNoAuth: {
    label: 'Personal time clock — No Face or pin',
    helpText: oneLine`
      Only this user will be able to Clock IN/OUT on this device,
      AND no face recognition and/or pin will be used.
    `,
    available: orgUser => orgUser?.roles?.includes?.('worker') === true
  },
  reports: {
    label: 'Reports only',
    helpText: oneLine`
      User will NOT be able to Clock IN/OUT on this device,
      but will be able to view reports on personal total hours and schedule.
    `,
    available: orgUser => orgUser?.roles?.includes?.('worker') === true
  },
  clockGroup: {
    label: 'Group time clock',
    helpText: oneLine`
      This user's personal device will function as a regular time clock that
      anyone can use. This option may be useful for a supervisor, or as a backup
      device.
    `
  }
})
