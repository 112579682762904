<template>
  <Dropdown
    ref="dropdown"
    v-model="inputValue"
    :options="presetTimeOptions"
    editable
    class="time-picker"
    :panelClass="['date-range-time-picker-dropdown-panel', hasVisibleOptions ? 'has-visible-options' : 'no-visible-options']"
    @change="onChange"
    @show="onShow"
    @hide="onHide"
  />
</template>

<script>
import moment from 'moment-timezone'
import Dropdown from 'primevue/dropdown'
import { modelTimeFormat as TIME_MODEL_FORMAT } from '@/utils/misc'

const MINUTES_IN_DAY = 60 * 24

export default {
  name: 'DateRangeTimePicker',
  components: {
    Dropdown
  },
  props: {
    modelValue: String,
    increment: {
      type: Number,
      default: 15,
      validator: value => [1, 5, 10, 15, 30].includes(value)
    },
    format: {
      type: String,
      default: 'hh:mm A'
    },
  },
  emits: ['update:modelValue'],
  data () {
    return {
      inputValue: null,
      hasVisibleOptions: true,
      valueChanged: false
    }
  },
  computed: {
    presetTimeOptions() {
      const optionCount = MINUTES_IN_DAY / this.increment
      const midnight = moment.utc().startOf('day')
      const options = [...Array(optionCount).keys()].map(index => {
        const time = moment(midnight).add(index * this.increment, 'minutes')
        return time.format(this.format)
      })
      if (this.increment != 1) {
        // Include 11:59 PM.
        options.push(moment(midnight).endOf('day').format(this.format))
      }
      return options
    },
    inputValueIsPreset () {
      return this.presetTimeOptions.includes(this.inputValue)
    }
  },
  watch: {
    modelValue: {
      handler (value) {
        const m = moment(value, TIME_MODEL_FORMAT)
        const inputValue = m.isValid()
          ? m.format(this.format)
          : this.presetTimeOptions[0]
        if (inputValue !== this.inputValue) {
          this.inputValue = inputValue
        }
      },
      immediate: true
    },
    inputValue (inputValue) {
      if (!this.inputValueIsPreset) {
        this.setFilterValue(inputValue)
      }
    }
  },
  methods: {
    setFilterValue (filterValue) {
      const dropdown = this.$refs.dropdown
      dropdown.filterValue = filterValue
      this.hasVisibleOptions = dropdown.visibleOptions.length > 0
    },
    onChange (event) {
      if (this.inputValueIsPreset) {
        this.resolveValue()
      }
    },
    onShow () {
      if (this.inputValueIsPreset) {
        const dropdown = this.$refs.dropdown
        dropdown.scrollInView(dropdown.findSelectedOptionIndex());
      }
    },
    onHide () {
      // Use valueChanged boolean to delay clearing filter until overlay hides.
      // This prevents user accidentally clicked on different value than entered.
      if (this.valueChanged) {
        this.setFilterValue(null)
        this.valueChanged = false
      }
    },
    resolveValue () {
      if (this.inputValue && !this.inputValueIsPreset) {
        const m = moment(this.inputValue, this.format)
        this.inputValue = m.isValid() ? m.format(this.format) : ''
      }
      this.emitValue(this.inputValue)
      this.$nextTick(() => { this.$refs.dropdown.hide() })
    },
    emitValue (value) {
      const mValue = moment(value, this.format)
      this.$emit('update:modelValue', mValue.isValid() ? mValue.format(TIME_MODEL_FORMAT) : '')
      this.valueChanged = true
    }
  },
  mounted () {
    this.$nextTick(() => {
      const inputEl = this.$el.querySelector('.p-inputtext')
      inputEl.addEventListener('focus', () => {
        this.$refs.dropdown.show()
      })
      inputEl.addEventListener('blur', () => {
        this.resolveValue()
      })
      inputEl.addEventListener('keyup', e => {
        if (e.key === 'Enter' || e.keyCode === 13) {
          this.resolveValue()
        }
      })
    })
  }
}
</script>

<style lang="scss" scoped>
.time-picker {
  :deep() {
    .p-dropdown-trigger svg {
      width: 12px;
      height: 12px;
    }
  }
}
</style>

<style lang="scss">
.date-range-time-picker-dropdown-panel {
  .p-dropdown-header {
    display: none;
  }
  .p-dropdown-empty-message {
    display: none;
  }
  .p-dropdown-items {
    padding-left: 0;
    .p-dropdown-item {
      height: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 14px;
    }
  }
  &.no-visible-options {
    .p-dropdown-items {
      display: none;
    }
  }
}
</style>
