import restClient from '@/services/clients/rest'
import _ from 'lodash'

const resourceName = 'reports/rollcall'

export default {
  // for report
  listAsync (params) {
    return restClient.listAsync(resourceName, params)
      .then(response => response.data)
      .catch(error => {
        console.warn('Error retrieving roll call', error)
        return Promise.reject(error)
      })
  },
  // for dashboard sumamry
  list (params) {
    return restClient.get(resourceName, { params })
      .then(response => response.data)
      .catch(error => {
        console.warn('Error retrieving roll call', error)
        return Promise.reject(error)
      })
  }
}
