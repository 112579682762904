<template>
  <span ref="icon" class="icon-container" :class="$attrs['class']">
    <font-awesome-icon v-bind="forwardAttributes" />
    <b-popover v-if="title && iconEl" :target="iconEl" triggers="hover" custom-class="not-printable">
      <template #title></template>
      {{ title }}
    </b-popover>
  </span>
</template>
<script>
import _ from 'lodash'

export default {
  name: 'FontAwesomeIconWithTitle',
  inheritAttrs: false,
  props: ['title'],
  data () {
    return {
      // A simpler implementation passing target prop as function doesn't work currently:
      // https://github.com/bootstrap-vue-next/bootstrap-vue-next/issues/2038
      iconEl: null
    }
  },
  computed: {
    forwardAttributes () {
      return _.omit(this.$attrs, ['class'])
    }
  },
  mounted () {
    this.iconEl = this.$refs.icon
  }
}
</script>
