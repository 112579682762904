<template>
  <div class="geo-result">
    <form-group class="status" label="Status" v-bind="formGroupConfig" v-if="value.geoEnabled">
      <div class="vertical-center-form-field-text">
        <font-awesome-icon icon="triangle-exclamation" v-if="geoStatus !== 'success'" />
        {{ geoStatus }}
      </div>
    </form-group>
    <template v-if="value.geoLat || value.geoLng">
      <div class="map" :style="`width:${width}px'; height:${height}px`">
        <img :src="`https://maps.googleapis.com/maps/api/staticmap?center=${value.geoLat},${value.geoLng}&markers=%7C${value.geoLat},${value.geoLng}&zoom=14&size=${width}x${height}&key=${googleApiKey}`" />
      </div>
      <div class="field-value-table">
        <div v-if="value.geoAddress">
          <div>Address</div>
          <div>{{ value.geoAddress }} </div>
        </div>
        <div>
          <div>Latitude</div>
          <div>{{ _$.round(value.geoLat, 5) }}&deg;</div>
        </div>
        <div>
          <div>Longitude</div>
          <div>{{ _$.round(value.geoLng, 5) }}&deg;</div>
        </div>
        <div v-if="value.geoAccuracy">
          <div>Accuracy</div>
          <div>{{ formatDistance(value.geoAccuracy) }} (95% confidence)</div>
        </div>
        <div v-if="value.geoFenceEnabled">
          <div>Fence</div>
          <div>
            {{ formatDistance(value.geoDelta) }} from
            <router-link v-if="value.geoFence"
              :to="{ name: 'geo-rule-list', params: { view: value.geoFence } }"
            >{{ value.geoFenceName }}</router-link>
            <template v-else>center</template>
          </div>
        </div>
        <div v-if="Number.isFinite(travelDistance)">
          <div>Travel</div>
          <div>
            {{ formatDistance(value.geoDelta) }} from Home
          </div>
        </div>
        <div v-if="value.geoDt">
          <div>Timestamp</div>
          <div>{{ formatDateTime(value.geoDt) }}</div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import constants from '@/constants'
import { mapGetters } from 'vuex'

const oConstants = constants()

export default {
  name: 'ClockLogGeoResult',
  data () {
    return {
      width: 300,
      height: 200,
      googleApiKey: oConstants.googleApiKey
    }
  },
  props: {
    value: Object,
    formGroupConfig: Object,
    travelDistance: Number
  },
  computed: {
    ...mapGetters('formatPreferences', ['formatDateTime', 'formatDistance']),
    geoStatus () {
      const geoError = this.value.geoError
      if (!geoError) return 'success'
      if (geoError === 'geofence') return 'outside geofence'
      return geoError
    }
  }
}
</script>
<style lang="scss" scoped>

.geo-result {

  .status {
    text-transform: capitalize;
  }

  .vertical-center-form-field-text {
    padding-top: 5px;
  }

  .field-value-table {
    > div {
      display: flex;

      > div {
        &:first-of-type {
          width: 5rem;
          text-align: right;
          &::after {
            content: ':';
          }
        }
        &:last-of-type {
          margin-left: 1rem;
        }
      }
    }
  }
}
</style>
