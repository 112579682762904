<template>
  <b-modal
    ref="modal"
    title="Save Changes"
    ok-title="Yes"
    cancel-title="No"
    no-close-on-backdrop
    no-close-on-esc
    no-fade
    centered
    @ok="close(true)"
    @cancel="close(false)"
    @close="close(undefined)"
  >
    <p>
      This form has unsaved changes. Do you wish to save them before continuing?
    </p>
  </b-modal>
</template>
<script>
import { useModalController } from 'bootstrap-vue-next'

export default {
  setup () {
    return {
      hideModal: useModalController().hide
    }
  },
  methods: {
    close (result) {
      this.hideModal()
      this.$emit('close', result)
    }
  }
}
</script>
