<template>
  <b-form-input
    v-bind="$attrs"
    v-model="forwardValue"
    type="number"
    class="duration-decimal-input"
    :min="min"
    :max="max"
    :number="true"
  />
</template>
<script>
export default {
  name: 'DurationDecimalInput',
  props: {
    modelValue: [Number, String],
    min: {
      type: Number,
      default: 0
    },
    max: Number
  },
  emits: ['update:modelValue'],
  inheritAttrs: false,
  computed: {
    forwardValue: {
      get () {
        return this.modelValue && Number.parseFloat((this.modelValue / 60).toFixed(2))
      },
      set (v) {
        const minutes = isNaN(parseFloat(v)) ? null : Math.round(Number.parseFloat(v) * 60)
        this.$emit('update:modelValue', minutes)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.duration-decimal-input.form-control{
  text-align: right;
}
</style>
