<template>
  <div class="briefing-type-form">
    <b-card no-body class="mb-1">
      <b-card-body>
        <b-container>
          <b-row>
            <b-col cols="12" sm="6">
              <form-group :validator="v$.form.name" :label="fieldLabels.name">
                <template #default="slotProps">
                  <b-form-input
                    v-bind="slotProps"
                    v-model.trim="form.name"
                    type="text"
                  />
                </template>
              </form-group>
            </b-col>
            <b-col cols="12" sm="6">
              <form-group>
                <b-form-checkbox v-model="form.active">
                  Active
                </b-form-checkbox>
              </form-group>
            </b-col>
            <b-col cols="12" sm="6">
              <form-group :validator="v$.form.autoDeactivateOn">
                <template #label>
                  {{ fieldLabels.autoDeactivateOn }}
                  <help-text-icon>
                    Optionally set a date when this briefing will automatically deactivate,
                    and workers will no longer be shown this briefing.
                  </help-text-icon>
                </template>
                <template #default="slotProps">
                  <date-picker
                    v-bind="slotProps"
                    v-model="form.autoDeactivateOn"
                  />
                </template>
              </form-group>
            </b-col>
            <b-col cols="12" sm="6">
              <form-group :validator="v$.form.rebriefAfter">
                <template #label>
                  {{ fieldLabels.rebriefAfter }}
                  <help-text-icon>
                    Optionally set a frequency for how often a worker will be re-prompted with this briefing
                    after the previous time.
                  </help-text-icon>
                </template>
                <template #default="slotProps">
                  <iso-duration-single-input
                    v-bind="slotProps"
                    v-model="form.rebriefAfter"
                    @validityChanged="rebriefAfterValid = $event"
                  />
                </template>
              </form-group>
            </b-col>
          </b-row>
        </b-container>
      </b-card-body>
    </b-card>
    <b-card no-body class="mb-1 document-card" v-if="originalData.id">
      <b-card-body sub-title="Document">
        <b-container>
          <b-row>
            <b-col cols="12">
              <worker-documents
                attachToType="BRIEFING"
                :attachToId="originalData.id"
                :allowMultiple="false"
                :hasDocuments="true"
                :useForm="false"
                :isFormOpen="isFormOpen"
                :showing="isFormOpen"
                :readOnly="isDocumentReadOnly"
                :extensions="['pdf']"
                @documents-loaded="loadedDocumentCount = $event"
              />
              <div class="error-message" v-if="loadedDocumentCount === 0">
                <font-awesome-icon icon="triangle-exclamation" class="icon" />
                You need to upload a document for this briefing.
              </div>
            </b-col>
          </b-row>
        </b-container>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import _ from 'lodash'
import { useVuelidate } from '@vuelidate/core'
import { maxLength, required } from '@vuelidate/validators'
import DetailBasedForm from '@/mixins/DetailBasedForm'
import DatePicker from '@/components/DatePicker.vue'
import HelpTextIcon from '@/components/HelpTextIcon.vue'
import IsoDurationSingleInput from '@/components/IsoDurationSingleInput.vue'
import WorkerDocuments from '../WorkerDocuments.vue'

function NewItem () {
  return {
    active: true,
    name: null,
    autoDeactivateOn: null,
    rebriefAfter: null
  }
}

// TODO: Don't display Save & Close button if there are no documents.

export default {
  name: 'BriefingTypeForm',
  setup () {
    return {
      v$: useVuelidate({ $scope: false, $stopPropagation: true })
    }
  },
  inheritAttrs: false,
  mixins: [DetailBasedForm],
  components: {
    DatePicker,
    HelpTextIcon,
    IsoDurationSingleInput,
    WorkerDocuments
  },
  data () {
    return {
      form: NewItem(),
      fieldLabels: {
        name: 'Name',
        autoDeactivateOn: 'Auto deactivate',
        rebriefAfter: 'Rebrief after'
      },
      rebriefAfterValid: true,
      loadedDocumentCount: null
    }
  },
  computed: {
    formInvalid () {
      return this.v$.$invalid
    },
    invalidFields () {
      const fields = Object.keys(this.v$.form.$params)
        .filter(fieldName => this.v$.form[fieldName].$invalid)
      return fields
    },
    isDocumentReadOnly () {
      return !!this.originalData.id && (this.loadedDocumentCount === null || this.loadedDocumentCount > 0)
    }
  },
  watch: {
    originalData: {
      handler (newValue) {
        if (_.isEmpty(newValue)) {
          this.formDataChanged(NewItem())
        } else {
          this.formDataChanged(_.cloneDeep(newValue))
        }
      },
      immediate: true
    },
    formData (newValue) {
      this.form = newValue
    },
    formInvalid: {
      handler (value) {
        this.formInvalidChanged(value)
      },
      immediate: true
    }
  },
  validations () {
    return {
      form: {
        name: {
          required,
          maxLength: maxLength(50)
        },
        autoDeactivateOn: {
          // TODO: future date
        },
        rebriefAfter: {

        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/_bootstrap-variables';

.briefing-type-form {
  .document-card {
    .card-subtitle {
      text-align: center;
      text-decoration: underline;
      font-weight: bold;
      margin-bottom: 1rem !important;
    }
  }
}
</style>
