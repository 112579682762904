<template>
  <remote-multiselect
    ref="multiselect"
    class="worker-multiselect"
    :modelValue="modelValue"
    :id="contextId"
    label="name"
    track-by="id"
    :placeholder="placeholder"
    select-label=""
    deselect-label=""
    :serviceFetch="fetchWorkers"
    :disabled="disabled"
    :multiple="multiple"
    @update:modelValue="$emit('update:modelValue', $event)"
    @fullValueChange="$emit('fullValueChange', $event)"
  >
    <template #beforeList2 v-if="activeOption">
      <div class="show-inactive">
        <b-form-checkbox v-model="includeInactive" ref="checkbox" @change="onChangeCheckbox">
          Include inactive workers
        </b-form-checkbox>
      </div>
    </template>
    <template #option="{ option }">
      <div class="select-item">
        <avatar :url="option.avatar" size="3rem" />
        {{ option.name }}
      </div>
    </template>
  </remote-multiselect>
</template>
<script>
import Avatar from '@/components/Avatar.vue'
import RemoteMultiselect from '@/components/RemoteMultiselect.vue'
import EmployeeSelectionService from '@/modules/dashboard/services/EmployeeSelectionService'
import { mapGetters } from 'vuex'
import _ from 'lodash'

export default {
  name: 'WorkerMultiselect',
  components: {
    Avatar,
    RemoteMultiselect
  },
  props: {
    contextId: Number,
    modelValue: [Number, Array],
    placeholder: {
      type: String,
      default: 'Select a worker'
    },
    disabled: Boolean,
    multiple: Boolean,
    active: {
      type: String,
      default: 'active'
    },
    activeOption: Boolean,
    // TODO: Support non-workers with new prop, and if set then allowedForOrgUnit must be null, and activeOption must be false.
    allowedForOrgUnit: Number,
    queryParams: Object
  },
  emits: ['update:modelValue', 'fullValueChange'],
  data () {
    return {
      includeInactive: false
    }
  },
  computed: {
    ...mapGetters('formatPreferences', ['formatName'])
  },
  watch: {
    queryParams (newParams, oldParams) {
      if (!_.isEqual(newParams, oldParams)) {
        this.$refs.multiselect.refresh()
      }
    },
    includeInactive () {
      this.$refs.multiselect.refresh()
    }
  },
  methods: {
    fetchWorkers (searchText, value, limit) {
      const active = this.activeOption
        ? (this.includeInactive ? 'all' : 'active')
        : this.active

      // value should never be set when using allowedForOrgUnit
      const promise = this.allowedForOrgUnit
        ? EmployeeSelectionService.listAllowedForOrgUnit(this.allowedForOrgUnit, searchText, limit, true)
        : EmployeeSelectionService.list(searchText, null, limit, active, { id: value, ...this.queryParams })
            .then(data => data.results)

      return promise
        .then(items => items.map(item => ({
          ...item,
          name: this.formatName(item.firstName, item.lastName, item.middleName, item.displayName)
        })))
    },
    clear () {
      if (this.$refs.multiselect) {
        this.$refs.multiselect.clear()
      }
    },
    onChangeCheckbox () {
      // Prevent multiselect from closing after clicking on checkbox.
      // I couldn't figure out how to block the blur event in the first place.
      this.$refs.multiselect.$refs.select.$refs.select.activate()
    }
  }
}
</script>
<style lang="scss" scoped>
.worker-multiselect {
  .show-inactive {
    padding: 0.5rem 1rem;
    font-size: .8rem;
  }
}
</style>
