
export async function printWindow (w) {
  return new Promise(resolve => {
    if (window.matchMedia) {
      const mediaQueryList = w.matchMedia('print')
      const listener = mql => {
        if (!mql.matches) {
          mediaQueryList.removeListener(listener)
          resolve()
        }
      }

      mediaQueryList.addListener(listener)
    }

    // This listener doesn't appear to work on IE and Firefox, so also use onafterprint event as a fallback.
    w.addEventListener('afterprint', resolve, { once: true })

    w.print()

    // For backwards compatibility, handle case where MediaQueryList not supported.
    // In truth, all our supported browsers should support MediaQueryList, so we may
    // not need this.
    // By trial-and-error, 200 ms delay seems to be acceptable.
    if (!w.matchMedia) setTimeout(resolve, 200)
  })
}
