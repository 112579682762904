<template>
  <hover-icon v-show="!!id" icon="id-card" class="id-icon not-printable" :placement="placement">
    <slot>ID: {{ id }}</slot>
  </hover-icon>
</template>
<script>
import HoverIcon from './HoverIcon.vue'

export default {
  name: 'IdIcon',
  components: {
    HoverIcon
  },
  props: {
    id: [Number, String],
    placement: String
  }
}
</script>
