<template>
  <form-drawer
    class="filter-drawer"
    :visible="visible"
    @update:visible="onChangeFormDrawer"
    :no-close-on-route-change="pinned"
  >
    <div class="filter-header">
      <div>
        <b-button size="sm" variant="link" @click="pinned = !pinned">
          <font-awesome-icon icon="thumbtack" :class="pinned ? 'untack' : null" />
        </b-button>
      </div>
      <div class="title">Filter</div>
      <div></div>
    </div>
    <hr/>

    <slot></slot>

    <template #footer-buttons>
      <b-button variant="link" @click="clear" size="sm" class="action" :disabled="disabled">
        Clear
      </b-button>
      <b-button variant="primary" @click="apply" size="sm" class="action" :disabled="disabled">
        Apply
      </b-button>
    </template>
  </form-drawer>
</template>

<script>
import FormDrawer from './FormDrawer.vue'

export default {
  name: 'FilterDrawer',
  components: {
    FormDrawer
  },
  props: {
    visible: Boolean,
    disabled: Boolean
  },
  data () {
    return {
      pinned: false
    }
  },
  watch: {
    visible (visible) {
      if (!visible) {
        this.pinned = false
      }
    },
    pinned (pinned) {
      this.$emit('pinned', pinned)
    }
  },
  methods: {
    apply () {
      this.$emit('apply')
      if (this.visible && !this.pinned) this.toggleVisible()
    },
    clear () {
      this.$emit('clear')
    },
    toggleVisible () {
      this.$emit('update:visible', !this.visible)
    },
    onChangeFormDrawer (visible) {
      if (visible !== this.visible) {
        this.$emit('update:visible', visible)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.filter-drawer {
  .filter-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    .btn {
      text-align: left;
      svg {
        margin: auto;
        &.untack {
          transform: rotate(45deg);
        }
      }
    }
    .title {
      font-size: 1.1rem;
    }
    > * {
      flex: 1;
    }
  }
  :deep(.b-sidebar .action) {
    margin-right: .5rem;
  }
}
</style>
