export default {
  emits: ['validityChanged'],
  watch: {
    'v$.$invalid': {
      handler (value) {
        this.$emit('validityChanged', !value)
      },
      immediate: true
    }
  }
}
