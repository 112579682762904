<template>
  <div class="color-picker-button">
    <div class="form__input">
      <VSwatches swatches="text-advanced" v-model="forwardValue">
        <template #trigger>
          <div class="trigger" :style="swatchStyle">
            <font-awesome-icon icon="fill-drip" />
          </div>
        </template>
      </VSwatches>
    </div>
  </div>
</template>
<script>
import { VSwatches } from 'vue3-swatches'
import colorConvert from 'color-convert'

export default {
  name: 'ColorPickerButton',
  props: {
    modelValue: String
  },
  emits: ['update:modelValue'],
  components: {
    VSwatches
  },
  computed: {
    forwardValue: {
      get () {
        return this.modelValue
      },
      set (v) {
        this.$emit('update:modelValue', v)
      }
    },
    hslColor () {
      return colorConvert.hex.hsl(this.modelValue)
    },
    swatchStyle () {
      const hslColor = this.hslColor
      return {
        '--swatch-color-h': hslColor[0],
        '--swatch-color-s': `${hslColor[1]}%`,
        '--swatch-color-l': `${hslColor[2]}%`,
      }
    }
  }
}
</script>
<style lang="scss">
@import "vue3-swatches/dist/style.css";
</style>
<style lang="scss" scoped>

.color-picker-button {
  .trigger {

    --background-color: hsl(var(--swatch-color-h), var(--swatch-color-s), var(--swatch-color-l));
    --border-color: hsl(var(--swatch-color-h), var(--swatch-color-s), calc(var(--swatch-color-l) - 10%));
    --background-color-hover: hsl(var(--swatch-color-h), var(--swatch-color-s), calc(var(--swatch-color-l) + 10%));
    --border-color-hover: hsl(var(--swatch-color-h), var(--swatch-color-s), calc(var(--swatch-color-l) - 20%));

    border: 3px solid var(--border-color);
    border-radius: 50%;
    background-color: var(--background-color);
    padding: 10px;

    &:hover {
      background-color: var(--background-color-hover);
      border-color: var(--border-color-hover);
    }

    svg {
      color: white;
      font-size: 24px;
    }
  }
}
</style>
