import mitt from 'mitt'

// Wrapper factory that adds `once` function.
// https://github.com/developit/mitt/issues/136#issuecomment-866939653
export default function () {
  const emitter = mitt()

  emitter.once = (type, fn) => {
    emitter.on(type, fn)
    emitter.on(type, emitter.off.bind(emitter, type, fn))
  }

  return emitter
}
