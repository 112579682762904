<template>
  <master-detail-table
    resourceName='User Briefing'
    :crudService="crudService"
    :fields="fields"
    :parentKey="orgUserId"
    :isFormOpen="showing"
    :autoLoad="showing"
    :allowEdit="false"
  />
</template>
<script>
import MasterDetailTable from '@/components/MasterDetailTable.vue'
import restClient from '@/services/clients/rest'
import { mapGetters } from 'vuex'

export default {
  name: 'UserBriefingList',
  components: {
    MasterDetailTable
  },
  props: {
    orgUserId: Number,
    showing: Boolean
  },
  data () {
    return {
      crudService: {
        list: this.list,
        delete: this.delete
      },
      fields: [
        {
          key: 'briefing',
          formatter: value => this.supplementalData?.briefing?.[value]?.name
        },
        {
          key: 'created',
          label: 'Briefed at',
          formatter: value => value ? this.formatDateTime(value) : null
        },
        {
          key: 'rebriefDt',
          label: 'Rebrief on',
          formatter: value => value ? this.formatDateTime(value) : null
        },
        { key: 'action', label: ' ', class: 'action' }
      ],
      supplementalData: null
    }
  },
  computed: {
    ...mapGetters('formatPreferences', ['formatDateTime'])
  },
  methods: {
    list (params) {
      return restClient.get('briefing/users', { params: { worker: this.orgUserId }})
        .then(response => {
          this.supplementalData = response.data.supplementalData
          return response.data.results
        })
    },
    delete (itemId, itemIndex, item) {
      return restClient.delete(`briefing/users/${itemId}`).then(response => {})
    }
  }
}
</script>
