import restClient from '@/services/clients/rest'
import { createRestCrudService } from '@/services/GenericCrudService'

const resourceName = 'Clock Log'
const resourcePath = 'clocklogs'
const crudService = createRestCrudService(resourceName, resourcePath)

export default Object.assign(crudService, {
  async attachPunch (clockLogId, punchId, punchType, costingSelection) {
    try {
      const response = await restClient.post(`${resourcePath}/${clockLogId}/attach-punch/${punchId}/${punchType}`, costingSelection)
      return response.data
    } catch (error) {
      console.warn('Error attaching clock log to punch', error)
      throw error
    }
  },
  async loadShiftData (clockLogId) {
    try {
      const response = await restClient.get(`${resourcePath}/${clockLogId}/shift-data`)
      return response.data
    } catch (error) {
      console.warn('Error loading clock log shift data', error)
      throw error
    }
  },
  async decideClockLog (clockLogId, decision, data) {
    try {
      const response = await restClient.post(`${resourcePath}/${clockLogId}/${decision}`, data)
      return response.data
    } catch (error) {
      console.warn('Error deciding clock log', error)
      throw error
    }
  },
  async applyBulkAction (actionData, itemIds) {
    try {
      const response = await restClient.post(`${resourcePath}/bulk-action`, {
        itemIds,
        actionData
      })
      return response.data
    } catch (error) {
      console.warn(`Error submitting bulk action`, error, actionData)
      throw error
    }
  },
  async listAsync (params) {
    return restClient.listAsync(resourcePath, params)
      .then(response => response.data)
      .catch(error => {
        console.warn('Error retrieving clock logs', error)
        return Promise.reject(error)
      })
  }
})
