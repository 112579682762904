<template>
  <detail-form-modal v-slot="slotProps"
    resourceName="Geo Fence"
    auditResourceName="GEORULE"
    :crudService="crudService"
    :itemId="itemId"
    dialog-class="geo-detail-form-modal-dialog"
    @close="$emit('close')"
    @saved="$emit('saved', $event)"
    @deleted="$emit('deleted', $event)"
    @apply-grid-transaction="$emit('apply-grid-transaction', $event)"
  >
    <geo-rule-form v-bind="slotProps" />
  </detail-form-modal>
</template>
<script>
import DetailFormModal from '@/components/DetailFormModal.vue'
import GeoRuleForm from './GeoRuleForm.vue'
import crudService from './services/GeoRuleService'

export default {
  name: 'GeoRuleFormModal',
  components: {
    DetailFormModal,
    GeoRuleForm
  },
  props: {
    itemId: Number
  },
  data () {
    return {
      crudService
    }
  }
}
</script>
<style lang="scss">
.geo-detail-form-modal-dialog {
  max-width: 600px !important;
}
</style>
