<template>
  <div role="tablist">
    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1 basic-information" role="tab">
        <b-btn block v-b-toggle.accordionBasic :variant="v$.$validationGroups.basicInformationGroup.$invalid ? 'danger' : 'dark'">
          <span class="text">Basic Information</span>
          <font-awesome-icon :icon="allTabsExpanded ? 'angle-double-up' : 'angle-double-down'" size="lg" class="toggle-all-tabs" @click.stop="toggleAllTabs" />
        </b-btn>
      </b-card-header>
      <b-collapse id="accordionBasic" ref="accordionBasic" visible :accordion="accordionId" role="tabpanel">
        <b-card-body>
          <b-container>
            <b-row>
              <b-col cols="12" sm="6">
                <form-group :validator="v$.form.name" :label="fieldLabels.name">
                  <template #default="slotProps">
                    <b-form-input
                      v-bind="slotProps"
                      v-model.trim="form.name"
                      type="text"
                    />
                  </template>
                </form-group>
              </b-col>
              <b-col cols="12" sm="6">
                <form-group>
                  <b-form-checkbox v-model="form.active">
                    Active
                  </b-form-checkbox>
                </form-group>
              </b-col>
                <b-col cols="12" sm="6">
                  <form-group :validator="v$.form.payrollId">
                    <template #label>
                      {{ fieldLabels.payrollId }}
                      <help-text-icon>
                        Payroll ID is used in conjunction with exporting to certain payroll
                        and costing services such as ADP, Paychex and Quickbooks, in order
                        to identify this job.
                      </help-text-icon>
                    </template>
                    <template #default="slotProps">
                      <b-form-input
                        v-bind="slotProps"
                        v-model.trim="form.payrollId"
                        type="text"
                      />
                    </template>
                  </form-group>
                </b-col>
                <b-col cols="12" sm="6">
                  <form-group>
                    <template #label>
                      Labels
                      <help-text-icon>
                        Labels may be defined under Settings => Organization => Labels.
                      </help-text-icon>
                    </template>
                    <template #default="slotProps">
                      <label-select
                        v-bind="slotProps"
                        v-model="form.labels"
                        :id="form.id"
                        labelType="work"
                      />
                    </template>
                  </form-group>
                </b-col>
                <b-col cols="12" sm="6">
                  <form-group>
                      <template #label>
                        Description
                        <help-text-icon>
                          Description is an optional field that gets displayed in the
                          mobile app job selection list.
                        </help-text-icon>
                      </template>
                    <b-form-textarea
                      v-model="form.description"
                      :rows="3"
                      :max-rows="6">
                    </b-form-textarea>
                  </form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" sm="6" v-if="enableBriefings">
                  <form-group :validator="v$.form.briefings">
                    <template #label>
                      {{ fieldLabels.briefings }}
                      <help-text-icon>
                        Specify what briefings must be read by worker in order to clock IN to this job.
                        Requires mobile app version 3.3 or later.
                      </help-text-icon>
                    </template>
                    <template #default="slotProps">
                      <briefing-type-select
                        v-bind="slotProps"
                        v-model="form.briefings"
                        :id="form.id"
                        :multiple="true"
                      />
                    </template>
                  </form-group>
                </b-col>
                <b-col cols="12" sm="6" v-if="credentialsEnabled">
                  <form-group :validator="v$.form.credentialTypes">
                    <template #label>
                      {{ fieldLabels.credentialTypes }}
                      <help-text-icon>
                        Specify the required credential types for this job.
                        If a worker is missing a credential of any of these types,
                        or if one of those credentials is invalid (e.g., expired, or missing documentation),
                        then the worker will not be able to clock IN to this job.
                      </help-text-icon>
                    </template>
                    <template #default="slotProps">
                      <credential-type-select
                        v-bind="slotProps"
                        v-model="form.credentialTypes"
                        :id="form.id"
                        :multiple="true"
                      />
                    </template>
                  </form-group>
                </b-col>
            </b-row>
          </b-container>
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body class="mb-1 permissions-card">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-btn block v-b-toggle.accordionPermissions :variant="v$.$validationGroups.permissionsGroup.$invalid ? 'danger' : 'dark'">Permissions</b-btn>
      </b-card-header>
      <b-collapse id="accordionPermissions" ref="accordionPermissions" :accordion="accordionId" role="tabpanel">
        <b-card-body>
          <b-container>
            <b-row>
              <b-col xl="8" lg="10" md="10" cols="12">
                <field-conditions
                  v-model="form.allowLabels"
                  :parentId="originalData.id"
                  :enableUserOrgUnit="true"
                  @validityChanged="costingPermissionsValid = $event"
                />
                <div v-if="geoEnabled" class="and-separator">-- AND --</div>
                <b-card v-if="geoEnabled" sub-title="Geo fences">
                  <form-group v-bind="permissionsLabelConfig">
                    <template #label>
                      IN Geo Fences
                      <help-text-icon>
                        A geo fence is an optional field, used to enforce the geographical location where this user can Clock IN or OUT.
                        Geo fences may be edited under <b>Settings -&gt; Devices -&gt; Geo Fences</b>.
                      </help-text-icon>
                    </template>
                    <geo-rule-select
                      v-model="form.inGeoRules"
                      :id="form.id"
                      @item-added="geoFenceCreated($event.id)"
                    />
                  </form-group>
                  <form-group v-if="bgGeoEnabled" v-bind="permissionsLabelConfig">
                    <template #label>
                      Background Geo Fences
                    </template>
                    <geo-rule-select
                      v-model="form.bgGeoRules"
                      :id="form.id"
                      @item-added="geoFenceCreated($event.id)"
                    />
                  </form-group>
                  <form-group v-bind="permissionsLabelConfig">
                    <template #label>
                      OUT Geo Fences
                    </template>
                    <geo-rule-select
                      v-model="form.outGeoRules"
                      :id="form.id"
                      @item-added="geoFenceCreated($event.id)"
                    />
                  </form-group>
                </b-card>
              </b-col>
            </b-row>

            <b-row>
                <b-col cols="12" sm="6" v-if="showOrgUsers">
                  <form-group :validator="v$.form.orgUsers">
                    <template #label>
                      {{ fieldLabels.orgUsers }}
                      <help-text-icon>
                        This setting applies for the Administrator role only.
                        It specifies which restricted admins who can view or edit punches for these jobs.
                        A restricted admin is an admin who has the permission setting "Required job access for admin".
                        A non-restricted admin will have access to all jobs, including this one.
                        <code>Account Owner</code>s always have access to all jobs.
                      </help-text-icon>
                    </template>
                    <remote-multiselect
                      v-model="form.orgUsers"
                      :multiple="true"
                      label="name"
                      track-by="id"
                      select-label=""
                      deselect-label=""
                      :serviceFetch="fetchRestrictedAdmins"
                      :limit="50"
                    />
                  </form-group>
                </b-col>
                <b-col cols="12" sm="6" v-if="canEditAdministrators">
                  <form-group :validator="v$.form.notifyOrgUsers">
                    <template #label>
                      {{ fieldLabels.notifyOrgUsers }}
                      <help-text-icon>
                        This setting applies for the Administrator role only.
                        It specifies which administrators receive notifications related to this job,
                        such as clocking IN with invalid credentials.
                      </help-text-icon>
                    </template>
                    <remote-multiselect
                      v-model="form.notifyOrgUsers"
                      :multiple="true"
                      label="name"
                      track-by="id"
                      select-label=""
                      deselect-label=""
                      :serviceFetch="fetchAdmins"
                      :limit="50"
                    />
                  </form-group>
                </b-col>
              </b-row>
          </b-container>
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body class="mb-1" v-if="enhancedCostingEnabled">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-btn block v-b-toggle.accordionEnhanced variant="dark">Enhanced Costing</b-btn>
      </b-card-header>
      <b-collapse id="accordionEnhanced" ref="accordionEnhanced" :accordion="accordionId" role="tabpanel">
        <b-card-body>
          <b-container>
            <b-row v-if="jobCategoryEnabled">
              <b-col cols="12" sm="6">
                <form-group :validator="v$.form.jobCategory">
                  <template #label>
                    {{ fieldLabels.jobCategory }}
                    <help-text-icon>
                      If set, job will be organized under selected category, and can be accessed that way in the time clock.
                      <br/><br/>
                      Job Categories may be added under <b>Settings =&gt; Costing =&gt; Job Categories</b>.
                    </help-text-icon>
                  </template>
                  <template #default="slotProps">
                    <key-multiselect
                      v-bind="slotProps"
                      v-model="form.jobCategory"
                      label="name"
                      track-by="id"
                      select-label=""
                      deselect-label=""
                      :options="sortedJobCategories">
                    </key-multiselect>
                  </template>
                </form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" sm="6">
                <form-group>
                  <template #label>
                    Job Phase mode
                    <help-text-icon>
                      Specifies whether a job phase may or must be selected when clocking into a job.
                      <br/><br/>
                      Job Phases may be added under <b>Settings =&gt; Costing =&gt; Job Phases</b>.
                    </help-text-icon>
                  </template>
                  <div class="flex flex-wrap gap-3">
                    <b-form-radio v-model="form.jobPhaseMode" :value="null">Off</b-form-radio>
                    <b-form-radio v-model="form.jobPhaseMode" value="enable">Optional</b-form-radio>
                    <b-form-radio v-model="form.jobPhaseMode" value="require">Require</b-form-radio>
                  </div>
                </form-group>
              </b-col>
              <b-col cols="12" sm="6">
                <form-group>
                  <template #label>
                    Cost Code mode
                    <help-text-icon>
                      Specifies whether a cost code may or must be selected when clocking into a job.
                      <br/><br/>
                      Cost Codes may be added under <b>Settings =&gt; Costing =&gt; Cost Codes</b>.
                    </help-text-icon>
                  </template>
                  <div class="flex flex-wrap gap-3">
                    <b-form-radio v-model="form.costCodeMode" :value="null">Off</b-form-radio>
                    <b-form-radio v-model="form.costCodeMode" value="enable">Optional</b-form-radio>
                    <b-form-radio v-model="form.costCodeMode" value="require">Require</b-form-radio>
                  </div>
                </form-group>
              </b-col>
            </b-row>
          </b-container>
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body class="mb-1" v-if="scheduleEnabled">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-btn block v-b-toggle.accordionShift variant="dark">Shift Overrides</b-btn>
      </b-card-header>
      <b-collapse id="accordionShift" ref="accordionShift" :accordion="accordionId" role="tabpanel">
        <b-card-body>
          <b-container>
            <b-row>
              <b-col cols="12" sm="6">
                <b-form-checkbox
                        v-model="form.enableShiftSchedule"
                        :value="true"
                        :unchecked-value="false">
                    Override Employee Shift Calendar for this Job
                    <help-text-icon>
                      If set, this job's shift schedule will override the employee shift schedule, as well as any device shift schedule.
                      This schedule is applied when the employee is not already in the middle of a shift.
                      If the employee is already in the middle of a shift, then that shift will continue.
                    </help-text-icon>
                </b-form-checkbox>
              </b-col>
              <b-col cols="12" v-if="form.enableShiftSchedule">
                <b-btn v-if="originalData.id" class="open-shift-calendar" variant="outline-primary" @click="openShiftCalendar">
                  Open Shift Calendar for this Job
                </b-btn>
                <div style="margin-top: 1rem" v-else>
                  <font-awesome-icon icon="circle-info" style="margin-right: .25rem" />
                  After you save this new Job, you'll be able to edit its shift calendar.
                </div>
                <div v-if="shiftMode === 'schedule' && !originalData.enableShiftSchedule && form.enableShiftSchedule" class="shift-schedule-warning">
                  <font-awesome-icon icon="triangle-exclamation" />
                  If you don't set a shift schedule, then no one will be able to clock IN to this job!
                </div>
              </b-col>
            </b-row>
          </b-container>
        </b-card-body>
      </b-collapse>
    </b-card>

  </div>
</template>
<script>
import Accordion from '@/mixins/Accordion'
import FieldConditions from '@/components/FieldConditions.vue'
import HelpTextIcon from '@/components/HelpTextIcon.vue'
import KeyMultiselect from '@/components/KeyMultiselect.vue'
import RemoteMultiselect from '@/components/RemoteMultiselect.vue'
import _ from 'lodash'
import { mapGetters, mapState } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import { helpers, maxLength, required } from '@vuelidate/validators'
import DetailBasedForm from '@/mixins/DetailBasedForm'
import BriefingTypeSelect from '@/views/settings/employees/credential/BriefingTypeSelect.vue'
import CredentialTypeSelect from '@/views/settings/employees/credential/CredentialTypeSelect.vue'
import GeoRuleSelect from '@/views/settings/devices/GeoRuleSelect.vue'
import LabelSelect from '@/views/settings/organization/LabelSelect.vue'
import EmployeeService from '@/views/settings/employees/services/EmployeeService'
import { useModalController } from 'bootstrap-vue-next'

const NewItem = () => ({
  active: true,
  allowLabels: [],
  orgUsers: [],
  notifyOrgUsers: [],
  labels: [],
  inGeoRules: [],
  bgGeoRules: [],
  outGeoRules: [],
  enableShiftSchedule: false,
  briefingTypes: [],
  credentialTypes: []
})

export default {
  name: 'JobForm',
  setup () {
    return {
      v$: useVuelidate({ $scope: false, $stopPropagation: true }),
      showModal: useModalController().show
    }
  },
  inheritAttrs: false,
  mixins: [
    Accordion,
    DetailBasedForm
  ],
  components: {
    BriefingTypeSelect,
    CredentialTypeSelect,
    FieldConditions,
    GeoRuleSelect,
    HelpTextIcon,
    KeyMultiselect,
    LabelSelect,
    RemoteMultiselect
  },
  data () {
    return {
      form: NewItem(),
      costingPermissionsValid: true,
      fieldLabels: {
        briefings: 'Briefings',
        credentialTypes: 'Credential Types',
        name: 'Name',
        orgUsers: 'Restricted admin access',
        notifyOrgUsers: 'Admins receiving notifications',
        payrollId: 'Payroll ID',
        jobCategory: 'Job category'
      },
      permissionsLabelConfig: {
        labelCols: 12,
        labelColsSm: 6,
        labelColLg: 4,
        labelColsXl: 4
      }
    }
  },
  computed: {
    ...mapGetters([
      'bgGeoEnabled',
      'credentialsEnabled',
      'enableBriefings',
      'enhancedCostingEnabled',
      'geoEnabled',
      'jobCategoryEnabled',
      'scheduleEnabled',
      'canRestrictJobAccessByUser',
      'canEditAdministrators',
      'requireJobAccess'
    ]),
    ...mapGetters({
      jobCategories: 'jobCategories/sortedItems',
      shiftClasses: 'shiftClasses/sortedItems'
    }),
    ...mapGetters('formatPreferences', ['formatName']),
    ...mapState(['shiftMode']),
    formInvalid () {
      return this.v$.$invalid
    },
    sortedJobCategories () {
      return this.jobCategories('name')
    },
    sortedShiftClasses () {
      return this.shiftClasses('name')
    },
    showOrgUsers () {
      return this.canRestrictJobAccessByUser && this.canEditAdministrators && !this.requireJobAccess
    }
  },
  watch: {
    originalData: {
      handler (newValue) {
        if (_.isEmpty(newValue)) {
          this.formDataChanged(NewItem())
        } else {
          // clean up some inconsistencies in original legacy data
          newValue.enableShiftSchedule = !!newValue.enableShiftSchedule
          newValue.orgUsers = newValue.orgUsers || []
          newValue.briefings = newValue.briefings || []
          newValue.credentialTypes = newValue.credentialTypes || []

          this.formDataChanged(_.cloneDeep(newValue))
        }
      },
      immediate: true
    },
    formData (newValue) {
      this.form = newValue
    },
    formInvalid: {
      handler (value) {
        this.formInvalidChanged(value)
      },
      immediate: true
    }
  },
  methods: {
    openShiftCalendar () {
      import(/* webpackChunkName: "schedule-modal" */ '@/views/manage/schedule/ScheduleModal.vue')
        .then(module => {
          const ScheduleModal = module.default
          this.showModal({
            component: ScheduleModal,
            props: {
              resourceTypeId: 'job',
              resourceId: this.originalData.id,
              resourceName: this.originalData.name
            }
          })
        })
    },
    fetchRestrictedAdmins (searchText, value, limit) {
      return this.fetchAdmins(searchText, value, limit, { requireJobAccess: 'yes' })
    },
    fetchAdmins (searchText, value, limit, extraParams) {
      return EmployeeService
        .list({
          searchText,
          active: value ? 'all' : 'active',
          roles: 'admin',
          id: value,
          limit,
          ...(extraParams || {})
        })
        .then(data => _.get(data, 'results', []))
        .then(items => items.map(item => ({
          ...item,
          name: this.formatName(item.firstName, item.lastName, item.middleName, item.displayName)
        })))
    },
    geoFenceCreated (geoRuleId) {
      this.form.inGeoRules = this.form.inGeoRules.concat(geoRuleId)
      this.form.outGeoRules = this.form.outGeoRules.concat(geoRuleId)
      if (this.bgGeoEnabled) {
        this.form.bgGeoRules = this.form.bgGeoRules.concat(geoRuleId)
      }
    }
  },
  mounted () {
    if (this.jobCategoryEnabled) {
      this.$store.dispatch('jobCategories/load')
    }
    if (this.scheduleEnabled) {
      // Load shift classes for ResourceCalendarWindow.
      this.$store.dispatch('shiftClasses/load')
    }
  },
  validations () {
    return {
      $validationGroups: {
        basicInformationGroup: ['form.name', 'form.payrollId'],
        permissionsGroup: ['costingPermissionsValid', 'form.allowLabels', 'form.orgUsers'],
      },
      form: {
        name: {
          required,
          maxLength: maxLength(100)
        },
        allowLabels: {},
        orgUsers: {},
        notifyOrgUsers: {},
        payrollId: {
          maxLength: maxLength(100)
        },
        jobCategory: {},
        briefings: {
          maxLength: helpers.withMessage('Job may not have more than 10 active briefings.', maxLength(10))
        },
        credentialTypes: {
          maxLength: helpers.withMessage('Job may not have more than 20 active credential types.', maxLength(20))
        }
      },
      costingPermissionsValid: {
        childValid: () => !!this.costingPermissionsValid
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables';

.multiselect {
  max-width: 20rem;
}

.open-shift-calendar {
  margin-top: 1rem;
}
.shift-schedule-warning {
  margin-top: .5rem;
  color: $flat-ui-carrot;
  svg {
    margin-right: .25rem;
  }
}
.permissions-card {
  .or-separator {
    text-align: center;
    margin: 10px 0;
  }
  .and-separator {
    text-align: center;
    margin: 10px 0;
  }
}
</style>
