<template>
  <div class="csv-file-upload">
    <h4 v-if="dropActive" class="drop-title">Drop file here</h4>
    <template v-else>
      <h6 class="upload-title">Import {{ pluralResourceName }} from CSV file</h6>
      <div class="upload-prompt">
        <font-awesome-icon :icon="['far', 'file-arrow-up']" />
        <label :for="fileInputId" class="choose-file">Choose file</label>
        <span>or drop file anywhere to upload</span>
      </div>
    </template>

    <file-upload
      v-model="files"
      :input-id="fileInputId"
      :drop="dropContainer"
      :drop-directory="false"
      :multiple="false"
      @input-filter="inputFilter"
      @input-file="inputFile"
      @drop-active-changed="dropActive = $event"
    />

    <div v-if="errorMessage" class="error-message" key="error-message">
      <font-awesome-icon icon="triangle-exclamation" />
      {{ errorMessage }}
    </div>
  </div>
</template>
<script>
import FileUpload from '@/components/FileUpload.vue'
import { pluralize } from 'inflection'
import { parse as csvParse } from 'csv-parse/browser/esm/sync'

export default {
  name: 'CsvFileUpload',
  components: {
    FileUpload
  },
  props: {
    resourceName: {
      type: String,
      default: 'Item'
    },
    itemsUploaded: Function
  },
  data () {
    return {
      dropContainer: null,
      dropActive: false,
      files: [],
      errorMessage: null
    }
  },
  computed: {
    file () {
      return this.files.length === 1 ? this.files[0] : null
    },
    fileInputId () {
      return `file-input-${this.$.uid}`
    },
    pluralResourceName () {
      return pluralize(this.resourceName)
    }
  },
  watch: {
    dropActive () {
      this.errorMessage = null
    }
  },
  methods: {
    inputFilter (newFile, oldFile, prevent) {
      if (newFile && !/\.(csv)$/i.test(newFile.name)) {
        console.log(`invalid input file name ${newFile.name}`)
        this.errorMessage = 'Only .csv files are accepted.'
        return prevent()
      }
    },
    inputFile (file) {
      this.errorMessage = null
      if (!file) return // sends dupe event with undefined file for some reason
      this.parseFile(file.file)
    },
    parseFile (file) {
      const reader = new FileReader()
      reader.onload = (e) => {
          const text = e.target.result
          const isCsv = file.name.toLowerCase().endsWith('.csv')
          const delimiter = isCsv ? ',' : '\t'
          let json
          try {
            json = csvParse(text, { delimiter, trim: true })
          } catch (error) {
            const fileType = isCsv ? 'csv' : 'tsv'
            console.warn(`Error parsing ${fileType} file`, error)
            this.errorMessae = `File is not valid ${fileType}.<br><br>(${error.message})`
          }
          this.onFileParsed(json)
          // console.log(`parsed ${isCsv ? 'csv' : 'tsv'} json`, json)
      }
      reader.readAsText(file)
    },
    onFileParsed (rowData) {
      if (!rowData || rowData.length < 1) {
        this.errorMessage = 'Your file has to have at least 1 row.'
      } else {
        this.itemsUploaded(rowData)
          .catch(error => {
            this.errorMessage = error.message
          })
      }
    }
  },
  mounted () {
    this.dropContainer = this.$el
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/app/app';

.csv-file-upload {

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border: 1px dashed #333;
  padding: 10px;
  text-align: center;
  margin-bottom: 15px;

  .drop-title {
    margin-bottom: 0;
  }

  .upload-title {
    font-weight: bold;
  }

  .upload-prompt {
    display: flex;
    flex-direction: row;
    justify-content: center;
    svg {
      margin-right: 10px;
      font-size: 1.2rem;
    }
    .choose-file {
      margin-bottom: 0;
      margin-right: 5px;
      color: $fc-logo-secondary-blue;
      &:hover, &:active {
        color: $flat-ui-carrot;
      }
    }
  }

  .error-message {
    svg {
      margin-right: .25rem;
    }
  }
}
</style>
