<template>
  <maz-phone-number-input
    size="sm"
    v-bind="$attrs"
    v-model="forwardValue"
    @update="onUpdate"
  />
</template>
<script>
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput'
import _ from 'lodash'

export default {
  name: 'E164PhoneNumberInput',
  inheritAttrs: false,
  components: {
    MazPhoneNumberInput
  },
  props: ['modelValue'],
  emits: ['update:modelValue', 'validityChanged'],
  data () {
    return {
      isValid: false
    }
  },
  computed: {
    forwardValue: {
      get () {
        return this.modelValue
      },
      set (v) {
        this.$emit('update:modelValue', v || null)
      }
    }
  },
  watch: {
    isValid (isValid) {
      this.$emit('validityChanged', isValid)
    }
  },
  methods: {
    onUpdate (data) {
      this.isValid = data.isValid
    }
  }
}
</script>
<style lang="scss">
// Use private copied maz ui style with its reset.css manually removed.
// Then we'll add reset.css below only within this component.
// https://github.com/LouisMazel/maz-ui/issues/981
@import '/css/maz-ui.css';

.m-phone-number-input {

  // Apply reset.css only in this component.
  // https://github.com/LouisMazel/maz-ui/blob/master/packages/lib/css/reset.css
  *,
  ::before,
  ::after {
    border-width: 0;
    border-style: solid;
    border-color: currentcolor;
    box-sizing: border-box;
  }

  button {
    background-color: transparent;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit;
    font-feature-settings: inherit;
    font-variation-settings: inherit;
    font-size: 100%;
    font-weight: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    color: inherit;
    margin: 0;
  }
}
</style>
