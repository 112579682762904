<template>
  <key-multiselect
    v-bind="$attrs"
    v-model="forwardValue"
    :multiple="multiple"
    label="name"
    track-by="id"
    select-label=""
    deselect-label=""
    resourceName="Shift Class"
    :options="sortedShiftClasses"
    :showAddNewItem="canEditOrgSettings"
    @add-new-item="addNewItem"
  />
</template>
<script>
import KeyMultiselect from '@/components/KeyMultiselect.vue'
import { mapGetters } from 'vuex'
import { forceArray } from '@/utils/misc'
import ShiftClassFormModal from './ShiftClassFormModal.vue'
import { useModalController } from 'bootstrap-vue-next'
import { computed, ref } from 'vue'

export default {
  name: 'ShiftClassSelect',
  setup () {
    return {
      showModal: useModalController().show
    }
  },
  inheritAttrs: false,
  components: {
    KeyMultiselect
  },
  props: {
    modelValue: [Number, Array],
    multiple: {
      type: Boolean,
      default: false
    },
    activeOnly: {
      type: Boolean,
      default: false
    }
  },
  emits: ['item-added', 'update:modelValue'],
  computed: {
    ...mapGetters(['canEditOrgSettings']),
    ...mapGetters({
      shiftClasses: 'shiftClasses/sortedItems'
    }),
    sortedShiftClasses () {
      const shiftClasses = this.shiftClasses('name')
      if (this.activeOnly) {
        const values = new Set(forceArray(this.modelValue))
        return shiftClasses.filter(shiftClass => shiftClass.active !== false || values.has(shiftClass.id))
      } else return shiftClasses
    },
    forwardValue: {
      get () {
        return this.modelValue
      },
      set (v) {
        this.$emit('update:modelValue', v)
      }
    }
  },
  methods: {
    addNewItem () {
      const itemId = ref(null)
      this.showModal({
          component: ShiftClassFormModal,
          props: computed(() => ({
            itemId: itemId.value,
            onSaved: item => {
              itemId.value = item.id
              this.$emit('update:modelValue', item.id)
              this.$emit('item-added', item)
            }
          }))
        })
    }
  },
  mounted () {
    this.$store.dispatch('shiftClasses/load')
  }
}
</script>
