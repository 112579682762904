<template>
  <master-detail-table
    resourceName='Pay Rate'
    saveText="Apply"
    :crudService="crudService"
    :showNewItemButton="true"
    :newItemFactory="newItemFactory"
    :fields="fields"
    :parentKey="parentKey"
    :formInvalid="formInvalid"
    :isFormOpen="isFormOpen"
  >
    <template #detail-form="{ value }">
      <pay-rate-form
        :value="value"
        :defaultHourly="defaultHourly"
        :defaultSalary="defaultSalary"
        :hourlyStartDates="hourlyStartDates"
        :salaryStartDates="salaryStartDates"
        @invalidChanged="formInvalid = $event"
      />
    </template>
  </master-detail-table>
</template>
<script>
import MasterDetailTable from '@/components/MasterDetailTable.vue'
import PayRateForm from './PayRateForm.vue'
import _ from 'lodash'
import { mapGetters } from 'vuex'

const NewItem = () => ({
  type: null,
  rate: null,
  interval: null,
  start: null
})

export default {
  name: 'PayRateList',
  components: {
    MasterDetailTable,
    PayRateForm
  },
  props: {
    value: Array,
    parentKey: [Number, String],
    isFormOpen: Boolean
  },
  data () {
    return {
      fields: [
        {
          key: 'type',
          formatter: _.capitalize
        },
        {
          key: 'rate',
        },
        {
          key: 'interval',
          formatter: _.capitalize
        },
        {
          key: 'start',
          formatter: value => value ? this.formatNaiveDate(value) : '(default)'
        },
        { key: 'action', label: ' ', class: 'action' }
      ],
      crudService: {
        list: this.list,
        create: this.create,
        update: this.update,
        delete: this.delete
      },
      newItemFactory: NewItem,
      formInvalid: false
    }
  },
  computed: {
    ...mapGetters('formatPreferences', ['formatNaiveDate']),
    defaultHourly () {
      return this.defaultPayRate('hourly')
    },
    defaultSalary () {
      return this.defaultPayRate('salary')
    },
    hourlyStartDates () {
      return this.payRateStartDates('hourly')
    },
    salaryStartDates () {
      return this.payRateStartDates('salary')
    }
  },
  methods: {
    list () {
      return Promise.resolve({ results: this.value?.map(
        (payRate, idx) => Object.assign({id: idx, ...payRate})) ?? [] })
    },
    create (payRate) {
      this.value.push({...payRate})
      return Promise.resolve({results: [Object.assign({}, payRate, {id: this.value.length - 1})]})
    },
    update (item) {
      const {id, ...payRate} = item
      if (!Number.isInteger(id)) return Promise.reject(`numeric id must be specified`)
      if (id < 0 || id > this.value.length -1) return Promise.reject(`id ${id} out of range`)
      this.value[id] = payRate
      return Promise.resolve({results: [item]})
    },
    delete (id) {
      if (!Number.isInteger(id)) return Promise.reject(`numeric id must be specified`)
      if (id < 0 || id > this.value.length - 1) return Promise.reject(`id ${id} out of range`)
      this.value.splice(id, 1)
      return Promise.resolve()
    },
    defaultPayRate (type) {
      const idx = (this.value || []).findIndex(payRate => payRate.type === type && !payRate.start)
      return idx > -1 ? idx : null
    },
    payRateStartDates (type) {
      return new Map((this.value || [])
        .filter(payRate => payRate.type === type)
        .filter(payRate => payRate.start)
        .map((payRate, idx) => [payRate.start, idx]))
    }
  }
}
</script>
