<template>
  <div class="sidebar">
    <SidebarHeader/>
    <SidebarForm/>
    <nav class="sidebar-nav">
      <ul class="nav" v-if="visible">
        <template v-for="(item, index) in visibleNavItems">
          <template v-if="item.title">
            <SidebarNavTitle :key="index" :name="item.name" :classes="item.class" :wrapper="item.wrapper"/>
          </template>
          <template v-else-if="item.divider">
            <SidebarNavDivider :key="index" :classes="item.class"/>
          </template>
          <template v-else-if="item.label">
            <SidebarNavLabel :key="index" :name="item.name" :url="item.url" :icon="item.icon" :label="item.label" :classes="item.class"/>
          </template>
          <template v-else>
            <template v-if="item.children">
              <!-- First level dropdown -->
              <SidebarNavDropdown :key="index" :name="item.name" :url="item.url" :icon="item.icon">
                <template v-for="(childL1, index) in item.children">
                  <template v-if="childL1.children">
                    <!-- Second level dropdown -->
                    <SidebarNavDropdown :key="index" :name="childL1.name" :url="childL1.url" :icon="childL1.icon">
                      <li class="nav-item" :key="index2" v-for="(childL2, index2) in childL1.children">
                        <SidebarNavLink :name="childL2.name" :url="childL2.url" :icon="childL2.icon" :badge="childL2.badge" :variant="item.variant"/>
                      </li>
                    </SidebarNavDropdown>
                  </template>
                  <template v-else>
                    <SidebarNavItem :key="index" :classes="item.class">
                      <SidebarNavLink :name="childL1.name" :url="childL1.url" :icon="childL1.icon" :badge="childL1.badge" :variant="item.variant"/>
                    </SidebarNavItem>
                  </template>
                </template>
              </SidebarNavDropdown>
            </template>
            <template v-else>
              <SidebarNavItem :key="index" :classes="item.class">
                <SidebarNavLink :name="item.name" :url="item.url" :icon="item.icon" :badge="item.badge" :variant="item.variant"/>
              </SidebarNavItem>
            </template>
          </template>
        </template>
      </ul>
      <slot></slot>
    </nav>
    <SidebarFooter/>
    <SidebarMinimizer/>
  </div>
</template>
<script>
import SidebarFooter from './SidebarFooter.vue'
import SidebarForm from './SidebarForm.vue'
import SidebarHeader from './SidebarHeader.vue'
import SidebarMinimizer from './SidebarMinimizer.vue'
import SidebarNavDivider from './SidebarNavDivider.vue'
import SidebarNavDropdown from './SidebarNavDropdown.vue'
import SidebarNavLink from './SidebarNavLink.vue'
import SidebarNavTitle from './SidebarNavTitle.vue'
import SidebarNavItem from './SidebarNavItem.vue'
import SidebarNavLabel from './SidebarNavLabel.vue'
import { mapGetters, mapState } from 'vuex'
import { cloneDeep } from 'lodash'
import { hasAccessToRouteMeta } from '@/router/RouterAccessGuard'

export default {
  name: 'NavSidebar',
  props: {
    navItems: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  computed: {
    ...mapGetters(['scheduleEnabled', 'needsSetup', 'insideOrgNamespace']),
    ...mapState(['features', 'permissions', 'organizationActive', 'organizationId', 'fatalError']),
    visibleNavItems () {
      // we'll prefix org to each url
      const prefix = '/orgs/' + this.organizationId

      // filter top-level items based on requirements
      let navItems = this.filterNavItems(cloneDeep(this.navItems))

      // filter 2nd-level items based on requirements
      navItems.forEach(navItem => {
        if (navItem.url) {
          navItem.url = prefix + navItem.url
        }

        if (navItem.children instanceof Array) {
          navItem.children = this.filterNavItems(navItem.children)
          navItem.children.forEach(child => {
            if (child.url) {
              child.url = prefix + child.url
            }
          })
        }
      })

      // filter out top-level items whose children were all removed
      navItems = navItems.filter(navItem => !(navItem.children instanceof Array && navItem.children.length < 1))

      return navItems
    },
    visible () {
      return this.insideOrgNamespace
    }
  },
  watch: {
    // need to watch certain session properties and re-render nav, because binding of visibleNavItems
    // is not able to pick them up
    permissions () {
      this.$forceUpdate()
    },
    features () {
      this.$forceUpdate()
    },
    scheduleEnabled () {
      this.$forceUpdate()
    },
    organizationActive () {
      this.$forceUpdate()
    }
  },
  components: {
    SidebarFooter,
    SidebarForm,
    SidebarHeader,
    SidebarMinimizer,
    SidebarNavDivider,
    SidebarNavDropdown,
    SidebarNavLink,
    SidebarNavTitle,
    SidebarNavItem,
    SidebarNavLabel
  },
  methods: {
    handleClick (e) {
      e.preventDefault()
      e.target.parentElement.classList.toggle('open')
    },
    filterNavItems (navItems) {
      return navItems.filter(navItem =>
        !this.fatalError &&
        !this.needsSetup &&
        navItem.nav !== false &&
        hasAccessToRouteMeta(navItem, this.$store)
      )
    }
  }
}
</script>
