<template>
  <div class="worker-documents">
    <div v-if="readOnly" class="read-only-warning">
      <template v-if="orgUserId">
        You do not have permission to edit your own documents.
      </template>
      <template v-else>
        Documents may not be edited.
      </template>
    </div>
    <div v-else-if="!isActiveWorker" class="not-active-warning">
      <font-awesome-icon icon="triangle-exclamation" />
      User must be active worker in order to upload or edit documents.
    </div>

    <document-upload
      v-else-if="documentCount < maxDocuments"
      :showing="isFormOpen"
      :parentKey="parentKey"
      :extensions="extensions"
      :maxFileSize="maxDocumentFileSize"
      @input="onDocumentInput"
    />

    <div v-else-if="orgUserId" class="max-docs-warning">
      <font-awesome-icon icon="triangle-exclamation" />
      This user is currently at the limit of {{ maxDocumentsPerUser }} documents per user.
      You'll need to delete a document in order to add another one.
    </div>
    <master-detail-table
      ref="masterDetailTable"
      resourceName="Document"
      auditResource="DOCUMENT"
      :crudService="crudService"
      :newItemFactory="newItemFactory"
      :fields="fields"
      :parentKey="parentKey"
      :formInvalid="formInvalid"
      :autoLoad="autoLoad"
      :allowEdit="isActiveWorker && !isReadOnly && !providedDocuments"
      :allowDelete="!isReadOnly"
      :stacked="stacked"
      :isFormOpen="isFormOpen"
      :useForm="useForm"
    >
      <template v-slot:cell(download)="data">
        <b-link @click="download(data.item)" class="download-link">
          <font-awesome-icon icon="file-arrow-down" class="download-icon" />
        </b-link>
      </template>

      <template v-slot:cell(size)="data">
        <div>{{ data.value }}</div>
        <div v-if="data.item.size > maxDocumentFileSize && fileCanBeCompressed(data.item)">
          <b-btn size="sm" variant="primary" class="compress-btn" @click="compress(data.item)">
            <font-awesome-icon icon="compress-arrows-alt" />
            Compress
          </b-btn>
        </div>
        <div v-if="data.item.size > maxDocumentFileSize" class="invalid-feedback d-block">
          Maximum file size allowed is 2 MB.
        </div>
      </template>

      <template #detail-form="{ value }">
        <worker-document-form
          :value="value"
          :kind="kind(value)"
          :size="size(value.size)"
          :modified="modified(value.modified)"
          @invalid-changed="formInvalid = $event"
          @download="download"
        />
      </template>
    </master-detail-table>
  </div>
</template>
<script>
import MasterDetailTable from '@/components/MasterDetailTable.vue'
import WorkerDocumentForm from './WorkerDocumentForm.vue'
import restClient from '@/services/clients/rest'
import { resolveFileType, resolveMime } from 'friendly-mimes'
import { mapGetters, mapState } from 'vuex'
import { saveAs } from '@/utils/file-saver'
import { extractErrorDataMessage, formatFileSize, jsonParseSafe } from '@/utils/misc'
import _ from 'lodash'
import { compressImage } from '@/utils/image'
import DocumentUpload from './DocumentUpload.vue'

const UNKNOWN_KIND = 'Unknown'

const NewItem = () => ({
  orgUser: null,
  attachToType: 'USER',
  attachToId: null,
  name: null,
  mimeType: null,
  suffix: null,
  size: null,
  file: null,
  modified: null
})

export default {
  name: 'WorkerDocuments',
  components: {
    DocumentUpload,
    MasterDetailTable,
    WorkerDocumentForm
  },
  props: {
    orgUserId: Number,
    isActiveWorker: {
      type: Boolean,
      default: true
    },
    hasDocuments: {
      type: Boolean,
      default: false
    },
    attachToType: {
      type: String,
      validator: v => ['USER', 'CREDENTIAL', 'BRIEFING'].includes(v)
    },
    attachToId: Number,
    // If documents already loaded and provided by parent, then they're supplied here.
    documents: Array,
    showing: Boolean,
    stacked: Boolean,
    isFormOpen: Boolean,
    allowMultiple: {
      type: Boolean,
      default: true
    },
    readOnly: Boolean,
    extensions: Array,
    useForm: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      formInvalid: false,
      fields: [
        'name',
        {
          key: 'kind',
          formatter: (value, key, item) => this.kind(item)
        },
        {
          key: 'size',
          formatter: value => this.size(value)
        },
        {
          key: 'modified',
          formatter: value => this.modified(value)
        },
        'download',
        { key: 'action', label: ' ', class: 'action' }
      ],
      newItemFactory: NewItem,
      crudService: {
        list: this.list,
        create: this.create,
        update: this.update,
        delete: this.delete
      },
      documentCount: 0,
      lastFileProhibited: false
    }
  },
  computed: {
    ...mapState(['maxDocumentFileSize', 'maxDocumentsPerUser']),
    ...mapGetters(['canEditSelf']),
    ...mapGetters('formatPreferences', ['formatDateTime']),
    ...mapState({
      loggedInOrgUserId: 'orgUserId'
    }),
    documentResourcePath () {
      return this.orgUserId ? `employees/${this.orgUserId}/documents` : 'documents'
    },
    fileInputId () {
      return `file-input-${this.$.uid}`
    },
    isSelf () {
      return this.loggedInOrgUserId === this.orgUserId
    },
    isReadOnly () {
      return this.readOnly || (this.isSelf && !this.canEditSelf)
    },
    providedDocuments () {
      // It doesn't mean there are documents. It just means parent handled it.
      return _.isArray(this.documents)
    },
    autoLoad () {
      return this.showing && (this.hasDocuments || this.providedDocuments)
    },
    parentKey () {
      return this.attachToId || this.orgUserId
    },
    maxDocuments () {
      // TODO: We're making an implict assumption here that if !allowMultiple, then documents are unrelated to the user,
      // TODO: i.e., briefings. We should separate into multiple props.
      return this.allowMultiple ? this.maxDocumentsPerUser : 1
    },
    invalid () {
      // TODO: validate items
      return this.formInvalid
    }
  },
  watch: {
    isFormOpen (isFormOpen) {
      if (!isFormOpen) this.documentCount = 0
    },
    invalid (invalid) {
      this.$emit('invalid-changed', invalid)
    }
  },
  methods: {
    list (params) {

      const orgUserId = this.orgUserId

      const op = this.providedDocuments
        // Clone provided documents array so that when WorkerDocuments component edits both this documents prop
        // as well as its own MasterDetailTable items, they don't both use a shared array
        // that will then cause a double item.
        ? Promise.resolve(_.clone(this.documents))
        : restClient.get(this.documentResourcePath, { params: {
            ...params,
            attachToType: this.attachToType,
            attachToId: this.attachToId
          }}).then(response => {
            // Make sure user didn't change during request.
            if (orgUserId !== this.orgUserId) return { results: [] }
            this.documentCount = response.data.results.length
            this.$emit('documents-loaded', this.documentCount)
            return response.data.results
          })

      return op.then(documents => {
        this.documentCount = documents.length
        return documents
      })
    },
    create (item, config) {
      // Construct multi-part form data containing the file and json.
      const { file, ...data } = item
      const formData = new FormData()
      formData.append('data', JSON.stringify(data))
      formData.append('file', file)

      return restClient.post(this.documentResourcePath, formData, config)
        .then(response => {
          this.documentCount++
          this.$emit('has-documents-changed', true)
          this.$emit('documents-loaded', this.documentCount)
          return response.data
        })
      // We don't need to update local user hasDocuments property,
      // because we only need it for auto-loading.
    },
    update (item, config) {
      return restClient.put(`${this.documentResourcePath}/${item.id}`, item, config)
        .then(response => response.data)
    },
    delete (itemId, itemIndex, item) {
      // If parent provided documents, check remove from provided list.
      // Parent will be responsible to figure out what was deleted.
      if (this.providedDocuments) {
        if (itemId) {
          const index = this.documents.findIndex(document => document.id === itemId)
          if (index > -1) delete this.documents[index]
        }
        else {
          const index = this.documents.findIndex(document => document === item)
          this.documents.splice(index, 1)
        }
        // We never emit 'has-documents-changed' false, because maybe there are credential documents.
        // It doesn't really matter if it's incorrectly true.
        this.documentCount--
        return Promise.resolve()
      }

      return restClient.delete(`${this.documentResourcePath}/${itemId}`)
        .then(response => {
          this.documentCount--
          return response.data
        })
      // We don't need to update local user hasDocuments property,
      // because we only need it for auto-loading.
    },
    kind (item) {
      try {
        const mimeItem = (
          resolveMime(item.mimeType || '') ||
          resolveFileType(`.${item.suffix}`)
        )
        return mimeItem ? mimeItem.name : UNKNOWN_KIND
      } catch (error) {
        // Mime type not found
        return UNKNOWN_KIND
      }
    },
    size (size) {
      return formatFileSize(size)
    },
    modified (modified) {
      return this.formatDateTime(modified)
    },
    fileName (item) {
      return item.suffix && !item.name.endsWith(`.${item.suffix}`)
        ? `${item.name}.${item.suffix}`
        : item.name
    },
    downloadResourcePath (item) {
      return `${this.documentResourcePath}/${item.id}/download`
    },
    download (item) {
      const fileName = this.fileName(item)
      if (item.file) {
        saveAs(item.file, fileName)
      } else {
        restClient.get(this.downloadResourcePath(item), { responseType: 'blob' })
          .then(response => {
            saveAs(response.data, fileName)
          })
          .catch(async error => {
            // Because response type is blob, we need to get back text json for displaying error.
            const content = await error.response.data.text()
            const data = jsonParseSafe(content)
            this.$toast.error(extractErrorDataMessage(data))
          })
      }
    },
    fileCanBeCompressed (item) {
      return ['image/gif', 'image/jpeg', 'image/png'].includes(item.mimeType)
    },
    compress (item) {
      compressImage(item, this.maxDocumentFileSize)
    },
    onDocumentInput (data) {
      const itemData = {
        orgUser: this.orgUserId,
        attachToType: this.attachToType,
        attachToId: this.attachToId,
        ...data
      }

      if (this.providedDocuments) {
        // If parent provided documents, we don't display an edit form nor save documents.
        // The parent will handle it.
        this.$refs.masterDetailTable.items.push(itemData)
        this.documents.push(itemData)
        this.documentCount++
      } else {
        this.$refs.masterDetailTable.createItem(itemData)
      }
    }
  },
  mounted () {
    this.dropContainer = this.$el
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/app/app';

.read-only-warning, .not-active-warning, .max-docs-warning {
  border: 1px dashed #333;
  padding: 10px;
  text-align: center;
  svg {
    margin-right: 5px;
    color: $flat-ui-sunflower;
  }
}

.master-detail-table {
  margin-top: 25px;

  .download-link {
    // center align to 'Download' header title.
    padding-left: 25px;

    .download-icon {
      font-size: 1.2rem;
    }
  }
}
</style>
