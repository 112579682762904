import { mapGetters, mapState } from 'vuex'
import { get } from 'lodash'
import { formatTimeEntryExceptions } from '@/utils/TimeEntryExceptions'
import { getCustomFieldValue, getSupplementalUserCustomFieldValue } from '@/utils/customFields'

export default {
  computed: {
    ...mapGetters('formatPreferences', [
      'formatDate',
      'formatDateDay',
      'formatDateTime',
      'formatDateTimeTz',
      'formatMillisAsDuration',
      'formatMinutesAsDuration',
      'formatNaiveDate',
      'formatNaiveDateDay',
      'formatName',
      'formatPay',
      'formatSecondsAsDuration',
      'formatTime',
      'modelToFormattedTime',
      'parseDateTime'
    ]),
    ...mapGetters('customFields', ['punchCustomFields', 'shiftCustomFields', 'supplementalUserCustomFields']),
    ...mapState('formatPreferences', {
      durationFormat: state => state.originalData.durationFormat
    }),
    durationColumnTypeInfo () {
      return this.durationFormat === 'minute'
        ? {
          cellClass: ['duration', 'align-right'],
          cellDataType: 'text'
        }
        : {
          cellClass: ['duration', 'align-right'],
          cellDataType: 'number',
          type: 'numericColumn'
        }
    },
    payColumnTypeInfo () {
      return {
        cellDataType: 'number',
        cellClass: ['pay', 'align-right'],
        type: 'numericColumn'
      }
    }
  },
  methods: {
    timezoneGetter (params) {
      const timezoneGetter = params.colDef.context?.timezoneGetter || params.colDef.timezoneGetter
      return timezoneGetter && timezoneGetter(params) || params.data.timezone || this.timezone
    },
    dateDayValueGetter (params) {
      const dt = params.value || get(params.data, params.colDef.field)
      if (!dt) return
      return this.formatDateDay(dt, this.timezoneGetter(params))
    },
    dateTimeValueGetter (params) {
      if (!params.data) return params.value
      const dt = params.value || get(params.data, params.colDef.field)
      if (!dt) return
      return this.formatDateTime(dt, this.timezoneGetter(params))
    },
    // TODO: Maybe we should automatically use dateTimeTzValueGetter instead of dateTimeValueGetter if
    // TODO: multiple timezones across org and org units?
    dateTimeTzValueGetter (params) {
      const dt = get(params.data, params.colDef.field)
      if (!dt) return
      return this.formatDateTimeTz(dt, this.timezoneGetter(params))
    },
    dateTimeStringComparator (dtString1, dtString2) {
      const dt1 = this.parseDateTime(dtString1)
      const dt2 = this.parseDateTime(dtString2)

      if (!dt1 && !dt2) return 0
      if (!dt1) return -1
      if (!dt2) return 1

      return +dt1 - +dt2
    },
    // All the following cell renderers can also be used as cell formatters as well.
    // But they can not be used as value getters, because the params arg is different.
    // https://www.ag-grid.com/documentation/vue/value-formatters
    // Note that cell renderers and formatters are not exported to csv:
    // https://www.ag-grid.com/documentation/javascript/export/#what-gets-exported
    // Instead value getters need to be used. Corresponding value getters are also
    // defined below. Note particularly for date, using value getters to format dates
    // can mess up sort order.
    // Beware of using dateCellRenderer. You most likely want to use naiveDateCellRenderer.
    dateCellRenderer (params) {
      return params.value ? this.formatDate(params.value) : ''
    },
    naiveDateCellRenderer (params) {
      return params.value ? this.formatNaiveDate(params.value) : ''
    },
    timeValueGetter (params) {
      const dt = params.value || get(params.data, params.colDef.field)
      if (!dt) return
      return this.formatTime(dt, this.timezoneGetter(params))
    },
    timeStringCellRenderer (params) {
      return params.value ? this.modelToFormattedTime(params.value) : ''
    },
    durationRenderer (params) {
      return this.formatSecondsAsDuration(params.value)
    },
    payRenderer (params) {
      return this.formatPay(params.value)
    },
    dateValueGetter (params) {
      if (!params.data) return params.value
      const value = params.value || get(params.data, params.colDef.field)
      return value ? this.formatDate(value, this.timezoneGetter(params)) : ''
    },
    naiveDateValueGetter (params) {
      if (!params.data) return params.value
      const value = params.value || get(params.data, params.colDef.field)
      return value ? this.formatNaiveDate(value) : ''
    },
    naiveDateDayValueGetter (params) {
      if (!params.data) return params.value
      const value = params.value || get(params.data, params.colDef.field)
      return value ? this.formatNaiveDateDay(value) : ''
    },
    durationValueGetter (params) {
      // TODO: The following line breaks SalaryReport total rows where we use this as valueFormatter,
      // TODO: with stringNumberValueGetter as valueGetter.
      // TODO: But does it break something else?
      // if (!params.data) return params.value
      const value = params.value || get(params.data, params.colDef.field)
      return this.formatSecondsAsDuration(value)
    },
    durationMillisValueGetter (params) {
      const value = params.value || get(params.data, params.colDef.field)
      return this.formatMillisAsDuration(value)
    },
    durationMinutesValueGetter (params) {
      const value = params.value || get(params.data, params.colDef.field)
      return this.formatMinutesAsDuration(value)
    },
    payValueGetter (params) {
      const value = params.value || get(params.data, params.colDef.field)
      return this.formatPay(value)
    },
    stringNumberValueGetter (params) {
      if (!params.data) return params.value
      const value = params.value || get(params.data, params.colDef.field)
      return parseFloat(value) || 0
    },
    supplementalDataValueGetter (relatedField, targetName, targetGetter) {
      return this.supplementalDataValueGetterFn((data, supplementalData) => {
        const relatedValue = data[relatedField]
        const supplementalValue = get(supplementalData, `${targetName}.${relatedValue}`)
        return targetGetter instanceof Function
          ? targetGetter(supplementalValue)
          : get(supplementalValue, targetGetter)
      })
    },
    supplementalListValue(list, supplementalData, targetName, targetGetter, limit) {
      if (list.length < 1) return null
      // Slice after sorting values, so we show first in alphabetical order.
      const values = list
        .map(item => {
          const supplementalValue = get(supplementalData, `${targetName}.${item}`)
          return targetGetter instanceof Function
            ? targetGetter(supplementalValue)
            : get(supplementalValue, targetGetter)
        })
        .sort()
      const value = (values.length > limit ? values.slice(0, limit || undefined) : values).join(', ')
      return value + (list.length > limit ? ` + ${list.length - limit} more` : '')
    },
    supplementalDataListValueGetter (targetName, targetField, limit) {
      return params =>
        this.supplementalListValue(
          get(params.data, params.colDef.field) || [],
          this.getSupplementalData(params),
          targetName, targetField, limit
        )
    },
    getSupplementalData (params) {
      return params.context?.componentParent?.supplementalData ?? {}
    },
    supplementalDataValueGetterFn(fn) {
      return params => {
        const supplementalData = this.getSupplementalData(params)
        return fn(params.data, supplementalData)
      }
    },
    formatOrgUserName (orgUser) {
      return this.formatName(
        get(orgUser, 'firstName'),
        get(orgUser, 'lastName'),
        get(orgUser, 'middleName'),
        get(orgUser, 'displayName')
      )
    },
    supplementalUserNameGetter (relatedField, targetName) {
      return this.supplementalDataValueGetterFn((data, supplementalData) => {
        const relatedValue = data[relatedField]
        const orgUser = get(supplementalData, `${targetName}.${relatedValue}`)
        return this.formatOrgUserName(orgUser)
      })
    },
    flagColumnValueFormatter (params) {
      // TODO: Should we enhance this like the old FormatsSyncFusionColumns.flagColumnFormatter?
      return params.node.group ? null : formatTimeEntryExceptions(params?.value || [])
    },
    allowLabelsValueGetter (allowLabelPrefix, supplementalDataKind, supplementalDataValueGetter = 'name') {
      return params => {
        const supplementalData = this.getSupplementalData(params)
        const ids = params.data.allowLabels
          .filter(label => label.startsWith(`${allowLabelPrefix}:`))
          .map(label => parseInt(label.replace(`${allowLabelPrefix}:`, '')))
        // if (ids.length < 1) return null // TODO: Or 'All'?
        return this.supplementalListValue(
          ids, supplementalData, supplementalDataKind, supplementalDataValueGetter, 1
        )
      }
    },
    getSupplementalUserCustomFieldColumns (workerIdProperty) {
      if (this.$store.getters.hasCustomFieldFeature) {
        this.$store.dispatch('customFields/load')
      }

      return this.supplementalUserCustomFields.map(customField => ({
        columnDef: {
          field: `customField${customField.id}`,
          headerName: customField.name,
          valueGetter: params => {
            if (!params.data) return null
            return getSupplementalUserCustomFieldValue(customField, params.data[workerIdProperty], this.getSupplementalData(params))
          },
          width: 125,
          hide: true
        },
        available: !customField.sensitive || this.$store.getters.canAccessEmployeeSensitive
      }))
    },
    getPunchCustomFieldColumns () {
      return this.getCustomFieldColumns('punch')
    },
    getShiftCustomFieldColumns () {
      return this.getCustomFieldColumns('shift')
    },
    getCustomFieldColumns (appliesTo) {
      if (this.$store.getters.hasCustomFieldFeature) {
        this.$store.dispatch('customFields/load')
      }

      return this[`${appliesTo}CustomFields`].map(customField => {
        const col = {
          columnDef: {
            field: `customField${customField.id}`,
            headerName: customField.name,
            valueGetter: params => {
              if (!params.data) return null
              return getCustomFieldValue(customField, params.data.customFieldValues, this.getSupplementalData(params))
            },
            width: 125,
            hide: true
          },
          available: !customField.sensitive || this.$store.getters.canAccessEmployeeSensitive
        }
        if (customField.type === 'number') {
          Object.assign(col.columnDef, {
            aggFunc: 'sum',
            cellClass: ['align-right'],
            cellDataType: 'number',
            type: 'numericColumn'
          })
        }
        return col
      })
    }
  }
}
