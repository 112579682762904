
export function openUserFormModal (orgUserId, showModal) {
  import(/* webpackChunkName: "user-form-modal" */ './EmployeeFormModal.vue')
    .then(async module => {
      const EmployeeFormModal = module.default
      await showModal({
        component: EmployeeFormModal,
        props: {
          itemId: orgUserId
        }
      })
    })
}
