<template>
  <div>
    <form-group :label="fieldLabels.name"
      :validator="v$.value.name"
    >
      <template #default="slotProps">
        <b-form-input
          v-bind="slotProps"
          v-model="value.name"
          type="text"
        />
      </template>
    </form-group>

    <form-group :validator="v$.value.roundingUnit">
      <template #label>
        {{ fieldLabels.roundingUnit }}
        <help-text-icon>
          If set to greater than 1, the shift or break that uses this rule will be rounded to this unit of time.
          For example, if rounding increment is set to 15, then the hours will be rounded to the quarter-hour.
        </help-text-icon>
      </template>
      <template #default="slotProps">
        <b-form-input
          v-bind="slotProps"
          v-model="value.roundingUnit"
          :number="true"
          type="number"
          min="1"
          max="60"
          class="number"
        />
      </template>
    </form-group>

    <form-group :validator="v$.value.breakPoint">
      <template #label>
        {{ fieldLabels.breakPoint }}
        <help-text-icon>
          The break point determines when to round up or down to the rounding increment.
          For example, if rounding increment is set to 15 and break point is set to 8,
          then 8 minutes will be rounded up to 15 while 7 minutes will be rounded down to 0.
        </help-text-icon>
      </template>
      <template #default="slotProps">
        <b-form-input
          v-bind="slotProps"
          v-model="value.breakPoint"
          :number="true"
          type="number"
          min="1"
          max="60"
          class="number"
        />
      </template>
    </form-group>
  </div>
</template>
<script>
import { useVuelidate } from '@vuelidate/core'
import { between, helpers, integer, maxLength, required } from '@vuelidate/validators'
import HelpTextIcon from '@/components/HelpTextIcon.vue'

export default {
  name: 'RoundingRuleForm',
  setup () {
    return { v$: useVuelidate() }
  },
  components: {
    HelpTextIcon
  },
  props: ['value'],
  data () {
    return {
      fieldLabels: {
        name: 'Name',
        roundingUnit: 'Rounding increment',
        breakPoint: 'Break point'
      }
    }
  },
  computed: {
    formInvalid () {
      return this.v$.$invalid
    }
  },
  watch: {
    formInvalid: {
      handler (formInvalid) {
        this.$emit('invalidChanged', formInvalid)
      },
      immediate: true
    }
  },
  validations: {
    value: {
      name: {
        required: helpers.withMessage('Name is a required field, e.g., "1/4 Hour - 8 min. break point"', required),
        maxLength: maxLength(40)
      },
      roundingUnit: {
        required,
        integer,
        between: between(1, 60)
      },
      breakPoint: {
        required,
        integer,
        between: between(1, 60),
        notGreaterThanRoundingUnit: helpers.withMessage('Break point may not be higher than the rounding increment.', breakPoint => {
          // if (!this.v$.value.breakPoint.between) return true
          // if (!this.v$.value.roundingUnit.between) return true
          return breakPoint <= this.value.roundingUnit
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.number {
  width: 8rem;
}
</style>
