import restClient from '@/services/clients/rest'
import { createRestCrudService } from '@/services/GenericCrudService'

const resourceName = 'User'
const resourcePath = 'employees'
const crudService = createRestCrudService(resourceName, resourcePath)

async function lookupByResource (resourceType, resourceValue) {
  try {
    const response = await restClient.get(`${resourcePath}/lookup-by-${resourceType}/${resourceValue}`)
    if (response.status === 204) return null
    if (response.status === 200) return response.data
    throw new Error('Unexpected response')
  } catch (error) {
    console.warn(`Error looking up user by ${resourceType} ${resourceValue}`, error)
    throw error
  }
}

export default Object.assign(crudService, {
  async lookupByPin (pin) {
    return lookupByResource('pin', pin)
  },
  async lookupByQrCode (qrCode) {
    return lookupByResource('qr-code', qrCode)
  },
  async lookupByEmail (email) {
    return lookupByResource('email', email)
  },
  async lookupByPhone (phone) {
    return lookupByResource('phone', phone)
  },
  async lookupByNationalId (nationalId) {
    return lookupByResource('national-id', nationalId)
  },
  async lookupByNfcTagId (nfcTagId) {
    return lookupByResource('nfc-tag-id', nfcTagId)
  },
  async lookupByCustomField(customFieldId, value) {
    return lookupByResource(`custom-field/${customFieldId}`, value)
  },
  async resendInvite (employeeId) {
    try {
      await restClient.post(`${resourcePath}/${employeeId}/resend-invite`)
    } catch (error) {
      console.warn(`Error resending invite for user ${employeeId}`, error)
      throw error
    }
  },
  async sendInstallLink (employeeId, medium) {
    try {
      await restClient.post(`${resourcePath}/${employeeId}/send-install-link/${medium}`)
    } catch (error) {
      console.warn(`Error sending ${medium} install link for user ${employeeId}`, error)
      throw error
    }
  },
  async createInstallCode (employeeId) {
    try {
      const response = await restClient.post(`${resourcePath}/${employeeId}/create-install-code`)
      return response.data.installCode
    } catch (error) {
      console.warn(`Error sending creating install code for user ${employeeId}`, error)
      throw error
    }
  },
  async applyBulkAction (actionData, itemIds) {
    try {
      const response = await restClient.post(`${resourcePath}/bulk-action`, {
        itemIds,
        actionData
      })
      return response.data
    } catch (error) {
      console.warn(`Error submitting bulk action`, error, actionData)
      throw error
    }
  },
  async bulkImport (items) {
    try {
      const response = await restClient.post(`${resourcePath}/bulk-import`, {
        items
      })
      return response.data
    } catch (error) {
      console.warn(`Error submitting worker bulk import`, error, items)
      throw error
    }
  },
  async sendNotificationMessage (orgUserId, title, body) {
    try {
      await restClient.post(`${resourcePath}/${orgUserId}/send-notification`, { title, body })
    } catch (error) {
      console.warn(`Error sending notification messaeg to user ${orgUserId}`, error)
      throw error
    }
  }
})
