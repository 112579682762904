<template>
  <b-popover
    ref="popover"
    v-bind="$attrs"
    v-model="forwardShow"
    @shown="shown"
    @hidden="hidden"
  >
    <slot />
  </b-popover>
</template>
<script>
import _ from 'lodash'

export default {
  inheritAttrs: false,
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    forwardShow: {
      get () {
        return this.show
      },
      set (v) {
        this.$emit('update:show', v)
      }
    }
  },
  methods: {
    shown () {
      document.addEventListener('click', this.click)
    },
    hidden () {
      document.removeEventListener('click', this.click)
    },
    getPopoverEl () {
      // TODO: Is there a better way to get popover element?
      // return this.$children?.[0]?.$refs?.content.parentElement.parentElement
      this.$refs.popover
    },
    click (event) {
      const popoverEl = this.getPopoverEl()
      if (popoverEl && !popoverEl.contains(event.target)) {
        this.forwardShow = false
      }
    }
  },
  beforeDestroy () {
    this.hidden()
  }
}
</script>
