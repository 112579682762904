<template>
  <div class="device-personal-settings">
    <spinner v-if="loading" />
    <div v-else-if="errorMessage" class="error-message">
      <font-awesome-icon icon="triangle-exclamation" />
      {{ errorMessage }}
    </div>
    <div v-else>
      <b-container>
        <b-row>
          <b-col cols="12" sm="6">
            <form-group label="User">
              <b-form-input :value="personalSettings.orgUserName"
                class="org-user-name"
                readonly
                @click="goToUser"
              />
            </form-group>
          </b-col>
          <b-col cols="12" sm="6">
            <form-group label="Mode">
              <b-form-input :value="personalDeviceMode" readonly />
            </form-group>
          </b-col>
          <b-col cols="12" sm="6">
            <form-group label="Reinstall Perm">
              <b-form-input :value="reinstallPerm" readonly />
            </form-group>
          </b-col>
          <b-col cols="12" sm="6">
            <form-group label="Mobile Phone">
              <b-form-input :value="personalSettings.mobilePhone" readonly />
            </form-group>
          </b-col>
          <b-col cols="12" sm="6">
            <form-group label="Email">
              <b-form-input :value="personalSettings.email" readonly />
            </form-group>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>
<script>
import deviceService from './services/DeviceService'
import { extractErrorMessage } from '@/utils/misc'
import Spinner from '@/components/Spinner.vue'
import personalDeviceModeOptions from '../employees/PersonalDeviceModeOptions'
import reinstallPermOptions from '../employees/ReinstallPermOptions'
import _ from 'lodash'

export default {
  name: 'DevicePersonalSettings',
  components: {
    Spinner
  },
  props: {
    deviceId: Number
  },
  data () {
    return {
      personalSettings: {},
      loading: false,
      errorMessage: null
    }
  },
  computed: {
    personalDeviceMode () {
      return _.get(personalDeviceModeOptions[this.personalSettings.personalDeviceMode], 'label')
    },
    reinstallPerm () {
      return _.get(reinstallPermOptions[this.personalSettings.reinstallPerm], 'label')
    }
  },
  watch: {
    deviceId: {
      handler (deviceId) {
        this.errorMessage = null
        this.loading = false
        if (!deviceId) {
          this.personalSettings = {}
          return
        }
        this.loading = true
        deviceService.retrievePersonalSettings(deviceId)
          .then(personalSettings => {
            // check that deviceId did not change during request
            if (deviceId !== this.deviceId) return
            this.personalSettings = personalSettings
          })
          .catch(error => {
            // check that deviceId did not change during request
            if (deviceId !== this.deviceId) return
            this.errorMessage = extractErrorMessage(error)
          })
          .finally(() => {
            // check that deviceId did not change during request
            if (deviceId !== this.deviceId) return
            this.loading = false
          })
      },
      immediate: true
    }
  },
  methods: {
    goToUser () {
      this.$router.push({
        name: 'employee-list',
        params: { view: this.personalSettings.orgUserId }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables';

.error-message {
  color: $flat-ui-alizarin;
  svg {
    margin-right: 5px;
  }
}

.org-user-name {
  color: $flat-ui-peter-river;
  text-decoration: underline;
  cursor: pointer;
}

</style>
