import { createCrudService } from '@/services/GenericCrudService'
import messageClient from '@/services/clients/messaging'

const resourceName = 'Message'
const resourcePath = 'messages'
const crudService = createCrudService(messageClient, resourceName, resourcePath)

export default Object.assign(crudService, {
  async updateContactByUri (contactType, uri, data) {
    try {
      const response = await messageClient.post(`contacts/${contactType}/uri/${uri}`, data)
      return response.data
    } catch (error) {
      console.warn(`Error updating message contact`, error, contactType, uri)
      throw error
    }
  }
})
