<template>
  <div :class="['master-detail-table', $attrs.class]">
    <spinner v-if="loading" />
    <div class="error-message" v-else-if="errorMessage">
      <font-awesome-icon icon="triangle-exclamation" class="icon" />
      {{ errorMessage }}
    </div>
    <template v-else>
      <csv-file-upload v-if="importItems"
        :resourceName="resourceName"
        :itemsUploaded="itemsUploaded"
      />

      <b-table
        striped
        :fields="fields"
        :items="sortedItems"
        :sort-by="itemsSortBy"
        class="item-list"
        show-empty
        responsive
        :stacked="stacked"
      >
        <template v-for="(_, name) in $slots" #[name]="slotData">
          <slot v-if="!['detail-form'].includes(name)" :name="name" v-bind="slotData" />
        </template>

        <template #empty>
          There are no <code>{{ pluralResourceName }}</code>
        </template>

        <template v-slot:cell(action)="data">
          <span v-show="shouldAllowEdit(data.item) && deletingItemId !== getItemId(data.item)" @click="editItem(data.item, data.index)" class="edit-item">
            <font-awesome-icon icon="pencil-alt" class="icon" /> Edit
          </span>
          <span v-show="shouldAllowDelete(data.item) && deletingItemId !== getItemId(data.item)" @click="deleteItem(data.item, data.index)" class="delete-item">
            <font-awesome-icon icon="trash-can" class="icon" /> Delete
          </span>
          <span class="deleting-item" v-show="deletingItemId === getItemId(data.item)">
            <font-awesome-icon icon="circle-notch" spin /> Deleting...
          </span>

          <div class="item-error-message error-message" v-if="itemErrorMessage && selectedItemId === getItemId(data.item)">
            <font-awesome-icon icon="triangle-exclamation" />
            {{ itemErrorMessage }}
          </div>
        </template>
      </b-table>
      <div class="list-buttons">
        <b-button v-if="showNewItemButton" variant="primary" class="new-item" size="sm" @click="createItem()">
          <font-awesome-icon icon="plus" class="icon" />
          New {{ resourceName }}
        </b-button>
        <b-button v-if="exportItems && sortedItems.length > 0" variant="outline-primary" class="export-item" size="sm" @click="exportItems(sortedItems)">
          <font-awesome-icon icon="file-arrow-down" class="icon" />
          Export {{ pluralResourceName }}
        </b-button>
      </div>
      <form-drawer v-if="!useForm"
        :visible="isEditing"
        @saveForm="saveEdit"
        @cancelForm="cancelEdit"
        @enter="enter"
        :save-disabled="saveDisabled"
        :cancel-disabled="cancelDisabled"
        :saveText="saveText"
        :saving="saving"
      >
        <h5 v-if="addingNew">Add New {{ resourceName }}</h5>
        <h5 v-else>
          Edit {{ resourceName }}
          <id-icon :id="getItemId(editingItem)" placement="bottom" />
          <audit-icon
            v-if="showAudit"
            :instanceId="getItemId(editingItem)"
            :kind="auditResource"
            :resourceName="resourceName"
            placement="bottom"
          />
        </h5>
        <hr/>
        <slot name="detail-form" :value="editingItem" :isDirty="isDirty" :showing="isEditing" :processing="processing" />

        <template v-if="formErrorMessage" #footer-above-buttons>
          <div class="error-message">
            <font-awesome-icon icon="triangle-exclamation" class="icon" />
            {{ formErrorMessage }}
          </div>
        </template>
      </form-drawer>
    </template>
  </div>
</template>
<script>
import CsvFileUpload from '@/components/CsvFileUpload.vue'
import FormDrawer from '@/components/FormDrawer.vue'
import IdIcon from '@/components/IdIcon.vue'
import Spinner from '@/components/Spinner.vue'
import _ from 'lodash'
import { pluralize } from 'inflection'
import { extractErrorMessage } from '@/utils/misc'
import { mapGetters } from 'vuex'
import AuditIcon from '@/components/AuditIcon.vue'

export default {
  name: 'MasterDetailTable',
  inheritAttrs: false,
  components: {
    AuditIcon,
    CsvFileUpload,
    FormDrawer,
    IdIcon,
    Spinner
  },
  props: {
    resourceName: String,
    // TODO: validate that either crudService or orchestrator is specified,
    // TODO: and not both.
    // https://github.com/vuejs/vue/issues/3495
    crudService: Object,
    orchestrator: String,
    fields: Array,
    newItemFactory: Function,
    importItems: Function,
    exportItems: Function,
    showNewItemButton: {
      type: Boolean,
      default: false
    },
    allowEdit: {
      type: [Boolean, Function],
      default: true
    },
    allowDelete: {
      type: [Boolean, Function],
      default: true
    },
    parentKey: [String, Number],
    isFormOpen: Boolean,
    formInvalid: Boolean,
    saveText: String,
    auditResource: String,
    itemsSortBy: {
      type: Array,
      default: () => ([{ key: 'name', order: 'asc' }])
    },
    autoLoad: {
      type: Boolean,
      default: true
    },
    stacked: Boolean,
    // TODO: Does useForm really mean useOwnCustomForm?
    // TODO: And it seems we don't even use this prop, so maybe remove it.
    // TODO: We have initially explored using it with briefings, but ended up implementing it differently.
    useForm: Boolean,
    getItemId: {
      type: Function,
      default: item => item.id
    },
    // getItemKey is generally the same as getItemId, but is specifically different for
    // user policy credentials, because we need to rely on credential type id instead.
    getItemKey: {
      type: Function,
      default: function (item) {
        return this.getItemId(item)
      }
    }
  },
  data () {
    return {
      loading: false,
      loadedParentKey: false, // dedupe
      errorMessage: null, // error loading list
      formErrorMessage: null, // error saving item
      itemErrorMessage: null, // error deleting item
      itemSaved: false,
      items: [],
      isEditing: false,
      selectedItem: null,
      editingItem: {},
      saving: false,
      deleting: false
    }
  },
  computed: {
    ...mapGetters(['canViewAudit']),
    pluralResourceName () {
      return pluralize(this.resourceName)
    },
    addingNew () {
      return !this.getItemId(this.editingItem)
    },
    sortedItems () {
      return _.sortBy(this.items, this.itemsSortBy.map(o => o.key))
    },
    deletingItemId () {
      return this.deleting && this.selectedItem ? this.getItemId(this.selectedItem) : null
    },
    selectedItemId () {
      return this.selectedItem && this.getItemId(this.selectedItem)
    },
    isDirty () {
      return !_.isEqual(
        _.omitBy(this.selectedItem, key => _.startsWith(key, '_')),
        _.omitBy(this.editingItem, key => _.startsWith(key, '_'))
      )
    },
    processing () {
      return this.saving || this.deleting
    },
    saveDisabled () {
      return !this.isDirty || this.formInvalid || this.processing
    },
    cancelDisabled () {
      return this.processing
    },
    showAudit () {
      return Boolean(this.auditResource && !this.addingNew && this.canViewAudit)
    },
    parentKeyOrIsFormOpen () {
      // Combine props in order to determine whether form context changed.
      return this.parentKey || this.isFormOpen !== false
    }
  },
  watch: {
    parentKeyOrIsFormOpen () {
      this.reset()
    },
    autoLoad (autoLoad) {
      if (autoLoad && !this.loadedParentKey) {
        this.load()
      }
    },
    processing (processing) {
      this.$emit('processingChanged', processing)
    },
    deletingItemId (deletingItemId) {
      this.$emit('deletingItemIdChanged', deletingItemId)
    }
  },
  methods: {
    reset () {
      this.cancelEdit()
      if (this.autoLoad && this.isFormOpen !== false) this.load()
      else this.items = [] // clear old data
      this.loadedParentKey = false
    },
    createItem (overrideData) {
      this.selectedItem = null
      this.editingItem = Object.assign({}, this.newItemFactory(), overrideData)
      this.formErrorMessage = null
      this.itemErrorMessage = null

      // Even if useForm is true, we'll still open form during save in case the save fails.
      this.isEditing = true
      if (this.useForm) {
        this.saveEdit()
      }
    },
    editItem (item, itemIndex) {
      // Make copy, and omit _rowVariant.
      const { _rowVariant, ...editingItem } = item

      this.selectedItem = item
      // Clone deep in case it's a nested object.
      this.editingItem = _.cloneDeep(editingItem)
      this.formErrorMessage = null
      this.itemErrorMessage = null
      this.isEditing = true
    },
    saveEdit () {
      this.saving = true
      this.formErrorMessage = null
      this.itemErrorMessage = null
      this.itemSaved = false

      const action = this.selectedItem ? 'update' : 'create'

      const saveOperation = this.orchestrator
        ? this.$store.dispatch(`${this.orchestrator}/${action}`, this.editingItem)
        : this.crudService[action](this.editingItem)

      const parentKey = this.parentKey

      saveOperation
        .then(data => {
          this.isEditing = false

          // Check for racing condition.
          if (parentKey !== this.parentKey) return

          // update item list with saved result
          const savedItems = this.unpackResultItems(data)
          const savedItem = _.isArray(savedItems) ? savedItems[0] : savedItems

          // we'll temporarily color the row for feedback
          savedItem._rowVariant = 'success'

          let index
          if (action === 'create') {
            index = this.items.length
            this.items.push(savedItem)
          } else {
            index = this.items.findIndex(i => this.getItemKey(i) === this.getItemKey(savedItem))
            this.items[index] = savedItem
          }

          // Set editing item in order to get form to bind to new item, so that if admin creates another
          // item, then it will detect the parentKey changing. This issue comes up when creating multiple
          // ad hoc credentials, and for each successive one we need the documents list to clear.
          this.editingItem = _.cloneDeep(savedItem)

          this.$emit('saved', savedItem)

          // remove success color
          setTimeout(() => {
            this.items[index] = _.omit(savedItem, ['_rowVariant'])
          }, 2000)
        })
        .catch(error => {
          // TODO: Submit 400 errors to bugsnag, since we want vuelidate to catch everything.

          if (error._item) {
            // The service has instructed to update the local item.
            // This typically occurs when the crud service makes multiple remote calls
            // and some but not all calls were successful, e.g., credential documents.
            this.editingItem = _.cloneDeep(error._item)
          }

          this.formErrorMessage = extractErrorMessage(error)
        })
        .finally(() => { this.saving = false })
    },
    cancelEdit () {
      this.isEditing = false
      this.editingItem = {}
      this.formErrorMessage = null
      this.itemErrorMessage = null
    },
    deleteItem (item, itemIndex) {
      if (item._rowVariant) return // in transition

      this.selectedItem = item
      this.deleting = true
      this.itemErrorMessage = null

      const parentKey = this.parentKey
      const itemId = this.getItemId(item)

      // If the form is open for the item we're deleting, then close it.
      if (this.isEditing && itemId === this.getItemId(this.editingItem)) {
        this.cancelEdit()
      }

      const deleteOperation = this.orchestrator
        ? this.$store.dispatch(`${this.orchestrator}/delete`, itemId)
        // Note that itemIndex is according to the sorted master list, but the service
        // may be used an unsorted list and thus the itemIndex won't match.
        // There's some technical debt here, because originally we could call the
        // GenericCrudService.delete method from here. But then we added the
        // 2nd and 3rd arguments here, and it broke GenericCrudService expecting
        // an optional 2nd argument object for Axios config. So we had to change
        // GenericCrudService to deal with that.
        : this.crudService.delete(itemId, itemIndex, item)

      deleteOperation
        .then(() => {
          // Check for racing condition.
          if (parentKey === this.parentKey) {
            this.removeWithAnimation(item)
          }
          this.$emit('deleted', item)
        })
        .catch(error => { this.itemErrorMessage = extractErrorMessage(error) })
        .finally(() => { this.deleting = false })
    },
    enter (el, done) {
      // this.formValidator.$reset()
      done()
    },
    removeWithAnimation (item) {
      const itemKey = this.getItemKey(item)
      const index = this.items.findIndex(i => i === item)

      item._rowVariant = 'danger'
      this.items[index] = item

      // after css animation ends for danger item, remove it from list
      setTimeout(() => {
        this.items = this.items.toSpliced(index, 1)
        if (this.selectedItem && itemKey === this.getItemKey(this.selectedItem)) {
          this.selectedItem = null
        }
      }, 500) // delay should match css animation duration below
    },
    load () {
      this.loading = true
      this.loadedParentKey = true
      this.errorMessage = null
      this.items = []

      const parentKey = this.parentKey

      const listOperation = this.orchestrator
        ? this.$store.dispatch(`${this.orchestrator}/load`, true)
        : this.crudService.list()

      listOperation
        .then(data => {
          // Check for racing condition.
          if (parentKey === this.parentKey) {
            this.items = this.unpackResultItems(data)
          }
        })
        .catch(error => { this.errorMessage = extractErrorMessage(error) })
        .finally(() => { this.loading = false })
    },
    unpackResultItems (data) {
      let d = data
      if (_.isPlainObject(d) && 'results' in d) {
        d = d.results
      }
      if (_.isArray(d)) return d
      // Maybe it's a single object, and that's what caller expects?
      return data
    },
    shouldAllowEdit (item) {
      return _.isFunction(this.allowEdit) ? this.allowEdit(item) : !!this.allowEdit
    },
    shouldAllowDelete (item) {
      return _.isFunction(this.allowDelete) ? this.allowDelete(item) : !!this.allowDelete
    },
    itemsUploaded (items) {
      this.saving = true
      this.errorMessage = null

      return this.importItems(items)
        .then(data => {
          this.items = this.unpackResultItems(data)
        })
        // Don't catch error. The CsvFileUpload component should catch and display error message.
        .finally(() => { this.saving = false })
    }
  },
  created () {
    if (this.autoLoad) this.load()
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/_bootstrap-variables';

.master-detail-table {
  .icon {
    margin-right: 5px;
  }

  .audit-icon {
    margin-left: .5rem;
  }

  .item-list {
    margin-top: 10px;
    th.action {
      width: 50px;
    }

    :deep(.action) {
      > * {
        white-space: nowrap;
        margin-right: 10px;
        cursor: pointer;
      }

      .edit-item {
        color: map-get($theme-colors, primary);
      }
      .delete-item {
        color: map-get($theme-colors, danger);
        margin-left: 1rem;
      }

      .deleting-item {
        * {
          color: #555;
        }
      }
    }

    &.b-table-stacked {
      :deep(.action) {
        text-align: center;
        margin-top: 0;
      }
    }

    tr.table-danger {
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s 500ms, opacity 500ms linear;
    }

    :deep(table) {
      th, td {
        border-bottom-width: 0;
        padding: .75rem;
      }
    }
  }

  .error-message {
    &.item-error-message {
      margin-top: .5rem;
    }
  }

  .list-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
</style>
