import { helpers } from "@vuelidate/validators"

// see https://github.com/monterail/vuelidate/issues/242
// TODO: We may need to pass in extra reactive dependencies:
//       https://vuelidate-next.netlify.app/custom_validators.html#async-validators
// Note that this will not work inside helper.forEach, so don't try it!
// https://github.com/vuelidate/vuelidate/issues/1199
export function debounceAsyncValidator (validator, delay, deps) {
  let currentTimer = null
  let currentPromiseReject = null

  function debounce () {
    return new Promise((resolve, reject) => {
      currentTimer = setTimeout(() => {
        currentTimer = null
        currentPromiseReject = null
        resolve()
      }, delay)
      currentPromiseReject = reject
    })
  }

  function _validator (value, parentVm) {
    if (currentTimer) {
      currentPromiseReject(new Error('debounced'))
      clearTimeout(currentTimer)
      currentTimer = null
    }

    // What is `this` supposed to be?
    return validator.call(this, value, parentVm, debounce)
  }

  return helpers.withAsync(_validator, deps)
}

// function exampleValidator (value, debounce) {
//   if (!value) return true
//
//   return debounce()
//     .then(() => {
//       return new Promise((resolve, reject) => {
//         // some async stuff
//         setTimeout(resolve, 3000)
//       })
//     })
// }

export function debouncePromise (delay) {
  let timerId, lastResolve
  return () => {
    return new Promise(resolve => {
      if (timerId) {
        lastResolve(false)
        clearTimeout(timerId)
      }
      timerId = setTimeout(() => resolve(true), delay)
      lastResolve = resolve
    })
  }
}
