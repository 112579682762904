<template>
  <b-offcanvas
    v-model="forwardVisible" no-trap
    placement="end"
    no-header
    bg-variant="sidebar"
    text-variant="sidebar"
    class="form-drawer-sidebar"
    hide-backdrop
    :no-close-on-route-change="noCloseOnRouteChange"
    width="320px"
  >
    <div class="form-drawer-content">
      <slot></slot>

      <hr/>

      <slot name="footer-above-buttons"></slot>

      <slot name="footer-buttons">
        <b-button
          @click="save"
          :disabled="saveDisabled"
          variant="primary"
          class="action save"
          size="sm"
        >
          <font-awesome-icon :icon="saving ? 'circle-notch' : 'circle-check'" :spin="saving" />
          {{ saveText }}
        </b-button>
        <b-button
          @click="cancel"
          :disabled="cancelDisabled"
          variant="secondary"
          class="action cancel"
          size="sm"
        >
          <font-awesome-icon icon="circle-xmark" />
          {{ cancelText }}
        </b-button>
      </slot>

      <slot name="footer-below-buttons"></slot>

      <mask-spinner v-show="mask" />
    </div>
  </b-offcanvas>
</template>

<script>
import MaskSpinner from './MaskSpinner.vue'

export default {
  components: {
    MaskSpinner
  },
  data () {
    return {
      height: undefined
    }
  },
  props: {
    visible: Boolean,
    saveText: {
      type: String,
      default: 'Save'
    },
    cancelText: {
      type: String,
      default: 'Cancel'
    },
    saveDisabled: {
      type: Boolean,
      default: false
    },
    cancelDisabled: {
      type: Boolean,
      default: false
    },
    mask: {
      type: Boolean,
      default: false
    },
    saving: {
      type: Boolean,
      default: false
    },
    noCloseOnRouteChange: {
      type: Boolean,
      default: false
    }
  },
  emits: ['cancelForm', 'saveForm', 'update:visible'],
  computed: {
    forwardVisible: {
			get () {
				return this.visible
			},
			set (v) {
				this.$emit('update:visible', v)
			}
    }
  },
  methods: {
    save () {
      this.$emit('saveForm')
    },
    cancel () {
      this.$emit('cancelForm')
    }
  }
}
</script>
<style lang="scss">
  @import '../assets/scss/_bootstrap-variables';

  // TODO: need to define in shared resource
  $header-height: 55px;
  $footer-height: 50px;

  .form-drawer-sidebar {
    // top should be under header
    margin-top: $header-height;

    // and bottom should be above footer
    height: calc(100vh - #{$header-height} - #{$footer-height});

    border-left: 1px solid $gray-300;
    background-color: #eee;

    .form-drawer-content {
      padding-top: 10px;
      opacity: 1.0;

      // Specifing global for action selector enables styling for scope overrides
      .action {
        margin-top: 25px;
        &.cancel {
          margin-left: 10px;
        }
      }
    }
  }
</style>
