<template>
  <detail-form-modal v-slot="slotProps"
    resourceName="Briefing Type"
    auditResourceName="BRIEFINGTYPE"
    :crudService="crudService"
    :itemId="itemId"
    dialog-class="briefing-type-form-modal-dialog"
    @close="$emit('close')"
    @saved="$emit('saved', $event)"
    @deleted="$emit('deleted', $event)"
    @apply-grid-transaction="$emit('apply-grid-transaction', $event)"
  >
    <briefing-type-form v-bind="slotProps" />
  </detail-form-modal>
</template>
<script>
import DetailFormModal from '@/components/DetailFormModal.vue'
import BriefingTypeForm from './BriefingTypeForm.vue'
import crudService from './BriefingTypeService'

export default {
  name: 'BriefingTypeFormModal',
  components: {
    BriefingTypeForm,
    DetailFormModal
  },
  props: {
    itemId: Number
  },
  data () {
    return {
      crudService
    }
  }
}
</script>
<style lang="scss">
.briefing-type-form-modal-dialog {
  max-width: 600px !important;
}
</style>
