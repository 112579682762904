import { logEvent } from '@/services/clients/firebase'
import { extractErrorMessage, forceArray, jsonParseSafe } from '@/utils/misc'
import MemorizeReportModal from '@/components/report/MemorizeReportModal.vue'
import ManagesGridColumns from '@/mixins/ManagesGridColumns'
import { mapGetters, mapState } from 'vuex'
import { generatePresets } from '@/utils/DateRangePresets'
import moment from 'moment-timezone'
import _ from 'lodash'
import { payClassesAreIntercompatible } from '@/views/settings/payroll/util'
import { NavigationFailureType } from 'vue-router'

export default {
  mixins: [
    ManagesGridColumns
  ],
  props: {
    routeHooks: Object,
    fetchReport: Function,
    // It's imperative that parent components ensure that restoreReportPrefsToState
    // will bring forward all columns to proper available and default visible state.
    // After the restoreReportPrefsToState promise resolves, then AbstractReport will
    // initialize the columns preferences from the route view param.
    restoreReportPrefsToState: {
      type: Function
    },
    getReportPrefs: {
      type: Function
    },
    filtersAreValid: {
      type: Boolean,
      default: true
    },
    serverFiltersAreDirty: Boolean,
    customizeIsValid: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      prefsRestored: false,
      loading: false,
      loadingFromRoute: false,
      errorMessage: null,
      rowItems: [],
      supplementalData: null,
      advancedFilterVisible: false,
      customizePinned: false,
      sortColumnsInternal: [],
      customSubtitles: []
    }
  },
  computed: {
    ...mapState(['organizationName', 'timezone']),
    ...mapGetters(['payPeriodSettings', 'payrollEnabled']),
    ...mapGetters('formatPreferences', ['formatNaiveDateDay']),
    ...mapGetters('payClasses', {
      payClassesById: 'itemsById'
    }),
    ...mapState('size', ['clientHeight']),
    activeRoute () {
      return this.routeHooks.activeRoute
    },
    reportParams () {
      return jsonParseSafe(_.get(this.activeRoute, 'params.view'), {})
    },
    reportTitle () {
      // Look for memorized report title in route.
      // Or else use default title for this report.
      return this.reportParams.title || _.get(this.activeRoute, 'meta.label')
    },
    exportFileName () {
      // TODO: Include date params, but formatted differently than subtitle.
      return `${this.reportTitle} - ${this.organizationName}`
    },
    customParams () {
      const params = {
        title: this.reportTitle,
        timezone: this.timezone,
        ...this.reportParams
      }
      const dateRange = this.reportDateRange
      if (dateRange) {
        if (dateRange[1] && !params.from) {
          params.from = dateRange[1]
        }
        if (dateRange[1] && !params.to) {
          params.to = dateRange[2]
        }
      }

      return params
    },
    referencePayClass () {
      const payClasses = forceArray(this.reportParams.payClass)
        .flatMap(payClassId => this.payClassesById[payClassId] ? [this.payClassesById[payClassId]] : [])
      const referencePayClass = payClasses.length > 0 && payClassesAreIntercompatible(payClasses)
        ? payClasses[0] : null
      return referencePayClass
    },
    presets () {
      const payPeriodSettings = this.payPeriodSettings(this.referencePayClass)
      return generatePresets({
        timezone: this.timezone,
        payPeriodType: payPeriodSettings.payPeriodType,
        payPeriodStarts: payPeriodSettings.payPeriodStarts,
        // Include all presets so we can display whatever it is.
        // We can still choose not to include future presets on the date range picker configuration.
        includeFuture: true
      })
    },
    reportDateRange () {
      const reportParams = this.reportParams
      if (!_.isEmpty(reportParams)) {
        if (reportParams.commondates) {
          const preset = this.presets[reportParams.commondates]
          if (preset) {
            const start = preset.start()
            const end = preset.end()
            return [preset.label, preset.start(), preset.end()]
          }
        }

        if (reportParams.date) {
          return [null, moment(reportParams.date), null]
        }

        const startDate = reportParams.from || reportParams.start
        const endDate = reportParams.to || reportParams.end
        if (startDate || endDate) {
          return [null, moment(startDate), moment(endDate)]
        }
      }

      return null
    },
    reportDateDisplay () {
      const dateRange = this.reportDateRange

      if (dateRange) {
        return this.formatDateDisplay(...dateRange)
      }

      // We'll assume if there is no date range, then it's today.
      // That will handle Roll Call report. We'll have to make sure
      // that assumption holds for all reports we may implement in
      // the future.
      // TODO: Doesn't work if audit report doesn't filter dates.
      // TODO: But maybe it's ok in terms of informing on when report was run?
      return this.formatDateDisplay('Today')
    },
    subtitles () {
      return [
        this.organizationName,
        this.reportDateDisplay,
       // TODO: Derive subtitles for: filters, groupings.
       // TODO: Similar to legacy getSubtitles.getSubtitles(),
       // TODO: but it may be more complicated to access display
       // TODO: names for filters, e.g., worker name rather than id.
       ...this.customSubtitles
      ]
    }
  },
  watch: {
    activeRoute (newRoute, oldRoute) {
      // I don't know why, but this watcher can fire even if the
      // new and old routes are the same. It might have to do with
      // that we bind to routeHooks, which recomputes activeRoute
      // even when it doesn't change. So we only handle new route
      // if it actually changes. If we didn't check this condition,
      // then this component would re-fetch the report when navigating
      // away.
      if (newRoute !== oldRoute) {
        this.handleNewRoute(newRoute)
      }
    },
    'routeHooks.routeUpdateNext' (next) {
      // we don't need to guard route updates
      next()
    },
    'routeHooks.routeLeaveNext' (next) {
      this.routeLeaving = true
      this.advancedFilterVisible = false
      next()
    },
    advancedFilterVisible (advancedFilterVisible) {
      if (!advancedFilterVisible) this.customizePinned = false
    }
  },
  methods: {
    handleNewRoute (newRoute) {
      if (!newRoute || !newRoute.params.view) {
        this.prefsRestored = true
        return // TODO: clear pref state?
      }

      const view = newRoute.params.view
      let reportPrefs
      try {
        reportPrefs = JSON.parse(decodeURIComponent(view))
      } catch (e) {
        console.warn(`Invalid filter string on url: ${view}`)
        this.prefsRestored = true
        return
      }

      if (reportPrefs.reportKey) {
        logEvent('fetch_report_key')
      }

      // When loading the web page directly to this report's route, it runs the report
      // automatically like a memorized report, as it should. After the report runs the
      // first time, if the user clicks "Run Report" again, it makes two fetch requests
      // for the report. It seems it does this because the activeRoute watcher fires twice:
      // once with the route path url-decoded, and a second time with it url-encoded. I'm
      // not sure why it does that, but by adding this loading check here, we can prevent
      // it making the second fetch request.
      if (this.loadingFromRoute) return
      this.loadingFromRoute = true

      // We always need format preferences.
      this.$store.dispatch('formatPreferences/load')
        .then(() => this.restoreReportPrefsToState(reportPrefs))
        // TODO: Show spinner while restoring prefs to state, since it can be asynchronous and take some time.
        // TODO: Validate.
        // Wait another tick for computed properties to update after restoring state
        .then(this.$nextTick)
        .then(() => {
          // Do not restore columns until after rest of state, because not all columns might be available
          // until certain state is loaded. For example, pay code columns won't be valid until the pay codes
          // are loaded.
          this.sortColumnsInternal = reportPrefs.sort
          this.columnPrefsUpdated(reportPrefs.columns)
          this.prefsRestored = true
        })
        .then(this.$nextTick)
        .then(this.runReportFromState)
        .catch(error => { this.errorMessage = extractErrorMessage(error) })
        .finally(() => { this.loadingFromRoute = false })

    },
    runReportFromState () {
      // Don't run memorized report if filters are not valid.
      if (!this.filtersAreValid) {
        this.notifyReportComplete('Customization options are invalid')
        return
      }

      this.loading = true
      this.errorMessage = null
      this.rowItems = []
      this.supplementalData = null

      // TODO: Show modal to send by email? Probably fetch report would need to return a promise with
      // TODO: an extra method to subscribe by email? Or a report key that can be subscribed to directly here?
      this.fetchReport()
        .then(data => {
          if (_.isPlainObject(data)) {
            this.rowItems = data.items
            this.supplementalData = data.data?.supplementalData
          } else {
            this.rowItems = data
          }
          console.log('Report is fetched')

          // TODO: Should we listen to a gridApi render event before calling notifyReportComplete?
          setTimeout(() => {
            this.notifyReportComplete()
          }, 50)
        })
        .catch(error => {
          this.errorMessage = extractErrorMessage(error)
          this.notifyReportComplete(this.errorMessage)
        })
        .finally(() => { this.loading = false })
    },
    runReport () {
      // put report parameters on route, so that route watcher can actually run the report
        this.$router.push({
          name: this.$route.name,
          params: { view: JSON.stringify({
            ...this.getReportPrefs(),
            columns: this.columnPrefObjects,
            sort: this.sortColumnsInternal
          }) }
        })
        .then(result => {
          if (!result) return
          if (result.type === NavigationFailureType.duplicated) {
            // Refresh report by calling runReportFromState() directly.
            this.runReportFromState()
          } else {
            return Promise.reject(result)
          }
        })
    },
    // clearReport can be called by specific reports via $refs,
    // e.g., PayRunAnalysisReport clears report if its isFilteringOrGroupingWorker
    // computed property value changes. It's a bit of an anti-pattern. Perhaps
    // vue 3 composition api may help.
    clearReport () {
      this.rowItems = []
    },
    toggleAdvancedFilter () {
      this.advancedFilterVisible = !this.advancedFilterVisible
    },
    memorize () {
      const reportId = this.$route.meta.link
      const prefs = this.getReportPrefs()
      prefs.columns = this.columnPrefs
      this.showModal({
        component: MemorizeReportModal,
        props: {
          reportId,
          prefs
        }
      })
    },
    formatDateDisplay (label, start, end) {
      const dateToken = (() => {
        if (start) {
          if (end) {
            return start.isSame(end)
              ? this.formatNaiveDateDay(start)
              : `${this.formatNaiveDateDay(start)} - ${this.formatNaiveDateDay(end)}`
          } else {
            return this.formatNaiveDateDay(start)
          }
        } else if (end) {
          return this.formatNaiveDateDay(end)
        } else {
          // should be today
          return this.formatNaiveDateDay()
        }
      })()
      return label ? `${label} (${dateToken})` : dateToken
    },
    exportReport(exportType, options) {
      switch (exportType) {
        case 'custom':
          return this.exportToCustom(options.customOptionId, true)
        case 'csv':
          return this.csv().then(title => ({ title }))
        case 'csvflat':
          return this.csv(true).then(title => ({ title }))
        case 'excel':
          return this.excel().then(title => ({ title }))
        case 'pdf':
          return this.pdfWithPreferences(options).then(title => ({ title }))
        case 'print':
          return this.printWithPreferences(options, true)
        default:
          console.warn(`Unrecognized export type "${exportType}" passed to exportReport`)
          return Promise.reject(new Error('Unrecognized export type'))
      }
    },
    notifyReportComplete (errorMessage) {
      // In headless mode, we need to notify the controller, and provide a function to export.
      try {
        if (errorMessage) {
          console.warn(`Failed to run report due to error: ${errorMessage}`)
        }
        document.dispatchEvent(new CustomEvent('reportComplete', {
          detail: {
            error: errorMessage,
            exportReport: errorMessage ? undefined : this.exportReport
          }
        }))
      } catch (error) {
        // Probably IE 11.
        console.warn('Failed to dispatch custom reportComplete event', error)
      }
    },
    onCustomizeSubtitles (subtitles) {
      this.customSubtitles = subtitles
    }
  },
  beforeMount () {
    if (this.payrollEnabled) this.$store.dispatch('payClasses/load')
  },
  mounted () {
    // Try to mitigate grid and column menu not ready yet.
    // TODO: Is the following comment still an issue?
    // TODO: We need a better solution, because visible columns not set properly.
    setTimeout(() => this.handleNewRoute(this.activeRoute), 50)
  }
}
