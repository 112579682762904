<template>
  <Calendar
    v-bind="$attrs"
    v-model="forwardValue"
    :dateFormat="dateFormat"
    showIcon
    iconDisplay="input"
    :panelClass="['date-picker-overlay', insideBootstrapOverlay ? 'inside-bs' : null]"
  />
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import { modelDateFormat } from '@/utils/misc'

export default {
  name: 'DatePicker',
  inheritAttrs: false,
  props: ['modelValue', 'insideBootstrapOverlay'],
  emits: ['update:modelValue'],
  computed: {
    ...mapGetters({
      dateFormat: 'formatPreferences/pvDateFormat'
    }),
    forwardValue: {
			get () {
				return this.modelValue ? moment(this.modelValue).toDate() : null
			},
			set (v) {
				this.$emit('update:modelValue', v ? moment(v).format(modelDateFormat) : null)
			}
    }
  }
}
</script>

<style lang="scss" scoped>
.p-calendar {
  font-size: .9rem;
  :deep(.p-inputtext) {
    width: 150px;
    padding: 0.25rem 0.5rem;
    border: 1px solid rgb(204, 204, 204);
    border-radius: 4px;
  }
}
</style>

<style lang="scss">
.date-picker-overlay {
  &.inside-bs {
    // above bootstrap modal containing it
    z-index: 1156 !important;
  }
}
</style>
