import { createRestCrudService } from '@/services/GenericCrudService'
import restClient from '@/services/clients/rest'
import { MaxQueryBranches } from '@/utils/misc'
import { chunk, concat } from 'lodash'

const resourceName = 'Time Off Entry'
const resourcePath = 'timeoff/entries'
const crudService = createRestCrudService(resourceName, resourcePath)

export default Object.assign(crudService, {
  listAsync (params) {
    return restClient.listAsync(resourcePath, params)
  },
  async setWorkerEntries (workerId, entries) {
    try {
      const response = await restClient.put(`${resourcePath}/workers/${workerId}`, entries)
      return response.data
    } catch (error) {
      console.warn(`Error setting worker entries for ${workerId}}`, error)
      throw error
    }
  },

  async setMultiWorkerEntries (applyMode, workerParams, entries) {
    try {
      const method = applyMode === 'replace' ? 'put' : 'post'
      const response = await restClient[method](`${resourcePath}/batch`, {
        workerParams,
        entries
      })
      return response.data
    } catch (error) {
      console.warn(`Error setting worker entries in batch`, error)
      throw error
    }
  },
  // query is an alternative to list using POST instead of GET to avoid
  // an error if the query string is too long due to many ids.
  // The backend limits query string to 2000 characters, and also many other
  // web servers have hard limits on max size of http headers.
  // We also split into multiple queries due to a backend limit.
  async query (params, workerIds) {
    // We split into chunks half the size of MaxQueryBranches, because time off entries
    // have separate subqueries for paid and unpaid time off.
    // TODO: Is there too much backend detail exposed to frontend?
    const workerIdChunks = chunk(workerIds, MaxQueryBranches / 2)

    const dataChunks = await Promise.all(
      workerIdChunks.map(async workerIdChunk => {
        try {
          const response = await restClient.post(`${resourcePath}/query`, { worker: workerIdChunk }, { params })
          return response.data.results
        } catch (error) {
          console.warn(`Error querying ${resourceName}`, error)
          throw error
        }
      })
    )
    return concat(...dataChunks)
  },
  async applyBulkAction (itemIds, actionData) {
    try {
      const response = await restClient.post(`${resourcePath}/bulk-action`, {
        itemIds,
        actionData
      })
      return response.data
    } catch (error) {
      console.warn(`Error submitting ${resourceName} bulk action`, error, actionData)
      throw error
    }
  }
})
