
// https://gist.github.com/colemanw/9c9a12aae16a4bfe2678de86b661d922
const MIME_TYPE_ICON_MAP = {
  // Media
  image: 'image',
  audio: 'audio',
  video: 'video',
  // Documents
  'application/pdf': 'pdf',
  'application/msword': 'word',
  'application/vnd.ms-word': 'word',
  'application/vnd.oasis.opendocument.text': 'word',
  'application/vnd.openxmlformatsfficedocument.wordprocessingml': 'word',
  'application/vnd.ms-excel': 'excel',
  'application/vnd.openxmlformatsfficedocument.spreadsheetml': 'excel',
  'application/vnd.oasis.opendocument.spreadsheet': 'excel',
  'application/vnd.ms-powerpoint': 'powerpoint',
  'application/vnd.openxmlformatsfficedocument.presentationml': 'powerpoint',
  'application/vnd.oasis.opendocument.presentation': 'powerpoint',
  'text/plain': 'alt',
  'text/html': 'code',
  'application/json': 'code',
  // Archives
  'application/gzip': 'archive',
  'application/zip': 'archive',
}

export function resolveFontAwesomeIconForMimeType (mimeType) {
  let icon
  if (mimeType in MIME_TYPE_ICON_MAP) {
    icon = MIME_TYPE_ICON_MAP[mimeType]
  } else {
    icon = mimeType && MIME_TYPE_ICON_MAP[(mimeType).split('/')[0]]
  }
  return icon ? `file-${icon}` : 'file'
}
