<template>
  <key-multiselect
    v-bind="$attrs"
    v-model="forwardValue"
    :options="options"
    taggable
    label="label"
    track-by="value"
    placeholder=""
    select-label=""
    deselect-label=""
  >
  </key-multiselect>
</template>
<script>
import KeyMultiselect from '@/components/KeyMultiselect.vue'

export default {
  name: 'RelationshipSelector',
  components: {
    KeyMultiselect
  },
  inheritAttrs: false,
  props: ['modelValue'],
  emits: ['update:modelValue'],
  computed: {
    forwardValue: {
      get () {
        return this.modelValue
      },
      set (v) {
        this.$emit('update:modelValue', v)
      }
    }
  },
  data () {
    return {
      options: [
        {
          value: 'spouse',
          label: 'Spouse'
        },
        {
          value: 'child',
          label: 'Child'
        },
        {
          value: 'mother',
          label: 'Mother'
        },
        {
          value: 'father',
          label: 'Father'
        },
        {
          value: 'brother',
          label: 'Brother'
        },
        {
          value: 'sister',
          label: 'Sister'
        },
        {
          value: 'friend',
          label: 'Friend'
        },
        {
          value: 'relative',
          label: 'Relative'
        }
      ]
    }
  }
}
</script>
