<template>
  <div>
    <b-table
        striped
        :fields="fields"
        :items="balances"
        class="item-list"
        show-empty
        empty-html="There are no <code>Time Off Balances</code>"
        responsive
    >
      <template v-slot:cell(balanceDuration)="data">
        {{ formatMinutesAsDuration(data.item.balanceDuration) }} hours
      </template>

      <template v-slot:cell(action)="data">
        <font-awesome-icon icon="edit" class="icon"
          v-if="canManageTimeOff"
          @click="openLedger(data.item)"
        />
      </template>
    </b-table>
  </div>
</template>
<script>
import _ from 'lodash'
import { mapGetters, mapState } from 'vuex'
import { useModalController } from 'bootstrap-vue-next'
import { computed, ref } from 'vue'

export default {
  name: 'TimeOffBalances',
  setup () {
    return {
      showModal: useModalController().show
    }
  },
  props: {
    workerId: Number,
    workerName: String,
    value: Object
  },
  data () {
    return {
      fields: [
        { key: 'timeOffCodeName', label: 'Time Off Code' },
        { key: 'balanceDuration', label: 'Balance' },
        { key: 'action', label: 'Ledger', class: 'action' }
      ]
    }
  },
  computed: {
    ...mapState({
      timeOffCodes: state => state.timeOffCodes.items
    }),
    ...mapGetters([
      'canViewWorkerTimeOffPolicy',
      'canManageTimeOff'
    ]),
    ...mapGetters({
      formatMinutesAsDuration: 'formatPreferences/formatMinutesAsDuration'
    }),
    timeOffCodesById () {
      return _.keyBy(this.timeOffCodes, 'id')
    },
    balances () {
      return _.isEmpty(this.value)
        ? []
        : _.orderBy(
          Object.entries(this.value)
            .map(([timeOffCodeId, balanceDuration]) => ({
              timeOffCodeId: timeOffCodeId,
              timeOffCodeName: _.get(this.timeOffCodesById[timeOffCodeId], 'name'),
              balanceDuration
            })),
          'timeOffCodeName'
        )
    }
  },
  methods: {
    openLedger (item) {
      // Load modal component as async chunk, because it loads sync fusion grid, which is a lot of code.
      import(/* webpackChunkName: "time-off-ledger-modal" */ './TimeOffLedgerModal.vue')
        .then(module => {
          const component = module.default
          const balanceDuration = ref(item.balanceDuration)
          this.showModal({
            component,
            props: computed(() => ({
              workerId: this.workerId,
              workerName: this.workerName,
              timeOffCodeId: parseInt(item.timeOffCodeId),
              timeOffCodeName: _.get(this.timeOffCodesById[item.timeOffCodeId], 'name'),
              balanceDuration: balanceDuration.value,
              onBalanceChanged: bd => {
                // Update in modal header.
                balanceDuration.value = bd
                // Update in original employee data object.
                // We once saw a bugsnag error that this.value was undefined here, so we'll check for that.
                if (this.value) this.value[item.timeOffCodeId] = bd
                // Emit event to parent to re-render from updated employee data object.
                this.$emit('time-off-balances-updated')
              }
            }))
          })
        })
    }
  },
  mounted () {
    if (this.canViewWorkerTimeOffPolicy) {
      this.$store.dispatch('timeOffCodes/load')
    }
  }
}
</script>
<style lang="scss" scoped>
  @import '@/assets/scss/_bootstrap-variables';

  :deep(.item-list) {
    .action {
      svg {
        color: map-get($theme-colors, primary);
        cursor: pointer;
      }
    }
  }
</style>
